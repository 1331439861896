import { AuthState } from "./types";

export const defaultState = (): AuthState => {
  return {
    isLoginMfa: false,
    isLoggedIn: false,
    hasValidLicense: null,
    loadedInitData: false,
    user: null,
    initData: null
  };
};

export const state = defaultState();
