import { FormBlockField, FormBlockFieldCondition } from "../../types";
import { getFieldValueByKey } from "./getFieldValueByKey";
import { conditionsEvaluateToTrueV2 } from "./shouldShowFieldV2";

const __testCondition = (
  condition: FormBlockFieldCondition,
  existingValue: string | number | Date | boolean
): boolean => {
  const { operator, value: compareToValue } = condition;
  switch (operator) {
    case "equals":
      if (
        typeof existingValue === "string" &&
        typeof compareToValue === "string"
      ) {
        return existingValue.toLowerCase() === compareToValue.toLowerCase();
      }
      return existingValue === compareToValue;
    case "gt":
      if (
        typeof existingValue === "number" &&
        typeof compareToValue == "number"
      ) {
        return compareToValue > existingValue;
      }
      break;
    case "lt":
      if (
        typeof existingValue === "number" &&
        typeof compareToValue == "number"
      ) {
        return compareToValue < existingValue;
      }
      break;
    case "not":
      if (
        typeof existingValue === "string" &&
        typeof compareToValue == "string"
      ) {
        return existingValue.toLowerCase() !== compareToValue.toLowerCase();
      }
      return existingValue !== compareToValue;
      break;
    case "includes":
      if (
        typeof existingValue === "string" &&
        typeof compareToValue == "string"
      ) {
        return existingValue
          .toLowerCase()
          .includes(compareToValue.toLowerCase());
      }
      if (Array.isArray(existingValue)) {
        return existingValue.includes(compareToValue);
      }
      break;
    case "exists":
      return !!existingValue === compareToValue;
    case "hasValue":
      if (typeof existingValue === "undefined") return false;
      if (typeof existingValue === "string") {
        if (existingValue.trim() === "") return false;
      }
      if (Array.isArray(existingValue)) {
        return existingValue.length > 0 === compareToValue;
      }
      return true;
  }
  return false;
};

const __evaluateAndConditions = (
  conditions: FormBlockFieldCondition[],
  formValues: Record<string, any>
) => {
  if (!conditions.length) return true;
  for (const condition of conditions) {
    const { field } = condition;
    const value = getFieldValueByKey(field, formValues);
    // if any fail, it fails.
    if (!__testCondition(condition, value)) {
      return false;
    }
  }
  return true;
};

const __evaluateOrConditions = (
  conditions: FormBlockFieldCondition[],
  formValues: Record<string, any>
) => {
  if (!conditions.length) return true;
  for (const condition of conditions) {
    const { field } = condition;
    const value = getFieldValueByKey(field, formValues);
    // if any pass, it passes.
    if (__testCondition(condition, value)) {
      return true;
    }
  }
  return false;
};

export const shouldShowField = (
  fieldSchema: FormBlockField,
  formValues: Record<string, any>
): any => {
  const { conditions = {}, conditionsV2 = {} } = fieldSchema.properties as any;
  if (Object.keys(conditionsV2).length) {
    return conditionsEvaluateToTrueV2(conditionsV2, formValues);
  }
  return conditionsEvaluateToTrue(conditions, formValues);
};

export const conditionsEvaluateToTrue = (
  conditions: { or: any[]; and: any[] },
  values: Record<string, any>
) => {
  const { or, and } = conditions;

  // no conditions
  if (!or && !and) {
    return true;
  }

  if (or && and) {
    return (
      __evaluateAndConditions(and, values) && __evaluateOrConditions(or, values)
    );
  }

  if (and) {
    return __evaluateAndConditions(and, values);
  }

  if (or) {
    return __evaluateOrConditions(or, values);
  }

  return true;
};
