import {
  documentsDescriptionUser,
  profilesDescriptionUser,
  messagesDescriptionUser
} from "./util";

export const menu = {
  usersLinkText: "Users",
  agentsLinkText: "Agents",
  changeRequestLinkText: "Change/Cancellation Requests",
  communicationsLinkText: "Communications",
  uploadsLinkText: "Uploads",
  toolsLinkText: "Tools",
  documentsLinkText: "Documents",
  documentsDescriptionUser: documentsDescriptionUser,
  documentsSublinks2019Tax: "2019 Tax Documents",
  profilesLinkText: "Investment Profiles",
  profilesDescriptionUser: profilesDescriptionUser,
  messagesLinkText: "Messages",
  messagesDescriptionUser: messagesDescriptionUser,
  propertiesLinkText: "Properties",
  propertiesDescriptionUser: "properties listing",
  userSettingsLinkText: "User Settings",
  userSettingsDescriptionUser: "Manage Your Account",
  userSettingsInvestmentProfile: "Investment Preferences",
  agencyAdminAgencyLinkText: "Agency"
};
