export function removeEmptyFields(
  record: Record<string, any>
): Record<string, any> {
  if (record && Object.keys(record)) {
    for (const key of Object.keys(record)) {
      if (
        (typeof record[key] === "string" && record[key].trim() === "") ||
        (typeof record[key] !== "boolean" && !record[key])
      ) {
        delete record[key];
      }
    }
  }
  return record;
}
