import { DefineGetters } from "vuex-type-helper";
import { CountyGetters, CountyState, ICounty } from "./types";

export const getters: DefineGetters<CountyGetters, CountyState> = {
  getTotalRecords: ({ links, counties }: CountyState) =>
    links && links.totalRecords ? links.totalRecords : counties.length,
  getMaxQuerySize: ({ links, counties }: CountyState) =>
    links && links.maxQueryLimit ? links.maxQueryLimit : counties.length,
  getCountyById: (state: CountyState) => (countyId: string) =>
    state.counties.find((county: ICounty) => county._id === countyId),
  hasProhibitedCounty: (state: CountyState) =>
    state.counties.some((county: ICounty) => county.prohibitBinding)
};
