import { DefineGetters } from "vuex-type-helper";
import { AgentReportGetters, AgentReportState } from "./types";

export const getters: DefineGetters<AgentReportGetters, AgentReportState> = {
  agentreports(state: AgentReportState) {
    return state.agentreports;
  },
  getTotalRecords: ({ links, agentreports }: AgentReportState) =>
    links && links.totalRecords ? links.totalRecords : agentreports.length,
  getAgentReportLinks: ({ links }) => links
};
