import defaultsDeep from "lodash/defaultsDeep";
import cloneDeep from "lodash/cloneDeep";
import { billingChangeRequestBase } from "@/helpers/defaultObjects";
import { IBillingChangeRequest } from "@/helpers/storageFormBaseInterface";

export function getBillingChangeRequestDefaults(
  billingChangeRequest: Partial<IBillingChangeRequest>
): IBillingChangeRequest {
  return defaultsDeep(
    cloneDeep(billingChangeRequest),
    Object.assign({}, billingChangeRequestBase())
  );
}
