
import { agencyMapActions, agencyMapState } from "@/store/modules/agency";
import { userMapGetters } from "@/store/modules/user";
import { IAgency } from "@/store/modules/agency/types";
import { cloneDeep, uniqBy } from "lodash";
import Vue from "vue";
interface ISelectAgencies {
  agencies: any[];
  defaultFilter: Record<string, any>;
}
export default Vue.extend({
  name: "search-select-agencies",
  props: {
    value: {
      type: [Number, String, Array, Boolean],
      default: ""
    },
    label: {
      type: String,
      default: "",
      required: false
    },
    preFetch: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: "Type to search agencies"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      required: false,
      default: ""
    },
    descriptionHTML: {
      type: String,
      required: false,
      default: ""
    },
    showOnlyAgencyCodes: {
      type: Boolean,
      required: false
    },
    useAgencyCodeAsValue: {
      type: Boolean,
      required: false
    }
  },
  data(): ISelectAgencies {
    return {
      agencies: [],
      defaultFilter: {
        deleted: false,
        status: "approved"
      }
    };
  },
  mounted() {
    if (this.preFetch) {
      this.remoteMethod("");
    }
  },
  watch: {
    value: {
      handler(value) {
        if (
          value &&
          value.length &&
          !this.agencies.find((val: any) => val._id === value)
        ) {
          this.getAgenciesWithValue();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...agencyMapActions(["getAgencies"]),
    getAgencyField(fieldName: string) {
      const { value } = this as any;
      if (typeof value === "string") {
        return [value];
      }
      const values = [];
      if (Array.isArray(value)) {
        for (const agency of value) {
          if (typeof agency === "string") {
            values.push(agency);
          } else {
            if (agency[fieldName]) {
              values.push(agency[fieldName]);
            }
          }
        }
      }
      return values;
    },
    async getAgenciesWithValue() {
      try {
        let params = {};
        if (this.useAgencyCodeAsValue) {
          params = {
            agencyCode__in: this.getAgencyField("agencyCode")
          };
        } else {
          params = {
            _id__in: this.getAgencyField("_id")
          };
        }

        const agency: any = await this.getAgencies({
          query: {
            ...params,
            _id__in: this.getAgencyField("_id"),
            ...this.defaultFilter,
            ...(this.getEditingUser &&
              this.getEditingUser.role === "ezlynx" && {
                allowEzlynxRating: true
              })
          },
          merge: true
        });
        this.agencies = uniqBy([...this.agencies, ...agency], "_id");
      } catch (error) {
        this.$appNotifyError("Error fetching agencies");
        this.$bugSnagClient.notify(error);
      }
    },
    async remoteMethod(searchTerm: string) {
      try {
        if (!searchTerm || (searchTerm && searchTerm.length < 4)) {
          return;
        }

        this.agencies = await this.getAgencies({
          query: {
            __query: searchTerm,
            ...this.defaultFilter,
            ...(this.getEditingUser &&
              this.getEditingUser.role === "ezlynx" && {
                allowEzlynxRating: true
              })
          },
          merge: true
        });
      } catch (error) {
        this.$appNotifyError("Error fetching agencies");
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    ...agencyMapState(["makingApiRequest", "editing"]),
    ...userMapGetters(["getEditingUser"]),
    agencyListOptions(): any {
      const clonedAgencies = cloneDeep(this.agencies).map((agency: IAgency) => {
        const fieldLabel = this.showOnlyAgencyCodes
          ? agency.agencyCode
          : `${agency.agencyName} - ${agency.agencyCode}`;
        const fieldValue = this.useAgencyCodeAsValue
          ? agency.agencyCode
          : agency._id;
        return {
          label: fieldLabel,
          value: fieldValue,
          key: fieldValue
        };
      });
      if (this.editing && this.editing._id) {
        const agenciesWithoutCurrentAgency = clonedAgencies.filter(
          (agency: any) => agency.key !== this.editing._id
        );
        return agenciesWithoutCurrentAgency;
      }
      return clonedAgencies;
    }
  }
});
