import {
  IQuoteCountyValidation,
  QuoteCountyValidationActions,
  QuoteCountyValidationState,
  QuoteCountyValidationMutations,
  QuoteCountyValidationGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import { GenericCreateOne, GenericGetMany } from "@/store/helpers/actions";
import QuoteCountyValidationService from "@/services/quoteCountyValidation.service";

export const actions: DefineActions<
  QuoteCountyValidationActions,
  QuoteCountyValidationState,
  QuoteCountyValidationMutations,
  QuoteCountyValidationGetters
> = {
  createQuoteCountyValidation: GenericCreateOne<
    QuoteCountyValidationState,
    QuoteCountyValidationGetters,
    QuoteCountyValidationActions,
    QuoteCountyValidationMutations,
    IQuoteCountyValidation
  >(QuoteCountyValidationService, types.SET_ITEM as any),
  getQuoteCountyValidations: GenericGetMany<
    QuoteCountyValidationState,
    QuoteCountyValidationGetters,
    QuoteCountyValidationActions,
    QuoteCountyValidationMutations
  >(
    QuoteCountyValidationService,
    "quoteCountyValidations",
    types.SET_ITEM as any
  ),
  async getQuoteCountyValidation({ commit }, quoteCountyValidationId) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const {
        data
      } = await QuoteCountyValidationService.getQuoteCountyValidation(
        quoteCountyValidationId
      );
      commit(types.SET_ITEM, data);
      commit(types.SET_STATE, { key: "lastUpdate", value: new Date() });
      return data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  async deleteQuoteCountyValidation({ commit }, quoteCountyValidationId) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const {
        data
      } = await QuoteCountyValidationService.deleteQuoteCountyValidation(
        quoteCountyValidationId
      );
      commit(types.DELETE_ITEM, data.id);
      commit(types.SET_STATE, { key: "lastUpdate", value: new Date() });
      return data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  async updateQuoteCountyValidation(
    { commit },
    payload: { id: string; update: any }
  ) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const {
        data
      } = await QuoteCountyValidationService.updateQuoteCountyValidation(
        payload.id,
        payload.update
      );
      commit(types.SET_ITEM, data);
      commit(types.SET_STATE, { key: "lastUpdate", value: new Date() });
      return data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  }
};
