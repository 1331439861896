import {
  ISetting,
  SettingActions,
  SettingState,
  SettingMutations,
  SettingGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericDeleteOne,
  GenericUpdateOne,
  GenericGetOne,
  GenericMakeRequest
} from "@/store/helpers/actions";
import SettingService from "@/services/setting.service";
import store from "@/store";

export const actions: DefineActions<
  SettingActions,
  SettingState,
  SettingMutations,
  SettingGetters
> = {
  createSetting: GenericCreateOne<
    SettingState,
    SettingGetters,
    SettingActions,
    SettingMutations,
    ISetting
  >(SettingService, types.SET_ITEM as any),
  getSettings: GenericGetMany<
    SettingState,
    SettingGetters,
    SettingActions,
    SettingMutations
  >(SettingService, "settings", types.SET_ITEM as any),
  getSetting: GenericGetOne<
    SettingState,
    SettingGetters,
    SettingActions,
    SettingMutations
  >(SettingService, types.SET_ITEM as any),
  deleteSetting: GenericDeleteOne<
    SettingState,
    SettingGetters,
    SettingActions,
    SettingMutations
  >(SettingService, types.DELETE_ITEM as any),
  updateSettings: GenericUpdateOne<
    SettingState,
    SettingGetters,
    SettingActions,
    SettingMutations,
    { id: string; update: Partial<ISetting> }
  >(SettingService, types.SET_ITEM as any),
  updateDefaultSettings: GenericMakeRequest<
    SettingState,
    SettingGetters,
    SettingActions,
    SettingMutations
  >(
    SettingService.updateDefaultSettings.bind(SettingService),
    types.SET_ITEM as any,
    {
      after(responseData: any) {
        const initData = store.state.auth["initData"];
        store.commit(`auth/${types.SET_STATE}`, {
          key: "initData",
          value: { ...initData, ...responseData }
        });
      }
    }
  ),
  updateInfoMessageSettings: GenericMakeRequest<
    SettingState,
    SettingGetters,
    SettingActions,
    SettingMutations
  >(
    SettingService.updateInfoMessageSettings.bind(SettingService),
    types.SET_ITEM as any,
    {
      after(commit: any, responseData: any) {
        const initData = store.state.auth["initData"];
        store.commit(`auth/${types.SET_STATE}`, {
          key: "initData",
          value: { ...initData, ...responseData.data }
        });
      }
    }
  )
};
