export const fields = {
  passwordFieldLabel: "Password",
  passwordFieldValidateError: (num: number) => `Minimum ${num} characters`,
  emailFieldLabel: "Email Address",
  emailFieldValidateError: "Please enter valid email address",
  confirmPasswordFieldLabel: "Confirm Password",
  confirmPasswordFieldValidateError: `Passwords do not match`,
  showPasswordLabel: "Show Password",
  firstNameLabel: "First Name",
  lastNameLabel: "Last Name",
  displayPhoneNumberLabel: "Primary Phone Number",
  displayNameLabel: "Display Name",
  primaryPhoneNumberLabel: "Primary Phone Number",
  physicalAddressLabel: "Physical Address",
  secondaryPhoneNumberLabel: "Secondary Phone Number",
  roleLayNameLabel: "Display Name",
  primarbel: "Role",
  statusLabel: "Status",
  titleLabel: "Title",
  nameFieldValidateError: "Insured name should be at least 5 characters",
  category: "Category",
  collection: "Collection",
  licenseNumberLabel: "License Number",
  licenseNumberExpirationDateLabel: "License Number Expiration Date",
  companyName: "Please Company Name is Required",
  site: "Please Select Select a site",
  companyNumber: "Please Company Number is Required",
  companyNAICCD: "Please Company NAICCD Number is Required",
  numberError: "Please this Field Must be a Number",
  companymCompany: "Please Company mCompany Number is Required",
  companyLineOfBusiness: "Please Select Company Line Of Business",
  //mCompany
  isPublicLabel: "Document can be viewed by anyone",
  countyCodeLabel: "County Code",
  countyName: "County Name",
  prohibitBinding: "Prohibit Binding",
  tier: "Tier",
  houseNumberFieldLabel: "House number",
  streetDirectionLabel: "Street Direction",
  streetTypeLabel: "Street Type",
  streetNameFieldLabel: "Street Name",
  unitNumberFieldLabel: "Unit Number",
  cityFieldLabel: "City",
  stateLabel: "State",
  zipCodeFieldLabel: "Zip code",
  agentCodeLabel: "Agent Code",
  agentCodeLengthValidateError: "Agent code must be at least 3 characters",
  mailingAddressLabel: "Mailing address",
  PostOfficeBoxLabel: "P.O. BOX NUMBER",
  postOfficeDisabledValue: "P.O. BOX",
  physicalAddress: "Physical Address",
  mailingAddress: "Mailing Address",
  howOurMarketFitsWithOtherMarketsAgentRepresents: "If other explain",
  percentage: "Percentage must be less than 3 characters",
  agency: "Search Agency",
  roleLabel: "Select Role",
  clickToClose: "Click to close",
  clickToExpand: "Click to expand",
  atlasUnavailability:
    "Sorry, Atlas General Agency's services are only available in Texas",
  buildingAmountCleared:
    "Building amount has been cleared and must be re-entered due to changes",
  agentAttest:
    "Agent attests that the above questions were asked of the applicant",
  chooseCorrectEndorsement:
    "Use HO-101 for a non-rental condo and HO-380 for a rental condo. Please choose the correct endorsement for your situation.",
  cancellation90DayMessage: `Your cancellation request is greater than 90 days old.  Additional review by Billing & Underwriting will be required. Please attach supporting documentation (See “Show List” ) & additional information by Billing or Underwriting maybe requested after review`,
  nonWeatherClaimsCleared:
    "Number of Non-Weather or Non-Appliance Claims has been reset to 0",
  distanceToFireStationLengthValidateError: "Distance must be at most 3 digits",
  licenseNumberMinLengthValidateError:
    "License number must be at least 3 characters"
};
