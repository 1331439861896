import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  AppSettingsState,
  AppSettingsGetters,
  AppSettingsMutations,
  AppSettingsActions
} from "./types";

const namespaced = true;
export const namespace = "appSettings";

export const {
  mapState: appSettingsMapState,
  mapGetters: appSettingsMapGetters,
  mapMutations: appSettingsMapMutations,
  mapActions: appSettingsMapActions
} = createNamespacedHelpers<
  AppSettingsState,
  AppSettingsGetters,
  AppSettingsMutations,
  AppSettingsActions
>("appSettings");

export const appSettings: Module<AppSettingsState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
