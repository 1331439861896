import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { UserState, UserGetters, UserMutations, UserActions } from "./types";

const namespaced = true;
export const namespace = "user";

export const {
  mapState: userMapState,
  mapGetters: userMapGetters,
  mapMutations: userMapMutations,
  mapActions: userMapActions
} = createNamespacedHelpers<UserState, UserGetters, UserMutations, UserActions>(
  namespace
);

export const user: Module<UserState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
