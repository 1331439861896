<template>
  <div class="form-group">
    <div class="form-field">
      <el-input
        ref="autocomplete"
        type="text"
        :class="classname"
        :id="id"
        :placeholder="placeholder"
        v-model="autocompleteText"
        @focus="onFocus()"
        @blur="onBlur()"
        @change="onChange"
        @input="onChange"
        @keypress="onKeyPress"
        @keyup="onKeyUp"
      />
    </div>
  </div>
</template>
<script>
const GoogleMapsApiLoader = require("google-maps-api-loader");
import { NormalizedAddress } from "@/helpers/addressFormatter/NormalizedAddress";
export default {
  name: "StepGoogleAutoComplete",
  props: {
    id: {
      type: String,
      required: true
    },
    classname: String,
    placeholder: {
      type: String,
      default: "Start typing"
    },
    types: {
      type: String,
      default: "address"
    },
    country: {
      type: [String, Array],
      default: null
    },
    enableGeolocation: {
      type: Boolean,
      default: false
    },
    geolocationOptions: {
      type: Object,
      default: null
    },
    defaultAutocompleteText: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      autocomplete: null,
      autocompleteText: "",
      geolocation: {
        geocoder: null,
        loc: null,
        position: null
      }
    };
  },
  watch: {
    autocompleteText: function(newVal, oldVal) {
      this.$emit("inputChange", { newVal, oldVal }, this.id);
    },
    country: function() {
      this.autocomplete.setComponentRestrictions({
        country: this.country === null ? [] : this.country
      });
    }
  },
  mounted: function() {
    const options = {};
    if (this.types) {
      options.types = [this.types];
    }
    if (this.country) {
      options.componentRestrictions = {
        country: this.country
      };
    }
    GoogleMapsApiLoader({
      libraries: ["places"],
      apiKey: process.env.VUE_APP_GOOGLE_API_PLACES_KEY
    }).then(
      googleApi => {
        this.autocomplete = new googleApi.maps.places.Autocomplete(
          document.getElementById(this.id),
          options
        );
        this.autocomplete.addListener("place_changed", this.onPlaceChanged);
        if (this.defaultAutocompleteText) {
          this.autocompleteText = document.getElementById(
            this.id
          ).value = this.defaultAutocompleteText;
          document.getElementById(this.id).focus();
        } else {
          document.getElementById(this.id).focus();
        }
      },
      err => {
        console.error("errror", err);
      }
    );
  },
  methods: {
    /**
     * When a place changed
     */
    onPlaceChanged() {
      let place = this.autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit("no-results-found", place, this.id);
        return;
      }
      if (place.address_components !== undefined) {
        const form = new NormalizedAddress(place);
        // return returnData object and PlaceResult object
        this.$emit("placechanged", form);
        // update autocompleteText then emit change event
        this.autocompleteText = document.getElementById(this.id).value;
        this.onChange();
      }
    },
    /**
     * When the input gets focus
     */
    onFocus() {
      this.biasAutocompleteLocation();
      this.$emit("focus");
    },
    /**
     * When the input loses focus
     */
    onBlur() {
      this.$emit("blur");
    },
    /**
     * When the input got changed
     */
    onChange() {
      this.$emit("change", this.autocompleteText);
    },
    /**
     * When a key gets pressed
     * @param  {Event} event A keypress event
     */
    onKeyPress(event) {
      this.$emit("keypress", event);
    },
    /**
     * When a keyup occurs
     * @param  {Event} event A keyup event
     */
    onKeyUp(event) {
      this.$emit("keyup", event);
    },
    /**
     * Clear the input
     */
    clear() {
      this.autocompleteText = "";
    },
    /**
     * Focus the input
     */
    focus() {
      this.$refs.autocomplete.focus();
    },
    /**
     * Blur the input
     */
    blur() {
      this.$refs.autocomplete.blur();
    },
    /**
     * Update the value of the input
     * @param  {String} value
     */
    update(value) {
      this.autocompleteText = value;
    },
    /**
     * Update internal location from navigator geolocation
     * @param  {Function} (geolocation, position)
     */
    updateGeolocation(callback = null) {
      if (navigator.geolocation) {
        let options = {};
        if (this.geolocationOptions)
          Object.assign(options, this.geolocationOptions);
        navigator.geolocation.getCurrentPosition(
          position => {
            let geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            this.geolocation.loc = geolocation;
            this.geolocation.position = position;
            if (callback) callback(geolocation, position);
          },
          err => {
            this.$emit("error", "Cannot get Coordinates from navigator", err);
          },
          options
        );
      }
    },
    // // Bias the autocomplete object to the user's geographical location,
    // // as supplied by the browser's 'navigator.geolocation' object.
    biasAutocompleteLocation() {
      if (this.enableGeolocation) {
        this.updateGeolocation((geolocation, position) => {
          // eslint-disable-next-line no-undef
          let circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });
          this.autocomplete.setBounds(circle.getBounds());
        });
      }
    }
  }
};
</script>
<style>
.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 111111;
}
.pac-logo:after {
  content: "";
  padding: 1px 1px 1px 0;
  height: 16px;
  text-align: right;
  display: block;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 120px 14px;
}
.hdpi.pac-logo:after {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png);
}
.pac-item {
  cursor: default;
  padding: 8px 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 11px;
  color: #999;
}
.pac-item:hover {
  background-color: #fafafa;
  cursor: pointer;
}
.pac-item-selected,
.pac-item-selected:hover {
  background-color: #ebf2fe;
}
.pac-matched {
  font-weight: 700;
}
.pac-item-query {
  font-size: 14px;
  padding-right: 3px;
  color: #000;
}
.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
}
.hdpi .pac-icon {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
}
.pac-icon-search {
  background-position: -1px -1px;
}
.pac-item-selected .pac-icon-search {
  background-position: -18px -1px;
}
.pac-icon-marker {
  background-position: -1px -161px;
}
.pac-item:hover .pac-icon,
.pac-item-selected .pac-icon-marker {
  background-position: -18px -161px;
}
.pac-placeholder {
  color: gray;
}
</style>
