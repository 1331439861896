<template>
  <button
    id="main-menu-toggle"
    type="button"
    aria-expanded="false"
    v-on:click="toggleMobileMenu"
  >
    <div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </button>
</template>

<script>
import { appMapMutations, appMapState } from "@/store/modules/app";
export default {
  methods: {
    ...appMapMutations(["TOGGLE_MOBILE_MENU"]),
    toggleMobileMenu() {
      this.TOGGLE_MOBILE_MENU(!this.showMobileMenu);
    }
  },
  computed: {
    ...appMapState(["showMobileMenu"])
  }
};
</script>
