import { IPolicySign } from "@/store/modules/policySign/types";
import { basePolicySign } from "./defaultObjects";
import { cloneDeep, defaultsDeep } from "lodash";

export function policySignDefault(
  policySign: Partial<IPolicySign>
): IPolicySign {
  return defaultsDeep(
    cloneDeep(policySign),
    Object.assign({}, basePolicySign())
  );
}
