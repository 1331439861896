
import Vue from "vue";

import { checkVuelidateValidation } from "@/helpers/processValidation";

export default Vue.extend({
  inheritAttrs: false,
  name: "check-box",
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    id: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: [Boolean, String, Number],
      required: false,
      default: false
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      val: ""
    };
  },
  computed: {
    checkboxValue: {
      get(): any {
        return this.value;
      },
      set($event: any): void {
        this.$emit("change", $event);
        this.$emit("input", $event);
      }
    },
    validationResult(): {
      isValid: boolean;
      error: string;
    } {
      return checkVuelidateValidation(this.validations as any, this.value);
    }
  }
});
