import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  gbsHelpState,
  gbsHelpGetters,
  GbsHelpMutations,
  GbsHelpActions
} from "./types";

const namespaced = true;
export const namespace = "gbsHelp";

export const {
  mapState: gbsHelpMapState,
  mapGetters: gbsHelpMapGetters,
  mapMutations: gbsHelpMapMutations,
  mapActions: gbsHelpMapActions
} = createNamespacedHelpers<
  gbsHelpState,
  gbsHelpGetters,
  GbsHelpMutations,
  GbsHelpActions
>(namespace);

export const gbsHelp: Module<gbsHelpState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
