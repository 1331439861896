import { DefineGetters } from "vuex-type-helper";
import { AppSettingsGetters, AppSettingsState } from "./types";
import { has, get } from "lodash";

export const getters: DefineGetters<AppSettingsGetters, AppSettingsState> = {
  getAppSettingsFromStore: (state: AppSettingsState) => state.appSettings,
  getEditing: (state: AppSettingsState) => state.editing,
  loadingGet: (state: AppSettingsState) => state.loadingGet,
  loadingCreate: (state: AppSettingsState) => state.loadingCreate,
  loadingUpdate: (state: AppSettingsState) => state.loadingUpdate,
  loadingDelete: (state: AppSettingsState) => state.loadingDelete,
  lastUpdate: (state: AppSettingsState) => state.lastUpdate,
  getAppSettingsLinks: (state: AppSettingsState) => state.appSettingsLinks,
  getTotalRecords: ({ appSettingsLinks, appSettings }: AppSettingsState) =>
    appSettingsLinks && appSettingsLinks.totalRecords
      ? appSettingsLinks.totalRecords
      : appSettings.length,
  getAppSettingsById: (state: AppSettingsState) => (appSettingsId: string) =>
    state.appSettings.find(appSettings => appSettings._id === appSettingsId),
  getThemeSetting: (state: AppSettingsState) => (
    key: string,
    id: string
  ): any => {
    const appSetting = state.appSettings.find(
      appSettings => appSettings._id === id
    );
    if (has(appSetting, key)) {
      return get(appSetting, key);
    } else {
      return "";
    }
  }
};
