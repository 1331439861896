import { IPersonalLiabilityPolicy } from "@/store/modules/personalLiabilityPolicy/types";
import { addYears } from "date-fns";
import { cloneDeep, defaultsDeep } from "lodash";
import { defaultAddressStructure } from "./defaultObjects";

const effectiveDate = new Date();
export const getPLExpiryDate = (effectiveDate: Date) => {
  return addYears(effectiveDate, 1);
};
export const personalLiabilityPolicyBase = () => ({
  deleted: false,
  agentCode: "",
  phone: "",
  applicantName: "",
  fax: "",
  spouseName: "",
  agencyName: "",
  applicantMailingAddress: { ...defaultAddressStructure },
  agencyMailingAddress: { ...defaultAddressStructure },
  locationOfProperty: { ...defaultAddressStructure },
  effectiveDate,
  expiryDate: getPLExpiryDate(effectiveDate),
  companyNumber: "",
  status: "",

  //ANSWERS
  personalLiabilityLimit: "",
  insuredLocationStatus: "",
  businessOrDayCareAtLocation: "",
  smokeDetectorsAtLocation: "",
  approvalCode: "",
  swimmingPoolAtLocation: "",
  poolFencedToLocalBuildingCode: "",
  poolHasDivingBoardOrSlide: "",
  viciousAnimalAtLocation: "",
  locationHasWrittenAtlasTdpPolicy: "",
  atlasTdpPolicyNumber: "",
  acknowledgementOfInsuredQuestioning: false,

  questionnaire: {
    personalLiabilityLimit: "",
    insuredLocationStatus: "",
    businessOrDayCareAtLocation: "",
    smokeDetectorsAtLocation: "",
    swimmingPoolAtLocation: "",
    poolFencedToLocalBuildingCode: "",
    poolHasDivingBoardOrSlide: "",
    viciousAnimalAtLocation: "",
    locationHasWrittenAtlasTdpPolicy: "",
    atlasTdpPolicyNumber: "",
    acknowledgementOfInsuredQuestioning: false
  }
});

export function getPersonalLiabilityPoliciesDefault(
  county: Partial<IPersonalLiabilityPolicy>
): IPersonalLiabilityPolicy {
  return defaultsDeep(
    cloneDeep(county),
    Object.assign({}, personalLiabilityPolicyBase())
  );
}
