import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  AgentApplicationsState,
  AgentApplicationsMutations,
  StateKeys,
  IAgentApplications
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import {
  getDefaultAgentApplications,
  getDefaultApproveAgent
} from "@/helpers/defaultAgentApplication";
import { has, set } from "lodash";
import { defaultState } from "./state";

export const mutations: DefineMutations<
  AgentApplicationsMutations,
  AgentApplicationsState
> = {
  [types.SET_STATE]: GenericSetState<AgentApplicationsState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<AgentApplicationsState>(),
  [types.SET_EDIT]: GenericSetEdit<
    AgentApplicationsState,
    string | Partial<IAgentApplications>
  >("agentApplications", getDefaultAgentApplications),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    AgentApplicationsState,
    keyof IAgentApplications
  >(),
  [types.SET_ITEM]: GenericSetItem<AgentApplicationsState, IAgentApplications>(
    "agentApplications",
    getDefaultAgentApplications
  ),
  [types.DELETE_ITEM]: GenericDeleteItem<AgentApplicationsState>(
    "agentApplications",
    "_id"
  ),
  // SETTING EDITING STATE
  [types.SET_EDIT_APPROVE](state: AgentApplicationsState, payload) {
    state.editingApprove = getDefaultApproveAgent(payload);
  },
  // [types.SET_EDITING_APPROVE]
  [types.SET_EDITING_APPROVE]: (
    state: AgentApplicationsState,
    { key, value }
  ): void => {
    if (state.editingApprove && has(state.editingApprove, key)) {
      set(state.editingApprove, key, value);
    }
  },
  [types.CLEAR_AGENTAPPLICATIONS_STORE]: GenericClearStore<
    AgentApplicationsState
  >(defaultState())
};
