import { stringifyAddress } from "@/helpers/stringifyAddress";
import { ResponseWrapper } from "@/services/response_wrapper";

export interface IPolicyInfoSuccessResponse {
  status: number;
  message: string;
  data: {
    correctDate?: string;
    status?: number;
    policyInfo: {
      status: number;
      Summary: {
        PrintName: string;
        compNum: string;
        compName: string;
        PrintAddress1: string;
        PrintAddress31: string;
        PrintAddress3: {
          City: string;
          State: string;
          Zip: string;
        };
        PolicyPrefix: string;
        PolicyNumber: string;
        CancelReasonCd: string;
        CompanyNum: string;
        BillingStatus: string;
        PolicyStatus: string;
        TermEffectiveDt: string;
        TermExpirationDt: string;
        Agent3Level: string;
        Agent6Level: string;
        Agent9Level: string;
        Agent: string;
        PayPlan: string;
        InstallmentsBilled: string;
        InstallmentsRemaining: string;
        CurrentAmtDue: string;
        ComputedCancellationDt: string;
        CurrentDueDt: string;
        LastPaymentAmt: string;
        LastPaymentDt: string;
        LastPaymentCheck: string;
        TotalAmtDue: string;
        TermPremium: string;
        TermFeesTaxes: string;
        TotalPaid: string;
        MinDue: string;
        vchecks_url: string;
      };
      dataX: string;
      policy: string;
      message: string;
    };
  };
}

export interface IPolicyInfoFailureResponse {
  status: number;
  message: string;
}

export interface IPaymentInfoData {
  computedCancellationDt: Date; //actually dates
  currentDueDate: Date; //date strings
  policyNumber: string;
  policyPrefix: string;
  printAddress1: string;
  zip: any;
  policyStatus: string;
  totalAmountDue: string;
  lastPayment: string;
  minAmtDue: string;
  currentAmtDue: string;
  policy: string;
  name: string;
  company: string;
  paymentMethod: string;
  companyNumber: string;
  Agent?: string;
  PrintName?: string;
  agencyName?: string;
  policySigned?: boolean;
  insuredEmail?: string;
}
export interface IInsuredInfoForPolicySign
  extends Pick<
    IPaymentInfoData,
    | "name"
    | "printAddress1"
    | "Agent"
    | "policy"
    | "agencyName"
    | "policySigned"
    | "policyPrefix"
    | "policyNumber"
    | "insuredEmail"
  > {}
export function extractPayInfo(response: ResponseWrapper): IPaymentInfoData {
  const responseData = response?.data?.data;
  const info = responseData?.Summary;
  return {
    companyNumber: info.compNum,
    company: `${info.compName} ${info.compNum}`,
    name: info.PrintName,
    policy: response.data.data.policy,
    currentAmtDue: info.CurrentAmtDue,
    minAmtDue: info.MinDue,
    lastPayment: info.LastPaymentAmt,
    totalAmountDue: info.TotalAmtDue,
    policyStatus: info.PolicyStatus,
    zip: info.PrintAddress3,
    printAddress1: info.PrintAddress1,
    policyPrefix: info.PolicyPrefix,
    policyNumber: info.PolicyNumber,
    currentDueDate: new Date(info.CurrentDueDt),
    computedCancellationDt: new Date(info.ComputedCancellationDt),
    paymentMethod: "",
    Agent: info.Agent,
    agencyName: info.agencyName,
    policySigned: responseData?.policySigned
  };
}

export function extraInsuredInfoForPolicySIgn(
  response: ResponseWrapper,
  policyNumber: number,
  policyPrefix: string
): IInsuredInfoForPolicySign {
  const responseData = response?.data?.data;
  const policySigned = response?.data?.policySigned;
  const policy = responseData?.PolicyNumber;
  return {
    name: responseData?.LegalName,
    printAddress1: stringifyAddress({
      city: responseData.LocCity,
      houseNumber: responseData.LocStreetNumber,
      state: responseData.LocState,
      streetName: responseData.LocStreetName,
      zipCode: responseData.LocZip
    }),
    Agent: responseData.AgentCode,
    policy,
    agencyName: responseData?.AgencyName.replace("MailingName:", ""),
    policySigned,
    policyPrefix,
    policyNumber: policyNumber.toString(),
    insuredEmail: response?.data?.insuredEmail
  };
}
