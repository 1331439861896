import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  AtlasPlatformState,
  AtlasPlatformGetters,
  AtlasPlatformMutations,
  AtlasPlatformActions
} from "./types";

const namespaced = true;
export const namespace = "atlasPlatform";

export const {
  mapState: atlasPlatformMapState,
  mapGetters: atlasPlatformMapGetters,
  mapMutations: atlasPlatformMapMutations,
  mapActions: atlasPlatformMapActions
} = createNamespacedHelpers<
  AtlasPlatformState,
  AtlasPlatformGetters,
  AtlasPlatformMutations,
  AtlasPlatformActions
>(namespace);

export const atlasPlatform: Module<AtlasPlatformState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
