import { shouldShowField } from "@/components/FormBuilder/Helpers";
import { FormBlock, FormBlockField } from "@/components/FormBuilder/types";
import { get } from "lodash";
import {
  getDefaultValidationErrorMessage,
  getVuelidateValidationFunction
} from "./processValidation";

export interface IFormBlockValidation {
  isValid: boolean;
  errors: string[];
  field: string;
  label: string;
}

export interface SchemaValidationData {
  isValid: boolean;
  errors: Record<string, any>;
  labels: Record<string, any>;
}

export function validateSchemaHelper(
  validations: any[],
  value: any
): { isValid: boolean; error: string } {
  for (const validation of validations) {
    const vuelidateMethod = getVuelidateValidationFunction(validation);
    if (!vuelidateMethod(value)) {
      return {
        isValid: false,
        error: validation.error || getDefaultValidationErrorMessage(validation)
      };
    }
  }
  return { isValid: true, error: "" };
}

export function validateFormFieldBlock(
  formBlockField: FormBlockField,
  formValues: Record<string, any>
): IFormBlockValidation {
  const { validations = [] } = formBlockField.properties;
  const fieldValidations = [...validations];
  const isValidFields: boolean[] = [];
  const errors: string[] = [];
  if (formBlockField.properties.required) {
    fieldValidations.push({
      method: "required",
      error: "this field is required"
    });
  }
  for (const validation of fieldValidations) {
    const vuelidateMethod = getVuelidateValidationFunction(validation);
    const value = get(formValues, formBlockField.properties.key);
    const isValid = vuelidateMethod(value);
    isValidFields.push(isValid);
    if (!isValid) {
      errors.push(
        validation.error || getDefaultValidationErrorMessage(validation)
      );
    }
  }
  return {
    isValid: isValidFields.every((isValid: boolean) => isValid),
    errors,
    field: formBlockField.properties.key,
    label: getFormBlockFieldLabel(formBlockField, formValues)
  };
}

export function getFormBlockFieldLabel(
  formBlockField: FormBlockField,
  formValues: Record<string, any>
): string {
  const label = formBlockField.properties.label;
  if (label) {
    if (typeof label === "string") {
      return label;
    } else if (typeof label === "function") {
      return label(formValues);
    }
  }
  return "";
}

//THIS SHOULD BE RECURSIVE
export function getFieldsFromBlocks(blocks: FormBlock[]): FormBlockField[] {
  const fields = [];
  for (const block of blocks) {
    if (block.element === "field") {
      fields.push(block);
    } else {
      if ((block as any).children) {
        fields.push(...getFieldsFromBlocks((block as any).children || []));
      }
    }
  }
  return fields;
}

export function validateSchema(
  blocks: FormBlock[],
  formValues: Record<string, any>
): SchemaValidationData {
  const result: SchemaValidationData = {
    isValid: true,
    errors: {},
    labels: {}
  };
  const allFieldBlocks = getFieldsFromBlocks(blocks);
  for (const block of allFieldBlocks) {
    if (shouldShowField(block, formValues)) {
      const blockValidation = validateFormFieldBlock(block, formValues);
      if (blockValidation.errors.length > 0 || !blockValidation.isValid) {
        result.errors[blockValidation.field] = blockValidation.errors;
        result.labels[blockValidation.field] = blockValidation.label;
        result.isValid = false;
      }
    }
  }
  return result;
}
