import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  AccreditedBuilderState,
  AccreditedBuilderGetters,
  AccreditedBuilderMutations,
  AccreditedBuilderActions
} from "./types";

const namespaced = true;
export const namespace = "accreditedBuilder";

export const {
  mapState: accreditedBuilderMapState,
  mapGetters: accreditedBuilderMapGetters,
  mapMutations: accreditedBuilderMapMutations,
  mapActions: accreditedBuilderMapActions
} = createNamespacedHelpers<
  AccreditedBuilderState,
  AccreditedBuilderGetters,
  AccreditedBuilderMutations,
  AccreditedBuilderActions
>(namespace);

export const accreditedBuilder: Module<AccreditedBuilderState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
