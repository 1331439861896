import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";
class AgentApplicationsService extends BaseCollectionService {
  constructor() {
    super("agent-applications");
  }
  public async printAgentApplicationById(
    payload: any
  ): Promise<ResponseWrapper> {
    const path = `/agent-applications/print/${payload._id}`;
    return await this.request(
      {
        method: "get",
        url: path
      },
      { useToken: "access" }
    );
  }
}

export default new AgentApplicationsService();
