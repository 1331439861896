import { RouteConfig } from "vue-router";

const userRoutes: RouteConfig[] = [
  {
    path: "/dashboard",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/views/user/dashboard/Dashboard.vue"
      ),
    meta: {
      pageTitle: "Dashboard",
      requireAuth: true,
      allowedRoles: [
        "agent",
        "agencyAdmin",
        "ezlynx",
        "subAdmin",
        "underwriting",
        "adjuster",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Dashboard"
      }
    }
  },
  {
    path: "/profile",
    component: () => import("@/views/user/Profile.vue"),
    meta: {
      pageTitle: "Profile",
      requireAuth: true,
      allowedRoles: [
        "agent",
        "admin",
        "ezlynx",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "adjuster",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Profile"
      }
    }
  },
  {
    path: "/propertychangerequest/new",
    component: () =>
      import(
        "@/views/shared/PropertyChangeRequest/SinglePropertyChangeRequests/EditPropertyChangeRequest.vue"
      ),
    meta: {
      pageTitle: "Policy Change Request",
      requireAuth: true,
      allowedRoles: ["agent", "admin"],
      metaTags: {
        title: "Policy Change Request"
      }
    }
  },
  {
    path: "/agencyadmin/agency",
    component: () => import("@/views/agencyAdmin/AgencyAdminEditAgency.vue"),
    meta: {
      pageTitle: "Edit Agency",
      requireAuth: true,
      allowedRoles: ["agencyAdmin", "subAdmin", "admin"],
      metaTags: {
        title: "Edit Agency"
      }
    }
  },
  {
    path: "/onboarding",
    component: () => import("@/views/user/onboarding/Onboarding.vue"),
    meta: {
      pageTitle: "Onboarding",
      requireAuth: true,
      allowedRoles: [
        "agent",
        "admin",
        "adjuster",
        "agencyAdmin",
        "subAdmin",
        "underwriting"
      ],
      metaTags: {
        title: "Onboarding"
      }
    }
  }
];

export default userRoutes;
