import BaseService from "./base.service";
import { ResponseWrapper } from "./response_wrapper";

class EmergencyService extends BaseService {
  constructor() {
    super();
  }

  public async check(): Promise<ResponseWrapper> {
    const path = "emergency";
    return await this.request(
      {
        method: "get",
        url: path
      },
      { useToken: "access" }
    );
  }
}

export default new EmergencyService();
