import {
  IPropertyanalytics,
  PropertyanalyticsActions,
  PropertyanalyticsState,
  PropertyanalyticsMutations,
  PropertyanalyticsGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import PropertyanalyticsService from "@/services/propertyanalytics.service";

export const actions: DefineActions<
  PropertyanalyticsActions,
  PropertyanalyticsState,
  PropertyanalyticsMutations,
  PropertyanalyticsGetters
> = {
  createPropertyanalytics: GenericCreateOne<
    PropertyanalyticsState,
    PropertyanalyticsGetters,
    PropertyanalyticsActions,
    PropertyanalyticsMutations,
    IPropertyanalytics
  >(PropertyanalyticsService, types.SET_ITEM as any),
  getPropertyanalyticss: GenericGetMany<
    PropertyanalyticsState,
    PropertyanalyticsGetters,
    PropertyanalyticsActions,
    PropertyanalyticsMutations
  >(PropertyanalyticsService, "propertyanalytics", types.SET_ITEM as any),
  getPropertyanalytics: GenericGetOne<
    PropertyanalyticsState,
    PropertyanalyticsGetters,
    PropertyanalyticsActions,
    PropertyanalyticsMutations
  >(PropertyanalyticsService, types.SET_ITEM as any),
  deletePropertyanalytics: GenericDeleteOne<
    PropertyanalyticsState,
    PropertyanalyticsGetters,
    PropertyanalyticsActions,
    PropertyanalyticsMutations
  >(PropertyanalyticsService, types.DELETE_ITEM as any),
  updatePropertyanalytics: GenericUpdateOne<
    PropertyanalyticsState,
    PropertyanalyticsGetters,
    PropertyanalyticsActions,
    PropertyanalyticsMutations,
    { id: string; update: Partial<IPropertyanalytics> }
  >(PropertyanalyticsService, types.SET_ITEM as any)
};
