import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  PersonalLiabilityPolicyState,
  PersonalLiabilityPolicyMutations,
  StateKeys,
  IPersonalLiabilityPolicy
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { getPersonalLiabilityPoliciesDefault } from "@/helpers/defaultPersonalLiabilityPolicy";
import { defaultState } from "./state";

export const mutations: DefineMutations<
  PersonalLiabilityPolicyMutations,
  PersonalLiabilityPolicyState
> = {
  [types.SET_STATE]: GenericSetState<PersonalLiabilityPolicyState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<PersonalLiabilityPolicyState>(),
  [types.SET_EDIT]: GenericSetEdit<
    PersonalLiabilityPolicyState,
    string | IPersonalLiabilityPolicy
  >("personalLiabilityPolicies", getPersonalLiabilityPoliciesDefault),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    PersonalLiabilityPolicyState,
    keyof IPersonalLiabilityPolicy
  >(),
  [types.SET_ITEM]: GenericSetItem<
    PersonalLiabilityPolicyState,
    IPersonalLiabilityPolicy
  >("personalLiabilityPolicies", getPersonalLiabilityPoliciesDefault),
  [types.DELETE_ITEM]: GenericDeleteItem<PersonalLiabilityPolicyState>(
    "personalLiabilityPolicies",
    "_id"
  ),
  [types.CLEAR_PERSONAL_LIABILITY_POLICY_STORE]: GenericClearStore<
    PersonalLiabilityPolicyState
  >(defaultState())
};
