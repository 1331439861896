import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  ActivityState,
  ActivityGetters,
  ActivityMutations,
  ActivityActions
} from "./types";

const namespaced = true;
export const namespace = "activities";

export const {
  mapState: activitiesMapState,
  mapGetters: activitiesMapGetters,
  mapMutations: activitiesMapMutations,
  mapActions: activitiesMapActions
} = createNamespacedHelpers<
  ActivityState,
  ActivityGetters,
  ActivityMutations,
  ActivityActions
>("activities");

export const activities: Module<ActivityState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
