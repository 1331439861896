import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  UnderWritingQuestionState,
  UnderWritingQuestionGetters,
  UnderWritingQuestionMutations,
  UnderWritingQuestionActions
} from "./types";

const namespaced = true;
export const namespace = "underWritingQuestion";

export const {
  mapState: underWritingQuestionMapState,
  mapGetters: underWritingQuestionMapGetters,
  mapMutations: underWritingQuestionMapMutations,
  mapActions: underWritingQuestionMapActions
} = createNamespacedHelpers<
  UnderWritingQuestionState,
  UnderWritingQuestionGetters,
  UnderWritingQuestionMutations,
  UnderWritingQuestionActions
>(namespace);

export const underWritingQuestion: Module<UnderWritingQuestionState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
