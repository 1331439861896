import {
  SearchState,
  SearchGetters,
  SearchMutations,
  SearchActions
} from "./types";
import { DefineActions } from "vuex-type-helper";
import SearchService from "@/services/search.service";

export const actions: DefineActions<
  SearchState,
  SearchGetters,
  SearchMutations,
  SearchActions
> = {
  async search({}, payload: { collection: string; query: any }): Promise<any> {
    const { collection, query } = payload;
    const response = await new SearchService(collection).getMany(query);
    const responseData: any = response.data;
    return responseData;
  }
};
