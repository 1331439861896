import { range, capitalize } from "lodash";
import { ISelectFieldOption } from "@/types";
import { AtlasFileCategoryArr } from "@/store/modules/atlasfile/types";

import { NotificationPriorities } from "@/store/modules/notification/types";
import { ImportTypeArr } from "@/store/modules/import/types";
import { KindOfLossForList, KindOfLossOptions, Site } from "./constants";

export const FormsByPolicyType: any = {
  D1: "Fire,EC,VMM(1)",
  D3: "Fire,EC Only(1)",
  D2: "Fire,EC,AEC(2)",
  D4: "Fire,EC,PLF(3)",
  HA: "Form A",
  HB: "Form B",
  BT: "Form BT",
  BC: "Form B-Con",
  AP: "Form A+"
};

export const atlasPublicContacts = [
  {
    title: "Questions or Concerns?",
    lines: [
      {
        label: "Toll Free",
        phone: "888-786-0003"
      },
      {
        label: "Local",
        phone: "972-595-5600"
      },
      {
        label: "Fax",
        phone: "888-769-0006"
      }
    ]
  },
  {
    title: "Billing Department",
    lines: [
      {
        label: "Valencia Roberts",
        phone: "972-595-5621"
      },
      {
        label: "Trinidad Cantu",
        phone: "972-595-5622"
      },
      {
        label: "Email",
        email: "AtlasBilling@atlasgeneral.com"
      }
    ]
  },
  {
    title: "For Technical Help",
    lines: [
      {
        label: "Phone",
        phone: "972-595-5650"
      },
      {
        label: "Email",
        email: "Pattyi@atlasgeneral.com"
      }
    ]
  },
  {
    title: "For Help With Online Bill Payment.",
    lines: [
      {
        label: "Phone",
        phone: "972-595-5650"
      },
      {
        label: "Email",
        email: "Pattyi@atlasgeneral.com"
      }
    ]
  },
  {
    title: "Claims Department.",
    lines: [
      {
        label: "Phone",
        phone: "972-595-5700"
      },
      {
        label: "Toll Free",
        phone: "866-522-0361"
      },
      {
        label: "Fax",
        phone: "866-260-8986"
      },
      {
        label: "Email",
        email: "atlasclaims@atlasgeneral.com"
      }
    ]
  }
];
export const atlasAdminTitle: Record<string, string> = {
  AGENCY_RELATION_ASSOCIATE: "Agency Relations Associate",
  MARKETING_REPRESENTATIVE: "Marketing Representative",
  VICE_PRESIDENT: "Vice-President",
  BILLING_REPRESENTATIVE: "Billing Representative",
  PRODUCTION_UNDERWRITER: "Production Underwriter",
  CLAIMS_MANAGER: "Claims Manager",
  ASSISTANT_CLAIMS_MANAGER: "Assistant Claims Manager",
  CLAIMS_EXAMINER: "Claims Examiner",
  ADMINISTRATIVE_ASSISTANCE: "Administrative Assistance",
  VP_OF_DATA_ENTRY: "VP of Data Entry",
  SYSTEM_ADMINISTRATOR: "System Administrator",
  PRESIDENT: "President",
  VICE_PRESIDENT_OF_OPERATIONS: "Vice President of Operations",
  ACCOUNTING_MANAGER: "Accounting Manager"
};
export const atlasContacts: { label: string; value: string }[] = [
  {
    label: "Main",
    value: "972-595-5600"
  },
  {
    label: "Toll Free",
    value: "888-786-0003"
  },
  {
    label: "Main Fax",
    value: "888-769-0006"
  },
  {
    label: "Claims",
    value: "972-595-5700"
  },
  {
    label: "Toll Free",
    value: "866-522-0361"
  },
  {
    label: "Claims Fax",
    value: "800-649-8305"
  }
];
export const atlasAdminDepartment: Record<string, string> = {
  MARKETING: "Marketing",
  CUSTOMER_BILLING: "Customer Billing",
  UNDERWRITING: "Underwriting",
  CLAIMS_DEPARTMENT: "Claims Department",
  RATING_DATA_ENTRY: "Data Entry",
  INFORMATION_TECHNOLOGY: "Information Technology",
  EXECUTIVE: "Executive"
};
export const atlasCarriersAddresses = [
  {
    name: "Insurors Indemnity Company",
    box: "PO Box 612484",
    location: "Dallas, TX 75261-2484"
  },
  {
    name: "Roadrunner Indemnity Company",
    box: "PO Box 975322",
    location: "Dallas Tx 75397-5322"
  },
  {
    name: "Spinnaker Insurance Company",
    box: "PO Box 679459",
    location: "Dallas, TX 75267-9459"
  },
  {
    name: "American Summit Insurance",
    box: "PO Box 30127",
    location: "Omaha, NE 68103-1227"
  }
];

export const occupancyOptions = [
  { label: "Owner", value: "O" },
  { label: "Tenant", value: "T" }
];

export const maritalStatusOptions = [
  { label: "Single", value: "single" },
  { label: "Married", value: "married" },
  { label: "Divorced", value: "divorced" }
];

export const liabilityLimit = [
  { label: "$50,000", value: "50000" },
  { label: "$100,000", value: "100000" },
  { label: "$200,000", value: "200000" },
  { label: "$300,000", value: "300000" }
];

export const medLiability = [
  { label: "$500", value: "500" },
  { label: "$1,000", value: "1000" },
  { label: "$2,000", value: "2000" },
  { label: "$3,000", value: "3000" },
  { label: "$4,000", value: "4000" },
  { label: "$5,000", value: "5000" }
];

export const companyLineOfBusiness = [
  { value: "H", label: "HOMEOWNER" },
  { value: "D", label: "DWELLING FIRE" },
  { value: "T", label: "TENANT/CONDO" }
];

export const yesNoOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const billingContacts = [
  {
    title: "Billing",
    text: "888-786-0003"
  },
  {
    text: "5623",
    title: "Carol Owens"
  },
  {
    text: "5621",
    title: "Valencia Roberts"
  }
];

export const underwritingContacts = [
  {
    text: "5611",
    title: "Duvonda Travis",
    type: "phone"
  },

  {
    text: "5620",
    title: "Jacob Pomroy",
    type: "phone"
  },
  {
    text: "5614",
    title: "Katie Cowan",
    type: "phone"
  },
  {
    text: "5634",
    title: "Kelly Folk",
    type: "phone"
  },
  {
    text: "5630",
    title: "Matt Leuschner",
    type: "phone"
  },
  {
    text: "5635",
    title: "Shelley Arteta",
    type: "phone"
  },
  {
    title: "Underwriting",
    text: "888-786-0003",
    type: "phone"
  }
];

export const claimsInquiries = [
  {
    title: "",
    text: "866-522-0361",
    type: "phone"
  },
  {
    text: "972-595-5700",
    title: "",
    type: "phone"
  }
];

export const marketingInquiries = [
  {
    title: "",
    text: "888-768-0003",
    type: "phone"
  },
  {
    text: "972-595-5600",
    title: "",
    type: "phone"
  }
];

export const marketingContacts = [
  {
    title: "Joy McWhorter Turner – Marketing Representative",
    text: "Ext 5629",
    email: "Joym@atlasgeneral.com",
    type: "email"
  },
  {
    title: "Kelly Folk – Agency Relations Associate",
    text: "Ext 5634",
    email: "KellyF@atlasgeneral.com",
    type: "email"
  }
];

export const claimsContacts = [
  {
    text: "Ext 5708",
    title: "Jessica Callan –   Claims Manager",
    email: "JessicaC@AtlasGeneral.com",
    type: "email"
  },
  {
    title: "Martha Lozano  – Assistant Claims Manager",
    text: "Ext 5705",
    email: "MarthaL@AtlasGeneral.com",
    type: "email"
  },
  {
    title: "Samuel Moore - Claims Examiner",
    text: "Ext 5709",
    email: "SamuelM@atlasgeneral.com",
    type: "email"
  },
  {
    title: "Morgan Shook –  Claims Examiner",
    text: "Ext 5706",
    email: "morgans@atlasgeneral.com",
    type: "email"
  },
  {
    title: "Phyllis Shamu –  Claims Examiner",
    text: "Ext 5704",
    email: "PhyllisS@AtlasGeneral.com",
    type: "email"
  },
  {
    title: "Ysabel Jopling –  Administrative Assistant",
    text: "Ext 5701",
    email: "YsabelJ@AtlasGeneral.com",
    type: "email"
  },
  {
    title: "Ellea Campillo - Administrative Assistance",
    text: "Ext 5703",
    email: "ElleaC@atlasgeneral.com",
    type: "email"
  }
];

export const basePermissionOptions = [
  { label: "Documents", value: "documents:read" },
  { label: "Notifications", value: "notifications:read" }
];
export const permissionOptions = [
  ...basePermissionOptions,
  { label: "Agent Reports", value: "agent_reports:read" }
];

export const prefixOptions = [
  { label: "SPDF", value: "SPDF" },
  {
    label: "SPHO",
    value: "SPHO"
  },
  {
    label: "INHO",
    value: "INHO"
  },
  {
    label: "INDF",
    value: "INDF"
  },
  {
    label: "INPL",
    value: "INPL"
  },
  { label: "CLD1", value: "CLD1" },
  { label: "CLDL", value: "CLDL" },
  { label: "CLHA", value: "CLHA" },
  { label: "CLMB", value: "CLMB" },
  { label: "CLP1", value: "CLP1" },
  { label: "CLPL", value: "CLPL" },

  { label: "CCAP", value: "CCAP" },
  { label: "CCMB", value: "CCMB" },
  { label: "CC2P", value: "CC2P" },
  { label: "CCP1", value: "CCP1" },
  { label: "CCPL", value: "CCPL" }
];

export const homeownerFormTypes = [
  { label: "Form A", value: "HA" },
  {
    label: "Form A+",
    value: "AP"
  },
  {
    label: "Form B",
    value: "HB"
  }
];

export const dwellingFireFormTypes = [
  {
    label: "Fire,EC,VMM(D1)",
    value: "D1"
  },
  {
    label: "Fire,EC,VMM(P1)",
    value: "D2"
  },
  {
    label: "Fire,EC,PLF(3)",
    value: "D3"
  },
  {
    label: "Fire,EC Only(1)",
    value: "D4"
  }
];

export const tenantCondoFormTypes = [
  {
    label: "Form BT",
    value: "BT"
  },
  {
    label: "Form B-Con",
    value: "BC"
  }
];

export const formTypeOptions = [
  { label: "HA", value: "HA" },
  {
    label: "HB",
    value: "HB"
  },
  {
    label: "AP",
    value: "AP"
  },
  {
    label: "BC",
    value: "BC"
  },
  {
    label: "BT",
    value: "BT"
  }
];

export const siteOptions: { value: Site; label: Site }[] = [
  { value: "ATLAS", label: "ATLAS" },
  { value: "COLONIAL", label: "COLONIAL" }
];
const baseStatus = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "pendingTutorial", label: "Pending Tutorial" },
  { value: "completedTutorial", label: "Completed Tutorial" }
];
export const userStatus = [
  ...baseStatus,
  { value: "pendingApproval", label: "Pending Approval" },
  { value: "pendingConfirmation", label: "Pending Confirmation" },
  { value: "pendingVerification", label: "Pending Verification" }
];
export const userStatusAgencyAdmin = [...baseStatus];
export const agencyStatus = [
  { value: "approved", label: "Approved" },
  { value: "pendingApproval", label: "Pending Approval" },
  { value: "rejected", label: "Rejected" },
  { value: "banned", label: "Banned" }
];
export const agentAdminOption = [{ value: "agent", label: "Agent" }];
export const userRoles = [
  { value: "adjuster", label: "Adjuster" },
  { value: "admin", label: "Admin" },
  { value: "agencyAdmin", label: "Agency Admin" },
  { value: "agent", label: "Agent" },
  { value: "billing", label: "Billing" },
  { value: "claims", label: "Claims" },
  { value: "dataEntry", label: "Data Entry" },
  { value: "ezlynx", label: "Ezlynx" },
  { value: "subAdmin", label: "Sub Admin" },
  { value: "underwriting", label: "Underwriting" }
];
export const streetDirections = [
  { label: "NORTH", value: "North" },
  { label: "SOUTH", value: "South" },
  { label: "EAST", value: "East" },
  { label: "WEST", value: "West" },
  { label: "NORTH EAST", value: "NE" },
  { label: "NORTH WEST", value: "NW" },
  { label: "SOUTH EAST", value: "SE" },
  { label: "SOUTH WEST", value: "SW" }
];

export const streetTypes = [
  { value: "ALY", label: "ALLEY" },
  { value: "ANX", label: "ANNEX" },
  { value: "ARC", label: "ARCADE" },
  { value: "AVE", label: "AVENUE" },
  { value: "BYU", label: "BAYOU" },
  { value: "BND", label: "BEND" },
  { value: "BLF", label: "BLUFF" },
  { value: "BLFS", label: "BLUFFS" },
  { value: "BTM", label: "BOTTOM" },
  { value: "BLVD", label: "BOULEVARD" },
  { value: "BR", label: "BRANCH" },
  { value: "BRG", label: "BRIDGE" },
  { value: "BRK", label: "BROOK" },
  { value: "BRKS", label: "BROOKS" },
  { value: "BG", label: "BURG" },
  { value: "BGS", label: "BURGS" },
  { value: "BYP", label: "BYPASS" },
  { value: "CTR", label: "CENTER" },
  { value: "CIR", label: "CIRCLE" },
  { value: "CV", label: "COVE" },
  { value: "CT", label: "COURT" },
  { value: "CRES", label: "CRESCENT" },
  { value: "DL", label: "DALE" },
  { value: "DR", label: "DRIVE" },
  { value: "EXPY", label: "EXPRESSWAY" },
  { value: "FWY", label: "FREEWAY" },
  { value: "GDN", label: "GARDEN" },
  { value: "GRV", label: "GROVE" },
  { value: "HTS", label: "HEIGHTS" },
  { value: "HWY", label: "HIGHWAY" },
  { value: "HILL", label: "HILL" },
  { value: "KNOL", label: "KNOLL" },
  { value: "LN", label: "LANE" },
  { value: "LOOP", label: "LOOP" },
  { value: "MALL", label: "MALL" },
  { value: "PARK", label: "PARK" },
  { value: "PKWY", label: "PARKWAY" },
  { value: "PATH", label: "PATH" },
  { value: "PIKE", label: "PIKE" },
  { value: "PL", label: "PLACE" },
  { value: "PLZ", label: "PLAZA" },
  { value: "PT", label: "POINT" },
  { value: "RD", label: "ROAD" },
  { value: "RTE", label: "ROUTE" },
  { value: "ROW", label: "ROW" },
  { value: "RUN", label: "RUN" },
  { value: "SQ", label: "SQUARE" },
  { value: "ST", label: "STREET" },
  { value: "TER", label: "TERRACE" },
  { value: "TRL", label: "TRAIL" },
  { value: "TPKE", label: "TURNPIKE" },
  { value: "VIA", label: "VIADUCT" },
  { value: "WALK", label: "WALK" },
  { value: "WAY", label: "WAY" }
];

export const windCheck = [
  { label: "None", value: "none" },
  { label: "Wind Included", value: "wind included" },
  { label: "Wind Excluded", value: "wind excluded" }
];

export const states = [
  {
    label: "Alabama",
    value: "AL"
  },
  {
    label: "Alaska",
    value: "AK"
  },
  {
    label: "American Samoa",
    value: "AS"
  },
  {
    label: "Arizona",
    value: "AZ"
  },
  {
    label: "Arkansas",
    value: "AR"
  },
  {
    label: "California",
    value: "CA"
  },
  {
    label: "Colorado",
    value: "CO"
  },
  {
    label: "Connecticut",
    value: "CT"
  },
  {
    label: "Delaware",
    value: "DE"
  },
  {
    label: "District Of Columbia",
    value: "DC"
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM"
  },
  {
    label: "Florida",
    value: "FL"
  },
  {
    label: "Georgia",
    value: "GA"
  },
  {
    label: "Guam",
    value: "GU"
  },
  {
    label: "Hawaii",
    value: "HI"
  },
  {
    label: "Idaho",
    value: "ID"
  },
  {
    label: "Illinois",
    value: "IL"
  },
  {
    label: "Indiana",
    value: "IN"
  },
  {
    label: "Iowa",
    value: "IA"
  },
  {
    label: "Kansas",
    value: "KS"
  },
  {
    label: "Kentucky",
    value: "KY"
  },
  {
    label: "Louisiana",
    value: "LA"
  },
  {
    label: "Maine",
    value: "ME"
  },
  {
    label: "Marshall Islands",
    value: "MH"
  },
  {
    label: "Maryland",
    value: "MD"
  },
  {
    label: "Massachusetts",
    value: "MA"
  },
  {
    label: "Michigan",
    value: "MI"
  },
  {
    label: "Minnesota",
    value: "MN"
  },
  {
    label: "Mississippi",
    value: "MS"
  },
  {
    label: "Missouri",
    value: "MO"
  },
  {
    label: "Montana",
    value: "MT"
  },
  {
    label: "Nebraska",
    value: "NE"
  },
  {
    label: "Nevada",
    value: "NV"
  },
  {
    label: "New Hampshire",
    value: "NH"
  },
  {
    label: "New Jersey",
    value: "NJ"
  },
  {
    label: "New Mexico",
    value: "NM"
  },
  {
    label: "New York",
    value: "NY"
  },
  {
    label: "North Carolina",
    value: "NC"
  },
  {
    label: "North Dakota",
    value: "ND"
  },
  {
    label: "Northern Mariana Islands",
    value: "MP"
  },
  {
    label: "Ohio",
    value: "OH"
  },
  {
    label: "Oklahoma",
    value: "OK"
  },
  {
    label: "Oregon",
    value: "OR"
  },
  {
    label: "Palau",
    value: "PW"
  },
  {
    label: "Pennsylvania",
    value: "PA"
  },
  {
    label: "Puerto Rico",
    value: "PR"
  },
  {
    label: "Rhode Island",
    value: "RI"
  },
  {
    label: "South Carolina",
    value: "SC"
  },
  {
    label: "South Dakota",
    value: "SD"
  },
  {
    label: "Tennessee",
    value: "TN"
  },
  {
    label: "Texas",
    value: "TX"
  },
  {
    label: "Utah",
    value: "UT"
  },
  {
    label: "Vermont",
    value: "VT"
  },
  {
    label: "Virgin Islands",
    value: "VI"
  },
  {
    label: "Virginia",
    value: "VA"
  },
  {
    label: "Washington",
    value: "WA"
  },
  {
    label: "West Virginia",
    value: "WV"
  },
  {
    label: "Wisconsin",
    value: "WI"
  },
  {
    label: "Wyoming",
    value: "WY"
  }
];

export const namePrefixOptions = [
  {
    label: "Mr",
    value: "Mr"
  },
  {
    label: "Mrs",
    value: "Mrs"
  },
  {
    label: "Miss",
    value: "Miss"
  },
  {
    label: "Ms",
    value: "Ms"
  },
  {
    label: "Mx",
    value: "Mx"
  },
  {
    label: "Sir",
    value: "Sir"
  },
  {
    label: "Dr",
    value: "Dr"
  }
];

export const nameSuffixOptions = [
  {
    label: "Sr",
    value: "Sr"
  },
  {
    label: "Jr",
    value: "Jr"
  },
  {
    label: "Miss",
    value: "Miss"
  },
  {
    label: "I",
    value: "I"
  },
  {
    label: "II",
    value: "II"
  },
  {
    label: "III",
    value: "III"
  }
];

export const stateOptions = [
  {
    value: "Alabama",
    label: "Alabama"
  },
  {
    value: "Alaska",
    label: "Alaska"
  },
  {
    value: "Arizona",
    label: "Arizona"
  },
  {
    value: "Arkansas",
    label: "Arkansas"
  },
  {
    value: "California",
    label: "California"
  },
  {
    value: "Colorado",
    label: "Colorado"
  },
  {
    value: "Connecticut",
    label: "Connecticut"
  },
  {
    value: "Delaware",
    label: "Delaware"
  },
  {
    value: "District Of Columbia",
    label: "District Of Columbia"
  },
  {
    value: "Florida",
    label: "Florida"
  },
  {
    value: "Georgia",
    label: "Georgia"
  },
  {
    value: "Guam",
    label: "Guam"
  },
  {
    value: "Hawaii",
    label: "Hawaii"
  },
  {
    value: "Idaho",
    label: "Idaho"
  },
  {
    value: "Illinois",
    label: "Illinois"
  },
  {
    value: "Indiana",
    label: "Indiana"
  },
  {
    value: "Iowa",
    label: "Iowa"
  },
  {
    value: "Kansas",
    label: "Kansas"
  },
  {
    value: "Kentucky",
    label: "Kentucky"
  },
  {
    value: "Louisiana",
    label: "Louisiana"
  },
  {
    value: "Maine",
    label: "Maine"
  },
  {
    value: "Maryland",
    label: "Maryland"
  },
  {
    value: "Massachusetts",
    label: "Massachusetts"
  },
  {
    value: "Michigan",
    label: "Michigan"
  },
  {
    value: "Minnesota",
    label: "Minnesota"
  },
  {
    value: "Mississippi",
    label: "Mississippi"
  },
  {
    value: "Missouri",
    label: "Missouri"
  },
  {
    value: "Montana",
    label: "Montana"
  },
  {
    value: "Nebraska",
    label: "Nebraska"
  },
  {
    value: "Nevada",
    label: "Nevada"
  },
  {
    value: "New Hampshire",
    label: "New Hampshire"
  },
  {
    value: "New Jersey",
    label: "New Jersey"
  },
  {
    value: "New Mexico",
    label: "New Mexico"
  },
  {
    value: "New York",
    label: "New York"
  },
  {
    value: "North Carolina",
    label: "North Carolina"
  },
  {
    value: "North Dakota",
    label: "North Dakota"
  },
  {
    value: "Ohio",
    label: "Ohio"
  },
  {
    value: "Oklahoma",
    label: "Oklahoma"
  },
  {
    value: "Oregon",
    label: "Oregon"
  },
  {
    value: "Palau",
    label: "Palau"
  },
  {
    value: "Pennsylvania",
    label: "Pennsylvania"
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico"
  },
  {
    value: "Rhode Island",
    label: "Rhode Island"
  },
  {
    value: "South Carolina",
    label: "South Carolina"
  },
  {
    value: "South Dakota",
    label: "South Dakota"
  },
  {
    value: "Tennessee",
    label: "Tennessee"
  },
  {
    value: "Texas",
    label: "Texas"
  },
  {
    value: "Utah",
    label: "Utah"
  },
  {
    value: "Vermont",
    label: "Vermont"
  },
  {
    value: "Virgin Islands",
    label: "Virgin Islands"
  },
  {
    value: "Virginia",
    label: "Virginia"
  },
  {
    value: "Washington",
    label: "Washington"
  },
  {
    value: "West Virginia",
    label: "West Virginia"
  },
  {
    value: "Wisconsin",
    label: "Wisconsin"
  },
  {
    value: "Wyoming",
    label: "Wyoming"
  }
];

export const countryOptions = [
  {
    label: "United States of America",
    value: "United States of America"
  },
  {
    label: "Austria",
    value: "Austria"
  },
  {
    label: "Belgium",
    value: "Belgium"
  },
  {
    label: "Canada",
    value: "Canada"
  },
  {
    label: "Denmark",
    value: "Denmark"
  },
  {
    label: "Finland",
    value: "Finland"
  },
  {
    label: "France",
    value: "France"
  },
  {
    label: "Germany",
    value: "Germany"
  },
  {
    label: "Greece",
    value: "Greece"
  },
  {
    label: "Italy",
    value: "Italy"
  },
  {
    label: "Netherlands",
    value: "Netherlands"
  },
  {
    label: "Portugal",
    value: "Portugal"
  },
  {
    label: "Sweden",
    value: "Sweden"
  },
  {
    label: "Switzerland",
    value: "Switzerland"
  },
  {
    value: "United Kingdom",
    label: "United Kingdom"
  }
];

export const booleanOptions = [
  {
    label: "Yes",
    value: true
  },
  {
    label: "No",
    value: false
  }
];

export const entityMemberRolesOptions = [
  {
    label: "Owner",
    value: "owner"
  },
  {
    label: "Co-owner",
    value: "co-owner"
  }
];

export const relationshipsOptions = [
  {
    label: "Spouse",
    value: "Spouse"
  },
  {
    label: "Sibling",
    value: "Sibling"
  },
  {
    label: "Friend",
    value: "Friend"
  }
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const documentMonths = months.map((item: string, index: number) => ({
  value: index,
  label: item
}));

export const monthOptions = months.map((item: string, index: number) => ({
  value: index,
  label: item
}));

export const getDayOptions = (daysInMonth: number): ISelectFieldOption[] => {
  const options = [];
  for (let day = 1; day <= daysInMonth; day++) {
    options.push({
      label: day.toString(),
      value: day
    });
  }
  return options;
};

export function getKindOfLoss(classId: string | number): string {
  const option = KindOfLossOptions.find(option => option.value == classId);
  return option ? option.label : "";
}

export function getKindOfLossForList(classId: string): string {
  const option = KindOfLossForList[classId];
  if (option) {
    const { code, name } = option;
    return `${code}-${name}`;
  }
  return classId;
}

const thisYear = new Date().getFullYear();
export const documentYears = range(thisYear - 10, thisYear + 1).map(
  (item: number) => ({
    value: item,
    label: item
  })
);

function generalYearOptions(rangeNumber: number) {
  return range(thisYear - rangeNumber, thisYear + 2)
    .map((item: number) => ({
      value: item,
      label: item.toString()
    }))
    .reverse();
}

export const yearOptions = generalYearOptions(92);
export const yearBuiltOptions = generalYearOptions(123);

export const menuPositionOptions = [
  {
    label: "Left",
    value: "left"
  },
  {
    label: "Right",
    value: "right"
  }
];

export const titlePositionOptions = [
  {
    label: "Left",
    value: "left"
  },
  {
    label: "Right",
    value: "right"
  },
  {
    label: "Center",
    value: "center"
  }
];

const generalFields = [{ label: "Created", value: "created" }];

const userFields = [
  { label: "First name", value: "firstName" },
  { label: "Last name", value: "lastName" },
  { label: "Email", value: "email" },
  { label: "Status", value: "status" },
  ...generalFields
];

const investmentFields = [
  { label: "Amount", value: "amount" },
  { label: "Verified", value: "verified" },
  ...generalFields
];

const profileFields = [
  { label: "Person Firstname", value: "person.firstName" },
  { label: "Person Lastname", value: "person.lastName" },
  { label: "Person Email", value: "person.email" },
  { label: "Company Name", value: "company.companyName" },
  { label: "Type", value: "type" },
  ...generalFields
];

export const queryBuilderCollectionOptions = {
  users: userFields,
  leads: userFields,
  investments: investmentFields,
  profiles: profileFields
};

export const allQueryOperations = [
  {
    value: "__eq",
    label: "Equals"
  },
  {
    value: "__ne",
    label: "Not Equals"
  },
  {
    value: "__in",
    label: "Includes"
  },
  {
    value: "__nin",
    label: "Not Includes"
  },
  {
    value: "__lt",
    label: "Less Than"
  },
  {
    value: "__gt",
    label: "Greater Than"
  }
];

export const entityTypeOptions = [
  {
    value: "Corporation",
    label: "Corporation"
  },
  {
    value: "Sole Proprietor",
    label: "Sole Proprietor"
  },
  {
    value: "Partnership",
    label: "Partnership"
  },
  {
    value: "LLC",
    label: "LLC"
  }
];

export const marketingPlansCheckBox = [
  "Use lead generation program",
  "Use telemarketer",
  "Agency website quoting capability",
  "Relationship with mortgage loan officer, real estate broker or title company",
  "Direct mail campaign",
  "Have effective cross-sell program",
  "Media advertising",
  "Book roll",
  "Have dedicated personal lines producers",
  "Other"
];

export const marketingPlansCheckBoxFormBuilder = [
  {
    label: "Use lead generation program",
    value: "Use lead generation program"
  },
  {
    label: "Use telemarketer",
    value: "Use telemarketer"
  },
  {
    label: "Agency website quoting capability",
    value: "Agency website quoting capability"
  },
  {
    label:
      "Relationship with mortgage loan officer, real estate broker or title company",
    value:
      "Relationship with mortgage loan officer, real estate broker or title company"
  },
  {
    label: "Direct mail campaign",
    value: "Direct mail campaign"
  },
  {
    label: "Have effective cross-sell program",
    value: "Have effective cross-sell program"
  },
  {
    label: "Media advertising",
    value: "Media advertising"
  },
  {
    label: "Book roll",
    value: "Book roll"
  },
  {
    label: "Have dedicated personal lines producers",
    value: "Have dedicated personal lines producers"
  },
  {
    label: "Other",
    value: "Other"
  }
];

export const communicationDeliveryMethods = [
  // {
  //   label: "SMS",
  //   value: "sms"
  // },
  {
    label: "Email",
    value: "email"
  },
  {
    label: "App",
    value: "app"
  }
];

export const communicationStatus = [
  { value: "scheduled", label: "Scheduled" },
  { value: "draft", label: "Draft" },
  { value: "completed", label: "Completed" }
];

export const communicationPriority = NotificationPriorities.map(
  (status: string) => ({ label: capitalize(status), value: status })
);

export const userRoleRecipientOptions = [
  { label: "Send to all agency administrators", value: "agencyAdmin" },
  { label: "Send to all agents", value: "agent" },
  { label: "Send to all sub administrators", value: "subAdmin" },
  { label: "Send to all billing", value: "billing" },
  { label: "Send to all claims", value: "claims" },
  { label: "Send to all data Entry", value: "dataEntry" },
  { label: "Send to all underwriters", value: "underwriting" },
  { label: "Send to all administrators", value: "admin" }
];

export const newsCategories = [
  { label: "Banner", value: "banner" },
  { label: "Bulletin", value: "bulletin" },
  { label: "Billing", value: "billing" },
  { label: "Public Banners", value: "public banners" },
  { label: "Marketing", value: "marketing" }
];

export const acknowledgementOptions = [
  { label: "Read all documents", value: "viewedAllAttachments" }
];

export const tiers = [
  { label: "All Tier", value: "All Tier" },
  { label: "Tier 1", value: "Tier 1" },
  { label: "Tier 2", value: "Tier 2" }
];
export const AdjusterShowOptions = [
  { label: "CSV Files", value: "files" },
  { label: "CSV Trash", value: "trash" }
];

export const AtlasFilesCategoryOptions = AtlasFileCategoryArr.map(
  (category: string) => ({ label: category, value: category })
);

export const importTypeOptions = ImportTypeArr.map((category: string) => ({
  label: capitalize(category),
  value: category
}));

export const userExportFieldOptions = [
  { label: "Email", value: "email" },
  { label: "First Name", value: "firstName" },
  { label: "Last Name", value: "lastName" },
  { label: "Phone Primary", value: "phone.primary" },
  { label: "Phone Secondary", value: "phone.secondary" },
  { label: "Mfa Enabled", value: "enableMfa" },
  { label: "Role", value: "role" },
  { label: "Status", value: "status" },
  { label: "Agent Code", value: "agentCode" },
  { label: "Agency ID", value: "agencyId" },
  { label: "Is Banned", value: "isBanned" }
];

export const countiesExportFieldOptions = [
  { label: "Binding Prohibition Status", value: "prohibitBinding" },
  { label: "County Name", value: "countyName" },
  { label: "County Code", value: "countyCode" },
  { label: "Tier", value: "tier" },
  { label: "Deleted", value: "deleted" }
];

export const policyChangeRequestsExportFieldOptions = [
  { label: "First Name", value: "data.insured.firstName" },
  { label: "Last Name", value: "data.insured.lastName" },
  { label: "Agent Code", value: "data.agentInfo" },
  { label: "Policy Number", value: "data.policyInfo.policyNumber" },
  { label: "Date Of Change", value: "modifiedOn" },
  { label: "Date Submitted", value: "submittedOn" },
  { label: "Status", value: "status" },
  { label: "Printed", value: "printed" }
];
export const billingRequestsExportFieldOptions = [
  { label: "First Name", value: "firstName" },
  { label: "Last Name", value: "lastName" },
  { label: "Agent Code", value: "data.agentInfo" },
  { label: "Policy Number", value: "policyNumber" },
  { label: "Date Of Change", value: "modifiedOn" },
  { label: "Date Submitted", value: "submittedOn" },
  { label: "Status", value: "status" },
  { label: "Printed", value: "printed" }
];
export const signedPoliciesExportFieldOptions = [
  {
    label: "Agency",
    value: "agency"
  },
  {
    label: "Policy Prefix",
    value: "policyPrefix"
  },
  {
    label: "Policy Number",
    value: "policyNumber"
  },
  {
    label: "Signed On",
    value: "modifiedOn"
  },
  {
    label: "Insured",
    value: "insuredName"
  },
  {
    label: "Insured Email",
    value: "insuredEmail"
  }
];
export const policyStatus = [
  { value: "NotSubmitted", label: "Not Submitted" },
  { value: "Submitted", label: "Submitted" },
  { value: "Received", label: "Received" },
  { value: "Issued", label: "Issued" }
];

export const changeRequestStatus = [
  { value: "Not Submitted", label: "Not Submitted" },
  { value: "Submitted", label: "Submitted" },
  { value: "Received", label: "Received" },
  { value: "Issued", label: "Issued" },
  { value: "Pending", label: "Pending" }
];

export const fieldAdjusters = [
  {
    "<ExamininerCD>": "ALC",
    "Field Adjuster": "Amarillo Claim Service, Inc.",
    "Phone Number": "806-373-5222"
  },
  {
    "<ExamininerCD>": "AMC",
    "Field Adjuster": "Americlaim",
    "Phone Number": "972-437-3394"
  },
  {
    "<ExamininerCD>": "CPS",
    "Field Adjuster": "Compass Adjusting Service",
    "Phone Number": "817-731-5008"
  },
  {
    "<ExamininerCD>": "CLC",
    "Field Adjuster": "ClaimCor LLC",
    "Phone Number": "813-579-6226"
  },
  {
    "<ExamininerCD>": "DGS",
    "Field Adjuster": "Douglas Claims Service",
    "Phone Number": "972-437-3394"
  },
  {
    "<ExamininerCD>": "GCS",
    "Field Adjuster": "Gentry & Associates Claims Service, Inc.",
    "Phone Number": "888-840-3901"
  },
  {
    "<ExamininerCD>": "HKL",
    "Field Adjuster": "Harper-Kennedy & Associates Inc.",
    "Phone Number": "800-530-4700"
  },
  {
    "<ExamininerCD>": "HWV",
    "Field Adjuster": "Howard Wilson Claims Service",
    "Phone Number": "940-552-7402"
  },
  {
    "<ExamininerCD>": "HWW",
    "Field Adjuster": "Howard Wilson Claims Service",
    "Phone Number": "940-767-3220"
  },
  {
    "<ExamininerCD>": "IAI",
    "Field Adjuster": "Ideal Adjusting, Inc.",
    "Phone Number": "866-334-4332"
  },
  {
    "<ExamininerCD>": "JDC",
    "Field Adjuster": "J D Claim Service",
    "Phone Number": "877-511-4795"
  },
  {
    "<ExamininerCD>": "JPC",
    "Field Adjuster": "John T. Parker Claims Service",
    "Phone Number": "903-753-3950"
  },
  {
    "<ExamininerCD>": "JPH",
    "Field Adjuster": "John T. Parker Claims Service",
    "Phone Number": "281-759-0900"
  },
  {
    "<ExamininerCD>": "MTM",
    "Field Adjuster": "Michael T. Mulholland",
    "Phone Number": "817-300-4287"
  },
  {
    "<ExamininerCD>": "SCS",
    "Field Adjuster": "State Claim Service Inc.",
    "Phone Number": "817-271-7756"
  },
  {
    "<ExamininerCD>": "SEL",
    "Field Adjuster": "Select Claims Service",
    "Phone Number": "817-300-4287"
  },
  {
    "<ExamininerCD>": "RAS",
    "Field Adjuster": "Royal Adjusting Service",
    "Phone Number": "817-757-2094"
  },
  {
    "<ExamininerCD>": "09",
    "Field Adjuster": "Martha Lozano",
    "Phone Number": "972-595-5705"
  },
  {
    "<ExamininerCD>": "21",
    "Field Adjuster": "Eileen Moring",
    "Phone Number": "972-595-5702"
  },
  {
    "<ExamininerCD>": "7",
    "Field Adjuster": "Haley Wooten",
    "Phone Number": "972-595-5700"
  },
  {
    "<ExamininerCD>": "24",
    "Field Adjuster": "Christopher Reeves",
    "Phone Number": "972-595-5704"
  },
  {
    "<ExamininerCD>": "30",
    "Field Adjuster": "Jessica Callan",
    "Phone Number": "972-595-5608"
  },
  // {
  //   "<ExamininerCD>": "WTA",
  //   "Field Adjuster": "TRANSCYND CLAIM PARTNERS",
  //   "Phone Number": "727-442-4900"
  // },
  {
    "<ExamininerCD>": "SUT",
    "Field Adjuster": "LAW SUIT",
    "Phone Number": ""
  },
  {
    "<ExamininerCD>": "SUB",
    "Field Adjuster": "SUBRO",
    "Phone Number": ""
  },
  {
    "<ExamininerCD>": "REP",
    "Field Adjuster": "ATTORNEY REPRESENTED",
    "Phone Number": ""
  },
  {
    "<ExamininerCD>": "SWA",
    "Field Adjuster": "SCHAFER, WOOD AND ASSOCIATES",
    "Phone Number": ""
  },
  {
    "<ExamininerCD>": "DEV",
    "Field Adjuster": "DEVAH ADJUSTING LLC",
    "Phone Number": ""
  },
  {
    "<ExamininerCD>": "KJS",
    "Field Adjuster": "KJS Claims Service",
    "Phone Number": "518-488-0331"
  },
  {
    "<ExamininerCD>": "MID",
    "Field Adjuster": "MID AMERICA CATASTROPHE SVCS",
    "Phone Number": "888-297-3092"
  },
  {
    "<ExamininerCD>": "PCG",
    "Field Adjuster": "PRIMECO CLAIMS GROUP",
    "Phone Number": ""
  }
];

export const atlasCredits = [
  {
    value: "Accredited Builder - (10% - 15%)",
    label: "Accredited Builder - (10% - 15%)"
  },
  {
    value: "Monitored Alarm - (5%)",
    label: "Monitored Alarm - (5%)"
  },
  {
    value: "Companion Auto (5%)",
    label: "Companion Auto (5%)"
  },
  {
    value:
      "Loss Free Experience Credit (5%) – Must be loss free with Atlas for two years",
    label:
      "Loss Free Experience Credit (5%) – Must be loss free with Atlas for two years"
  }
];

export const accreditedBuilderName = [
  { value: "ANGLIA HOMES", label: "ANGLIA HOMES" },
  { value: "ASHTON WOODS HOMES", label: "ASHTON WOODS HOMES" },
  { value: "ASGI HOMES", label: "ASGI HOMES" },
  { value: "AZALI HOMES", label: "AZALI HOMES" },
  { value: "ASHLYN HOMES", label: "ASHLYN HOMES" },
  { value: "BAYWAY HOMES", label: "BAYWAY HOMES" },
  { value: "B&M Homes", label: "B&M Homes" },
  { value: "BEAZER HOMES", label: "BEAZER HOMES" },
  { value: "BEST HOMES", label: "BEST HOMES" },
  { value: "BETENBOUGH HOMES", label: "BETENBOUGH HOMES" },
  { value: "BLOOMFIELD HOMES LP", label: "BLOOMFIELD HOMES LP" },
  { value: "BlueHaven Homes", label: "BlueHaven Homes" },
  { value: "BRASELTON HOMES", label: "BRASELTON HOMES" },
  { value: "BRIGHTON HOMES", label: "BRIGHTON HOMES" },
  { value: "CAMBRIDGE BUILDERS", label: "CAMBRIDGE BUILDERS" },
  { value: "CARMEL BUILDERS", label: "CARMEL BUILDERS" },
  {
    value: "Carothers Executive Homes",
    label: "Carothers Executive Homes"
  },
  { value: "CASTLEROCK COMMUNITIES", label: "CASTLEROCK COMMUNITIES" },
  { value: "CENTEX", label: "CENTEX" },
  { value: "CENTURY COMMUNITIES", label: "CENTURY COMMUNITIES" },
  { value: "CERVELLE HOMES", label: "CERVELLE HOMES" },
  { value: "CHESMAR HOMES", label: "CHESMAR HOMES" },
  { value: "CHOICE HOMES", label: "CHOICE HOMES" },
  { value: "COMPASS POINTE", label: "COMPASS POINTE" },
  { value: "COUTO HOMES", label: "COUTO HOMES" },
  { value: "COVENTRY HOMES", label: "COVENTRY HOMES" },
  { value: "DAKOTA BLUE", label: "DAKOTA BLUE" },
  { value: "DARLING HOMES", label: "DARLING HOMES" },
  { value: "DAVID WEEKLY", label: "DAVID WEEKLY" },
  { value: "DEERWOOD HOMES", label: "DEERWOOD HOMES" },
  { value: "DEVON STREET HOMES", label: "DEVON STREET HOMES" },
  { value: "DEVONSHIRE CUSTOM HOMES", label: "DEVONSHIRE CUSTOM HOMES" },
  { value: "D R HORTON", label: "D R HORTON" },
  { value: "DUNN and STONE BUILDERS", label: "DUNN and STONE BUILDERS" },
  { value: "DSW Homes", label: "DSW Homes" },
  { value: "ESPERANZA HOMES", label: "ESPERANZA HOMES" },
  {
    value: "FEDRICK, HARRIS ESTATE HOMES",
    label: "FEDRICK, HARRIS ESTATE HOMES"
  },
  { value: "FIRST AMERICA HOMES", label: "FIRST AMERICA HOMES" },
  { value: "FIRST TEXAS HOMES", label: "FIRST TEXAS HOMES" },
  { value: "FOX & JACOBS", label: "FOX & JACOBS" },
  { value: "FOX HOME BUILDERS", label: "FOX HOME BUILDERS" },
  {
    value: "FRONTIER CUSTOM BUILDERS",
    label: "FRONTIER CUSTOM BUILDERS"
  },
  { value: "GATEWAY HOMES", label: "GATEWAY HOMES" },
  { value: "GEHAN HOMES", label: "GEHAN HOMES" },
  { value: "GRACE POINT HOMES", label: "GRACE POINT HOMES" },
  { value: "GRAND VIEW BUILDERS", label: "GRAND VIEW BUILDERS" },
  { value: "GREENECO BUILDERS", label: "GREENECO BUILDERS" },
  { value: "HALLMARK DESIGN HOMES", label: "HALLMARK DESIGN HOMES" },
  { value: "HIGHLAND HOMES", label: "HIGHLAND HOMES" },
  { value: "HISTORY MAKER HOMES", label: "HISTORY MAKER HOMES" },
  { value: "HOGAN HOMES", label: "HOGAN HOMES" },
  { value: "HOMELINK HOMES", label: "HOMELINK HOMES" },
  { value: "HORIZON HOMES", label: "HORIZON HOMES" },
  { value: "IMPRESSION HOMES", label: "IMPRESSION HOMES" },
  { value: "J. PATRICK HOMES", label: "J. PATRICK HOMES" },
  {
    value: "JOHN HOUSTON CUSTOM HOMES",
    label: "JOHN HOUSTON CUSTOM HOMES"
  },
  { value: "K B HOMES", label: "K B HOMES" },
  { value: "K. HOVNANIAN HOMES", label: "K. HOVNANIAN HOMES" },
  { value: "KIMBERLITE HOMES", label: "KIMBERLITE HOMES" },
  { value: "LEGEND HOMES", label: "LEGEND HOMES" },
  { value: "LENNAR HOMES", label: "LENNAR HOMES" },
  { value: "LGI HOMES", label: "LGI HOMES" },
  { value: "M/I HOMES", label: "M/I HOMES" },
  { value: "M STREET HOMES", label: "M STREET HOMES" },
  { value: "MAIN STREET HOMES", label: "MAIN STREET HOMES" },
  { value: "MEGATEL HOMES", label: "MEGATEL HOMES" },
  { value: "MERCEDES HOMES", label: "MERCEDES HOMES" },
  { value: "MERITAGE HOMES", label: "MERITAGE HOMES" },
  { value: "MHI/McGUYER", label: "MHI/McGUYER" },
  { value: "MLB CUSTOM HOMES", label: "MLB CUSTOM HOMES" },
  { value: "MORRISON HOMES OF TEXAS", label: "MORRISON HOMES OF TEXAS" },
  { value: "NEWLEAF HOMES", label: "NEWLEAF HOMES" },
  { value: "NEWMARK HOMES", label: "NEWMARK HOMES" },
  { value: "NuHOME DESIGNS", label: "NuHOME DESIGNS" },
  { value: "N&B Homes", label: "N&B Homes" },
  {
    value: "OAKWOOD CUSTOM HOMES GROUP LTD",
    label: "OAKWOOD CUSTOM HOMES GROUP LTD"
  },
  { value: "OMEGA BUILDERS", label: "OMEGA BUILDERS" },
  { value: "ONYX HOMES", label: "ONYX HOMES" },
  { value: "PARKWOOD BUILDERS", label: "PARKWOOD BUILDERS" },
  { value: "PARTNERS IN BUILDING", label: "PARTNERS IN BUILDING" },
  { value: "PERRY HOMES", label: "PERRY HOMES" },
  { value: "PIONEER HOMES", label: "PIONEER HOMES" },
  { value: "PLANTATION HOMES", label: "PLANTATION HOMES" },
  { value: "PREMIER HOMES BUILDERS", label: "PREMIER HOMES BUILDERS" },
  { value: "PRINCE HOME BUILDERS", label: "PRINCE HOME BUILDERS" },
  { value: "PRINCETON CLASSIC HOMES", label: "PRINCETON CLASSIC HOMES" },
  {
    value: "PROVIDENTIAL CUSTOM HOMES",
    label: "PROVIDENTIAL CUSTOM HOMES"
  },
  { value: "PULTE", label: "PULTE" },
  { value: "ROYCE HOMES", label: "ROYCE HOMES" },
  { value: "RAVENNA HOMES", label: "RAVENNA HOMES" },
  { value: "RJ WACHSMAN HOMES", label: "RJ WACHSMAN HOMES" },
  { value: "ROBBIE HALE HOMES", label: "ROBBIE HALE HOMES" },
  { value: "RYLAND HOMES", label: "RYLAND HOMES" },
  { value: "SARATOGA HOMES", label: "SARATOGA HOMES" },
  {
    value: "SOUTH TEXAS HOME BUILDERS, INC",
    label: "SOUTH TEXAS HOME BUILDERS, INC"
  },
  { value: "SCOTT THOMAS HOMES", label: "SCOTT THOMAS HOMES" },
  { value: "SHEPHERD PLACE HOMES", label: "SHEPHERD PLACE HOMES" },
  { value: "SIMMONS HOMES", label: "SIMMONS HOMES" },
  { value: "SITTERLE HOMES", label: "SITTERLE HOMES" },
  { value: "STANDARD PACIFIC HOMES", label: "STANDARD PACIFIC HOMES" },
  { value: "STATELY BUILDERS", label: "STATELY BUILDERS" },
  { value: "STYLECRAFT BUILDERS", label: "STYLECRAFT BUILDERS" },
  { value: "SUMEER HOMES", label: "SUMEER HOMES" },
  { value: "TAYLOR MORRISON", label: "TAYLOR MORRISON" },
  { value: "TILSON HOMES", label: "TILSON HOMES" },
  { value: "TREMONT HOMES", label: "TREMONT HOMES" },
  { value: "TRENDMAKER HOMES", label: "TRENDMAKER HOMES" },
  { value: "TRIUMPH HOMES", label: "TRIUMPH HOMES" },
  { value: "TROY BUILDERS", label: "TROY BUILDERS" },
  { value: "VILLAGE BUILDERS", label: "VILLAGE BUILDERS" },
  { value: "WESTIN HOME", label: "WESTIN HOME" },
  { value: "WILSHIRE HOMES", label: "WILSHIRE HOMES" },
  { value: "WOODY BUTLER HOMES", label: "WOODY BUTLER HOMES" }
];

export const hailResistantRoof = [
  {
    value: "0",
    label: "None"
  },
  {
    value: "1",
    label: "Class 1"
  },
  {
    value: "2",
    label: "Class 2"
  },
  {
    value: "3",
    label: "Class 3"
  },
  {
    value: "4",
    label: "Class 4"
  }
];

export const occupiedDailyOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Vacant", label: "Vacant" }
];

export const roofStructureOptions = [
  { value: "4", label: "Other" },
  { value: "2-Box Gable", label: "Box Gable" },
  { value: "3-Flat", label: "Flat" },
  { value: "4-Gambrel", label: "Gambrel" },
  { value: "1-Hip", label: "Hip" },
  { value: "4-Jerkinhead", label: "Jerkinhead" },
  { value: "4-Mansard", label: "Mansard" },
  { value: "2-Open Gable", label: "Open Gable" },
  { value: "1-Pyramid Hip", label: "Pyramid Hip" },
  { value: "4-Shed", label: "Shed" }
];

export const adjusterFirms = [
  {
    "<ExamininerCD>": "CPS",
    "Field Adjuster": "COMPASS ADJUSTING",
    "Phone Number": "817-731-5008"
  },
  {
    "<ExamininerCD>": "CLC",
    "Field Adjuster": "CLAIMCOR SOLUTIONS",
    "Phone Number": "813-579-6226"
  },
  {
    "<ExamininerCD>": "RAS",
    "Field Adjuster": "ROYAL ADJUSTING SERVICES",
    "Phone Number": "817-757-2094"
  },
  {
    "<ExamininerCD>": "09",
    "Field Adjuster": "Martha Lozano",
    "Phone Number": "972-595-5705"
  },
  {
    "<ExamininerCD>": "30",
    "Field Adjuster": "Jessica Callan",
    "Phone Number": "972-595-5708"
  },
  {
    "<ExamininerCD>": "EBR",
    "Field Adjuster": "Eberl Claims Service LLCS",
    "Phone Number": "303-988-6286"
  },
  {
    "<ExamininerCD>": "SUT",
    "Field Adjuster": "LAW SUIT",
    "Phone Number": ""
  },
  {
    "<ExamininerCD>": "SUB",
    "Field Adjuster": "SUBRO",
    "Phone Number": ""
  },
  {
    "<ExamininerCD>": "REP",
    "Field Adjuster": "ATTORNEY REPRESENTED",
    "Phone Number": ""
  },
  {
    "<ExamininerCD>": "DEV",
    "Field Adjuster": "DEVAH ADJUSTING LLC",
    "Phone Number": "713-725-3699"
  },
  {
    "<ExamininerCD>": "KJS",
    "Field Adjuster": "KJS CLAIM SERVICE",
    "Phone Number": "518-488-0331"
  },
  {
    "<ExamininerCD>": "MID",
    "Field Adjuster": "MID-AMERICA CAT",
    "Phone Number": "888-297-3092"
  },
  {
    "<ExamininerCD>": "PCG",
    "Field Adjuster": "PRIMECO CLAIMS GROUP",
    "Phone Number": "918-442-1300"
  },
  {
    "<ExamininerCD>": "20",
    "Field Adjuster": "Ysabel Jopling",
    "Phone Number": "972-595-5701"
  },
  {
    "<ExamininerCD>": "28",
    "Field Adjuster": "Phyllis Shamu",
    "Phone Number": "972-595-5704"
  },
  {
    "<ExamininerCD>": "43",
    "Field Adjuster": "Morgan Shook",
    "Phone Number": "972-595-5706"
  },
  {
    "<ExamininerCD>": "39",
    "Field Adjuster": "Sam Moore",
    "Phone Number": "972-595-5709"
  },
  {
    "<ExamininerCD>": "40",
    "Field Adjuster": "Ellea Campillo",
    "Phone Number": "972-595-5703"
  },
  {
    "<ExamininerCD>": "31",
    "Field Adjuster": "Atlas Claims",
    "Phone Number": "972-595-5700"
  },
  {
    "<ExamininerCD>": "97",
    "Field Adjuster": "Wind Adjuster",
    "Phone Number": "972-595-5700"
  },
  {
    "<ExamininerCD>": "TPA",
    "Field Adjuster": "TPA Desk Examiner",
    "Phone Number": ""
  }
];

export const fieldAdjusterOptions = fieldAdjusters.map((fa: any) => ({
  label: `${fa["<ExamininerCD>"]} - ${fa["Field Adjuster"]}`,
  value: `${fa["<ExamininerCD>"]} - ${fa["Field Adjuster"]}`
}));

export const fieldAdjustingFirmsOptions = adjusterFirms.map((fa: any) => ({
  label: `${fa["Field Adjuster"].toUpperCase()}`,
  value: `${fa["<ExamininerCD>"]} - ${fa["Field Adjuster"]}`
}));

export const formUsage = [
  { value: "Optional", label: "Optional" },
  { value: "Mandatory", label: "Mandatory" }
];

export const allRoofMaterialOptions = [
  { label: "Composition", value: "6" },
  { label: "Tile", value: "7" },
  { label: "Metal", value: "8" },
  { label: "All Others", value: "9" }
];

export const waterLimitOptions = [
  {
    label: "2,500",
    value: "2500"
  },
  {
    label: "5,000",
    value: "5000"
  },
  {
    label: "10,000",
    value: "10000"
  },
  {
    label: "15,000",
    value: "15000"
  },
  {
    label: "20,000",
    value: "20000"
  },
  {
    label: "25,000",
    value: "25000"
  }
];

export const limitedSlabFoundationOptions = [
  {
    label: "2,500",
    value: "2500"
  },
  {
    label: "5,000",
    value: "5000"
  },
  {
    label: "10,000",
    value: "10000"
  },
  {
    label: "15,000",
    value: "15000"
  }
];

export const monitoringAlarmOptions = [
  {
    label: "None",
    value: 0
  },
  {
    label: "Multi-Function Central (15%)",
    value: 1
  },
  {
    label: "Multi-Function Local (4%)",
    value: 2
  },
  {
    label: "Multi-Function Remote (8%)",
    value: 3
  },
  {
    label: "Single-Function Central (5%)",
    value: 4
  },
  {
    label: "Single-Function Local (2%)",
    value: 5
  },
  {
    label: "Single-Function Remote (4%)",
    value: 6
  },
  {
    label: "Monitored Alarm - (5%)",
    value: 7
  }
];

export const without26 = "(26 w/out wind)";

export const limitDed1OptionsCompanyGreaterThan4 = [
  { label: `$250 (26)`, value: "250" },
  { label: `$500 (26)`, value: "500" },
  { label: `$1000 (26)`, value: "1000" }
];

export const limitDed1OptionsCompany = [
  { label: "1 %", value: "1.0000" },
  { label: "1.5 %", value: "1.5000" },
  { label: "2 %", value: "2.0000" },
  { label: "2.5 %", value: "2.5000" },
  { label: "3 %", value: "3.0000" },
  { label: "4 %", value: "4.0000" },
  { label: "5 %", value: "5.0000" }
];

export const billingPlanOptions = [
  { label: "In Full", value: "1" },
  { label: "Monthly", value: "2" },
  { label: "Quarterly", value: "3" },
  { label: "Semi-Annual", value: "4" }
  // { label: "3 Pay", value: "5" }
];

export const customBulkMenuOptions = [
  { title: "Not Submitted", disabled: false },
  { title: "Received", disabled: false },
  { title: "Submitted", disabled: false },
  { title: "Issued", disabled: false },
  { title: "Rejected", disabled: false }
];

export const PLAOptions = [
  {
    label: "Submitted",
    value: "submitted"
  },
  {
    label: "Received",
    value: "received"
  },
  {
    label: "Rejected",
    value: "rejected"
  }
];
export const oldCompanies = [
  { value: "18", label: "Southern Insurance Company" },
  { value: "21", label: "Republic Underwriters" },
  { value: "23", label: "Gulfstream Insurance" },
  { value: "24", label: "Tower Hill Prime Insurance Company" },
  { value: "25", label: "Tower Hill Signature Insurance Company" }
];

export const waterCraftTypes = [
  {
    label: "I/O motor HP < 16 and < 26ff",
    value: "I1626F"
  },
  {
    label: "I/O motor HP < 16 and < 26-40ff",
    value: "I1640F"
  },
  {
    label: "I/O motor HP < 16 and > 40ff",
    value: "I1641F"
  },
  {
    label: "I/O motor HP 16-30 and < 26ff",
    value: "I3026F"
  },
  {
    label: "I/O motor HP 16-30 and > 26-40ff",
    value: "I3040F"
  },
  {
    label: "I/O motor HP 16-30 and > 40ff",
    value: "I3041F"
  },
  {
    label: "I/O motor HP > 30 and < 26ff",
    value: "I3126F"
  },
  {
    label: "I/O motor HP < 30 and < 26-40ff",
    value: "I3140F"
  },
  {
    label: "OutBoard motor HP 26-49",
    value: "O26MPH"
  },
  {
    label: "OutBoard motor HP 50 and over",
    value: "O50MPH"
  },
  {
    label: "Sail Boat NO Aux 26-40ff",
    value: "SA40F"
  }
];

export type completedStatus = "submitted" | "received" | "rejected" | "issued";
export const completedStatuses: completedStatus[] = [
  "submitted",
  "received",
  "rejected",
  "issued"
];
