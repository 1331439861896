import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  PropertyChangeRequestState,
  PropertyChangeRequestMutations,
  StateKeys
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { getPropertyChangeRequestDefaults } from "@/helpers/getPropertyChangeRequestDefaults";
import { IPropertyChangeRequest } from "../../../helpers/storageFormBaseInterface";
import { defaultState } from "./state";
export const mutations: DefineMutations<
  PropertyChangeRequestMutations,
  PropertyChangeRequestState
> = {
  [types.SET_STATE]: GenericSetState<PropertyChangeRequestState, StateKeys>(),
  [types.SET_PROPERTY_CHANGE_REQUEST]: GenericSetItem<
    PropertyChangeRequestState,
    IPropertyChangeRequest
  >("propertyChangeRequests", getPropertyChangeRequestDefaults),
  [types.SET_STATES]: GenericSetStates<PropertyChangeRequestState>(),
  [types.SET_EDIT]: GenericSetEdit<
    PropertyChangeRequestState,
    string | IPropertyChangeRequest
  >("propertyChangeRequests", getPropertyChangeRequestDefaults),
  [types.SET_EDIT_PROPERTY_CHANGE_REQUEST]: GenericSetEditField<
    PropertyChangeRequestState,
    keyof IPropertyChangeRequest
  >(),
  [types.SET_ITEM]: GenericSetItem<
    PropertyChangeRequestState,
    IPropertyChangeRequest
  >("propertyChangeRequests", getPropertyChangeRequestDefaults),
  [types.DELETE_ITEM]: GenericDeleteItem<PropertyChangeRequestState>(
    "propertyChangeRequests",
    "_id"
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    PropertyChangeRequestState,
    keyof IPropertyChangeRequest
  >(),
  [types.CLEAR_PROPERTY_CHANGE_REQUEST_STORE]: GenericClearStore<
    PropertyChangeRequestState
  >(defaultState())
};
