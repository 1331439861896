/**
 * a plugin to inject globally values that
 * are used frequently and in multiple places
 *
 * all auth getters in fact are a good starting point
 * @param Vue
 */
import store from "@/store";
import {
  doNotify,
  doNotifyError,
  doNotifySuccess,
  doNotifyInfo
} from "../helpers/app-notification";

export default function install(Vue: any) {
  const appStore = Vue.$store || store;

  Vue.prototype.$appNotify = (o: any) => doNotify(o);
  Vue.prototype.$appNotifySuccess = (
    message: any,
    title?: string,
    duration?: number
  ) => doNotifySuccess(message, title, duration);
  Vue.prototype.$appNotifyError = (
    message: any,
    title?: string,
    duration?: number
  ) => doNotifyError(message, title, duration);
  Vue.prototype.$appNotifyInfo = (
    message: any,
    title?: string,
    duration?: number
  ) => doNotifyInfo(message, title, duration);

  Object.defineProperty(Vue.prototype, "$isLoginMfa", {
    get() {
      return appStore.getters["auth/isLoginMfa"];
    }
  });
  Object.defineProperty(Vue.prototype, "$isLoggedIn", {
    get() {
      return appStore.getters["auth/isLoggedIn"];
    }
  });
  Object.defineProperty(Vue.prototype, "$isCurrentUserAdmin", {
    get() {
      return appStore.getters["auth/isCurrentUserAdmin"];
    }
  });
  Object.defineProperty(Vue.prototype, "$getCurrentUser", {
    get() {
      return appStore.getters["auth/getCurrentUser"];
    }
  });
  Object.defineProperty(Vue.prototype, "$isCurrentUserAgencyAdmin", {
    get() {
      return appStore.getters["auth/getCurrentUser"]?.role === "agencyAdmin";
    }
  });
  Object.defineProperty(Vue.prototype, "$isCurrentUserSubAdmin", {
    get() {
      return appStore.getters["auth/getCurrentUser"]?.role === "subAdmin";
    }
  });
  Object.defineProperty(Vue.prototype, "$isCurrentUserUnderwriter", {
    get() {
      return appStore.getters["auth/getCurrentUser"]?.role === "underwriting";
    }
  });
  Object.defineProperty(Vue.prototype, "$isCurrentUserClaims", {
    get() {
      return appStore.getters["auth/getCurrentUser"]?.role === "claims";
    }
  });
  Object.defineProperty(Vue.prototype, "$isCurrentUserBilling", {
    get() {
      return appStore.getters["auth/getCurrentUser"]?.role === "billing";
    }
  });
  Object.defineProperty(Vue.prototype, "$isCurrentUserDataEntry", {
    get() {
      return appStore.getters["auth/getCurrentUser"]?.role === "dataEntry";
    }
  });
  Object.defineProperty(Vue.prototype, "$isCurrentUserEzlynx", {
    get() {
      return appStore.getters["auth/getCurrentUser"]?.role === "ezlynx";
    }
  });
  Object.defineProperty(Vue.prototype, "$getWindowSize", {
    get() {
      return appStore.getters["app/getWindowSize"];
    }
  });
  Object.defineProperty(Vue.prototype, "$getAppTheme", {
    get() {
      return appStore.getters["app/getAppTheme"];
    }
  });
  Object.defineProperty(Vue.prototype, "$windowVisible", {
    get() {
      return appStore.state.app["windowVisible"];
    }
  });
  Object.defineProperty(Vue.prototype, "$showMobileMenu", {
    get() {
      return appStore.state.app["showMobileMenu"];
    }
  });
  Object.defineProperty(Vue.prototype, "$lastActivity", {
    get() {
      return appStore.state.app["lastActivity"];
    }
  });
  Object.defineProperty(Vue.prototype, "$isInEmergencyMaintenanceMode", {
    get() {
      const initData = appStore.state.auth["initData"];
      return initData ? initData.isInEmergencyMaintenanceMode : false;
    }
  });
  Object.defineProperty(Vue.prototype, "$isInFiservScheduledMaintenanceMode", {
    get() {
      const initData = appStore.state.auth["initData"];
      return initData ? initData.isInFiservScheduledMaintenanceMode : false;
    }
  });
  Object.defineProperty(Vue.prototype, "$emergencyMaintenanceModeMessage", {
    get() {
      const initData = appStore.state.auth["initData"];
      return initData ? initData.emergencyMaintenanceModeMessage : "";
    }
  });
  Object.defineProperty(Vue.prototype, "$numberOfUnreadNotifications", {
    get() {
      const initData = appStore.state.auth["initData"];
      return initData ? initData.unreadNotifications.length : 0;
    }
  });
  Object.defineProperty(Vue.prototype, "$canReadNotifications", {
    get() {
      const currentUser = appStore.getters["auth/getCurrentUser"];
      const permissions = currentUser ? currentUser.disabledPermissions : [];
      return !permissions.includes("notifications:read");
    }
  });
}
