import { Route } from "vue-router";
import store from "@/store";

const redirectToLogin = (next: any, from: string) =>
  next({ path: "/login", query: { redirectFrom: from } });
const redirectToError = (next: any) => next({ path: "/not-authorized" });
const isRouteAuthenticationRequired = (toRoute: Route) => ({
  required: toRoute?.matched?.some((route: any) => route.meta.requireAuth),
  allowed: toRoute?.meta?.allowedRoles
});

export function userHasRouteAccessDisabled(
  routePermissions: string[],
  disabledPermissions: string[]
): boolean {
  for (const routePermission of routePermissions) {
    if (disabledPermissions.includes(routePermission)) {
      return true;
    }
  }
  return false;
}

export const canAccessRoute = async (
  toRoute: Route,
  fromRoute: Route,
  next: any
): Promise<void> => {
  // Check whether the route requires authentication. If not, continue.
  const { required, allowed } = isRouteAuthenticationRequired(toRoute);
  if (!required) {
    return next();
  }

  // Route requires authentication. Check to make sure they are logged in.
  const isLoggedIn = store.getters["auth/isLoggedIn"];
  if (isLoggedIn) {
    try {
      // User is logged in. Check to see if the user's role is allowed
      // to access the requested route. If not, redirect them to error page.
      const { role, disabledPermissions = [] } = store.getters[
        "auth/getCurrentUser"
      ];
      const isAllowed = allowed === "anyone" || allowed.includes(role);
      const { permissions: routePermissions = [] } = toRoute.meta || {
        permissions: []
      };
      if (
        !isAllowed ||
        userHasRouteAccessDisabled(routePermissions, disabledPermissions)
      ) {
        return redirectToError(next);
      }

      return next();
    } catch (e) {
      // Possibly there was an issue with token verification from server
      return redirectToLogin(next, toRoute.fullPath);
    }
  }

  return redirectToLogin(next, toRoute.fullPath);
};
