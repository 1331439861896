import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  alpha,
  alphaNum,
  integer,
  decimal,
  email
} from "vuelidate/lib/validators";

export function isValidEmail(emailAddress: string): boolean {
  return email(emailAddress);
}

export function isAlpha(text: any): boolean {
  return alpha(text);
}

export function isAlphaNum(text: any): boolean {
  return alphaNum(text);
}

export function isInteger(text: any): boolean {
  return integer(text);
}

export function isDecimal(text: any): boolean {
  return decimal(text);
}

export function isProvided(text: any): boolean {
  return required(text);
}

export function hasMinLength(text: any, size: number): boolean {
  return minLength(size)(text);
}

export function hasMaxLength(text: any, size: number): boolean {
  return maxLength(size)(text);
}

export function hasMinValue(text: any, value: number): boolean {
  return minValue(value)(text);
}

export function hasMaxValue(text: any, value: number): boolean {
  return maxValue(value)(text);
}
