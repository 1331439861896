import { DefineGetters } from "vuex-type-helper";
import {
  PayPlanChangeRequestGetters,
  PayPlanChangeRequestState
} from "./types";

export const getters: DefineGetters<
  PayPlanChangeRequestGetters,
  PayPlanChangeRequestState
> = {
  getPayPlanChangeRequestById: (state: PayPlanChangeRequestState) => (
    payPlanChangeRequestId: string
  ) =>
    state.payPlanChangeRequests.find(
      (payPlanChangeRequest: any) =>
        payPlanChangeRequest._id === payPlanChangeRequestId
    ),
  getPayPlanChangeRequestsFromStore: (state: PayPlanChangeRequestState) =>
    state.payPlanChangeRequests,
  getPayPlanChangeRequestsLinks: (state: PayPlanChangeRequestState) =>
    state.links,
  getTotalRecords: ({
    links,
    payPlanChangeRequests
  }: PayPlanChangeRequestState) =>
    links && links.totalRecords
      ? links.totalRecords
      : payPlanChangeRequests.length,
  getMaxQuerySize: ({
    links,
    payPlanChangeRequests
  }: PayPlanChangeRequestState) =>
    links && links.maxQueryLimit
      ? links.maxQueryLimit
      : payPlanChangeRequests.length
};
