import Vue from "vue";
import { contactUsMenu, FAQMenu } from "./menuItemsUser";

export const agencyAdminTopMenu = ({
  unreadNotifications
}: {
  unreadNotifications?: number;
}) => [
  {
    to: "/notifications",
    text: "",
    badgeNumber: unreadNotifications,
    icon: "message"
  }
];

export const agencyAdminPrimaryMenu = () => [
  {
    to: "/dashboard",
    text: "Home"
  },
  {
    to: "/agencyAdmin/agency/",
    text: Vue.prototype.__getText("menu", "agencyAdminAgencyLinkText")
  },
  {
    to: "/agencyAdmin/users",
    text: Vue.prototype.__getText("menu", "agentsLinkText"),
    icon: "users-cog",
    subLinks: [
      { to: "/agencyAdmin/users/new", text: "Add Agent" },
      { to: "/agencyAdmin/users", text: "Manage Agents" }
    ]
  },
  {
    to: "/Claims",
    text: "Claims",
    description: "Manage claims",
    icon: "folders",
    subLinks: [
      {
        to: "/claims",
        text: "Claims"
      },
      {
        to: "/claims-lookup",
        text: "Claims Status Lookup"
      },
      {
        to: "/file-claim",
        text: "File a Claim"
      }
    ]
  },
  {
    to: "/documents",
    permission: "documents:read",
    text: Vue.prototype.__getText("menu", "documentsLinkText"),
    description: "View Documents",
    icon: "folders",
    subLinks: [
      {
        permission: "agent_reports:read",
        to: "/agent-reports",
        text: "Agent Reports"
      },
      {
        to: "/documents",
        text: Vue.prototype.__getText("menu", "documentsLinkText"),
        description: "browse documents"
      }
    ]
  },
  {
    to: "/policy-inquiry",
    text: "Policy",
    description: "Policy Inquiry",
    icon: "",
    subLinks: [
      {
        to: "/billing",
        text: "Billing Inquiry",
        description: "Get billing information about policies",
        icon: ""
      },
      {
        to: "/quickbillpay",
        text: "Bill Pay",
        description: "Pay bills",
        icon: ""
      },
      {
        to: "/change-requests",
        text: Vue.prototype.__getText("menu", "changeRequestLinkText"),
        description: "Manage change requests",
        icon: "exchange"
      },
      {
        to: "/policy-inquiry",
        text: "Policy Inquiry"
      }
    ]
  },
  {
    to: "/quotes",
    text: "Quotes",
    description: "Manage Quotes",
    icon: "folders",
    subLinks: [
      {
        to: "/quotes",
        text: "Quotes"
      },
      {
        to: "/personal-liability-policies",
        text: "Personal Liability Application"
      }
    ]
  },
  contactUsMenu,
  FAQMenu
];
