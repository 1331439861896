import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  TransactionsState,
  TransactionsMutations,
  StateKeys,
  ITransaction
} from "./types";
import {
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericClearStore
} from "@/store/helpers";

import { getTransactionDefaults } from "@/helpers/transactions";
import { defaultState } from "./state";

export const mutations: DefineMutations<
  TransactionsMutations,
  TransactionsState
> = {
  [types.SET_STATE]: GenericSetState<TransactionsState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<TransactionsState>(),
  [types.SET_ITEM]: GenericSetItem<TransactionsState, ITransaction>(
    "transactions",
    getTransactionDefaults
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    TransactionsState,
    keyof ITransaction
  >(),
  [types.DELETE_ITEM]: GenericDeleteItem("transactions", "_id"),
  [types.SET_EDIT]: GenericSetEdit<
    TransactionsState,
    string | Partial<ITransaction>
  >("transactions", getTransactionDefaults),
  [types.CLEAR_TRANSACTIONS_STORE]: GenericClearStore<TransactionsState>(
    defaultState()
  )
};
