import Vue from "vue";
import { contactUsMenu, FAQMenu } from "./menuItemsUser";

export const adminTopMenu = ({
  unreadNotifications
}: {
  unreadNotifications?: number;
}) => [
  {
    to: "/admin/notifications",
    text: "",
    description: "view notifications",
    badgeNumber: unreadNotifications,
    icon: "message"
  }
];

// export const adminPrimaryMenu = [
export const adminPrimaryMenu = () => [
  {
    to: "/admin/dashboard",
    text: "Home"
  },
  {
    to: "/admin/users",
    text: Vue.prototype.__getText("menu", "usersLinkText"),
    description: "manage users"
  },
  {
    to: "/admin/agencies",
    text: "Agencies"
  },
  {
    to: "/admin/users",
    text: "Admin",
    description: "General Configs",
    subLinks: [
      {
        to: "/admin/accreditedBuilder",
        text: "Accredited Builder"
      },
      {
        to: "/adjuster-files",
        text: "Adjuster Portal"
      },
      {
        to: "/agent-reports",
        text: "Agent Reports"
      },
      {
        to: "/admin/agent-applications",
        text: "Agent Applications"
      },
      {
        to: "/admin/atlasPlatforms",
        text: "Atlas Platforms"
      },
      { to: "/admin/blocking", text: "Blocking" },
      { to: "/admin/companies", text: "Companies" },
      {
        to: "/admin/communications",
        text: Vue.prototype.__getText("menu", "communicationsLinkText")
      },
      { to: "/admin/counties", text: "Counties" },
      {
        to: "/admin/documents",
        text: Vue.prototype.__getText("menu", "documentsLinkText"),
        description: "browse documents"
      },
      {
        to: "/admin/document-categories",
        text: "Document categories"
      },
      {
        to: "/admin/faqs",
        text: "FAQs"
      },
      { to: "/admin/imports", text: "Imports" },
      { to: "/admin/mortgages", text: "Mortgages" },
      { to: "/admin/settings", text: "Settings" },
      {
        to: "/admin/transactions",
        text: "Transactions"
      },
      {
        to: "/admin/quoteCountyValidations",
        text: "Quote Validation"
      }
    ]
  },
  {
    to: "/Claims",
    text: "Claims",
    description: "Manage claims",
    icon: "folders",
    subLinks: [
      {
        to: "/claims",
        text: "Claims"
      },
      {
        to: "/claims-lookup",
        text: "Claims Status Lookup"
      },
      {
        to: "/file-claim",
        text: "File a Claim"
      }
    ]
  },
  {
    to: "/policy-inquiry",
    text: "Policy",
    description: "Policy Inquiry",
    icon: "",
    subLinks: [
      {
        to: "/billing",
        text: "Billing Inquiry",
        description: "Get billing information about policies",
        icon: ""
      },
      {
        to: "/quickbillpay",
        text: "Bill Pay",
        description: "Pay bills",
        icon: ""
      },
      {
        to: "/change-requests",
        text: Vue.prototype.__getText("menu", "changeRequestLinkText"),
        description: "Manage change requests",
        icon: "exchange"
      },
      {
        to: "/policy-inquiry",
        text: "Policy Inquiry"
      },
      {
        to: "/admin/signed-policies",
        text: "Signed Policies"
      }
    ]
  },
  {
    to: "/quotes",
    text: "Quotes",
    description: "Manage Quotes",
    icon: "folders",
    subLinks: [
      {
        to: "/admin/endorsements",
        text: "Endorsements"
      },
      {
        to: "/personal-liability-policies",
        text: "Personal Liability Application",
        description: "Personal Liability Policy application"
      },

      { to: "/admin/ppc", text: "PPC" },
      {
        to: "/admin/underwriting-questions",
        text: "Underwriting Questions"
      },
      {
        to: "/quotes",
        text: "Quotes"
      }
    ]
  },
  contactUsMenu,
  FAQMenu
];
