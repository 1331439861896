import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { ActivityState, ActivityMutations } from "./types";
import { Activity } from "@/types";
import has from "lodash/has";

export const mutations: DefineMutations<ActivityMutations, ActivityState> = {
  [types.SET_ACTIVITIES](state: ActivityState, payload: Activity | Activity[]) {
    const add = (activity: Activity) => {
      const activityIndex = state.activities.findIndex(
        u => u._id === activity._id
      );
      if (activityIndex > -1) {
        state.activities.splice(activityIndex, 1, activity);
      } else {
        state.activities.push(activity);
      }
    };
    if (Array.isArray(payload)) {
      payload.forEach(activity => add(activity));
    } else {
      add(payload);
    }
  },

  [types.SET_ACTIVITY_STATE](state: ActivityState, { key, value }): void {
    if (has(state, key)) {
      state[key] = value;
    }
  },

  [types.SET_ACTIVITY_LINKS](state: ActivityState, links) {
    state.activityLinks = links;
  },

  [types.DROP_ACTIVITIES](state: ActivityState) {
    state.activities = [];
  }
};
