import { DefineActions } from "vuex-type-helper";
import {
  PersonalLiabilityPolicyActions,
  PersonalLiabilityPolicyState,
  PersonalLiabilityPolicyGetters,
  PersonalLiabilityPolicyMutations,
  IPersonalLiabilityPolicy
} from "./types";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericDeleteOne,
  GenericUpdateOne,
  GenericGetOne
} from "@/store/helpers/actions";
import PersonalLiabilityPolicyService from "@/services/personalLiabilityPolicy.service";
import { getPersonalLiabilityPoliciesDefault } from "@/helpers/defaultPersonalLiabilityPolicy";

export const actions: DefineActions<
  PersonalLiabilityPolicyActions,
  PersonalLiabilityPolicyState,
  PersonalLiabilityPolicyMutations,
  PersonalLiabilityPolicyGetters
> = {
  createPersonalLiabilityPolicy: GenericCreateOne<
    PersonalLiabilityPolicyState,
    PersonalLiabilityPolicyGetters,
    PersonalLiabilityPolicyActions,
    PersonalLiabilityPolicyMutations,
    IPersonalLiabilityPolicy
  >(PersonalLiabilityPolicyService, types.SET_ITEM as any),
  getPersonalLiabilityPolicies: GenericGetMany<
    PersonalLiabilityPolicyState,
    PersonalLiabilityPolicyGetters,
    PersonalLiabilityPolicyActions,
    PersonalLiabilityPolicyMutations
  >(
    PersonalLiabilityPolicyService,
    "personalLiabilityPolicies",
    types.SET_ITEM as any
  ),
  getPersonalLiabilityPolicy: GenericGetOne<
    PersonalLiabilityPolicyState,
    PersonalLiabilityPolicyGetters,
    PersonalLiabilityPolicyActions,
    PersonalLiabilityPolicyMutations
  >(
    PersonalLiabilityPolicyService,
    types.SET_ITEM as any,
    getPersonalLiabilityPoliciesDefault
  ),
  deletePersonalLiabilityPolicy: GenericDeleteOne<
    PersonalLiabilityPolicyState,
    PersonalLiabilityPolicyGetters,
    PersonalLiabilityPolicyActions,
    PersonalLiabilityPolicyMutations
  >(PersonalLiabilityPolicyService, types.DELETE_ITEM as any),
  updatePersonalLiabilityPolicy: GenericUpdateOne<
    PersonalLiabilityPolicyState,
    PersonalLiabilityPolicyGetters,
    PersonalLiabilityPolicyActions,
    PersonalLiabilityPolicyMutations,
    { id: string; update: Partial<IPersonalLiabilityPolicy> }
  >(
    PersonalLiabilityPolicyService,
    types.SET_ITEM as any,
    getPersonalLiabilityPoliciesDefault
  )
};
