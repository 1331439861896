export class Session {
  get(id: string) {
    const value: any = sessionStorage.getItem(id);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
  set(id: string, value: any) {
    if (typeof value === "object") value = JSON.stringify(value);
    sessionStorage.setItem(id, value);
  }
  delete(id: string) {
    sessionStorage.removeItem(id);
  }
}
