import { AtlasfileState } from "./types";

export const defaultState = (): AtlasfileState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    atlasfiles: [],
    links: {}
  };
};

export const state = defaultState();
