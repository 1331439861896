import axios from "axios";
import { capitalize } from "lodash";
import { getPathAPI } from ".";

export async function downloadImportFileTemplate(
  collection: string
): Promise<void> {
  axios({
    url: `${getPathAPI()}/atlas-files/imports/templates?accessToken=${localStorage.getItem(
      "access"
    )}`,
    method: "POST",
    responseType: "blob",
    data: { collection }
  }).then((response: any) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${capitalize(collection)}ImportTemplate.csv`
    );
    document.body.appendChild(link);
    link.click();
  });
}
