import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  ClaimsState,
  ClaimsGetters,
  ClaimsMutations,
  ClaimsActions
} from "./types";

const namespaced = true;
export const namespace = "claims";

export const {
  mapState: claimsMapState,
  mapGetters: claimsMapGetters,
  mapMutations: claimsMapMutations,
  mapActions: claimsMapActions
} = createNamespacedHelpers<
  ClaimsState,
  ClaimsGetters,
  ClaimsMutations,
  ClaimsActions
>(namespace);

export const claims: Module<ClaimsState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
