import { UserState } from "./types";

export function defaultState(): UserState {
  return {
    users: [],
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    links: {},
    underWriters: []
  };
}

export const state = defaultState();
