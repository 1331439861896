import * as types from "@/store/mutation-types";
import { InterfaceMap } from "@/store/helpers";
import { ILinks } from "@/types";
import { IActionQueryPayload } from "@/store/types";
import { IUserModel } from "../../../types/interfaces/user";

export type AtlasFileCategory =
  | "Banner"
  | "Billing"
  | "Marketing"
  | "Policy Forms & Endorsements"
  | "Public Banners"
  | "Underwriting-Gulfstream"
  | "Underwriting-Republic"
  | "Underwriting-Southern"
  | "None";

export const AtlasFileCategoryArr = [
  "Banner",
  "Billing",
  "Marketing",
  "Policy Forms & Endorsements",
  "Public Banners",
  "Underwriting-Gulfstream",
  "Underwriting-Republic",
  "Underwriting-Southern",
  "None"
];

export interface AtlasfileState {
  editing: any; //TODO: TYPE ME PLEASE?.
  makingApiRequest: boolean;
  lastUpdate: Date;
  atlasfiles: any[]; //TODO: TYPE ME TOO PLEASE!
  links: ILinks | null;
}

export type StateKeys = keyof AtlasfileState;

export interface ISetStatePayload {
  key: StateKeys;
  value: any;
}

export interface AtlasfileGetters {
  getTotalRecords: number;
  getAtlasfileById: (id: string) => IAtlasfile;
}

export interface IAtlasfile {
  //TODO: type me please and don't go fooling about
  _id: string;
  name: string;
  type: string;
  ext: string;
  size: number;
  path: string;
  uploadsId: string;
  deleted: boolean;
  createdOn: Date;
  modifiedOn: Date;
  createdBy: string;
  deletedBy?: string;
  deletedOn?: Date;
  tags: string[];
  additionalSizes?: Record<string, any>[];
  isUploaded: boolean;
  uploadError?: string;
  awsVersionId?: string;
  isPublic: boolean;
  category: string;
  documentAccessConfig: any;
  createdByData?: IUserModel;
  deletedByData?: IUserModel;
}

export interface ISetIAtlasfileEditField {}

export interface AtlasfileMutations {
  [types.SET_STATE]: ISetStatePayload;
  [types.SET_STATES]: Partial<InterfaceMap<AtlasfileState>>;
  [types.SET_ITEM]: IAtlasfile | IAtlasfile[];
  [types.SET_EDIT]: string | IAtlasfile;
  [types.SET_EDIT_FIELD]: { key: keyof IAtlasfile; value: any };
  [types.DELETE_ITEM]: string;
  [types.CLEAR_ATLASFILE_STORE]: AtlasfileState;
}

export interface IAtlasFileExportPayload {
  collection: string;
  query: Record<string, any>;
}

export interface AtlasfileActions {
  createAtlasfile: any; //TYPE ME
  updateAtlasfile: { id: string; update: Partial<IAtlasfile> }; //TYPE ME
  getAtlasfile: string;
  getAtlasfiles: IActionQueryPayload;
  deleteAtlasFile: string;
  exportCollection: IAtlasFileExportPayload;
}
