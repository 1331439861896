import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  PolicySignState,
  PolicySignGetters,
  PolicySignMutations,
  PolicySignActions
} from "./types";

const namespaced = true;
export const namespace = "policySign";

export const {
  mapState: policySignMapState,
  mapGetters: policySignMapGetters,
  mapMutations: policySignMapMutations,
  mapActions: policySignMapActions
} = createNamespacedHelpers<
  PolicySignState,
  PolicySignGetters,
  PolicySignMutations,
  PolicySignActions
>(namespace);

export const policySign: Module<PolicySignState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
