
import { focusInputFields } from "@/helpers/focusInputField";
import { get } from "lodash";
import Vue from "vue";
export default Vue.extend({
  name: "TaxIdField",
  props: {
    value: {
      type: String,
      default: ""
    },
    taxIdMaskType: {
      type: String,
      required: false
    },
    defaultTaxIdMask: {
      type: String,
      default: "SSN",
      validator: val => {
        return val === "SSN" || val === "EIN";
      }
    },
    fieldGroupClass: {
      type: String,
      default: "pb-12"
    },
    required: {
      type: Boolean,
      default: true,
      required: false
    },
    label: {
      type: String,
      default: "Tax Id",
      required: false
    },
    placeholder: {
      type: String,
      default: "xxx-xx-2077",
      required: false
    },
    labelClass: {
      type: String,
      required: false,
      default: ""
    },
    prefixIcon: {
      type: String,
      required: false,
      default: ""
    },
    suffixIcon: {
      type: String,
      required: false,
      default: ""
    },
    fixedLabels: {
      type: Boolean,
      default: false
    },
    showMaskSelector: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      editTaxId: false,
      newValue: "",
      origValue: "",
      taxIdMask: "SSN"
    };
  },
  mounted() {
    if (this.taxIdMaskType) {
      this.taxIdMask = this.taxIdMaskType;
    } else {
      this.taxIdMask = this.defaultTaxIdMask;
    }
    this.origValue = this.value;
    this.newValue = this.value;
  },
  methods: {
    onFieldBlurred(): void {
      // if (
      //   (this.taxIdMask === "SSN" && this.newValue.length === 11) ||
      //   (this.taxIdMask === "EIN" && this.newValue.length === 10)
      // ) {
      //   this.$emit("input", this.newValue);
      // } else {
      //   this.newValue = this.origValue;
      // }
      // if (!this.newValue && this.value) {
      //   this.newValue = this.origValue;
      // }

      if (this.taxIdIsValid() || this.newValue === "") {
        this.$emit("input", this.newValue);
        this.$emit("blur");
        this.editTaxId = false;
      }
    },
    changeTaxIdMaskingFormat(maskingFormat: "SSN" | "EIN"): void {
      this.taxIdMask = maskingFormat;
      this.$emit("taxIdMaskChanged", maskingFormat);
    },
    handleInput(value: string): void {
      this.editTaxId = true;
      this.newValue = value;
      this.$emit("input", {
        value: this.newValue,
        isValid: this.taxIdIsValid()
      });
    },
    handleEditTaxId(): void {
      this.newValue = "";
      this.editTaxId = true;
    },
    clearData() {
      this.editTaxId = true;
      this.newValue = "";
      this.$emit("input", { value: this.newValue, isValid: true });
      focusInputFields(this.$refs.field_taxId);
    },
    taxIdIsValid(checkingValue?: string): boolean {
      const value = checkingValue
        ? checkingValue
        : get(this, "newValue", "") || "";
      return (
        (this.taxIdMask === "SSN" && value.length === 11) ||
        (this.taxIdMask === "EIN" && value.length === 10)
      );
    }
  },
  computed: {
    taxIdValueMasked(): string {
      const value = this.value;
      if (this.taxIdIsValid(value)) {
        if (this.taxIdMask === "SSN") {
          return "xxx-xx-" + value.substr(value.length - 4);
        } else {
          return "xx-xxx" + value.substr(value.length - 4);
        }
      }
      return value;
    },
    getTaxIdValidation: function(): any[] {
      const validations: any[] = [
        {
          method: "minLength",
          value: this.taxIdMask === "SSN" ? 11 : 10,
          error: `Minimum 9 characters!`
        },
        {
          method: "maxLength",
          value: this.taxIdMask === "SSN" ? 11 : 10,
          error: `Maximum 9 characters`
        }
      ];
      return validations;
    }
  }
});
