import { DefineGetters } from "vuex-type-helper";
import {
  AccreditedBuilderGetters,
  AccreditedBuilderState,
  IAccreditedBuilder
} from "./types";

export const getters: DefineGetters<
  AccreditedBuilderGetters,
  AccreditedBuilderState
> = {
  getAccreditedBuilderById: (state: AccreditedBuilderState) => (
    accreditedBuilderId: string
  ) =>
    state.accreditedBuilders.find(
      (accreditedBuilder: IAccreditedBuilder) =>
        accreditedBuilder._id === accreditedBuilderId
    ),
  editingAccreditedBuilder: (state: AccreditedBuilderState) => state.editing,
  accreditedBuilders: (state: AccreditedBuilderState) =>
    state.accreditedBuilders,
  isLoading: (state: AccreditedBuilderState) => state.makingApiRequest,
  getAccreditedBuildersLinks: (state: AccreditedBuilderState) => state.links,
  getTotalRecords: ({ links, accreditedBuilders }: AccreditedBuilderState) =>
    links && links.totalRecords ? links.totalRecords : accreditedBuilders.length
};
