import { CommunicationState } from "./types";

export const defaultState = (): CommunicationState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    communications: [],
    links: {},
    readersData: {
      links: {},
      readers: []
    }
  };
};

export const state = defaultState();
