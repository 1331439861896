
import Vue from "vue";

export default Vue.extend({
  name: "logout",
  data() {
    return {
      hovering: false
    };
  },
  computed: {
    icon(): string[] {
      return !this.hovering ? ["fas", "sign-out-alt"] : ["fal", "portal-exit"];
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    }
  }
});
