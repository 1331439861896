import { gbsHelpState } from "./types";

export const defaultState = (): gbsHelpState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    gbsHelps: [],
    links: {}
  };
};

export const state = defaultState();
