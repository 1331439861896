import { createNamespacedHelpers, Module } from "vuex";
import { actions } from "./actions";
import {
  SearchState,
  SearchGetters,
  SearchMutations,
  SearchActions
} from "./types";

const namespaced = true;
export const namespace = "search";

export const {
  mapState: searchMapState,
  mapGetters: searchMapGetters,
  mapMutations: searchMapMutations,
  mapActions: searchMapActions
} = createNamespacedHelpers<
  SearchState,
  SearchGetters,
  SearchMutations,
  SearchActions
>("search");

export const search: Module<SearchState, any> = {
  namespaced,
  state: {},
  getters: {},
  mutations: {},
  actions
};
