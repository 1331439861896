import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "@/services/response_wrapper";

class AppSettingsService extends BaseCollectionService {
  constructor() {
    super("app-settings");
  }
  public async getAppSettings(): Promise<ResponseWrapper> {
    const path = `${this.url}`;
    return await this.request(
      {
        method: "get",
        url: path
      },
      { useToken: "access" }
    );
  }
}

export const appSettingsService = new AppSettingsService();
