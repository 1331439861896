import {
  IImport,
  ImportActions,
  ImportState,
  ImportMutations,
  ImportGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import ImportService from "@/services/import.service";

export const actions: DefineActions<
  ImportActions,
  ImportState,
  ImportMutations,
  ImportGetters
> = {
  createImport: GenericCreateOne<
    ImportState,
    ImportGetters,
    ImportActions,
    ImportMutations,
    IImport
  >(ImportService, types.SET_ITEM as any),
  getImports: GenericGetMany<
    ImportState,
    ImportGetters,
    ImportActions,
    ImportMutations
  >(ImportService, "imports", types.SET_ITEM as any),
  getImport: GenericGetOne<
    ImportState,
    ImportGetters,
    ImportActions,
    ImportMutations
  >(ImportService, types.SET_ITEM as any),
  deleteImport: GenericDeleteOne<
    ImportState,
    ImportGetters,
    ImportActions,
    ImportMutations
  >(ImportService, types.DELETE_ITEM as any),
  updateImport: GenericUpdateOne<
    ImportState,
    ImportGetters,
    ImportActions,
    ImportMutations,
    { id: string; update: Partial<IImport> }
  >(ImportService, types.SET_ITEM as any),
  completeImport: async ({ commit }, importId) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await ImportService.completeImport(importId);
      const responseData = response.data;
      commit(types.SET_ITEM, response.data);
      commit(types.SET_EDIT, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
};
