import { FormBlock, FormBlockField, FormFieldGroupOptions } from "../../types";

// For a group of fields. Like a row.
export const useFormFieldGroup = (
  fields: FormBlockField[] = [],
  options: FormFieldGroupOptions = {}
): any => {
  const defaults = {
    layout: "full"
  };
  const classList = {
    full: ["w-full"],
    "3-col": ["w-full md:w-1/3"],
    "4-col": ["w-full md:w-1/4"],
    "5-col": ["w-full md:w-1/5"],
    "2-col": ["w-full md:w-1/2"]
  };

  const o = Object.assign({}, defaults, options);

  const fieldGroupClassList = {
    full: ["flex flex-col"],
    "3-col": ["flex flex-col md:flex-row has-3-col"],
    "4-col": ["flex flex-col md:flex-row has-4-col"],
    "2-col": ["flex flex-col md:flex-row has-2-col"],
    "5-col": ["flex flex-col md:flex-row has-5-col"]
  };

  return [
    {
      element: "html",
      tagName: "div",
      classList: [
        "field-group",
        "mb-6 md:mb-12",
        ...fieldGroupClassList[o.layout]
      ],
      children: fields.map((f: FormBlock) => ({
        ...f,
        ...{ classList: [...classList[o.layout], ...(f.classList || [])] }
      }))
    }
  ];
};
