import { NotificationState } from "./types";

export const defaultState = (): NotificationState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    notifications: [],
    links: {}
  };
};

export const state = defaultState();
