import {
  PropertyChangeRequestActions,
  PropertyChangeRequestState,
  PropertyChangeRequestMutations,
  PropertyChangeRequestGetters,
  IPolicyInquiryData
} from "./types";
import { IPropertyChangeRequest } from "../../../helpers/storageFormBaseInterface";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericDeleteOne,
  GenericUpdateOne,
  GenericGetOne
} from "@/store/helpers/actions";
import PropertyChangeRequestService from "@/services/propertychangerequest.service";
import { extractPolicyInquiryData } from "./utils";

export const actions: DefineActions<
  PropertyChangeRequestActions,
  PropertyChangeRequestState,
  PropertyChangeRequestMutations,
  PropertyChangeRequestGetters
> = {
  createPropertyChangeRequest: GenericCreateOne<
    PropertyChangeRequestState,
    PropertyChangeRequestGetters,
    PropertyChangeRequestActions,
    PropertyChangeRequestMutations,
    IPropertyChangeRequest
  >(PropertyChangeRequestService, types.SET_ITEM as any),
  getPropertyChangeRequests: GenericGetMany<
    PropertyChangeRequestState,
    PropertyChangeRequestGetters,
    PropertyChangeRequestActions,
    PropertyChangeRequestMutations
  >(
    PropertyChangeRequestService,
    "propertyChangeRequests",
    types.SET_ITEM as any
  ),
  getPropertyChangeRequest: GenericGetOne<
    PropertyChangeRequestState,
    PropertyChangeRequestGetters,
    PropertyChangeRequestActions,
    PropertyChangeRequestMutations
  >(PropertyChangeRequestService, types.SET_PROPERTY_CHANGE_REQUEST),
  deletePropertyChangeRequest: GenericDeleteOne<
    PropertyChangeRequestState,
    PropertyChangeRequestGetters,
    PropertyChangeRequestActions,
    PropertyChangeRequestMutations
  >(PropertyChangeRequestService, types.DELETE_ITEM as any),
  updatePropertyChangeRequest: GenericUpdateOne<
    PropertyChangeRequestState,
    PropertyChangeRequestGetters,
    PropertyChangeRequestActions,
    PropertyChangeRequestMutations,
    { id: string; update: Partial<IPropertyChangeRequest> }
  >(PropertyChangeRequestService, types.SET_ITEM as any),

  async scrubAddress({}, address) {
    const { data } = await PropertyChangeRequestService.scrubAddress(address);
    return data;
  },

  async policyNumberInquiry({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );

      const {
        data
      } = await PropertyChangeRequestService.getPolicyInquiryInformation(
        payload
      );
      return data;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  printPropertyChangeRequest: async ({ commit }, propertyChangeRequestIds) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await PropertyChangeRequestService.printPropertyChangeRequest(
        propertyChangeRequestIds
      );
      const responseData = response.data;
      if (response && response.data && response.data.changeRequests) {
        commit(types.SET_ITEM, response.data.changeRequests);
      }
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  async updateRequestsStatus({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await PropertyChangeRequestService.updateRequestsStatus(
        payload
      );
      const responseData = response.data;
      commit(types.SET_ITEM, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  async getPolicyInquiry({ commit }, payload): Promise<IPolicyInquiryData> {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await PropertyChangeRequestService.getPolicyInquiryInformation(
        payload
      );
      const responseData = response.data;
      commit(types.SET_ITEM, response.data);
      commit(types.SET_STATE, {
        key: "editing",
        value: extractPolicyInquiryData(responseData.data)
      });
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  async printPolicyInquiry({ commit }, payload): Promise<any> {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await PropertyChangeRequestService.printPolicyInquiryById(
        payload
      );
      const responseData = response.data;
      if (response && response.data) {
        commit(types.SET_ITEM, response.data);
      }
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  deleteBulkChangeRequests: async ({ commit }, changeRequestsIds) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await PropertyChangeRequestService.deleteChangeRequestsByIds(
        changeRequestsIds,
        "policy"
      );
      return response.data;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  async restorePropertyChangeRequests({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await PropertyChangeRequestService.restorePropertyChangeRequests(
        payload
      );
      return response;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
};
