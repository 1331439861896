import { AuthActions, AuthState, AuthMutations, AuthGetters } from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  IAuthLoginResponse,
  IAuthCheckResponse,
  IUserAuthCheck
} from "@/types";
import AuthService from "@/services/auth.service";
import { setCookie } from "@/helpers/generalHelpers";
const loggedInKey = "atlas_logged_in";

export const actions: DefineActions<
  AuthActions,
  AuthState,
  AuthMutations,
  AuthGetters
> = {
  async authCheckGetUser({ commit }) {
    try {
      const checkAuth = await AuthService.check();
      const checkAuthData: IAuthCheckResponse = checkAuth.data;
      const authorized = checkAuthData.authorized;
      const user = checkAuthData.user;
      commit(types.LOGIN);
      commit(types.SET_STATE, { value: user, key: "user" });
      commit(types.SET_STATE, {
        value: checkAuthData?.hasValidLicense,
        key: "hasValidLicense"
      });
      return { authorized };
    } catch (e) {
      localStorage.removeItem("access");
      commit(types.LOGOUT, false);
      throw e;
    }
  },
  async init({ commit }) {
    const response = await AuthService.init();
    commit(types.SET_STATE, { key: "initData", value: response.data });
    commit(types.SET_STATE, { key: "loadedInitData", value: true });
  },
  async authRefreshUserToken({ commit }) {
    const checkAuth = await AuthService.refreshToken();
    const checkAuthData: { token: string; user: IUserAuthCheck } =
      checkAuth.data;
    const token = checkAuthData.token;
    localStorage.setItem("access", token);
    const user = checkAuthData.user;
    commit(types.LOGIN);
    commit(types.SET_STATE, { value: user, key: "user" });
    return;
  },
  async login({ commit }, credentials) {
    const login = await AuthService.login(credentials);
    const loginData: IAuthLoginResponse = login.data;
    const { token, tokenType, loginStatusKey } = loginData;
    localStorage.setItem("loginStatusKey", loginStatusKey);
    setCookie(loggedInKey, Boolean(loginStatusKey), 1);

    if (tokenType === "application") {
      localStorage.setItem("access", token);
      commit(types.SET_STATES, {
        isLoggedIn: true,
        isLoginMfa: false
      });
    } else {
      localStorage.setItem("auth_mfa_request", token);
      commit(types.SET_STATES, {
        isLoginMfa: true,
        isLoggedIn: false
      });
    }
  },
  async authGetAdminPreviewToken({}) {
    const login = await AuthService.getAdminPreviewToken();
    const loginData: IAuthLoginResponse = login.data;
    const { token } = loginData;
    return token;
  },
  async register({}, userInfo) {
    const registrationResponse = await AuthService.register(userInfo);
    const registrationData = registrationResponse.data;
    return registrationData;
  },
  async checkEmailInUse({}, email) {
    const registrationResponse = await AuthService.checkEmailInUse(email);
    const registrationData = registrationResponse.data;
    return registrationData;
  },

  async authCheckMfaRequest({}) {
    const checkAuth = await AuthService.checkMfaRequest();
    const checkAuthData = checkAuth.data;
    return checkAuthData;
  },

  async authCheckMfaVerify({}) {
    const checkAuth = await AuthService.checkMfaVerify();
    const checkAuthData = checkAuth.data;
    return checkAuthData;
  },

  async requestMfaMethod({}, mfaMethod) {
    const checkAuth = await AuthService.requestMfaMethod(mfaMethod);
    const checkAuthData = checkAuth.data;
    const { token } = checkAuthData;
    localStorage.removeItem("auth_mfa_request");
    localStorage.setItem("auth_mfa_verify", token);
    return true;
  },

  async verifyMfa({ commit }, mfaVerificationCode) {
    const checkAuth = await AuthService.verifyMfaCode(mfaVerificationCode);
    localStorage.removeItem("auth_mfa_verify");
    const checkAuthData = checkAuth.data;
    const { token, tokenType } = checkAuthData;
    if (tokenType === "application") {
      localStorage.setItem("access", token);
      commit(types.LOGIN);
    }
    return checkAuthData;
  },
  async passwordReset({}, credentials) {
    return await AuthService.passwordReset(credentials);
  },
  async updatePassword({}, credentials) {
    return await AuthService.updatePassword(credentials);
  },
  async canResetPassword({}) {
    return await AuthService.canResetPassword();
  },
  async canClaimAccount({}) {
    return await AuthService.claimAccount();
  },
  async requestClaimAccount({}, credentials) {
    const { data } = await AuthService.requestClaimAccount(credentials);
    return data;
  },
  async confirmAccount({}) {
    return await AuthService.confirmAccount();
  },
  async requestConfirmAccount({}, credentials) {
    const { data } = await AuthService.requestConfirmAccount(credentials);
    return data;
  },

  async logout({ commit }) {
    try {
      commit("activities/DROP_ACTIVITIES", {}, { root: true });
      commit("user/CLEAR_USER_STORE", {}, { root: true });
      commit("quote/CLEAR_QUOTE_STORE", {}, { root: true });
      commit("adjusterfile/CLEAR_ADJUSTERFILE_STORE", {}, { root: true });
      commit("agency/CLEAR_AGENCY_STORE", {}, { root: true });
      commit(
        "agentApplications/CLEAR_AGENTAPPLICATIONS_STORE",
        {},
        { root: true }
      );
      commit("agentreport/CLEAR_AGENT_REPORT_STORE", {}, { root: true });
      commit("app/CLEAR_APP_STORE", {}, { root: true });
      commit("atlasfile/CLEAR_ATLASFILE_STORE", {}, { root: true });
      commit(
        "billingChangeRequest/CLEAR_BILLING_CHANGE_REQUEST_STORE",
        {},
        { root: true }
      );
      commit("chart/CLEAR_CHART_STORE", {}, { root: true });
      commit("claims/CLEAR_CLAIMS_STORE", {}, { root: true });
      commit("communication/CLEAR_COMMUNICATION_STORE", {}, { root: true });
      commit("companies/CLEAR_COMPANIES_STORE", {}, { root: true });
      commit("county/CLEAR_COUNTY_STORE", {}, { root: true });
      commit("endorsements/CLEAR_ENDORSEMENT_STORE", {}, { root: true });
      commit("gbsHelp/CLEAR_GBSHELP_STORE", {}, { root: true });
      commit("imports/CLEAR_IMPORT_STORE", {}, { root: true });
      commit("mortgage/CLEAR_MORTGAGE_STORE", {}, { root: true });
      commit("notification/CLEAR_NOTIFICATION_STORE", {}, { root: true });
      commit(
        "payplanchangerequest/CLEAR_PAYPLAN_CHANGE_REQUEST_STORE",
        {},
        { root: true }
      );
      commit(
        "personalLiabilityPolicy/CLEAR_PERSONAL_LIABILITY_POLICY_STORE",
        {},
        { root: true }
      );
      commit(
        "propertyanalytics/CLEAR_PROPERTY_ANALYTICS_STORE",
        {},
        { root: true }
      );
      commit(
        "propertychangerequest/CLEAR_PROPERTY_CHANGE_REQUEST_STORE",
        {},
        { root: true }
      );
      commit("setting/CLEAR_SETTING_STORE", {}, { root: true });
      commit("transactions/CLEAR_TRANSACTIONS_STORE", {}, { root: true });
      await AuthService.logout();
    } catch (error) {
      // Handle error if necessary
    } finally {
      setCookie(loggedInKey, "", 1);
      localStorage.removeItem("access");
      localStorage.removeItem("auth_mfa_request");
      localStorage.removeItem("auth_mfa_verify");
      commit(types.LOGOUT, false);
    }
  }
};
