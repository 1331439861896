export const quote = {
  buildingAmountMessage:
    "Looking to set building amount for different company/form type? Click here",
  onlyPrintSubmitted: "Only quotes that have been submitted will be printed",
  quotesNotSubmitted:
    "Quotes selected are not submitted and therefore can't be printed",
  quotesPrintAlertTitle: "Please note:",
  oldQuoteRatingInvalidated: "Quote has to be rerated.",
  endorsementHO160ANotice:
    "The following limits are the maximum available. Jewelry: $30,000 per item, $60,000 per schedule. Fine Arts: $2,000 per item, $10,000 per schedule."
};
