import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class PayplanchangerequestService extends BaseCollectionService {
  constructor() {
    super("storage-forms/pay-plan-change-requests");
  }
  public async printPayPlanChangeRequestById(
    payload: any
  ): Promise<ResponseWrapper> {
    const path = `/storage-forms/print-payplan-storage-form`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async updateRequestsStatus(payload: any): Promise<ResponseWrapper> {
    const path = `/storage-forms/pay-plan-change-requests/update-status`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async deleteChangeRequestsByIds(
    _ids: string[],
    type: string
  ): Promise<ResponseWrapper> {
    const path = `/storage-forms`;
    return await this.request(
      {
        method: "delete",
        url: path,
        data: { _ids, type }
      },
      { useToken: "access" }
    );
  }

  async restorePayPlanChangeRequests(data: Record<string, any>) {
    const path = `/storage-forms/restore`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: data
      },
      { useToken: "access" }
    );
  }
}

export default new PayplanchangerequestService();
