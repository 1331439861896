import defaultsDeep from "lodash/defaultsDeep";
import cloneDeep from "lodash/cloneDeep";
import {
  baseAgentApplications,
  baseApproveAgent
} from "@/helpers/defaultObjects";
import {
  IAgentApplications,
  IEditingApprove
} from "@/store/modules/agentApplications/types";

export function getDefaultAgentApplications(
  agentApplications: Partial<IAgentApplications>
): IAgentApplications {
  return defaultsDeep(
    cloneDeep(agentApplications),
    Object.assign(baseAgentApplications())
  );
}

export function getDefaultApproveAgent(
  approveAgent: Partial<IEditingApprove>
): IEditingApprove {
  return defaultsDeep(
    cloneDeep(approveAgent),
    Object.assign(baseApproveAgent())
  );
}
