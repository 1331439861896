export default [
  {
    path: "/admin/documents/category/new",
    component: () =>
      import("@/views/shared/atlasFiles/Category/AddCategory.vue"),
    meta: {
      pageTitle: "Add Category",
      requireAuth: true,
      allowedRoles: ["admin", "agencyAdmin", "subAdmin", "dataEntry"],
      metaTags: {
        title: "Add Category"
      },
      breadcrumbs: [
        {
          link: "/admin/documents",
          title: "Documents"
        }
      ]
    }
  },
  {
    path: "/admin/document-categories",
    component: () =>
      import("@/views/shared/atlasFiles/Category/ListDocumentCategories.vue"),
    meta: {
      pageTitle: "Document Categories",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Document Categories"
      }
    }
  },
  {
    path: "/admin/document-categories/:categoryId",
    component: () =>
      import("@/views/shared/atlasFiles/Category/SingleCategory.vue"),
    meta: {
      pageTitle: "Edit Document Categories",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Edit Document Categories"
      },
      breadcrumbs: [
        {
          link: "/admin/document-categories",
          title: "Document Categories"
        }
      ]
    }
  },
  {
    path: "/admin/documentCategories/activities",
    component: () =>
      import(
        "@/views/shared/atlasFiles/Category/Activities/DocumentCategoryAllActivities.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/document-categories",
          title: "Document Category"
        }
      ],
      pageTitle: "Document Category Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Document Category Activities"
      }
    }
  }
];
