import store from "@/store";
import { get } from "lodash";
import { ICompany } from "../../store/modules/companies/types";
import { getSite } from "@/helpers/companies";

export function UPPValidator(value: string) {
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) return false;
  const parsedBuildingAmount = parseFloat(
    get(store.state.quote.editing || {}, "amountDWP")
  );
  if (isNaN(parsedBuildingAmount)) return false;
  const percent = parsedValue / parsedBuildingAmount;
  return percent >= 0.3 && percent <= 0.8;
}

export function DWPValidator(value: string) {
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) return false;
  const parsedSquareFeet = parseFloat(
    get(store.state.quote.editing || {}, "squareFeet")
  );
  if (isNaN(parsedSquareFeet)) return false;
  const buildingAmountPerSquareFoot = Math.floor((95 + 85 + 60) / 3);
  const reasonableBuildingAmount =
    buildingAmountPerSquareFoot * parsedSquareFeet;

  return parsedValue >= reasonableBuildingAmount;
}

export function OTSValidator(value: string) {
  const amountOTS = parseFloat(value);
  if (isNaN(amountOTS)) return false;
  const buildingAmount = parseFloat(
    get(store.state.quote.editing || {}, "amountDWP")
  );
  if (isNaN(buildingAmount)) return false;
  const lowerBound = Math.round(buildingAmount * 0.1);
  const upperBound = Math.round(buildingAmount * 0.8);
  return amountOTS >= lowerBound && amountOTS <= upperBound; //lowerBound >= amountOTS && upperBound <= amountOTS;
}

export function getContentsAmountPerBuildingAmount(
  endorsementToCheck?: string
): number {
  const parsedBuildingAmount = getBuildingAmount();
  const percentageOfBuildingAmount = getBuildingAmountPercentage(
    endorsementToCheck
  );
  const result = Math.round(percentageOfBuildingAmount * parsedBuildingAmount);
  return result;
}

export function getOtherStructuresAmountPerBuildingAmount(): number {
  const parsedBuildingAmount = getBuildingAmount();
  return Math.round(parsedBuildingAmount / 10);
}

function getBuildingAmount(): number {
  return parseFloat(get(store.state.quote.editing || {}, "amountDWP") || 0);
}

export function getBuildingAmountPercentage(
  endorsementToCheck?: string
): number {
  let percentageOfBuildingAmount = 0;

  //do endorsement checks
  const dwellType = get(store.state.quote.editing || {}, "dwellType");
  const endorsements = get(store.state.quote.editing || {}, "endorsements");
  const endorsementsArray = endorsements.map((endorsement: any) => {
    return endorsement.formNumber;
  });

  if (checkHO401A_HO101B_HO101(endorsementToCheck, endorsementsArray)) {
    percentageOfBuildingAmount = 0.6;
  } else if (dwellType == "2") {
    percentageOfBuildingAmount = 0.3;
  } else {
    percentageOfBuildingAmount = 0.4;
  }
  return percentageOfBuildingAmount;
}

export function checkEndorsement(
  endorsementsToCheck: string | Array<string>,
  endorsementList: Array<string>
): boolean {
  if (Array.isArray(endorsementsToCheck)) {
    return endorsementList.some(
      (r: string) => endorsementsToCheck.indexOf(r) >= 0
    );
  } else {
    return endorsementList.indexOf(endorsementsToCheck) > -1;
  }
}

export function checkHO401A_HO101B_HO101(
  possibleHO401A_HO101B_HO101Endorsement: string | undefined,
  endorsementList: Array<string>
) {
  const HO401A_HO101B_HO101EndorsementArray = ["HO-401", "HO-101B", "HO-101"];
  if (
    possibleHO401A_HO101B_HO101Endorsement &&
    checkEndorsement(
      possibleHO401A_HO101B_HO101Endorsement,
      HO401A_HO101B_HO101EndorsementArray
    )
  ) {
    return checkEndorsement(
      HO401A_HO101B_HO101EndorsementArray,
      endorsementList
    );
  }
  return false;
}

export function checkIfIsAtlasCompany(companyNumbers: Array<number>) {
  return companyNumbers.some(
    (r: number) => [26, 27, 28, 29, 30].indexOf(r) >= 0
  );
}

export function hasStateWide(companyNumbers: Array<number>): boolean {
  return (
    companyNumbers && companyNumbers.length > 0 && companyNumbers.includes(99)
  );
}

export function hasSeacoast(companyNumbers: Array<number>): boolean {
  return (
    companyNumbers && companyNumbers.length > 0 && companyNumbers.includes(20)
  );
}

export function onlyStateWideSelected(companyNumbers: Array<number>): boolean {
  return (
    companyNumbers && companyNumbers.includes(99) && companyNumbers.length === 1
  );
}

export function onlySeacoastSelected(companyNumbers: Array<number>): boolean {
  return (
    companyNumbers && companyNumbers.includes(20) && companyNumbers.length === 1
  );
}

export function mCompanyBetween1And3(companies: ICompany[]): boolean {
  return (
    companies &&
    companies.length > 0 &&
    companies.filter(company => company.mCompany >= 1 && company.mCompany <= 3)
      .length > 0
  );
}

export function companyNumber28Or30(companyNumbers: Array<number>): boolean {
  return (
    companyNumbers &&
    companyNumbers.length > 0 &&
    (companyNumbers.includes(28) || companyNumbers.includes(30))
  );
}

export function isColonial(companyNumbers: Array<number>): boolean {
  companyNumbers = companyNumbers.filter(n => n !== null);
  let isColonial = companyNumbers
    .map(number => getSite(number))
    .some(site => site === "COLONIAL");

  if (!isColonial) {
    isColonial =
      companyNumbers &&
      companyNumbers.length > 0 &&
      (companyNumbers.includes(20) || companyNumbers.includes(99));
  }
  return isColonial;
}

export function hasSeacoastSeaTerrData(companyTerritories: any): boolean {
  return (
    companyTerritories &&
    companyTerritories.find((terr: any) => {
      return terr.companyNumber === 20 && terr.seaTerr;
    })
  );
}

export function mCompanyLessThanOrEqual3(companies: ICompany[]): boolean {
  return (
    companies &&
    companies.length > 0 &&
    companies.filter(company => company.mCompany <= 3).length > 0
  );
}

export function companyNumber28(companyNumbers: Array<number>): boolean {
  return (
    companyNumbers && companyNumbers.length > 0 && companyNumbers.includes(28)
  );
}

export function hasInsurorsIndemnity(companyNumbers: Array<number>): boolean {
  return (
    companyNumbers && companyNumbers.length > 0 && companyNumbers.includes(26)
  );
}

export function hasOnlyInsurorsIndemnity(
  companyNumbers: Array<number>
): boolean {
  return (
    companyNumbers && companyNumbers.length == 1 && companyNumbers.includes(26)
  );
}

export function hasAtlasCompaniesExceptInsurors(companyNumbers: Array<number>) {
  return companyNumbers.some((r: number) => [27, 28, 29, 30].indexOf(r) >= 0);
}

export function shouldAddRoofMaterial(
  companies: ICompany[],
  companyNumbers: Array<number>
): boolean {
  return (
    hasInsurorsIndemnity(companyNumbers) || // added 26 so it can be sent to PHP server for 'skip' status code to be returned instead
    mCompanyBetween1And3(companies) ||
    companyNumber28Or30(companyNumbers) ||
    isColonial(companyNumbers)
  );
}

export function hasColonialSeacoastSeaTerritoryOnly(
  companyTerritories: any,
  companyNumbers: Array<number>
): boolean {
  return (
    onlySeacoastSelected(companyNumbers) &&
    hasSeacoastSeaTerrData(companyTerritories)
  );
}

export function shouldAddTrampolineInFenceYardDF(
  companyNumbers: Array<number>
) {
  return (
    hasStateWide(companyNumbers) ||
    companyNumbers?.includes(24) ||
    companyNumbers?.includes(25) ||
    companyNumbers?.includes(27) ||
    companyNumbers?.includes(28) ||
    companyNumbers?.includes(29) ||
    companyNumbers?.includes(30)
  );
}

export function hasSeacoastAndNotSeacoastSeaTerritory(
  companyTerritories: any,
  companyNumbers: Array<number>
): boolean {
  return (
    hasSeacoast(companyNumbers) && !hasSeacoastSeaTerrData(companyTerritories)
  );
}

export function isPoolInApprovedFence(value: any, policyType: any): boolean {
  if (policyType === "H") {
    return value !== 0;
  }
  return true;
}
export function occupancyValidator(occupancy: string): boolean {
  return occupancy !== "T";
}

export function validateNumberOfWeatherClaims(
  value: number,
  policyType: any,
  companyNumbers: any
): boolean {
  let response = true;
  if (policyType === "D" || policyType === "H") {
    if (checkIfIsAtlasCompany(companyNumbers)) {
      if (value == 3) {
        response = false;
      }
    }
    if (isColonial(companyNumbers)) {
      if (value > 1) {
        response = false;
      }
    }
  }
  if (policyType === "T") {
    if (checkIfIsAtlasCompany(companyNumbers)) {
      if (value > 0) {
        response = false;
      }
    }
    if (isColonial(companyNumbers)) {
      if (value == 3) {
        response = false;
      }
    }
  }
  return response;
}

export function getBillingErrorSection(billingValue: string): any[] {
  let billingErrorSection: any[] = [];
  if (
    billingValue &&
    (billingValue === "2" || billingValue === "3" || billingValue === "4")
  ) {
    const descriptionBlock = [
      {
        element: "html",
        tagName: "p",
        innerText:
          "* An Installment Fee Of $5.00 Will Be Added To Each Payment",
        classList: ["mb-10", "font-bold", "text-yellow-500"]
      }
    ];
    billingErrorSection = descriptionBlock;
  }
  return billingErrorSection;
}

export function validateOccupiedDaily(
  value: string,
  policyType: string | undefined,
  companyNumbers: any
) {
  return !shouldHaveVacantOption(companyNumbers, policyType) &&
    value === "Vacant"
    ? false
    : true;
}

export function shouldHaveVacantOption(
  companyNumbers: any,
  policyType: string | undefined
): boolean {
  return (
    (companyNumbers &&
      companyNumbers.length > 0 &&
      companyNumbers.every((num: number) =>
        [18, 23, 24, 26, 28, 30].includes(num)
      )) ||
    policyType === "D"
  );
}

export function correctLineOfBusiness(policyType: string): string {
  switch (policyType) {
    case "H":
      policyType = "HO";
      break;
    case "D":
      policyType = "DF";
      break;
    case "T":
      policyType = "TC";
      break;
    default:
      break;
  }
  return policyType;
}

export function maxDwellingValidator(
  value: string
): { success: boolean; message: string } {
  const response = {
    success: true,
    message: ""
  };
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) {
    response.success = false;
    response.message = "Please enter a number";
  }
  const companyNumbers = get(store.state.quote.editing || {}, "companyNumbers");
  const baseErrorMessage = "Building Amount exceeds program limit";
  if (
    // check if companyNumbers contains only companies from a certain "limit group"
    // then apply that limit. If not we allow any, and skip when rating if invalid
    companyNumbers &&
    companyNumbers.length > 0
  ) {
    if (
      parsedValue > 350000 &&
      companyNumbers.every((num: number) => [20, 99].includes(num))
    ) {
      response.success = false;
      response.message = `${baseErrorMessage} $350,000.00`;
    } else if (
      parsedValue > 500000 &&
      companyNumbers.some((num: number) => [27, 28].includes(num)) &&
      !companyNumbers.some((num: number) => [26, 29, 30].includes(num))
    ) {
      response.success = false;
      response.message = `${baseErrorMessage} $500,000.00`;
    } else if (
      parsedValue > 600000 &&
      !companyNumbers.some((num: number) => [26].includes(num))
    ) {
      response.success = false;
      response.message = `${baseErrorMessage} $600,000.00`;
    } else if (parsedValue > 650000) {
      response.success = false;
      response.message = `${baseErrorMessage} $650,000.00`;
    }
  }
  return response;
}
