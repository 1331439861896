import {
  SocketIOActions,
  SocketIOState,
  SocketIOMutations,
  SocketIOGetters,
  IIOResourceUpdateData
} from "./types";
import { DefineActions } from "vuex-type-helper";
import store from "@/store";
import { maybeParseJSON } from "@/helpers/maybeParseJSON";

export function doQuoteSocketLogic(payload: IIOResourceUpdateData) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
  let [quoteId, ratingId] = payload.resourceId.split("-");
  const { retries = 0 } = maybeParseJSON(payload.data);
  const editingQuote = store.state.quote["editing"];
  if (editingQuote && editingQuote._id === quoteId) {
    ratingId = ratingId.toString();
    const socketRatingIds = store.state.quote["socketRatingIds"];
    if (!socketRatingIds.includes(ratingId) || retries !== 0) {
      console.log("getting quote for rating " + "\x1b[33m" + ratingId);
      store.dispatch("quote/getQuote", quoteId);
      store.commit("quote/ADD_RATING_ID", ratingId);
    }
  }
}

export function doAuthenticationSocketLogic(payload: IIOResourceUpdateData) {
  //if loginStatusKey has changed then another use logged in with the same credentials so show alert
  const [userId, loginStatusKey] = payload.resourceId.split("-");
  const getCurrentUser = store.getters["auth/getCurrentUser"];
  if (
    getCurrentUser &&
    getCurrentUser._id === userId &&
    localStorage.getItem("loginStatusKey") !== loginStatusKey
  ) {
    store.commit("app/SET_STATE", {
      key: "showLoggedOutModal",
      value: true
    });
  }
}

export function doSettingsSocketLogic(payload: IIOResourceUpdateData) {
  if (payload.reason === "updated") {
    store.dispatch("auth/init");
  }
}

export function doSocketLogic(payload: IIOResourceUpdateData) {
  const { resourceType = "" } = payload;
  switch (resourceType) {
    case "quote":
      doQuoteSocketLogic(payload);
      break;
    case "authentication":
      doAuthenticationSocketLogic(payload);
      break;
    case "settings":
      doSettingsSocketLogic(payload);
      break;
    default:
      throw `Invalid resource type ${resourceType}`;
    // break;
  }
}

export const actions: DefineActions<
  SocketIOActions,
  SocketIOState,
  SocketIOMutations,
  SocketIOGetters
> = {
  async socket_ioResourceUpdate({}, payload) {
    doSocketLogic(payload);
  }
};
