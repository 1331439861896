import { IUnderWritingQuestion } from "@/store/modules/underWritingQuestion/types";
import defaultsDeep from "lodash/defaultsDeep";
import { cloneDeep } from "lodash";
import { baseUnderWritingQuestion } from "@/helpers/defaultObjects";
import { FormBlock, FormBlockField } from "@/components/FormBuilder/types";

export function underWritingQuestionDefault(
  underWritingQuestion: Partial<IUnderWritingQuestion>
): IUnderWritingQuestion {
  return defaultsDeep(
    cloneDeep(underWritingQuestion),
    Object.assign(baseUnderWritingQuestion())
  );
}

export function singleUnderwritersQuestion(question: FormBlock) {
  if ("children" in question && question.children && question.children.length) {
    return question.children[0] as FormBlockField;
  }
  return null;
}
