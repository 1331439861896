<template>
  <header
    id="site-header"
    itemscope="itemscope"
    itemtype="https://schema.org/WPHeader"
    class="site-header z-50"
  >
    <div class="wrap">
      <div class="site-top-bar">
        <div class="wrap hidden sm:flex">
          <top-menu
            id="top-menu"
            :currentRole="getCurrentUser ? getCurrentUser.role : ''"
            ref="topMenu"
          />
          <TopMenuUserDropdown v-if="isLoggedIn" />
          <TopMenuLogout v-if="isLoggedIn" />
        </div>
      </div>
      <div class="title-area">
        <router-link to="/login" itemprop="headline" class="site-title">
          <AtlasLogo />
        </router-link>
      </div>
      <div class="widget-area header-widget-area">
        <nav
          itemscope="itemscope"
          itemtype="https://schema.org/SiteNavigationElement"
          class="nav-primary-menu nav-primary"
        >
          <user-menu
            id="user-menu"
            :currentRole="getCurrentUser ? getCurrentUser.role : ''"
            ref="primaryMenu"
            @toggleSubMenu="toggleSubMenu"
          />
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import Vue from "vue";
import { appMapMutations, appMapGetters } from "@/store/modules/app";
import { authMapGetters, authMapState } from "@/store/modules/auth";

import TopMenuUserDropdown from "@/components/GlobalMenu/TopMenuUserDropdown.vue";
import TopMenu from "@/components/GlobalMenu/TopMenu.vue";
import UserMenu from "@/components/GlobalMenu/UserMenu.vue";
import TopMenuLogout from "@/components/GlobalMenu/TopMenuLogout.vue";
import AtlasLogo from "@/components/AppRootWrapper/Header/Logo.vue";
import { get } from "lodash";

export default Vue.extend({
  data() {
    return {
      mobileActiveSubMenu: null
    };
  },
  components: {
    TopMenu,
    UserMenu,
    AtlasLogo,
    TopMenuUserDropdown,
    TopMenuLogout
  },
  methods: {
    ...appMapMutations(["TOGGLE_MOBILE_MENU"]),
    showMobileMenu() {
      document.body.dataset.mobileMenuOpen = "true";
      this.$el.style.height = this.windowHeight + "px";
      this.$el.style.overflowY = "hidden";
      document.body.style.overflowY = "hidden";
      document.body.style.position = "relative";
      document.body.style.height = this.windowHeight + "px";
      document.documentElement.style.overflowY = "hidden";
      document.documentElement.style.position = "relative";
      document.documentElement.style.height = this.windowHeight + "px";
      this.TOGGLE_MOBILE_MENU(true);
    },
    hideMobileMenu() {
      document.body.dataset.mobileMenuOpen = "false";
      this.$el.style.height = null;
      this.$el.style.overflowY = null;
      document.body.style.overflowY = null;
      document.body.style.position = null;
      document.body.style.height = null;
      document.documentElement.style.overflowY = "visible";
      document.documentElement.style.position = null;
      document.documentElement.style.height = null;
      document.documentElement.removeAttribute("style");
      this.closeAllSubmenus();
      this.TOGGLE_MOBILE_MENU(false);
    },
    toggleSubMenu(num) {
      if (!this.isMobile) return;
      const { primaryMenu } = this.$refs;
      let current = this.mobileActiveSubMenu;
      let menu_to_toggle = `mainMenuItem_${num}`;
      let sub_menu_to_toggle = `subMenu_${num}`;

      const compon = primaryMenu?.$refs[menu_to_toggle];

      this.closeAllSubmenus();

      if (this.mobileActiveSubMenu !== num) {
        compon[0].$el.classList.add("sub-menu-item-active");
        const subMenu = get(compon[0].$refs, sub_menu_to_toggle);
        subMenu.classList.add("active-sub-menu");
      }

      if (current === num) {
        this.mobileActiveSubMenu = null;
      } else {
        this.mobileActiveSubMenu = num;
      }
    },
    closeAllSubmenus() {
      const { $refs = {} } = this;
      const { primaryMenu } = $refs;

      let i = 0;
      const refs = primaryMenu.$refs;
      for (const menuitem of Object.keys(refs)) {
        const parentItem = get(refs || [], menuitem);
        if (parentItem[0] && parentItem[0].$el.classList)
          parentItem[0].$el.classList.remove("sub-menu-item-active");
        const childItem = get(parentItem[0].$refs || [], `subMenu_${i}`);
        if (childItem && childItem.classList)
          childItem.classList.remove("active-sub-menu");
        i++;
      }
    }
  },
  computed: {
    ...authMapGetters(["isLoggedIn", "getCurrentUser"]),
    ...appMapGetters(["getShowMobileMenu", "getScreenSize", "getWindowSize"]),
    ...authMapState(["initData"]),
    mobileMenuOpen() {
      return this.getShowMobileMenu;
    },
    windowHeight() {
      return this.getWindowSize.height;
    },
    isMobile() {
      return ["sm"].includes(this.getScreenSize);
    }
  },
  watch: {
    mobileMenuOpen: function(state) {
      return state ? this.showMobileMenu() : this.hideMobileMenu();
    },
    isMobile: function(state) {
      return !state ? this.hideMobileMenu() : "";
    },
    $route: function(to, from) {
      if (from.path && to.path && from.path === to.path) return;
      if ((to && to.name) || to.path) {
        this.hideMobileMenu();
      }
    }
  }
});
</script>

<style scoped>
.title-area {
  margin-bottom: 5px !important;
}
</style>
