<template>
  <ModalBase
    :name="$attrs.name"
    :size="size"
    :clickToClose="clickToClose"
    :showClose="showClose"
    @before-open="$emit('before-open', $event)"
    @before-close="$emit('before-close', $event)"
    @opened="$emit('opened', $event)"
    @closed="$emit('closed', $event)"
  >
    <div>
      <div class="mx-auto flex items-center justify-center h-8 w-8">
        <font-awesome-icon :icon="icon" :class="iconClass" />
      </div>
      <div class="mt-3 text-center sm:mt-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ title }}
        </h3>
        <div class="mt-2">
          <p class="text-sm text-gray-500" v-if="description">
            {{ description }}
          </p>
          <slot v-if="$slots.default"></slot>
        </div>
      </div>

      <div class="mt-5 sm:mt-6 flex justify-center" v-if="buttons.length">
        <IAButton
          v-for="(item, index) in buttons"
          :key="`modal_${$attrs.name}_button_${index}`"
          :title="item.text"
          :click="item.click"
          :class="item.classList"
          size="medium"
        />
      </div>
    </div>
  </ModalBase>
</template>
<script>
import ModalBase from "./ModalBase.vue";
export default {
  name: "ModalMessage",
  components: { ModalBase },
  /*
      Not listed as a prop, but this component _requires_ a name attribute.
    */
  props: {
    /* Used to pass through to ModalBase */
    clickToClose: {
      type: Boolean,
      default: false
    },
    /* Used to pass through to ModalBase */
    showClose: {
      type: Boolean,
      default: false
    },
    /* All below are unique to this modal */
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    icon: {
      type: Array,
      default: () => []
    },
    iconClass: {
      type: String,
      default: ""
    },
    buttons: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: "medium"
    }
  },
  data() {
    return {};
  }
};
</script>
