
import Vue from "vue";
export default Vue.extend({
  name: "CustomAddressViewer",
  props: {
    value: {
      type: [Object, String],
      required: true,
      default: () => ({})
    },
    label: {
      type: String,
      required: true,
      default: "Physical Address"
    },
    hideTopBorder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    canDisplayAddress() {
      return Object.values(this.value).some(
        (value: any) => value && value.trim().length > 0
      );
    }
  }
});
