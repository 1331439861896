import { DefineGetters } from "vuex-type-helper";
import { AuthGetters, AuthState } from "./types";

export const getters: DefineGetters<AuthGetters, AuthState> = {
  isLoggedIn: (state: AuthState) => state.isLoggedIn,
  isLoginMfa: (state: AuthState) => state.isLoginMfa,
  initialDataSetup: (state: AuthState) => state.loadedInitData,
  getCurrentUser: (state: AuthState) => state.user,
  getCurrentUserId: (state: AuthState) =>
    state.user && state.user._id ? state.user._id : "",
  isCurrentUserAdmin: (state: AuthState) =>
    state.user && state.user.role ? state.user.role === "admin" : false,
  getCompanyByCompanyNumber: (state: AuthState) => (companyNumber: number) => {
    const { companies = [] } = state.initData || {};
    return companies.find(
      (company: any) => company.companyNumber === companyNumber
    );
  },
  userHasRoutePermissionDisabled: (state: AuthState) => (
    routePermission: string
  ) => {
    const { disabledPermissions = [] } = state.user || {};
    return !!disabledPermissions.includes(routePermission);
  }
};
