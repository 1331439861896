import {
  IAgentReport,
  AgentReportActions,
  AgentReportState,
  AgentReportMutations,
  AgentReportGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import AgentReportService from "@/services/agentreport.service";

export const actions: DefineActions<
  AgentReportActions,
  AgentReportState,
  AgentReportMutations,
  AgentReportGetters
> = {
  createAgentReport: GenericCreateOne<
    AgentReportState,
    AgentReportGetters,
    AgentReportActions,
    AgentReportMutations,
    IAgentReport
  >(AgentReportService, types.SET_ITEM as any),
  getAgentReports: GenericGetMany<
    AgentReportState,
    AgentReportGetters,
    AgentReportActions,
    AgentReportMutations
  >(AgentReportService, "agentreports", types.SET_ITEM as any),
  getAgentReport: GenericGetOne<
    AgentReportState,
    AgentReportGetters,
    AgentReportActions,
    AgentReportMutations
  >(AgentReportService, types.SET_ITEM as any),
  deleteAgentReport: GenericDeleteOne<
    AgentReportState,
    AgentReportGetters,
    AgentReportActions,
    AgentReportMutations
  >(AgentReportService, types.DELETE_ITEM as any),
  updateAgentReport: GenericUpdateOne<
    AgentReportState,
    AgentReportGetters,
    AgentReportActions,
    AgentReportMutations,
    { id: string; update: Partial<IAgentReport> }
  >(AgentReportService, types.SET_ITEM as any),
  async exportAgentReportFile({ commit }, payload) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      await AgentReportService.exportAgentReportFile(payload);
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  getAgentReportsInInputBucket: async ({ commit }) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await AgentReportService.getUploadedAgentReports();
      const responseData = response.data;
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
};
