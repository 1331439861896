import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  QuoteCountyValidationState,
  QuoteCountyValidationMutations,
  StateKeys,
  IQuoteCountyValidation
} from "./types";
import {
  GenericClearStore,
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates
} from "@/store/helpers";
import { getQuoteCountyValidationsDefaults } from "@/helpers/quoteCountyValidation";
import { defaultState } from "./state";

export const mutations: DefineMutations<
  QuoteCountyValidationMutations,
  QuoteCountyValidationState
> = {
  [types.SET_STATE]: GenericSetState<QuoteCountyValidationState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<QuoteCountyValidationState>(),
  [types.SET_EDIT]: GenericSetEdit<
    QuoteCountyValidationState,
    string | Partial<IQuoteCountyValidation>
  >("quoteCountyValidations", getQuoteCountyValidationsDefaults),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    QuoteCountyValidationState,
    keyof IQuoteCountyValidation
  >(),
  [types.SET_ITEM]: GenericSetItem<
    QuoteCountyValidationState,
    IQuoteCountyValidation
  >("quoteCountyValidations", getQuoteCountyValidationsDefaults),
  [types.DELETE_ITEM]: GenericDeleteItem<QuoteCountyValidationState>(
    "quoteCountyValidations",
    "_id"
  ),
  [types.CLEAR_QUOTE_COUNTY_VALIDATIONS_STORE]: GenericClearStore<
    QuoteCountyValidationState
  >(defaultState())
};
