import defaultsDeep from "lodash/defaultsDeep";
import cloneDeep from "lodash/cloneDeep";
import { payPlanChangeRequestBase } from "@/helpers/defaultObjects";
import { IPayPlanChangeRequest } from "@/helpers/storageFormBaseInterface";

export function getPayPlanChangeRequestDefaults(
  payPlanChangeRequest: Partial<IPayPlanChangeRequest>
): IPayPlanChangeRequest {
  return defaultsDeep(
    cloneDeep(payPlanChangeRequest),
    Object.assign({}, payPlanChangeRequestBase())
  );
}
