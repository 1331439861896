import { DefineGetters } from "vuex-type-helper";
import { PropertyanalyticsGetters, PropertyanalyticsState } from "./types";

export const getters: DefineGetters<
  PropertyanalyticsGetters,
  PropertyanalyticsState
> = {
  getLinks: (state: PropertyanalyticsState) => state.links,
  getTotalRecords: ({ links, propertyanalytics }: PropertyanalyticsState) =>
    links && links.totalRecords ? links.totalRecords : propertyanalytics.length
};
