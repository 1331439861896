import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  AtlasfileState,
  AtlasfileMutations,
  StateKeys,
  IAtlasfile
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates
} from "@/store/helpers";
import { getAtlasfileDefaults } from "@/helpers/defaultObjectGenerators";
import { GenericClearStore } from "@/store/helpers";
import { defaultState } from "./state";

export const mutations: DefineMutations<AtlasfileMutations, AtlasfileState> = {
  [types.SET_STATE]: GenericSetState<AtlasfileState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<AtlasfileState>(),
  [types.SET_EDIT]: GenericSetEdit<AtlasfileState, string | IAtlasfile>(
    "atlasfiles",
    getAtlasfileDefaults
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    AtlasfileState,
    keyof IAtlasfile
  >(),
  [types.SET_ITEM]: GenericSetItem<AtlasfileState, IAtlasfile>(
    "atlasfiles",
    getAtlasfileDefaults
  ), //TODO: WRITE A getXDefaultsFunction and pass it here in place of the anonymous function
  [types.DELETE_ITEM]: GenericDeleteItem<AtlasfileState>("atlasfiles", "_id"),
  [types.CLEAR_ATLASFILE_STORE]: GenericClearStore<AtlasfileState>(
    defaultState()
  )
};
