import {
  IGbsHelp,
  GbsHelpActions,
  gbsHelpState,
  GbsHelpMutations,
  gbsHelpGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import GbsService from "@/services/gbs.service";

export const actions: DefineActions<
  GbsHelpActions,
  gbsHelpState,
  GbsHelpMutations,
  gbsHelpGetters
> = {
  createGbsHelp: GenericCreateOne<
    gbsHelpState,
    gbsHelpGetters,
    GbsHelpActions,
    GbsHelpMutations,
    IGbsHelp
  >(GbsService, types.SET_ITEM as any),
  getGbsHelps: GenericGetMany<
    gbsHelpState,
    gbsHelpGetters,
    GbsHelpActions,
    GbsHelpMutations
  >(GbsService, "gbsHelps", types.SET_ITEM as any),
  getGbsHelp: GenericGetOne<
    gbsHelpState,
    gbsHelpGetters,
    GbsHelpActions,
    GbsHelpMutations
  >(GbsService, types.SET_ITEM as any),
  deleteGbsHelp: GenericDeleteOne<
    gbsHelpState,
    gbsHelpGetters,
    GbsHelpActions,
    GbsHelpMutations
  >(GbsService, types.DELETE_ITEM as any),
  updateGbsHelp: GenericUpdateOne<
    gbsHelpState,
    gbsHelpGetters,
    GbsHelpActions,
    GbsHelpMutations,
    { id: string; update: Partial<IGbsHelp> }
  >(GbsService, types.SET_ITEM as any)
};
