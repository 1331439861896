import {
  PolicySignActions,
  PolicySignState,
  PolicySignMutations,
  PolicySignGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import PolicySignService from "@/services/policySign.service";

export const actions: DefineActions<
  PolicySignActions,
  PolicySignState,
  PolicySignMutations,
  PolicySignGetters
> = {
  async getSignedPolicies({ commit }, query) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const { data } = await PolicySignService.getAllSignedPolicies(query);
      commit(types.SET_STATES, {
        signedPolicies: data
      });
      commit(types.SET_STATE, { key: "lastUpdate", value: new Date() });
      return data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  }
};
