import { canAccessRouteQuoteStep } from "@/router/middleware/beforeEnter/beforeEnterQuoteSteps";
import { IRouterConfig } from "@/types";

export const CompanyAndLineOfBusiness: IRouterConfig = {
  path: "/quotes/create-draft",
  component: () => import("@/views/shared/quotes/LineOfBusiness.vue"),
  meta: {
    breadcrumbs: [
      {
        link: "/quotes",
        title: "Quotes"
      }
    ],
    metaTags: {
      title: "Line Of Business Selection"
    },
    pageTitle: "Create Draft Quote",
    requireAuth: true,
    allowedRoles: [
      "admin",
      "agent",
      "ezlynx",
      "agencyAdmin",
      "subAdmin",
      "underwriting",
      "claims",
      "billing",
      "dataEntry"
    ]
  }
};

export const RiskAddress: IRouterConfig = {
  name: "risk-address-step",
  path: "risk-address",
  component: () => import("@/views/shared/quotes/RiskAddress.vue"),
  props: (route: any) => ({
    quoteId: route.params.quoteId,
    showCoApplicant: route.params.showCoApplicant
  }),
  meta: {
    breadcrumbs: [
      {
        link: "/quotes",
        title: "Quotes"
      }
    ],
    pageTitle: "Specify Risk Address",
    requireAuth: true,
    allowedRoles: [
      "admin",
      "agent",
      "ezlynx",
      "agencyAdmin",
      "subAdmin",
      "underwriting",
      "claims",
      "billing",
      "dataEntry"
    ],
    metaTags: {
      title: "Edit Draft Quote"
    }
  },
  beforeEnter: canAccessRouteQuoteStep
};

export const Territory: IRouterConfig = {
  name: "territory",
  path: "territory",
  component: () => import("@/views/shared/quotes/TerritoryLookup.vue"),
  props: (route: any) => ({
    quoteId: route.params.quoteId
  }),
  meta: {
    breadcrumbs: [
      {
        link: "/quotes",
        title: "Quotes"
      }
    ],
    pageTitle: "Territory",
    requireAuth: true,
    allowedRoles: [
      "admin",
      "agent",
      "ezlynx",
      "agencyAdmin",
      "subAdmin",
      "underwriting",
      "claims",
      "billing",
      "dataEntry"
    ],
    metaTags: {
      title: "County Territory Information"
    }
  },
  beforeEnter: canAccessRouteQuoteStep
};

export const PropertyRating: IRouterConfig = {
  name: "property-rating",
  path: "property-rating",
  component: () => import("@/views/shared/quotes/PropertyRating.vue"),
  props: (route: any) => ({
    quoteId: route.params.quoteId
  }),
  meta: {
    breadcrumbs: [
      {
        link: "/quotes",
        title: "Quotes"
      }
    ],
    pageTitle: "Property Rating",
    requireAuth: true,
    allowedRoles: [
      "admin",
      "agent",
      "ezlynx",
      "agencyAdmin",
      "subAdmin",
      "underwriting",
      "claims",
      "billing",
      "dataEntry"
    ],
    metaTags: {
      title: "Property Rating"
    }
  },
  beforeEnter: canAccessRouteQuoteStep
};

export const PropertyRatings: IRouterConfig = {
  name: "property-ratings",
  path: "property-ratings",
  component: () => import("@/views/shared/quotes/PropertyRatings.vue"),
  props: (route: any) => ({
    quoteId: route.params.quoteId
  }),
  meta: {
    breadcrumbs: [
      {
        link: "/quotes",
        title: "Quotes"
      }
    ],
    pageTitle: "Property Ratings",
    requireAuth: true,
    allowedRoles: [
      "admin",
      "agent",
      "ezlynx",
      "agencyAdmin",
      "subAdmin",
      "underwriting",
      "claims",
      "billing",
      "dataEntry"
    ],
    metaTags: {
      title: "Property Ratings"
    }
  },
  beforeEnter: canAccessRouteQuoteStep
};

export const RatingApplication: IRouterConfig = {
  name: "rating-application",
  path: "rating-application",
  component: () =>
    import("@/views/shared/quotes/RatingApplication/Application.vue"),
  props: (route: any) => ({
    quoteId: route.params.quoteId
  }),
  meta: {
    breadcrumbs: [
      {
        link: "/quotes",
        title: "Quotes"
      }
    ],
    pageTitle: "Rating Application",
    requireAuth: true,
    allowedRoles: [
      "admin",
      "agent",
      "agencyAdmin",
      "subAdmin",
      "underwriting",
      "claims",
      "billing",
      "dataEntry"
    ],
    metaTags: {
      title: "Rating Application"
    }
  },
  beforeEnter: canAccessRouteQuoteStep
};

export const UnderwritingQualifications: IRouterConfig = {
  name: "underwriting-qualifications",
  path: "underwriting-qualifications",
  component: () =>
    import("@/views/shared/quotes/UnderwritingQualifications.vue"),
  props: (route: any) => ({
    quoteId: route.params.quoteId
  }),
  meta: {
    breadcrumbs: [
      {
        link: "/quotes",
        title: "Quotes"
      }
    ],
    pageTitle: "Underwriting Qualifications",
    requireAuth: true,
    allowedRoles: [
      "admin",
      "agent",
      "agencyAdmin",
      "subAdmin",
      "underwriting",
      "claims",
      "billing",
      "dataEntry"
    ],
    metaTags: {
      title: "Underwriting Qualifications"
    }
  },
  beforeEnter: canAccessRouteQuoteStep
};

export const LossReportViewer: IRouterConfig = {
  name: "loss-report",
  path: "loss-report",
  component: () => import("@/views/shared/quotes/LossReport.vue"),
  props: (route: any) => ({
    quoteId: route.params.quoteId
  }),
  meta: {
    breadcrumbs: [
      {
        link: "/quotes",
        title: "Quotes"
      }
    ],
    pageTitle: "Loss Reports",
    requireAuth: true,
    allowedRoles: [
      "admin",
      "agent",
      "agencyAdmin",
      "subAdmin",
      "underwriting",
      "claims",
      "billing",
      "dataEntry"
    ],
    metaTags: {
      title: "Loss Reports"
    }
  },
  beforeEnter: canAccessRouteQuoteStep
};

export const RemarksAndMortgages: IRouterConfig = {
  name: "remarks-and-mortgages",
  path: "remarks-and-mortgages",
  component: () => import("@/views/shared/quotes/RemarksAndMortgages.vue"),
  props: (route: any) => ({
    quoteId: route.params.quoteId
  }),
  meta: {
    breadcrumbs: [
      {
        link: "/quotes",
        title: "Quotes"
      }
    ],
    pageTitle: "Remarks & Mortgages",
    requireAuth: true,
    allowedRoles: [
      "admin",
      "agent",
      "agencyAdmin",
      "subAdmin",
      "underwriting",
      "claims",
      "billing",
      "dataEntry"
    ],
    metaTags: {
      title: "Remarks & Mortgages"
    }
  },
  beforeEnter: canAccessRouteQuoteStep
};
