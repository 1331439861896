import BaseCollectionService from "./base.collection.service";

class CompaniesService extends BaseCollectionService {
  constructor() {
    super("companies");
  }

  async getOneCompany(query: { _id?: string; companyNumber?: string }) {
    const path = `companies`;
    return await this.request(
      {
        method: "get",
        url: path,
        params: query
      },
      { useToken: "access" }
    );
  }
}

export default new CompaniesService();
