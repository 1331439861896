import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  QuoteState,
  QuoteGetters,
  QuoteMutations,
  QuoteActions
} from "./types";

const namespaced = true;
export const namespace = "quote";

export const {
  mapState: quoteMapState,
  mapGetters: quoteMapGetters,
  mapMutations: quoteMapMutations,
  mapActions: quoteMapActions
} = createNamespacedHelpers<
  QuoteState,
  QuoteGetters,
  QuoteMutations,
  QuoteActions
>(namespace);

export const quote: Module<QuoteState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
