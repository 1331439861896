import defaultsDeep from "lodash/defaultsDeep";
import cloneDeep from "lodash/cloneDeep";
import { propertyChangeRequestBase } from "@/helpers/defaultObjects";
import { IPropertyChangeRequest } from "@/helpers/storageFormBaseInterface";

export function getPropertyChangeRequestDefaults(
  propertyChangeRequest: Partial<IPropertyChangeRequest>
): IPropertyChangeRequest {
  return defaultsDeep(
    cloneDeep(propertyChangeRequest),
    Object.assign({}, propertyChangeRequestBase())
  );
}
