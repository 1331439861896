import defaultsDeep from "lodash/defaultsDeep";
import cloneDeep from "lodash/cloneDeep";
import { baseCompanies } from "@/helpers/defaultObjects";
import { ICompany } from "@/store/modules/companies/types";
import store from "@/store";
import { formTypeOptions, oldCompanies } from "./selectOptions";
import { Platform } from "@/types";
import { Site } from "./constants";
import { isExemptedUserRole } from ".";

export function getCompanyDefaults(company: Partial<ICompany>): ICompany {
  return defaultsDeep(cloneDeep(company), Object.assign({}, baseCompanies()));
}

export function companiesData(_giveAllCompanies = false): ICompany[] {
  const initData = store.state.auth["initData"];
  const getCurrentUser = store.getters["auth/getCurrentUser"];
  let companiesData = [];
  if (getCurrentUser) {
    if (isExemptedUserRole(getCurrentUser.role) || _giveAllCompanies) {
      companiesData = initData?.companies || [];
    }
    companiesData = companiesData.length
      ? companiesData
      : getCurrentUser.companiesData;
  }
  return companiesData;
}

export let excludedCompanies = [18, 21, 23, 24, 25];

export function getUserCompanies(
  platform: Platform,
  companyData?: ICompany[]
): any[] {
  const isQuote = platform === "quote";
  const isProfile = platform === "profile";
  const isLegacyQuote = platform === "legacyQuote";
  const isPLApplication = platform === "plApplication";
  let companies: ICompany[] = [];
  if ((isQuote || isPLApplication) && companyData) {
    companies = companyData;
  } else if (isProfile) {
    companies = companiesData();
  } else {
    const userCompanyData = [...companiesData(isLegacyQuote)];
    const includesColonial99 = userCompanyData.findIndex(
      (company: ICompany) => company.companyNumber === 99
    );
    const includesColonial20 = userCompanyData.findIndex(
      (company: ICompany) => company.companyNumber === 20
    );
    const includesSpinnaker27 = userCompanyData.findIndex(
      (company: ICompany) => company.companyNumber === 27
    );
    const includesAmericanSummit29 = userCompanyData.findIndex(
      (company: ICompany) => company.companyNumber === 29
    );
    if (!isLegacyQuote) {
      //Show old companies for these platforms
      if (["lossForm", "billing", "policyInfo"].includes(platform)) {
        excludedCompanies = [];
      }
      if (includesColonial99 > -1 && includesColonial20 === -1) {
        const company20 = store.getters["auth/getCompanyByCompanyNumber"](20);
        userCompanyData.push({ ...company20 });
        excludedCompanies.push(99);
      }
      if (includesColonial20 > -1) {
        excludedCompanies.push(99);
      }
      if (includesSpinnaker27 > -1) {
        excludedCompanies.push(28);
      }
      if (includesAmericanSummit29 > -1) {
        excludedCompanies.push(30);
      }
      companies = userCompanyData.filter((company: any) => {
        return !excludedCompanies.includes(company.companyNumber);
      });
    } else {
      companies = userCompanyData;
    }
  }
  if (isPLApplication) {
    companies = companies.filter(
      (company: ICompany) =>
        company.companyNumber !== 27 && company.companyNumber !== 28
    );
  }

  const options = [];
  for (const company of companies) {
    const companyLabelNumber = getCompanyLabel(
      company.companyNumber,
      company.companyName,
      platform
    );
    //skip options already has one colonial | exempting colonial statewide
    if (
      !isQuote &&
      company.companyNumber === 20 &&
      options.filter((e: any) => e.value === 20).length > 0
    ) {
      continue;
    }
    options.push({
      value: company.companyNumber,
      label: companyLabelNumber || company.companyName,
      companyPrefix: company.companyPrefix,
      territories: company.territories,
      formTypes: company.formTypes,
      mCompany: company.mCompany,
      companyName: company.companyName //used on profile page
    });
  }

  const oldCompanyNumbers = oldCompanies.map(company =>
    parseInt(company.value)
  );

  options.sort((a, b) => {
    const aExcluded = oldCompanyNumbers.includes(a.value);
    const bExcluded = oldCompanyNumbers.includes(b.value);

    // Move excluded companies to the bottom
    if (aExcluded && !bExcluded) return 1;
    if (!aExcluded && bExcluded) return -1;

    // Sort non-excluded companies alphabetically by companyName
    if (!aExcluded && !bExcluded) {
      return a.companyName.localeCompare(b.companyName);
    }

    return 0; // Keep order for excluded ones
  });
  return options;
}

export function getCompanyLabel(
  companyNumber: number,
  companyName: string,
  platform: Platform
) {
  let label = "";
  const isPLApplication = platform === "plApplication";
  const roadRunnerName = "Roadrunner Indemnity Company";
  switch (companyNumber) {
    case 20:
      if (platform !== "quote" && !isPLApplication) {
        label = `${roadRunnerName} (${companyNumber})`;
      } else {
        label = `${roadRunnerName} Seacoast (20)`;
      }
      break;
    case 99:
      if (isPLApplication) {
        label = `${roadRunnerName} (20)`;
      } else {
        label = `${roadRunnerName} StateWide (20)`;
      }
      break;
    case 26:
      label = `Insurors Indemnity Company (26)`;
      break;
    case 27:
      if (platform === "quote") {
        label = `Harris & Fort Bend ONLY (${companyNumber})`;
      } else {
        label = `${companyName} (27/28)`;
      }
      break;
    case 28:
      label = `Spinnaker Insurance (Tier 1 & Remaining Tier 2)  (${companyNumber})`;
      break;
    case 29:
      if (platform === "quote") {
        label = `American Summit Ins. Company (29)`;
      } else if (isPLApplication) {
        label = `American Summit (Harris & Fort Bent only) (29)`;
      } else {
        label = `${companyName} (29/30)`;
      }
      break;
    case 30:
      if (platform === "quote") {
        label = `American Summit Ins. Co. (${companyNumber})`;
      } else {
        label = `American Summit (Tier 1 & Remaining Tier 2) (${companyNumber})`;
      }
      break;
    default:
      {
        const companies = companiesData(true);
        const singleCompany = companies.find(
          company => company.companyNumber === companyNumber
        );
        label = `${companyName ||
          singleCompany?.companyName} (${companyNumber})`;
      }
      break;
  }

  return label;
}

export function getCompanyPrefix(
  companies: ICompany[],
  selectedCompanyValue: number
) {
  const selectedCompany = companies.find(
    (company: any) =>
      company.value.toString() == selectedCompanyValue.toString()
  );
  let companyPrefixOptions = [] as any;
  if (selectedCompany) {
    companyPrefixOptions = selectedCompany.companyPrefix.map(
      (prefix: string) => ({ label: prefix, value: prefix })
    );
  }
  return companyPrefixOptions;
}

export function getCompaniesPrefixes(platform: Platform) {
  const companies = getUserCompanies(platform);
  const isLoggedIn = store.getters["auth/isLoggedIn"];
  let options: any = [];
  if (companies && isLoggedIn) {
    const prefixes = companies
      .map((company: any) => company.companyPrefix)
      .flat();
    options = prefixes.map((option: string) => {
      return {
        label: option,
        value: option
      };
    });
  }
  return options;
}

export function getCompaniesFormType(platform: Platform) {
  const companies = getUserCompanies(platform);
  const isLoggedIn = store.getters["auth/isLoggedIn"];
  let options: any = [];
  if (companies && isLoggedIn) {
    const formTypes = companies.map((company: any) => company.formTypes).flat();
    const uniqueFormTypes = [...new Set(formTypes)];
    options = uniqueFormTypes.map((option: string) => {
      return {
        label: option,
        value: option
      };
    });
  } else {
    options = formTypeOptions;
  }
  return options;
}

export function getCompaniesForFilter(
  idAsValue = false
): { label: string; value: string }[] {
  const companies = companiesData();
  return companies
    .map((company: any) => {
      return {
        label: getCompanyLabel(
          company.companyNumber,
          company.companyName,
          "quote"
        ),
        value: idAsValue ? company._id : company.companyNumber
      };
    })
    .sort((firstCompany: any, secondCompany: any) => {
      const first = firstCompany.value;
      const second = secondCompany.value;
      if (first > second) return 1;
      if (second > first) return -1;
      return 0;
    });
}

export function quoteHasUnassignedUserCompanies(
  selectedCompanyNumbers: number[],
  companyOptions: any[]
) {
  const userAllowedCompanyNumbers = companyOptions.map(
    (company: any) => company.value
  );
  const filteredCompanies = selectedCompanyNumbers.filter(
    (companyNumber: number) => userAllowedCompanyNumbers.includes(companyNumber)
  );

  if (filteredCompanies.length !== selectedCompanyNumbers.length) {
    return { hasUnassigned: true, filteredCompanies };
  }
  return { hasUnassigned: false };
}

export function extraCompanyOptions(
  companies: ICompany[],
  dataToExtraAsValue: string = "_id"
) {
  return companies
    .filter(
      (company: any) => company.companyName && company[dataToExtraAsValue]
    )
    .map((company: any) => ({
      label: `${company.companyNumber == 99 ? 20 : company.companyNumber} - ${
        company.companyName
      }`,
      value: company[dataToExtraAsValue],
      companyName: company.companyName
    }))
    .sort(function(
      str1: { companyName: string },
      str2: { companyName: string }
    ) {
      return str1.companyName.localeCompare(str2.companyName);
    });
}

export function getSite(selectedCompanyNumber: number): Site {
  return companiesData(true)
    .filter(({ companyNumber }) => companyNumber === selectedCompanyNumber)
    .map(({ site }) => site)[0] as Site;
}
