import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  BillingChangeRequestState,
  BillingChangeRequestGetters,
  BillingChangeRequestMutations,
  BillingChangeRequestActions
} from "./types";

const namespaced = true;
export const namespace = "billingChangeRequest";

export const {
  mapState: billingChangeRequestMapState,
  mapGetters: billingChangeRequestMapGetters,
  mapMutations: billingChangeRequestMapMutations,
  mapActions: billingChangeRequestMapActions
} = createNamespacedHelpers<
  BillingChangeRequestState,
  BillingChangeRequestGetters,
  BillingChangeRequestMutations,
  BillingChangeRequestActions
>(namespace);

export const billingChangeRequest: Module<BillingChangeRequestState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
