import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "@/services/response_wrapper";

class PropertyChangeRequestService extends BaseCollectionService {
  constructor() {
    super("storage-forms/policy-change-requests");
  }

  async scrubAddress(address: any) {
    return await this.request(
      {
        method: "post",
        url: "/scrub-address",
        data: address
      },
      { useToken: "access" }
    );
  }

  async updateRequestsStatus(payload: any): Promise<ResponseWrapper> {
    const path = `/storage-forms/policy-change-requests/update-status`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async printPropertyChangeRequest(payload: any): Promise<ResponseWrapper> {
    const path = `/storage-forms/print-property-storage-form`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async getPolicyInquiryInformation(payload: any): Promise<ResponseWrapper> {
    const path = `/storage-forms/make-policy-number-inquiry`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async printPolicyInquiryById(payload: any): Promise<ResponseWrapper> {
    const path = `/storage-forms/policy-inquiry`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: { data: payload }
      },
      { useToken: "access" }
    );
  }

  async deleteChangeRequestsByIds(
    _ids: string[],
    type: string
  ): Promise<ResponseWrapper> {
    const path = `/storage-forms`;
    return await this.request(
      {
        method: "delete",
        url: path,
        data: { _ids, type }
      },
      { useToken: "access" }
    );
  }

  async restorePropertyChangeRequests(data: Record<string, any>) {
    const path = `/storage-forms/restore`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: data
      },
      { useToken: "access" }
    );
  }
}

export default new PropertyChangeRequestService();
