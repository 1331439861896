import { getPathAPI } from "@/helpers";
import axios, { AxiosResponse } from "axios";
import BaseCollectionService from "./base.collection.service";

class AdjusterfileService extends BaseCollectionService {
  constructor() {
    super("adjuster");
  }
  public async exportAdjusterFile(payload: any): Promise<AxiosResponse> {
    const path = `/adjuster/file/view`;
    const response = await axios({
      url: `${getPathAPI()}${path}?accessToken=${localStorage.getItem(
        "access"
      )}&adjusterCode=${payload.adjusterCode}&fileName=${payload.fileName}`,
      method: "GET",
      responseType: "blob"
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${payload.fileName}`);
    document.body.appendChild(link);
    link.click();
    return response;
  }
}

export default new AdjusterfileService();
