
import Vue, { PropType } from "vue";
import { authMapState } from "@/store/modules/auth";
import { appMapGetters } from "@/store/modules/app";
interface IActionButtonSubItem {
  key: string;
  text: string;
}

interface IActionButton {
  text: string;
  key: string;
  subItems: IActionButtonSubItem[];
}

export default Vue.extend({
  components: {
    PageBreadcrumbs: () =>
      import("@/components/PageWrapper/PageBreadcrumbs.vue"),
    PageWrapperActionButton: () =>
      import("@/components/PageWrapper/PageWrapperActionButton.vue"),
    TopHeader: () => import("@/components/User/TopHeader.vue")
  },
  props: {
    pageTitle: {
      type: String,
      default: ""
    },
    hidePageTitle: {
      type: Boolean,
      default: false
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    topActionButtonPrimary: {
      type: Object,
      default(): IActionButton | {} {
        return {};
      }
    },
    topActionButtonSecondary: {
      type: Object,
      default(): {} {
        return {};
      }
    },
    bottomActionButtonPrimary: {
      type: Object,
      default(): IActionButton | {} {
        return {};
      }
    },
    bottomActionButtonAfter: {
      type: Object,
      default(): IActionButton | {} {
        return {};
      }
    },
    showTopActionButtonPrimaryAtBottom: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      required: false,
      default: ""
    },
    successMessage: {
      type: String,
      required: false,
      default: ""
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    baseClass: {
      type: String,
      default: "ui-block"
    },
    showBack: {
      type: Boolean,
      required: false,
      default: true
    },
    breadcrumbs: {
      type: Array as PropType<string[]>,
      default(): string[] {
        return [];
      }
    },
    showBreadcrumbs: {
      type: Boolean,
      required: false,
      default: true
    },
    disableStickyHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    hideInfoMessage: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  watch: {
    successMessage: {
      handler(message) {
        if (message) {
          this.$appNotifySuccess(message);
          this.$emit("successMessageClosed");
        }
      }
    },
    errorMessage(message) {
      if (message) {
        this.$appNotifyError(message);
        this.$emit("errorMessageClosed");
      }
    }
  },
  mounted() {
    const { getWindowSize = { width: 0 } } = this;
    const { width } = getWindowSize;
    const { topActionButtonPrimary, disableStickyHeader } = this;
    const { theContentWrapper } = this.$refs;
    const scrollMonitor = window.scrollMonitor;
    if (
      !disableStickyHeader &&
      topActionButtonPrimary &&
      topActionButtonPrimary.key
    ) {
      if (scrollMonitor && scrollMonitor.viewportHeight > 450 && width > 800) {
        if (theContentWrapper) {
          var scrollContentIndicator = scrollMonitor.create(theContentWrapper);
          scrollContentIndicator.lock();

          scrollContentIndicator.enterViewport(function() {
            document.body.classList.remove("past-site-header2");
          });

          scrollContentIndicator.exitViewport(function() {
            document.body.classList.add("past-site-header2");
          });
        }
      }
    }
  },
  methods: {
    goBack() {
      const { path } = this.$route;
      if (path.includes("quotes/")) {
        const quoteSteps = [
          "create-draft",
          "risk-address",
          "territory",
          "property-rating",
          "property-ratings",
          "underwriting-qualifications",
          "rating-application",
          "loss-report",
          "remarks-and-mortgages"
        ];
        const index = path.lastIndexOf("/");
        const basePath = path.substring(0, index + 1);
        const currentStep = path.substring(index + 1, path.length);
        const currentIndex = quoteSteps.indexOf(currentStep);

        if (
          currentStep === "create-draft" ||
          currentStep.includes("view") ||
          currentStep.includes("activities")
        ) {
          this.$router.push(`/quotes`).catch(() => {});
        } else if (currentStep === "risk-address") {
          this.$router.push(`/quotes/create-draft`).catch(() => {});
        } else if (currentStep === "VS" || currentStep === "VR") {
          this.$router.back();
        } else {
          this.$router.push(`${basePath}${quoteSteps[currentIndex - 1]}`);
        }
      } else {
        this.$router.back();
      }
    }
  },
  computed: {
    renderPageTitle(): string {
      // allow overriding page header
      if (this.pageTitle) {
        return this.pageTitle;
      }
      const { meta = {} } = this.$route;
      const { pageTitle: routerPageTitle = "" } = meta;
      return routerPageTitle;
    },
    showToolbar(): boolean {
      const {
        showSearch,
        topActionButtonPrimary,
        topActionButtonSecondary
      } = this;
      return (
        showSearch ||
        Object.keys(topActionButtonPrimary).length > 0 ||
        Object.keys(topActionButtonSecondary).length > 0
      );
    },
    hasPrimaryOrSecondaryToolbar(): boolean {
      return (
        (this.topActionButtonPrimary &&
          Object.keys(this.topActionButtonPrimary).length > 0) ||
        (this.topActionButtonSecondary &&
          Object.keys(this.topActionButtonSecondary).length > 0)
      );
    },
    ...authMapState(["initData"]),
    ...appMapGetters(["getWindowSize"]),
    getMobileFlexClass() {
      const { topActionButtonPrimary, topActionButtonSecondary } = this;
      const hasPrimary = topActionButtonPrimary && topActionButtonPrimary.key;
      const hasSecondary =
        topActionButtonSecondary && topActionButtonSecondary.key;
      if (hasPrimary && hasSecondary) {
        return "justify-between";
      }
      if (hasPrimary && !hasSecondary) {
        return "justify-end";
      }
      if (!hasPrimary && hasSecondary) {
        return "justify-start";
      }
      return "";
    }
  }
});
