import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class AgencyService extends BaseCollectionService {
  constructor() {
    super("agencies");
  }

  async verifyAgencyCode(agencyCode: string): Promise<ResponseWrapper> {
    return await this.request(
      {
        method: "get",
        url: `${this.url}/verify/${agencyCode}`
      },
      { useToken: "access" }
    );
  }
  async assignAgentsToAgencies(
    agencyId: string,
    agentIds: string[]
  ): Promise<ResponseWrapper> {
    const path = `agencies/add/${agencyId}`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { agentIds }
      },
      { useToken: "access" }
    );
  }
}

export default new AgencyService();
