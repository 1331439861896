
import Vue from "vue";

export default Vue.extend({
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    showAsCheckbox: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    outerClass() {
      const baseClasses = [
        "flex",
        "items-center",
        "justify-center",
        "w-4",
        "h-4",
        "bg-gray-200",
        "border",
        "border-gray-400"
      ];

      if (!this.showAsCheckbox) {
        baseClasses.push("rounded-full");
      }
      return Object.fromEntries(baseClasses.map((i: string) => [i, true]));
    },
    innerClass() {
      const { hover, checked, focus } = this;
      const baseClasses = [
        "transition-all",
        "flex",
        "items-center",
        "justify-center",
        "w-full",
        "h-full",
        "border-white",
        "border-1"
      ];

      if (!this.showAsCheckbox) {
        baseClasses.push("rounded-full");
        baseClasses.push("border-2");
      }

      const activeClass = ["opacity-100", "scale-100"];
      const inActiveClass = ["opacity-0", "scale-0"];

      const hoverClass = ["border-gray-100", "bg-green-300"];
      const focusClass = ["border-gray-300", "bg-green-300"];
      const selectedClass = ["bg-atlas-blue"];

      return Object.assign(
        {},
        Object.fromEntries(baseClasses.map((i: string) => [i, true])),
        Object.fromEntries(
          activeClass.map((i: string) => [i, hover || checked])
        ),
        Object.fromEntries(
          inActiveClass.map((i: string) => [i, !hover && !checked])
        ),
        Object.fromEntries(hoverClass.map((i: string) => [i, hover])),
        Object.fromEntries(selectedClass.map((i: string) => [i, checked])),
        Object.fromEntries(focusClass.map((i: string) => [i, focus]))
      );
    }
  }
});
