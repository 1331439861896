import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { ResponseWrapper } from "@/services/response_wrapper";
import { ErrorWrapper } from "@/services/error_wrapper";
import { getPathAPI } from "@/helpers";
const { version } = require("../../package.json");
export const getAuthorizationBearerStringFor = (tokenKey: string) => {
  return `Bearer ${localStorage.getItem(tokenKey)}`;
};

const DB_URL = getPathAPI();

export default class BaseService {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: DB_URL
    });
  }

  async request(
    axiosConfig: AxiosRequestConfig,
    options?: any
  ): Promise<ResponseWrapper> {
    try {
      if (options && options.useToken) {
        this.instance.defaults.headers[
          "Authorization"
        ] = getAuthorizationBearerStringFor(options.useToken);
      }
      if (options && options.contentType) {
        // headers: { "Content-Type":  }
        this.instance.defaults.headers["Content-Type"] = options.contentType;
      }
      //default header
      this.instance.defaults.headers["X-Atlas-App-Version"] = version;
      const response = await this.instance.request(axiosConfig);
      return new ResponseWrapper(response);
    } catch (e) {
      throw new ErrorWrapper(e);
    }
  }

  getInstance(): AxiosInstance {
    return this.instance;
  }
}
