/**
 * our dead simple logger based on the one created
 * for api
 * @param Vue
 */
import { atlasConsole } from "@/helpers/logger";

export default function install(Vue: any) {
  Vue.prototype.$log = atlasConsole.log;
  Vue.prototype.$dev = atlasConsole.dev;
  Vue.prototype.$test = atlasConsole.test;
}
