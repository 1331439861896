import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class CountyService extends BaseCollectionService {
  constructor() {
    super("counties");
  }

  public async prohibitBindingByCode(
    countyCode: string
  ): Promise<ResponseWrapper> {
    const path = `county/prohibit-binding/${countyCode}`;
    return await this.request(
      {
        method: "put",
        url: path
      },
      { useToken: "access" }
    );
  }

  public async cancelProhibitBindingByCode(
    countyCode: string
  ): Promise<ResponseWrapper> {
    const path = `county/cancel-prohibit-binding/${countyCode}`;
    return await this.request(
      {
        method: "put",
        url: path
      },
      { useToken: "access" }
    );
  }

  public async deleteCountiesByCountyCodes(
    countyCodes: string[]
  ): Promise<ResponseWrapper> {
    const path = `counties/bulk-delete`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { countyCodes }
      },
      { useToken: "access" }
    );
  }

  public async prohibitBindingByCodes(
    countyCodes: string[]
  ): Promise<ResponseWrapper> {
    const path = `counties/prohibit-binding`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { countyCode: countyCodes }
      },
      { useToken: "access" }
    );
  }

  //

  public async toggleBulkCountyBindingProhibition(payload: {
    prohibitBinding: boolean;
  }): Promise<ResponseWrapper> {
    const path = `counties/toggle-binding-prohibition`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }
}

export default new CountyService();
