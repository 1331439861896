import {
  FieldTypes,
  FormBlockField,
  FormBlockFieldProperties
} from "../../types";

import { useField } from "./useField";
export const useSelectField = (
  field: Omit<FormBlockFieldProperties, "type" | "placeholder">,
  classList: string[] = []
): FormBlockField => {
  const type: FieldTypes = "select";
  return useField(Object.assign({}, field, { type }), classList);
};
