import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  PropertyanalyticsState,
  PropertyanalyticsMutations,
  StateKeys,
  IPropertyanalytics
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { defaultState } from "./state";

export const mutations: DefineMutations<
  PropertyanalyticsMutations,
  PropertyanalyticsState
> = {
  [types.SET_STATE]: GenericSetState<PropertyanalyticsState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<PropertyanalyticsState>(),
  [types.SET_EDIT]: GenericSetEdit<
    PropertyanalyticsState,
    string | IPropertyanalytics
  >("propertyanalytics", (data: any) => ({ ...data })),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    PropertyanalyticsState,
    keyof IPropertyanalytics
  >(),
  [types.SET_ITEM]: GenericSetItem<PropertyanalyticsState, IPropertyanalytics>(
    "propertyanalytics",
    (data: any) => ({ ...data })
  ), //TODO: WRITE A getXDefaultsFunction and pass it here in place of the anonymous function
  [types.DELETE_ITEM]: GenericDeleteItem<PropertyanalyticsState>(
    "propertyanalytics",
    "_id"
  ),
  [types.CLEAR_PROPERTY_ANALYTICS_STORE]: GenericClearStore<
    PropertyanalyticsState
  >(defaultState())
};
