export function validateFields(refs: any): boolean {
  const refKeys = Object.keys(refs);
  for (let i = 0; i < refKeys.length; i++) {
    if (!!refs[refKeys[i]] && refKeys[i].substr(0, 6) === "field_") {
      const element = refs[refKeys[i]];
      if (element.$v) {
        element.$v["sample_text"].$touch();
        if (element.$v["sample_text"].$error) {
          return false;
        }
      }
    }
  }
  return true;
}
