import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  UnderWritingQuestionState,
  UnderWritingQuestionMutations,
  StateKeys,
  IUnderWritingQuestion
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates
} from "@/store/helpers";
import { underWritingQuestionDefault } from "@/helpers/underWritingQuestionsDefault";

export const mutations: DefineMutations<
  UnderWritingQuestionMutations,
  UnderWritingQuestionState
> = {
  [types.SET_STATE]: GenericSetState<UnderWritingQuestionState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<UnderWritingQuestionState>(),
  [types.SET_EDIT]: GenericSetEdit<
    UnderWritingQuestionState,
    string | IUnderWritingQuestion
  >("underWritingQuestions", underWritingQuestionDefault),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    UnderWritingQuestionState,
    keyof IUnderWritingQuestion
  >(),
  [types.SET_ITEM]: GenericSetItem<
    UnderWritingQuestionState,
    IUnderWritingQuestion
  >("underWritingQuestions", underWritingQuestionDefault),
  [types.DELETE_ITEM]: GenericDeleteItem<UnderWritingQuestionState>(
    "underWritingQuestions",
    "_id"
  )
};
