import defaultsDeep from "lodash/defaultsDeep";
import { cloneDeep } from "lodash";
import { baseAccreditedBuilder } from "@/helpers/defaultObjects";
import { IAccreditedBuilder } from "@/store/modules/accreditedBuilder/types";

export function accreditedBuilderDefault(
  accreditedBuilder: Partial<IAccreditedBuilder>
): IAccreditedBuilder {
  return defaultsDeep(
    cloneDeep(accreditedBuilder),
    Object.assign({}, baseAccreditedBuilder())
  );
}
