import Vue from "vue";

export const adjusterPrimaryMenu = () => [
  {
    to: "/documents",
    permission: "documents:read",
    text: Vue.prototype.__getText("menu", "documentsLinkText"),
    description: "View Documents",
    icon: "folders"
  }
];
