import {
  IClaims,
  ClaimsActions,
  ClaimsState,
  ClaimsMutations,
  ClaimsGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericMakeRequest,
  GenericDeleteOne,
  GenericUpdateOne,
  GenericGetOne
} from "@/store/helpers/actions";
import ClaimsService from "@/services/claims.service";

export const actions: DefineActions<
  ClaimsActions,
  ClaimsState,
  ClaimsMutations,
  ClaimsGetters
> = {
  createClaims: GenericCreateOne<
    ClaimsState,
    ClaimsGetters,
    ClaimsActions,
    ClaimsMutations,
    IClaims
  >(ClaimsService, types.SET_ITEM as any),

  getClaims: GenericGetMany<
    ClaimsState,
    ClaimsGetters,
    ClaimsActions,
    ClaimsMutations
  >(ClaimsService, "claims", types.SET_ITEM as any),
  getClaim: GenericGetOne<
    ClaimsState,
    ClaimsGetters,
    ClaimsActions,
    ClaimsMutations
  >(ClaimsService, types.SET_ITEM as any),
  deleteClaims: GenericDeleteOne<
    ClaimsState,
    ClaimsGetters,
    ClaimsActions,
    ClaimsMutations
  >(ClaimsService, types.DELETE_ITEM as any),
  updateClaims: GenericUpdateOne<
    ClaimsState,
    ClaimsGetters,
    ClaimsActions,
    ClaimsMutations,
    { id: string; update: Partial<IClaims> }
  >(ClaimsService, types.SET_ITEM as any),
  async getFiservData({ commit }, payload: any) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      commit(types.SET_STATES, { fiservClaimsData: payload });
      commit(types.SET_STATE, { key: "lastUpdate", value: new Date() });
      return payload;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  lookupClaimByNumber: GenericMakeRequest<
    ClaimsState,
    ClaimsGetters,
    ClaimsActions,
    ClaimsMutations
  >(ClaimsService.lookupClaimByNumber.bind(ClaimsService)),
  lookupClaimByPolicyNumber: GenericMakeRequest<
    ClaimsState,
    ClaimsGetters,
    ClaimsActions,
    ClaimsMutations
  >(ClaimsService.lookupClaimByPolicyNumber.bind(ClaimsService)),
  async printClaimsLookupData({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await ClaimsService.printClaimsLookupData(payload);

      return response;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  async getLossData({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await ClaimsService.getLossData(payload);
      commit(types.SET_STATE, {
        key: "fiservClaimsData",
        value: response.data.data
      });
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  async updateClaimsRequestsStatus({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await ClaimsService.updateClaimsStatus(payload);
      return response;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  async restoreClaimsRequests({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await ClaimsService.restoreClaims(payload);
      return response;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
};
