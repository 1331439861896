import * as quoteChildren from "./children.quotes";

export default [
  quoteChildren.CompanyAndLineOfBusiness,
  {
    path: "/quotes/:quoteId",
    component: () => import("@/views/shared/quotes/Home.vue"),
    props: (route: any) => ({
      quoteId: route.params.quoteId
    }),
    meta: {
      pageTitle: "Quote",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "ezlynx",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Quote"
      }
    },
    children: [
      quoteChildren.RiskAddress,
      quoteChildren.Territory,
      quoteChildren.PropertyRating,
      quoteChildren.PropertyRatings,
      quoteChildren.UnderwritingQualifications,
      quoteChildren.LossReportViewer,
      quoteChildren.RatingApplication,
      quoteChildren.RemarksAndMortgages
    ]
  },
  {
    path: "/quotes",
    name: "quotes",
    component: () =>
      import("@/views/shared/quotes/QuotesListView/QuotesListView.vue"),
    meta: {
      pageTitle: "Quotes",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "ezlynx",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Quotes"
      }
    },
    props: true
  },
  {
    path: "/quotesPropertyAnalytics/list",
    component: () =>
      import(
        "@/views/shared/quotes/PropertyAnalyticsListView/QuotesPropertyAnalyticsListView.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/quotes",
          title: "Quotes"
        }
      ],
      pageTitle: "Quote Property Analytics",
      requireAuth: true,
      allowedRoles: ["admin"]
    }
  },
  {
    path: "/quotes/xml/:ratingId/:responseTypeLabel",
    component: () => import("@/views/shared/quotes/rawXMLViewer.vue"),
    meta: {
      pageTitle: "Sending XML",
      requireAuth: true,
      allowedRoles: ["admin", "agent", "agencyAdmin", "subAdmin"]
    }
  },
  {
    path: "/quotes/:quoteId/view",
    component: () => import("@/views/shared/quotes/ViewSingleQuote.vue"),
    props: (route: any) => ({
      quoteId: route.params.quoteId
    }),
    meta: {
      breadcrumbs: [
        {
          link: "/quotes",
          title: "Quotes"
        }
      ],
      pageTitle: "View Quote",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "ezlynx",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "View Quote"
      }
    }
  },
  {
    path: "/quotes/:quoteId/view-legacy",
    component: () => import("@/views/shared/quotes/ViewSingleQuoteLegacy.vue"),
    props: (route: any) => ({
      quoteId: route.params.quoteId
    }),
    meta: {
      breadcrumbs: [
        {
          link: "/quotes?isLegacy=true",
          title: "Quotes"
        }
      ],
      pageTitle: "View Quote",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "View Quote"
      }
    }
  }
];
