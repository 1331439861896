import { get } from "lodash";
import { FormBlockField } from "../../types";

export const getFieldValue = (
  fieldSchema: FormBlockField,
  formValues: Record<string, any>
): any => {
  const { properties } = fieldSchema;
  if (properties && properties.key) {
    const { key } = properties;
    return get(formValues, key);
  }
  return null;
};
