export default [
  {
    path: "/admin/communications/:communicationId",
    component: () =>
      import("@/views/admin/communication/SingleCommunication/Edit.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/communications",
          title: "Communications"
        }
      ],
      pageTitle: "Communication",
      requireAuth: true,
      allowedRoles: ["admin"]
    }
  },
  {
    path: "/admin/communications",
    component: () =>
      import(
        "@/views/admin/communication/ListCommunications/ListCommunications.vue"
      ),
    meta: {
      pageTitle: "Communications",
      title: "Communications",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Communications"
      }
    }
  },
  {
    path: "/admin/communications/:communicationId/completed",
    component: () =>
      import(
        "@/views/admin/communication/SingleCommunicationDispatched/CommunicationDispatched.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/communications",
          title: "Communications"
        }
      ],
      pageTitle: "Communication",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Communication Details"
      }
    }
  },
  {
    path: "/admin/communications/bulk/activities",
    component: () =>
      import(
        "@/views/admin/communication/Activities/CommunicationAllActivities.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/communications",
          title: "Communications"
        }
      ],
      pageTitle: "Communication Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Communication Activities"
      }
    },
    name: "CommunicationActivities"
  }
];
