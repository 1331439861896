import { IClaimsInfo } from "@/store/modules/claims/types";
import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class ClaimsService extends BaseCollectionService {
  constructor() {
    super("storage-forms/claims-request");
  }

  async lookupClaimByNumber(data: {
    claimNumber: string | "number";
    site: string;
    type: string;
  }): Promise<ResponseWrapper> {
    const path = `storage-forms/get-claim-lookup-by-claim-number`;
    return await this.request(
      {
        method: "post",
        url: path,
        data
      },
      { useToken: "access" }
    );
  }

  async lookupClaimByPolicyNumber(data: {
    policyNumber: string | "number";
    site: string;
    policyPrefix: string;
  }): Promise<ResponseWrapper> {
    const path = `storage-forms/get-claim-lookup-by-policy-number`;
    return await this.request(
      {
        method: "post",
        url: path,
        data
      },
      { useToken: "access" }
    );
  }

  async printClaimsLookupData(_claimNumber: number): Promise<ResponseWrapper> {
    const path = `storage-forms/printClaimsLookup/${_claimNumber}`;
    return await this.request(
      {
        method: "get",
        url: path
      },
      { useToken: "access" }
    );
  }

  async getLossData(lossRequestData: IClaimsInfo) {
    const path = "storage-forms/policy-info-from-fiserv";
    return await this.request(
      {
        method: "post",
        url: path,
        data: lossRequestData
      },
      { useToken: "access" }
    );
  }

  async updateClaimsStatus(claims: Record<string, any>) {
    const path = `/storage-forms/claims-request/update-status`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: claims
      },
      { useToken: "access" }
    );
  }

  async restoreClaims(claims: Record<string, any>) {
    const path = `/storage-forms/restore`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: claims
      },
      { useToken: "access" }
    );
  }
}

export default new ClaimsService();
