<template>
  <div id="site-top-bar-mobile" class="site-top-bar-mobile">
    <div class="wrap">
      <!-- <div class="memo" v-if="infoMessage">
        Alert: {{ infoMessage }}
        <a href="#" class="bt-color-white bt-text-weight-600 ">Learn More</a>
      </div> -->
    </div>
  </div>
</template>

<script>
import { appMapGetters } from "@/store/modules/app";
import { authMapState } from "@/store/modules/auth";

export default {
  mounted() {
    this.innerText = this.$el.innerText;
  },
  destroyed() {
    document.body.style.marginTop = ``;
  },
  data: function() {
    return {};
  },
  computed: {
    ...appMapGetters(["getShowMobileMenu", "getScreenSize"]),
    ...authMapState(["initData"]),
    isMobile: function() {
      return ["sm"].includes(this.getScreenSize);
    },
    headerHeight() {
      const isMobile = this;
      const header = document.querySelector(".site-header");
      if (header && isMobile) {
        return header.offsetHeight;
      }
      return 0;
    },
    infoMessage: function() {
      return this.initData ? this.initData.topHeaderMessage : "";
    }
  },
  watch: {
    getShowMobileMenu: {
      immediate: true,
      handler: function(state) {
        this.$nextTick(() =>
          state ? this.hideMobileTopBar() : this.setMobileTopBar()
        );
      }
    },
    isMobile: {
      immediate: true,
      handler(v) {
        this.$nextTick(() =>
          v ? this.setMobileTopBar() : this.hideMobileTopBar()
        );
      }
    }
  },
  methods: {
    setMobileTopBar() {
      const top = this.$el.offsetHeight;
      const needToMoveFromTop = [
        document.getElementById("main-menu-toggle"),
        document.getElementById("mobile-header-button"),
        document.getElementById("mobile-header-button-alt"),
        document.querySelector(".site-header")
      ];
      for (const el of needToMoveFromTop) {
        if (el) {
          el.style.transform = `translateY(${top}px)`;
        }
      }
      document.body.style.marginTop = `${top + this.headerHeight}px`;
    },
    hideMobileTopBar() {
      const top = 0;
      const needToMoveFromTop = [
        document.getElementById("main-menu-toggle"),
        document.getElementById("mobile-header-button"),
        document.getElementById("mobile-header-button-alt"),
        document.querySelector(".site-header")
      ];
      for (const el of needToMoveFromTop) {
        if (el) {
          el.style.transform = `translateY(${top}px)`;
        }
      }

      document.body.style.marginTop = `${top}px`;
    }
  }
};
</script>
