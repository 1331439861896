import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  AtlasPlatformState,
  AtlasPlatformMutations,
  StateKeys,
  IAtlasPlatform
} from "./types";
import {
  GenericClearStore,
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates
} from "@/store/helpers";
import { defaultState } from "./state";
import { atlasPlatformDefault } from "@/helpers/atlasPlatformDefault";

export const mutations: DefineMutations<
  AtlasPlatformMutations,
  AtlasPlatformState
> = {
  [types.SET_STATE]: GenericSetState<AtlasPlatformState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<AtlasPlatformState>(),
  [types.SET_EDIT]: GenericSetEdit<
    AtlasPlatformState,
    string | Partial<IAtlasPlatform>
  >("atlasPlatforms", atlasPlatformDefault),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    AtlasPlatformState,
    keyof IAtlasPlatform
  >(),
  [types.SET_ITEM]: GenericSetItem<AtlasPlatformState, IAtlasPlatform>(
    "atlasPlatforms",
    atlasPlatformDefault
  ),
  [types.DELETE_ITEM]: GenericDeleteItem<AtlasPlatformState>(
    "atlasPlatforms",
    "_id"
  ),
  [types.CLEAR_ATLAS_PLATFORM_STORE]: GenericClearStore<AtlasPlatformState>(
    defaultState()
  )
};
