
import Vue from "vue";
export default Vue.extend({
  name: "custom-select-field-with-input",
  props: {
    showInputLabel: {
      type: Boolean,
      required: false,
      default: true
    },
    labelClass: {
      type: String,
      required: false,
      default: ""
    },
    type: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Select"
    },
    value: {
      type: [Object, String],
      default() {
        return {};
      }
    },
    options: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    labelKey: {
      type: [String, Array],
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    showFieldAs: {
      type: String,
      default: ""
    },
    remote: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    remoteMethod: {
      type: Function,
      default: () => {}
    },
    fieldGroupClass: {
      type: String,
      default: "pb-12"
    },
    optionComponent: {
      type: Function,
      required: false
    },
    optionHeight: {
      type: String,
      required: false,
      default: "34px"
    },
    size: {
      type: String,
      required: false
    },
    disabledOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    allowCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    selectFieldClass: {
      type: String,
      required: false,
      default: ""
    },
    fixedLabels: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      input: "",
      select: ""
    };
  },
  created() {
    this.input = this.valueFormatted.input;
    this.select = this.valueFormatted.select;
  },
  methods: {
    change(type: string, value: any) {
      if (type === "input") {
        this.input = value;
      } else if (type === "select") {
        this.select = value;
      }
      return this.$emit("change", {
        input: this.input,
        select: this.select
      });
    }
  },
  computed: {
    valueFormatted() {
      if (typeof this.value === "object") return this.value;
      const [select = "", input = ""] = this.value.split("-");
      return {
        select,
        input
      };
    }
  }
});
