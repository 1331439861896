import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class ImportService extends BaseCollectionService {
  constructor() {
    super("imports");
  }

  async completeImport(importId: string): Promise<ResponseWrapper> {
    const path = `${this.url}/${importId}/complete`;
    return await this.request(
      {
        method: "put",
        url: path
      },
      { useToken: "access" }
    );
  }
}

export default new ImportService();
