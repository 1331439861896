import {
  ActivityActions,
  ActivityState,
  ActivityMutations,
  ActivityGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import ActivityService from "@/services/activity.service";
import { ActivityResponse, Activity } from "@/types";

export const actions: DefineActions<
  ActivityActions,
  ActivityState,
  ActivityMutations,
  ActivityGetters
> = {
  async getActivities(
    { commit },
    { query, drop_store = false }
  ): Promise<Activity[]> {
    try {
      commit(types.SET_ACTIVITY_STATE, {
        key: "makingApiRequest",
        value: true
      });
      const activities = await ActivityService.getMany(query);
      const activityData: ActivityResponse[] = activities.data;
      if (drop_store) {
        commit(types.DROP_ACTIVITIES);
      }
      commit(types.SET_ACTIVITIES, activityData);
      commit(types.SET_ACTIVITY_LINKS, activities.links);
      commit(types.SET_ACTIVITY_STATE, {
        key: "lastUpdate",
        value: new Date()
      });
      return activityData;
    } finally {
      commit(types.SET_ACTIVITY_STATE, {
        key: "makingApiRequest",
        value: false
      });
    }
  }
};
