import { DefineGetters } from "vuex-type-helper";
import { TransactionsGetters, TransactionsState, ITransaction } from "./types";

export const getters: DefineGetters<TransactionsGetters, TransactionsState> = {
  getTransactionById: (state: TransactionsState) => (transactionId: string) =>
    state.transactions.find(
      (transaction: ITransaction) => transaction._id === transactionId
    ),
  editingTransaction: (state: TransactionsState) => state.editing,
  transactions: (state: TransactionsState) => state.transactions,
  isLoading: (state: TransactionsState) => state.makingApiRequest,
  getTransactionsLinks: (state: TransactionsState) => state.links,
  getTotalRecords: ({ links, transactions }: TransactionsState) =>
    links && links.totalRecords ? links.totalRecords : transactions.length
};
