<template>
  <div
    class="alert-container py-4 px-6 mb-6"
    :class="backgroundColorClass"
    v-if="show"
  >
    <div class="flex items-center">
      <div class="flex">
        <div
          class="flex-shrink-0 flex items-start mr-2"
          :class="textColorClass"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="mt-0.5 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              v-if="type === 'warning'"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              v-else-if="type === 'danger'"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
            <path
              v-else-if="type === 'success'"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
            />
            <path
              v-else-if="type === 'info'"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
            />
          </svg>
          <!-- <font-awesome-icon :icon="['fal', faIcon]" /> -->
        </div>
        <div>
          <h3
            class="text-lg font-medium drop-shadow-md	"
            :class="textColorClass"
            v-if="title"
          >
            {{ title }}
          </h3>
          <div class="mt-1" :class="textColorClass">
            <slot v-if="$slots.default"></slot>
            <p v-else>
              {{ message }}
            </p>
          </div>
        </div>
      </div>

      <div class="ml-auto" v-if="dismissible">
        <div class="flex">
          <button
            @click="$emit('dismiss')"
            type="button"
            class="dismiss-btn-base"
            :class="{
              'dismiss-btn-warning': type === 'warning',
              'dismiss-btn-danger': type === 'danger',
              'dismiss-btn-info': type === 'info',
              'dismiss-btn-success': type === 'success'
            }"
          >
            Dismiss
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "custom-alert",
  props: {
    title: {
      type: String,
      required: false,
      default: ""
    },
    message: {
      type: String,
      required: false,
      default: ""
    },
    show: {
      type: Boolean,
      required: false,
      default: true
    },
    dismissible: {
      type: Boolean,
      required: false,
      default: true
    },
    type: {
      type: String,
      required: false,
      default: "warning"
    }
  },
  computed: {
    backgroundColorClass() {
      return {
        "bg-yellow-200": this.type === "warning",
        "bg-red-100": this.type === "danger",
        "bg-blue-100": this.type === "info",
        "bg-green-100": this.type === "success"
      };
    },
    textColorClass() {
      return {
        "text-yellow-900": this.type === "warning",
        "text-red-900": this.type === "danger",
        "text-blue-900": this.type === "info",
        "text-green-900": this.type === "success"
      };
    }
  }
});
</script>

<style>
.dismiss-btn-base {
  @apply p-1.5;
  @apply rounded-md;
  @apply text-xs;
  @apply font-medium;
}

/* -----------------------WARNING ------------------ */
.dismiss-btn-warning {
  @apply bg-yellow-100;
  @apply text-yellow-800;
}

.dismiss-btn-warning:hover {
  @apply bg-yellow-50;
}

.dismiss-btn-warning:focus {
  @apply bg-yellow-100;
  @apply focus:ring-2;
  @apply focus:ring-offset-2;
  @apply focus:ring-offset-yellow-50;
  @apply focus:ring-yellow-600;
}

/* -----------------------DANGER ------------------ */
.dismiss-btn-danger {
  @apply bg-red-50;
  @apply text-red-800;
}

.dismiss-btn-danger:hover {
  @apply bg-red-100;
}

.dismiss-btn-danger:focus {
  @apply bg-red-100;
  @apply focus:ring-2;
  @apply focus:ring-offset-2;
  @apply focus:ring-offset-red-50;
  @apply focus:ring-red-600;
}

/* -----------------------INFO ------------------ */
.dismiss-btn-info {
  @apply bg-blue-50;
  @apply text-blue-800;
}

.dismiss-btn-info:hover {
  @apply bg-blue-100;
}

.dismiss-btn-info:focus {
  @apply bg-blue-100;
  @apply focus:ring-2;
  @apply focus:ring-offset-2;
  @apply focus:ring-offset-blue-50;
  @apply focus:ring-blue-600;
}

/* -----------------------SUCCESS------------------ */
.dismiss-btn-success {
  @apply bg-green-50;
  @apply text-green-900;
}

.dismiss-btn-success:hover {
  @apply bg-green-100;
}

.dismiss-btn-success:focus {
  @apply bg-green-100;
  @apply focus:ring-2;
  @apply focus:ring-offset-2;
  @apply focus:ring-offset-green-50;
  @apply focus:ring-green-600;
}
</style>
