
import Vue from "vue";
import SectionBlock from "./Components/SectionBlock.vue";
import FieldBlock from "./FieldBlock.vue";
import { elementId } from "@/helpers";
import { get } from "lodash";

export default Vue.extend({
  name: "FormBlockRenderer",
  inheritAttrs: false,
  components: {
    SectionBlock,
    FieldBlock
  },
  props: {
    schema: {
      type: Object,
      required: true,
      default: () => ({})
    },
    formValues: {
      type: Object,
      required: true,
      default: () => ({})
    },
    formValueDefaults: {
      type: Object,
      required: false,
      default: () => ({})
    },
    dynamicOptions: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    onClickElement(id: any) {
      if (id && typeof id === "string") {
        this.$emit("onClickElement", id);
      }
    }
  },
  computed: {
    getSectionComponent(): any {
      const { element, tagName } = this.schema as any;
      switch (element) {
        case "field":
          return FieldBlock;
        case "section":
          return SectionBlock;
        case "html":
          return tagName;
        default:
          return "div";
      }
    },
    elementId(): string {
      const { id, element } = this.schema;
      return `${element}_wrap_${elementId(id)}`;
    },
    innerTextValue(): any {
      if (this.schema.element !== "html") return "";
      if (this.schema.innerText && this.schema.innerText.length > 0) {
        return this.schema.innerText;
      }
      return get(this.formValues, this.schema.key);
    }
  }
});
