export class NormalizedAddress {
  postal_code: string;
  street_number: string;
  street: string;
  street_abbr: string;
  city: string;
  state: string;
  country: string;
  formatted_address: string;

  constructor(address: any) {
    const getComponent = this.componentFinder(address.address_components);
    const [, streetNum] = getComponent("street_number");
    const [streetAbbr, street] = getComponent("route");
    const [, city] = getComponent("locality");
    const [stateAbbr] = getComponent("administrative_area_level_1");
    const [, country] = getComponent("country");
    const [postalCode] = getComponent("postal_code");
    const { formatted_address } = address;

    this.street_number = streetNum;
    this.street = street;
    this.street_abbr = streetAbbr;
    this.city = city;
    this.state = stateAbbr;
    this.country = country;
    this.postal_code = postalCode;
    this.formatted_address = formatted_address;
  }

  componentFinder(components: any) {
    return (type: string) => {
      const it = components.find((c: { types: string | any[] }) =>
        c.types.includes(type)
      );
      return [
        it != null ? it.short_name : undefined,
        it != null ? it.long_name : undefined
      ];
    };
  }
  getStreet(useStreetAbbr: any) {
    const streetVal = useStreetAbbr ? "street_abbr" : "street";
    let str = this[streetVal];
    if (this.street_number) {
      str = `${this.street_number} ${str}`;
    }
    return str;
  }
  // toString() {
  //   const arr = [];
  //   for (const prop of ["city", "state", "country"]) {
  //     if (this[prop]) {
  //       arr.push(this[prop]);
  //     }
  //   }
  //   const str = arr.join(", ");
  //   return `${this.getStreet()} ${str}`;
  // }
}

export function getAddressComponents(address: any) {
  const {
    city = "",
    state = "",
    country = "",
    postal_code = "",
    street_number = "",
    street = ""
  } = address;
  return {
    city,
    state,
    country,
    postal_code,
    street_number,
    street,
    street_full: street_number ? `${street_number} ${street}` : `${street}`
  };
}
