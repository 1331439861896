import { defaultAddressStructure } from "@/helpers/defaultObjects";
import { set } from "lodash";
import { FormBlock, FormBlockField } from "../../types";

export function getFormBlockInitialValue(block: FormBlockField): any {
  switch (block.properties.type) {
    case "address":
    case "address_viewer":
      return defaultAddressStructure;
    case "radio_button_group":
    case "phone":
    case "text":
    case "textarea":
      return "";
    case "taxId":
      break;
    default:
      return null;
  }
}

export function getSchemaKeys(blocks: FormBlock[]): FormBlockField[] {
  let schemaKeys: any = {};
  for (const block of blocks) {
    if (block.element === "field") {
      set(schemaKeys, block.properties.key, getFormBlockInitialValue(block));
    } else {
      if ((block as any).children) {
        schemaKeys = {
          ...schemaKeys,
          ...getSchemaKeys((block as any).children || [])
        };
      }
    }
  }
  return schemaKeys;
}
