import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { AuthState, AuthMutations, StateKeys } from "./types";
import { defaultState } from "./state";
import { GenericSetState, GenericSetStates } from "@/store/helpers";

export const mutations: DefineMutations<AuthMutations, AuthState> = {
  [types.SET_STATE]: GenericSetState<AuthState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<AuthState>(),
  [types.LOGIN](state: AuthState) {
    state.isLoggedIn = true;
    state.isLoginMfa = false;
  },
  [types.LOGOUT](state: AuthState) {
    state.isLoggedIn = false;
    state.isLoginMfa = false;
    state.loadedInitData = false;
    state.hasValidLicense = null;
    state.user = null;
    const newState = Object.assign({}, state, defaultState());
    state = newState;
  }
};
