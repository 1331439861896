export const elementId = (id?: string | undefined): string => {
  const randomId = `${Math.random()
    .toString(36)
    .substring(2, 15)}`;
  // if no id, generate a random one
  // if in prod, use random ids so scrapers get mad
  if (!id /*|| process.env.NODE_ENV === "production"*/) {
    return randomId;
  }
  return id;
};
