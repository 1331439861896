import {
  IAtlasfile,
  AtlasfileActions,
  AtlasfileState,
  AtlasfileMutations,
  AtlasfileGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne,
  GenericMakeRequest
} from "@/store/helpers/actions";
import AtlasfileService from "@/services/atlasfile.service";

export const actions: DefineActions<
  AtlasfileActions,
  AtlasfileState,
  AtlasfileMutations,
  AtlasfileGetters
> = {
  createAtlasfile: GenericCreateOne<
    AtlasfileState,
    AtlasfileGetters,
    AtlasfileActions,
    AtlasfileMutations,
    IAtlasfile
  >(AtlasfileService, types.SET_ITEM as any),
  getAtlasfiles: GenericGetMany<
    AtlasfileState,
    AtlasfileGetters,
    AtlasfileActions,
    AtlasfileMutations
  >(AtlasfileService, "atlasfiles", types.SET_ITEM as any),
  getAtlasfile: GenericGetOne<
    AtlasfileState,
    AtlasfileGetters,
    AtlasfileActions,
    AtlasfileMutations
  >(AtlasfileService, types.SET_ITEM as any),
  deleteAtlasFile: GenericDeleteOne<
    AtlasfileState,
    AtlasfileGetters,
    AtlasfileActions,
    AtlasfileMutations
  >(AtlasfileService, types.DELETE_ITEM as any),
  updateAtlasfile: GenericUpdateOne<
    AtlasfileState,
    AtlasfileGetters,
    AtlasfileActions,
    AtlasfileMutations,
    { id: string; update: Partial<IAtlasfile> }
  >(AtlasfileService, types.SET_ITEM as any),
  exportCollection: GenericMakeRequest<
    AtlasfileState,
    AtlasfileGetters,
    AtlasfileActions,
    AtlasfileMutations
  >(AtlasfileService.exportCollection.bind(AtlasfileService), types.SET_ITEM)
};
