import { ISendingReceivingXML } from "@/store/modules/quote/types";
import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class QuoteService extends BaseCollectionService {
  constructor() {
    super("quotes");
  }

  async step9BindQuote(quoteId: string): Promise<ResponseWrapper> {
    const path = `quotes/${quoteId}/bind`;
    return await this.request(
      {
        method: "get",
        url: path
      },
      { useToken: "access" }
    );
  }

  async saveRemarksAndMortgages({
    id,
    payload
  }: {
    id: string;
    payload: Record<string, any>;
  }): Promise<ResponseWrapper> {
    const path = `quotes/${id}/step-9/save-remarks-and-mortgages`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async getSendingOrReceivingXML(
    payload: ISendingReceivingXML
  ): Promise<ResponseWrapper> {
    const path = payload?.type !== "creditLoss" ? `get-xmls` : "creditLossXMLS";
    return await this.request(
      {
        method: "post",
        url: `quotes/${path}`,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async runQuoteCredit({ id }: { id: string }): Promise<ResponseWrapper> {
    const path = `quotes/${id}/step-7/run-credit`;
    return await this.request(
      {
        method: "post",
        url: path
      },
      { useToken: "access" }
    );
  }

  async saveQuoteLossData({
    id,
    payload
  }: {
    id: string;
    payload: Record<string, any>;
  }): Promise<ResponseWrapper> {
    const path = `quotes/${id}/step-8/save-loss`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: { responses: payload }
      },
      { useToken: "access" }
    );
  }

  async runQuoteLosses({ id }: { id: string }): Promise<ResponseWrapper> {
    const path = `quotes/${id}/step-7/run-losses`;
    return await this.request(
      {
        method: "post",
        url: path
      },
      { useToken: "access" }
    );
  }

  async saveQuotePropertyFields({
    id,
    payload
  }: {
    id: string;
    payload: Record<string, any>;
  }): Promise<ResponseWrapper> {
    const path = `quotes/${id}/step-4/save-fields`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async scrubQuoteAddress({
    id,
    payload
  }: {
    id: string;
    payload: { riskAddress: any };
  }): Promise<ResponseWrapper> {
    const path = `quotes/${id}/step-2/scrub-address`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async saveStep2RiskAddress({
    id,
    payload
  }: {
    id: string;
    payload: { riskAddress: any };
  }): Promise<ResponseWrapper> {
    const path = `quotes/${id}/step-2/save`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async saveTerritoryInformation({
    id,
    payload
  }: {
    id: string;
    payload: {
      territoryCode: any;
      territoryCodeForPPC: any;
      territoryCodeForSeacoast: any;
    };
  }): Promise<ResponseWrapper> {
    const path = `quotes/${id}/step-3/save-territory`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async getQuoteTerritoryInformation(id: string): Promise<ResponseWrapper> {
    const path = `quotes/${id}/territories`;
    return await this.request(
      {
        method: "get",
        url: path
      },
      { useToken: "access" }
    );
  }

  async rateQuote(payload: { quoteId: string }): Promise<ResponseWrapper> {
    const { quoteId } = payload;
    const path = `quotes/${quoteId}/rate-quote`;
    return await this.request(
      {
        method: "post",
        url: path
      },
      { useToken: "access" }
    );
  }

  async selectRating(payload: {
    quoteId: string;
    ratingId: string;
  }): Promise<ResponseWrapper> {
    const { quoteId, ratingId } = payload;
    const path = `quotes/${quoteId}/step-5/select-rating`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: { ratingId }
      },
      { useToken: "access" }
    );
  }

  async saveUnderwritingResponses(payload: {
    quoteId: string;
    underwritingResponses: Record<string, any>;
  }): Promise<ResponseWrapper> {
    const { quoteId, underwritingResponses } = payload;
    const path = `quotes/${quoteId}/step-6/save-underwriting-responses`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: { underwritingResponses }
      },
      { useToken: "access" }
    );
  }

  async updateQuoteApplication(payload: {
    quoteId: string;
    updates: Record<string, any>;
  }): Promise<ResponseWrapper> {
    const { quoteId, updates } = payload;
    const path = `quotes/${quoteId}/step-7/save-application-fields`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: updates
      },
      { useToken: "access" }
    );
  }

  async getPropertyFieldBuildingAmountValidations(payload: {
    quoteId: string;
    buildingAmount: number;
  }): Promise<ResponseWrapper> {
    const path = `/checkBuildingAmount`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async deleteBulkQuotes(_ids: string): Promise<ResponseWrapper> {
    const path = `/quotes-bulk`;
    return await this.request(
      {
        method: "delete",
        url: path,
        data: { _ids }
      },
      { useToken: "access" }
    );
  }

  async restoreBulkQuotes(
    _ids: string,
    status?: string
  ): Promise<ResponseWrapper> {
    const path = `/quotes-bulk`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { _ids, status }
      },
      { useToken: "access" }
    );
  }

  async changeQuoteStatus(payload: {
    _ids: string[];
    status?: string;
  }): Promise<ResponseWrapper> {
    const path = `/quotes-bulk`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }
}

export default new QuoteService();
