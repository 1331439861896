export default [
  {
    path: "/admin/imports",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/views/admin/imports/ListImports/ListViewImports.vue"
      ),
    meta: {
      pageTitle: "Imports",
      requireAuth: true,
      allowedRoles: ["admin"]
    }
  },
  {
    path: "/admin/imports/:importId",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/views/admin/imports/SingleImport/SingleImport.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/imports",
          title: "Imports"
        }
      ],
      requireAuth: true,
      allowedRoles: ["admin"]
    }
  }
];
