import BaseCollectionService from "./base.collection.service";

class AccreditedBuilderService extends BaseCollectionService {
  constructor() {
    super("accreditedBuilder");
  }

  async getAccreditedBuilder(query: { _id?: string }) {
    const path = `accreditedBuilder`;
    return await this.request(
      {
        method: "get",
        url: path,
        params: query
      },
      { useToken: "access" }
    );
  }
}

export default new AccreditedBuilderService();
