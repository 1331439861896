import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/plugins/filters";
import ElementUI from "element-ui";
import Vuelidate from "vuelidate";
import VueProgressBar from "vue-progressbar";
import * as strings from "@/strings";
import VueContext from "vue-context";
import VueGtag from "vue-gtag";
import analyticsTiming from "@/plugins/analyticsTiming";
import { bugSnag } from "@/plugins/bugsnag";
//@ts-ignore
import locale from "element-ui/lib/locale/lang/en";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";
import fontAwesomeIcons from "@/helpers/fontAwesomeIcons";
import PageTopSaveButton from "@/components/PageTopSaveButton/PageTopSaveButton.vue";
import BackToLink from "@/components/BackToLink/BackToLink.vue";
import VerifiablePhoneField from "@/components/FormFields/VerifiablePhoneField.vue";
import ResourceNotFound from "@/components/NotFound/ResourceNotFound.vue";
import Globals from "@/plugins/globals";
import Logger from "@/plugins/logger";
import FormBuilder from "@/components/FormBuilder/FormBuilder.vue";
import ModalBase from "@/components/Dialog/ModalBase.vue";
import ModalMessage from "@/components/Dialog/ModalMessage.vue";
import RadioBlockField from "@/components/FormFields/RadioBlockField.vue";

import VueSocketio from "vue-socket.io-extended";
import socket from "@/services/socket-io";
import vmodal from "vue-js-modal";

///External custom libraries and filters
import {
  INFButton as IAButton,
  INFSelectField as SelectField,
  INFTextField as TextField,
  INFContentCard as UITile,
  INFPageButton as PageTopButtons,
  INFPageMessage as PageMessage,
  INFTaxIdField as TaxIDField,
  INFDatePicker as DatePicker,
  INFDateTimePicker as DateTimePicker,
  INFAuthCodeInput as AuthCodeInput,
  INFCurrencyFormatter as CurrencyFormatter,
  INFHTMLContent as HTMLContent,
  INFCurrencyInput as IACurrencyInput,
  INFGetText as GetText,
  INFListView,
  INFCustomDatePicker as CustomDatePicker
} from "@infonaligy/inf-vue-components";

import { INFFilters, GetText as getText } from "@infonaligy/inf-vue-components";

import PageWrapDefault from "@/views/wrappers/PageWrapDefault.vue";

Vue.use(bugSnag);
Vue.use(VueSocketio, socket, {
  store
});
Vue.use(
  VueGtag,
  {
    enabled: process.env.NODE_ENV === "production",
    config: { id: "G-70P3W36F3B" }
  },
  router
);
Vue.use(INFFilters);
Vue.use(getText, { strings });
Vue.use(INFListView, {
  store,
  name: "ListView",
  router,
  searchBarName: "SearchBar"
});
Vue.use(getText);
Vue.use(analyticsTiming);
Vue.use(Globals);
Vue.use(Logger);
Vue.use(vmodal);

// STYLES INTENDED TO OVERRIDE THOSE IN inf-vue-components
// import "element-ui/lib/theme-chalk/reset.css";
/* purgecss start ignore */
import "./assets/css/theme-defaults.scss";
import "vue-context/src/sass/vue-context.scss";
import "./assets/css/base.css";
import "./assets/css/formbuilder.css";
import "./assets/css/style.css";
import "./assets/css/print.css";
import "animate.css/animate.min.css";
/* purgecss end ignore */

//Register custom fonts from fontawesome library
library.add(...fontAwesomeIcons);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
Vue.component("GetText", GetText);
Vue.component("IAButton", IAButton);
Vue.component("TextField", TextField);
Vue.component("SelectField", SelectField);
Vue.component("DatePicker", DatePicker);
Vue.component("DateTimePicker", DateTimePicker);
Vue.component("UITile", UITile);
Vue.component("PageMessage", PageMessage);
Vue.component("PageTopButtons", PageTopButtons);
Vue.component("PageTopSaveButton", PageTopSaveButton);
Vue.component("TaxIDField", TaxIDField);
Vue.component("BackToLink", BackToLink);
Vue.component("HTMLContent", HTMLContent);
Vue.component("AuthCodeInput", AuthCodeInput);
Vue.component("VerifiablePhoneField", VerifiablePhoneField);
Vue.component("IACurrencyInput", IACurrencyInput);
Vue.component("ResourceNotFound", ResourceNotFound);
Vue.component("CurrencyFormatter", CurrencyFormatter);
Vue.component("VueContext", VueContext);
Vue.component("FormBuilder", FormBuilder);
Vue.component("ModalMessage", ModalMessage);
Vue.component("ModalBase", ModalBase);
Vue.component("RadioBlockField", RadioBlockField);
Vue.component("CustomDatePicker", CustomDatePicker);

Vue.component("PageWrapDefault", PageWrapDefault);

const ProgressBarOptions = {
  color: "#3182CE",
  failedColor: "#E53E3E",
  thickness: "2px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  inverse: false
};

Vue.use(VueProgressBar, ProgressBarOptions);
Vue.use(ElementUI, { locale });
Vue.use(Vuelidate);

Vue.config.productionTip = false;

export default new Vue({
  router,
  store,
  render: (h: any) => h(App)
}).$mount("#app");
