var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative userAddress"},[_c('GoogleAutoCompleteModal',{attrs:{"loading":_vm.scrubbingAddressStatus,"value":_vm.addressEnteredInGoogleAutoCompleteField,"errorMessage":_vm.error,"modalName":_vm.modalName},on:{"closeError":function($event){_vm.error = ''},"close":function($event){return _vm.$modal.hide(_vm.modalName)},"change":function($event){_vm.addressEnteredInGoogleAutoCompleteField = $event},"placechanged":_vm.storeAddressInStorage,"scrubAddress":_vm.doAddressScrubbing}}),_c('form-builder',{ref:"formElement2",attrs:{"schemas":_vm.addressFields,"formValues":_vm.value,"disabled":_vm.disabled},on:{"formChangedVisibly":function($event){return _vm.makingAChange('formChangedVisibly', {
        fieldKey: $event.key,
        value: $event.value
      })},"formFieldChanged":function($event){return _vm.makingAChange('formFieldChanged', {
        fieldKey: $event.key,
        value: $event.value
      })},"onClickElement":_vm.onClickElement}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }