
import Vue from "vue";
import { claimsMapActions, claimsMapState } from "@/store/modules/claims";
import ClaimStatusLookup from "./ClaimStatusLookup.vue";
import { get } from "lodash";
import DataView from "@/components/DataView/DataView.vue";
import { downloadClaimsDocumentPolicyNumber } from "@/helpers/downloadLossForm";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";

export default Vue.extend({
  name: "ClaimsLookupResults",
  components: { ClaimStatusLookup, DataView, CustomAlert },
  data() {
    return {
      errorMessage: ""
    };
  },
  props: {
    result: {
      type: Object
    }
  },
  methods: {
    ...claimsMapActions(["printClaimsLookupData", "lookupClaimByNumber"]),
    async viewClaim(claim: any) {
      this.$emit("loadingText", "Fetching claim. Please wait...");
      this.errorMessage = "";
      try {
        const response = await this.lookupClaimByNumber({
          claimNumber: claim.ClaimNumber,
          site: "ATLAS"
        });
        const { data } = response.data;
        this.$emit("sendBackClaimsLookUpByPolicyNumberInformation", {});
        this.$emit("sendBackClaimsLookUpByClaimsNumberInformation", data);
        this.$emit("lookupMethod", "claimNumber");
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async printClaims(policyClaims: any) {
      try {
        await downloadClaimsDocumentPolicyNumber({
          data: policyClaims
        });
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async doPrintClaimsLookupByPolicyNumberData() {
      await downloadClaimsDocumentPolicyNumber({
        data: {
          ClaimOccurrence: this.claims,
          Name: this.result.data.Name,
          PolicyNum: this.result.data.PolicyNum
        }
      });
    },
    async onToolbarItemClick(event: string): Promise<void> {
      switch (event) {
        case "claims-lookup":
          this.$modal.show("claimLookupModal");
          break;
        case "print-form":
          await downloadClaimsDocumentPolicyNumber({
            data: {
              ClaimOccurrence: this.claims,
              Name: this.result.data.Name,
              PolicyNum: this.result.data.PolicyNum
            }
          });
          break;
      }
    }
  },
  computed: {
    ...claimsMapState(["makingApiRequest"]),
    claims(): any[] {
      if (!this.result || !this.result.data) return [];
      const { ClaimOccurrence = [] } = this.result.data;
      return ClaimOccurrence;
    },
    getData() {
      let result = {};
      result = {
        attachments: [],
        sections: [
          {
            sectionTitle: `POLICY INFORMATION`,
            showSection: true,
            sectionColumnSize: "3",
            information: [
              {
                informationColumnSize: "1",
                key: "Name",
                value: get(this.result.data, "Name", "")
              },
              {
                informationColumnSize: "1",
                key: "Claim Number",
                value: get(this.result.data, "PolicyNum", "")
              }
            ]
          }
        ]
      };
      return result;
    }
  }
});
