import { DefineActions, ActionContext } from "vuex-type-helper";
import {
  UserActions,
  UserState,
  UserMutations,
  UserGetters,
  IUserUpdatePayload
} from "./types";
import * as types from "@/store/mutation-types";
import { userService } from "@/services/users";
import { IUserModel } from "@/types";
import {
  GenericCreateOne,
  GenericDeleteOne,
  GenericGetMany,
  GenericGetOne,
  GenericMakeRequest,
  GenericUpdateOne
} from "@/store/helpers/actions";

export type UserActionContext = ActionContext<
  UserState,
  UserGetters,
  UserActions,
  UserMutations
>;

export const actions: DefineActions<
  UserActions,
  UserState,
  UserMutations,
  UserGetters
> = {
  createUser: GenericCreateOne<
    UserState,
    UserGetters,
    UserActions,
    UserMutations,
    IUserModel
  >(userService, types.SET_USER as any),

  getUsers: GenericGetMany<UserState, UserGetters, UserActions, UserMutations>(
    userService,
    "users",
    "SET_USER"
  ),
  getUser: GenericGetOne<UserState, UserGetters, UserActions, UserMutations>(
    userService,
    types.SET_USER
  ),
  deleteUser: GenericDeleteOne<
    UserState,
    UserGetters,
    UserActions,
    UserMutations
  >(userService, types.DELETE_USER),
  updateUser: GenericUpdateOne<
    UserState,
    UserGetters,
    UserActions,
    UserMutations,
    IUserUpdatePayload
  >(userService, types.SET_USER),

  makeScreenViewActivity: GenericMakeRequest<
    UserState,
    UserGetters,
    UserActions,
    UserMutations
  >(userService.makeScreenViewActivity.bind(userService)),

  async getContactUsDetails({ commit }, query) {
    const { data } = await userService.getContactUsDetails(query);
    commit(types.SET_USER, data);

    return data;
  },
  async requestVerifyPhoneCode({}, phone) {
    const { data } = await userService.requestVerifyPhoneCode(phone);
    return data;
  },

  async verifyPhone({ commit }, codeData) {
    const { data } = await userService.verifyPhone(codeData);
    commit(types.SET_USER, data.data);
    return data;
  },

  banUsers: async ({ commit }, userIds) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await userService.banUsers(userIds);
      const responseData = response.data;
      commit(types.SET_USER, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  restoreUser: async ({ commit }, userId) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await userService.restoreDeletedUser(userId);
      const responseData = response.data;
      commit(types.DELETE_USER, userId);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },

  restrictUsers: async ({ commit }, payload) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await userService.restrictUsers(
        payload.userIds,
        payload.restrictData
      );
      const responseData = response.data;
      commit(types.SET_USER, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },

  deRestrictUsers: async ({ commit }, userIds) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await userService.deRestrictUsers(userIds);
      const responseData = response.data;
      commit(types.SET_USER, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },

  getUnderWriters: async ({ commit }, query) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await userService.getUnderWriters(query);
      const responseData = response.data;
      commit(types.SET_UNDER_WRITER, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  restrictUser: async ({ commit }, payload) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await userService.restrictUser(
        payload.userIds,
        payload.restrictData
      );
      const responseData = response.data;
      commit(types.SET_USER, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  banUser: async ({ commit }, userId) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await userService.banUser([userId]);
      const responseData = response.data;
      commit(types.SET_USER, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  unbanUser: async ({ commit }, userId) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await userService.unbanUser([userId]);
      const responseData = response.data;
      commit(types.SET_USER, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  deRestrictUser: async ({ commit }, userId) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await userService.derestrictUser(userId);
      const responseData = response.data;
      commit(types.SET_USER, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  switchUserAgencyCode: async ({ commit }, { userId, agentCode }) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await userService.switchUserAgencyCode(
        userId,
        agentCode
      );
      const responseData = response.data;
      commit(types.SET_USER, responseData);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
};
