import { activities } from "./activities";
import { agency } from "./agency";
import { agentApplications } from "./agentApplications";
import { app } from "./app";
import { appSettings } from "./appSettings";
import { atlasfile } from "./atlasfile";
import { auth } from "./auth";
import { billingChangeRequest } from "./billingchangerequest";
import { claims } from "./claims";
import { communication } from "./communication";
import { companies } from "./companies";
import { county } from "./county";
import { imports } from "./import";
import { mortgage } from "./mortgage";
import { notification } from "./notification";
import { payplanchangerequest } from "./payplanchangerequest";
import { propertychangerequest } from "./propertychangerequest";
import { propertyanalytics } from "./propertyanalytics";
import { quickpay } from "./quickpay";
import { search } from "./search";
import { setting } from "./setting";
import { user } from "./user";
import { personalLiabilityPolicy } from "./personalLiabilityPolicy";
import { socketIO } from "./socketIO";
import { quote } from "./quote";
import { transactions } from "./transactions";
import { endorsements } from "./endorsements";
import { chart } from "./chart";
import { adjusterfile } from "./adjusterfile";
import { agentreport } from "./agentreports";
import { gbsHelp } from "./gbshelp";
import { emergency } from "./emergency";
import { atlasFileCategory } from "./atlasFileCategory";
import { underWritingQuestion } from "./underWritingQuestion";
import { accreditedBuilder } from "./accreditedBuilder";
import { quoteCountyValidation } from "./quoteCountyValidation";
import { faq } from "./faq";
import { atlasPlatform } from "./atlasPlatform";
import { policySign } from "./policySign";
export default {
  quoteCountyValidation,
  accreditedBuilder,
  personalLiabilityPolicy,
  activities,
  agency,
  agentApplications,
  app,
  appSettings,
  atlasfile,
  auth,
  billingChangeRequest,
  claims,
  communication,
  companies,
  county,
  imports,
  mortgage,
  notification,
  payplanchangerequest,
  propertychangerequest,
  quickpay,
  search,
  setting,
  user,
  quote,
  transactions,
  endorsements,
  chart,
  adjusterfile,
  agentreport,
  gbsHelp,
  propertyanalytics,
  emergency,
  atlasFileCategory,
  underWritingQuestion,
  socketIO,
  faq,
  atlasPlatform,
  policySign
};
