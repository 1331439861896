import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  AgentReportState,
  AgentReportMutations,
  StateKeys,
  IAgentReport
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { defaultState } from "./state";

export const mutations: DefineMutations<
  AgentReportMutations,
  AgentReportState
> = {
  [types.SET_STATE]: GenericSetState<AgentReportState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<AgentReportState>(),
  [types.SET_EDIT]: GenericSetEdit<AgentReportState, string | IAgentReport>(
    "agentreports",
    (data: any) => ({ ...data })
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    AgentReportState,
    keyof IAgentReport
  >(),
  [types.SET_ITEM]: GenericSetItem<AgentReportState, IAgentReport>(
    "agentreports",
    (data: any) => ({ ...data })
  ), //TODO: WRITE A getXDefaultsFunction and pass it here in place of the anonymous function
  [types.DELETE_ITEM]: GenericDeleteItem<AgentReportState>(
    "agentreports",
    "_id"
  ),
  [types.CLEAR_AGENT_REPORT_STORE]: GenericClearStore<AgentReportState>(
    defaultState()
  )
};
