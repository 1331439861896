import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { gbsHelpState, GbsHelpMutations, StateKeys, IGbsHelp } from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { getGbsHelpDefaults } from "@/helpers/defaultObjectGenerators";
import { defaultState } from "./state";

export const mutations: DefineMutations<GbsHelpMutations, gbsHelpState> = {
  [types.SET_STATE]: GenericSetState<gbsHelpState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<gbsHelpState>(),
  [types.SET_EDIT]: GenericSetEdit<gbsHelpState, string | IGbsHelp>(
    "gbsHelps",
    getGbsHelpDefaults
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<gbsHelpState, keyof IGbsHelp>(),
  [types.SET_ITEM]: GenericSetItem<gbsHelpState, IGbsHelp>(
    "gbsHelps",
    getGbsHelpDefaults
  ),
  [types.DELETE_ITEM]: GenericDeleteItem<gbsHelpState>("gbsHelps", "_id"),
  [types.CLEAR_GBSHELP_STORE]: GenericClearStore<gbsHelpState>(defaultState())
};
