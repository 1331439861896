import {
  FormBlock,
  FormBlockFieldProperties
} from "@/components/FormBuilder/types";
import { get } from "lodash";
export * from "./quotes";

export function setFieldObjectProperties(
  form: any[],
  fieldKey: string,
  properties: Partial<FormBlockFieldProperties>
): any {
  const blocksAsArray = Array.isArray(form) ? form : [form];
  for (const block of blocksAsArray) {
    if (block.element === "field" && block.properties.key === fieldKey) {
      block.properties = Object.assign(block.properties, properties);
      break;
    }
    if (
      (block.element === "html" || block.element === "section") &&
      block.children &&
      block.children.length
    ) {
      setFieldObjectProperties(block.children, fieldKey, properties);
    }
  }
}

export function getFieldObjectProperty(
  form: FormBlock[],
  fieldKey: string,
  property: keyof FormBlockFieldProperties
): any {
  let foundValue: any;
  const _lookInBlock = (block: any) => {
    if (
      (block.element === "html" || block.element === "section") &&
      block.children &&
      block.children.length
    ) {
      for (const blockChild of block.children) {
        _lookInBlock(blockChild);
      }
    }
    if (block.element === "field" && block.properties.key === fieldKey) {
      foundValue = get(block.properties, property);
      return;
    }
  };

  const blocksAsArray = Array.isArray(form) ? form : [form];
  for (const block of blocksAsArray) {
    if (foundValue) {
      return foundValue;
    }

    _lookInBlock(block);
  }
}
