import { QuoteState } from "./types";

export const defaultState = (): QuoteState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    quotes: [],
    links: {},
    socketRatingIds: []
  };
};

export const state = defaultState();
