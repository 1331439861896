export const confirmAccount = {
  confirmAccountHeader: "Account Confirmed!",
  confirmAccountEmailText: "Send Account Confirmation Email",
  confirmAccountTokenInvalid: `Your token is invalid. Please enter your email address below.
                              You will receive an email with a link to confirm your account.`,
  confirmAccountAlreadyActive: "Account is already active.",
  confirmAccountRequestSent:
    "An account confirmation link has been sent to your email",
  confirmAccountGetNewLink: "Click here to get new account confirmation link"
};
