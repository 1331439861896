import { Notification } from "element-ui";
import { ElNotificationOptions } from "element-ui/types/notification";

let notificationIndex = 0;
export const doNotify = (options: Partial<ElNotificationOptions>) => {
  const { type = "info", position, duration } = options as any;
  const realPosition = !position ? "right-0 sm:right-2" : "";

  const defaults = {
    title: "",
    message: "",
    offset: 100,
    customClass: `el-notification-${type} w-full sm:w-96 ${realPosition}`,
    onClose: () => (notificationIndex -= 1)
  };
  if (typeof duration !== "number") {
    delete options.duration;
  }
  Notification(Object.assign({}, defaults, options));
  notificationIndex += 1;
};

export const doNotifySuccess = (
  message: string = "",
  title: string = "Success",
  duration?: number
) => {
  doNotify({ title, message, type: "success", duration });
};
export const doNotifyError = (
  message: string = "",
  title: string = "Error",
  duration?: number
) => {
  doNotify({ title, message, type: "error", duration });
};
export const doNotifyInfo = (
  message: string = "",
  title: string = "Info",
  duration?: number
) => {
  doNotify({ title, message, type: "info", duration });
};
