const title = "Change Requests";
export default [
  {
    path: "/change-requests",
    component: () =>
      import("@/views/shared/ChangeRequests/ChangeRequestsPick.vue"),
    meta: {
      pageTitle: title,
      requireAuth: true,
      allowedRoles: [
        "agent",
        "admin",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title
      }
    }
  }
];
