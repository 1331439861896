<template>
  <div>
    <router-link
      v-show="$isLoggedIn"
      id="mobile-header-button"
      class="button py-1 px-2 transition-all"
      :to="$isCurrentUserAdmin ? '/admin/notifications' : '/notifications'"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-7 h-7 sm:h-5 sm:w-5 text-gray-700"
      >
        <path
          fill-rule="evenodd"
          d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </router-link>
    <div id="mobile-header-button-alt" class="button py-1 px-2 transition-all">
      <TopMenuUserDropdown v-show="$isLoggedIn" class="relative" />
    </div>
    <ApplicationPrimaryMobileMenuToggle v-show="$isLoggedIn" />
    <ApplicationSiteHeader />
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import ApplicationPrimaryMobileMenuToggle from "@/components/AppRootWrapper/Header/ApplicationPrimaryMobileMenuToggle.vue";
import ApplicationSiteHeader from "@/components/AppRootWrapper/Header/ApplicationSiteHeader.vue";
import TopMenuUserDropdown from "@/components/GlobalMenu/TopMenuUserDropdown.vue";

export default {
  components: {
    TopMenuUserDropdown,
    ApplicationSiteHeader,
    ApplicationPrimaryMobileMenuToggle
  }
};
</script>
