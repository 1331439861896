import Vue from "vue";

export const contactUsMenu = {
  to: "/contactUs",
  text: "Contact Us/Payment Info"
};

export const FAQMenu = {
  to: "/FAQ",
  text: "FAQs"
};
export const userTopMenu = ({
  unreadNotifications
}: {
  unreadNotifications?: number;
}) => [
  {
    to: "/notifications",
    text: "",
    description: "view notifications",
    badgeNumber: unreadNotifications,
    icon: "message"
  }
];

export type IRole = "agent" | "ezlynx";

export const userPrimaryMenu = function(role: IRole) {
  const agentsOnlyMenu = isAgent(role)
    ? [
        {
          to: "/Claims",
          text: "Claims",
          description: "Manage claims",
          icon: "folders",
          subLinks: [
            {
              to: "/claims",
              text: "Claims"
            },
            {
              to: "/claims-lookup",
              text: "Claims Status Lookup"
            },
            {
              to: "/file-claim",
              text: "File a Claim"
            }
          ]
        },
        {
          to: "/documents",
          permission: "documents:read",
          text: Vue.prototype.__getText("menu", "documentsLinkText"),
          description: "View Documents",
          icon: "folders",
          subLinks: [
            {
              permission: "agent_reports:read",
              to: "/agent-reports",
              text: "Agent Reports"
            },
            {
              to: "/documents",
              text: Vue.prototype.__getText("menu", "documentsLinkText"),
              description: "browse documents"
            }
          ]
        },
        {
          to: "/policy-inquiry",
          text: "Policy",
          description: "Policy Inquiry",
          icon: "",
          subLinks: [
            {
              to: "/billing",
              text: "Billing Inquiry",
              description: "Get billing information about policies",
              icon: ""
            },
            {
              to: "/quickbillpay",
              text: "Bill Pay",
              description: "Pay bills",
              icon: ""
            },
            {
              to: "/change-requests",
              text: Vue.prototype.__getText("menu", "changeRequestLinkText"),
              description: "Manage change requests",
              icon: "exchange"
            },
            {
              to: "/policy-inquiry",
              text: "Policy Inquiry"
            }
          ]
        }
      ]
    : [];

  const quotesSubLinks: any[] = [
    {
      to: "/quotes",
      text: "Quotes"
    }
  ];
  if (isAgent(role)) {
    quotesSubLinks.push({
      to: "/personal-liability-policies",
      text: "Personal Liability Application",
      description: "Personal Liability Policy application"
    });
  }

  return [
    {
      to: "/dashboard",
      text: "Home"
    },
    ...agentsOnlyMenu,
    {
      to: "/quotes",
      text: "Quotes",
      description: "Manage Quotes",
      icon: "folders",
      subLinks: quotesSubLinks
    },
    contactUsMenu,
    FAQMenu
  ];
};

function isAgent(role: IRole): boolean {
  return role === "agent";
}
