import { DefineGetters } from "vuex-type-helper";
import { AppGetters, AppState } from "./types";

export const getters: DefineGetters<AppGetters, AppState> = {
  getWindowSize: (state: AppState) => state.window,
  getShowMobileMenu: (state: AppState) => state.showMobileMenu,
  getScrollTop: (state: AppState) => state.scrollTop,
  getScreenSize: (state: AppState) => state.screenSize,
  getAppViewSettings: (state: AppState) => state.settings,
  getAppTheme: (state: AppState) => state.theme,
  getShowLoggedOutValue: (state: AppState) => state.showLoggedOutModal
};
