import { DefineMutations } from "vuex-type-helper";
import {
  AtlasFileCategoryMutations,
  AtlasFileCategoryState,
  StateKeys,
  IAtlasFileCategory
} from "./types";
import * as types from "@/store/mutation-types";
import { getAtlasFileCategoryDefault } from "../../../helpers/atlasFileCategoryDefault";
import {
  GenericSetState,
  GenericSetStates,
  GenericSetEditField,
  GenericSetEdit,
  GenericSetItem,
  GenericDeleteItem
} from "../../helpers/mutations";

export const mutations: DefineMutations<
  AtlasFileCategoryMutations,
  AtlasFileCategoryState
> = {
  [types.SET_STATE]: GenericSetState<AtlasFileCategoryState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<AtlasFileCategoryState>(),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    AtlasFileCategoryState,
    keyof IAtlasFileCategory
  >(),
  [types.SET_EDIT]: GenericSetEdit<
    AtlasFileCategoryState,
    string | Partial<IAtlasFileCategory>
  >("atlasFileCategories", getAtlasFileCategoryDefault),
  [types.SET_ITEM]: GenericSetItem<AtlasFileCategoryState, IAtlasFileCategory>(
    "atlasFileCategories",
    getAtlasFileCategoryDefault
  ),
  [types.DELETE_ITEM]: GenericDeleteItem<AtlasFileCategoryState>(
    "atlasFileCategories",
    "_id"
  )
};
