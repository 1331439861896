import { DefineGetters } from "vuex-type-helper";
import { EmergencyGetters, EmergencyState } from "./types";

export const getters: DefineGetters<EmergencyGetters, EmergencyState> = {
  isInEmergencyMaintenanceMode: (state: EmergencyState) =>
    state.emergencyData
      ? state.emergencyData.isInEmergencyMaintenanceMode
      : false,
  isInFiservScheduledMaintenanceMode: (state: EmergencyState) =>
    state.emergencyData
      ? state.emergencyData.isInFiservScheduledMaintenanceMode
      : false
};
