export const PIXEL_RATIO = window.devicePixelRatio || 1;
export const VIEWPORT_RATIO = 0.98;
export const LEGACY_PHP_API_DATE_FORMAT = "MM/dd/yyyy";

//DISCLAIMER
/**
 * CONSTANTS BELOW MAY NEED TO BE MOVED TO THE SETTINGS COLLECTION
 * OR SOMEWHERE ELSE WHERE THEY ARE MORE DYNAMIC
 *
 * THAT DECISION IS OUTSIDE THE SCOPE OF THIS TASK
 * TODO: create a task for the it after this "Gigantic" whatever is done!!!
 */

export const KindOfLoss = [
  { value: "5", label: "WINDSTORM" },
  { value: "6", label: "HAIL" },
  { value: "24", label: "HURRICANE" },
  { value: "14", label: "DISCHARGE-SUDDEN & ACCIDENTAL" },
  { value: "15", label: "DISCHARGE-APPLIANCE" },
  { value: "16", label: "DISCHARGE-BACKUP SEWER/DRAIN" },
  { value: "17", label: "DISCHARGE-CONTINUOUS/REPEATED" },
  { value: "13", label: "DISCHARGE-SLAB LEAK" },
  { value: "1", label: "FIRE-INTERNAL SOURCE" },
  { value: "2", label: "FIRE-EXTERNAL SOURCE" },
  { value: "3", label: "FIRE-UNKNOWN SOURCE" },
  { value: "4", label: "LIGHTNING-NO FIRE" },
  { value: "8", label: "SMOKE" },
  { value: "22", label: "LIABILITY/MED PAY" },
  { value: "11", label: "VANDALISM/M.MISCHIEF" },
  { value: "20", label: "BURGLARY/THEFT" },
  { value: "9", label: "AIRCRAFT/VEHICLES" },
  { value: "26", label: "GLASS" },
  { value: "21", label: "PHYSICAL DMG/OTHER" },
  { value: "12", label: "COLLAPSE" },
  { value: "18", label: "FREEZE-DMG.TO SLAB" },
  { value: "19", label: "FREEZE-OTHER DAMAGE" },
  { value: "25", label: "FLOOD/SURFACE WATER" },
  { value: "7", label: "EXPLOSION" },
  { value: "10", label: "RIOT-CIVIL COMMOTION" }
];

export const KindOfLossForList: any = {
  "1": { code: "005", name: "FIRE-INTERNAL SOURCE" },
  "2": { code: "010", name: "FIRE-EXTERNAL SOURCE" },
  "3": { code: "015", name: "FIRE-UNKNOWN SOURCE" },
  "4": { code: "020", name: "LIGHTNING-NO FIRE" },
  "5": { code: "025", name: "WINDSTORM" },
  "6": { code: "030", name: "HAIL" },
  "7": { code: "033", name: "EXPLOSION" },
  "8": { code: "035", name: "SMOKE" },
  "9": { code: "040", name: "AIRCRAFT/VEHICLES" },
  "10": { code: "045", name: "RIOT-CIVIL COMMOTION" },
  "11": { code: "050", name: "VANDALISM/M.MISHCIEF" },
  "12": { code: "055", name: "COLLAPSE" },
  "13": { code: "060", name: "DISCHARGE-SLAB LEAK" },
  "14": { code: "061", name: "DISCHARGE-SUDDEN & ACCIDENTAL" },
  "15": { code: "062", name: "DISCHARGE-APPLIANCE" },
  "16": { code: "063", name: "DISCHARGE-BACKUP SEWER/DRAIN" },
  "17": { code: "064", name: "DISCHARGE-CONTINOUS/REPEATED" },
  "18": { code: "070", name: "FREEZE-DMG.TO SLAB" },
  "19": { code: "071", name: "FREEZE-OTHER DAMAGE" },
  "20": { code: "075", name: "BURGLARY/THEFT" },
  "21": { code: "080", name: "PHYSICAL DMG/OTHER" },
  "22": { code: "090", name: "LIABILITY/MED PAY" },
  "24": { code: "026", name: "HURRICANE" },
  "25": { code: "065", name: "FLOOD/SURFACE WATER" },
  "26": { code: "081", name: "GLASS" }
};

export const ATLAS_CLAIM_REPRESENTATIVE = {
  "20": "YSABEL JOPLING EXT 5701",
  "28": "Phyllis Shamu, EXT 5704",
  "43": "Morgan Shook, EXT 5706",
  "30": "Jessica Callan, EXT 5708",
  "31": "Atlas Claims",
  "39": "Samuel Moore,  EXT 5709",
  "40": "Ellea Campillo, Ext. 5703",
  "97": "Wind Adjuster",
  "09": "Martha Lozano, EXT 5705",
  TPA: "TPA Desk Examiner"
};

export const ATLAS_FIELD_ADJUSTER: Record<string, any> = {
  DEV: "Devah Adjusting",
  IAI: "Ideal Adjusting, Inc.",
  EAS: "EAGLE ADJUSTING SERVICE",
  AMC: "Americlaim",
  EVC: "Evolv Claim Solutions",
  CPS: "COMPASS ADJUSTING SERVICES,INC",
  CLC: "CLAIMCOR SOLUTIONS",
  JDC: "J D CLAIM SERVICES",
  KJS: "KJS Claims Service",
  MID: "MID AMERICA CATASTROPHE SVCS",
  PCG: "Primeco Claims Group",
  PRE: "PRECISE ADJUSTING",
  RAS: "ROYAL ADJUSTING SERVICES",
  REP: "ATTORNEY REPRESENTED",
  SUB: "SUBRO",
  SUT: "LAW SUIT",
  SWA: "SCHAFER, WOOD AND ASSOCIATES",
  USA: "U.S. ADJUSTING SERVICES",
  EBR: "Eberl Claims Service LLCS",
  "01": "JAMI BROWNING",
  "09": "MARTHA LOZANO,",
  "20": "YSABEL JOPLING",
  "30": "Jessica Callan",
  "31": "Atlas Claims",
  // "34": "Kelsey Roman",
  // "35": "DARIELLE THOMPSON",
  // "40": "Ellea Campillo",
  "40": "Ellea Campillo",
  "97": "Wind Adjuster",
  "39": "Samuel Moore",
  "28": "Phyllis Shamu",
  "43": "Morgan Shook"
};

export const ATLAS_FIELD_ADJUSTEROLD: Record<string, any> = {
  "7": {
    "Haley Wooten": "972-595-5700"
  },
  "21": {
    "Eileen Moring": "972-595-5702"
  },
  "24": {
    "Christopher Reeves": "972-595-5704"
  },
  "30": {
    "Jessica Callan": "972-595-5608"
  },
  "09": {
    "Martha Lozano": "972-595-5705"
  },
  ALC: {
    "Amarillo Claim Service, Inc.": "806-373-5222"
  },
  AMC: {
    Americlaim: "972-437-3394"
  },
  CPS: {
    "Compass Adjusting Service": "817-731-5008"
  },
  DGS: {
    "Douglas Claims Service": "972-437-3394"
  },
  GCS: {
    "Gentry & Associates Claims Service, Inc.": "888-840-3901"
  },
  HKL: {
    "Harper-Kennedy & Associates Inc.": "800-530-4700"
  },
  HWV: {
    "Howard Wilson Claims Service": "940-552-7402"
  },
  HWW: {
    "Howard Wilson Claims Service": "940-767-3220"
  },
  IAI: {
    "Ideal Adjusting, Inc.": "866-334-4332"
  },
  JDC: {
    "J D Claim Service": "877-511-4795"
  },
  JPC: {
    "John T. Parker Claims Service": "903-753-3950"
  },
  JPH: {
    "John T. Parker Claims Service": "281-759-0900"
  },
  MTM: {
    "Michael T. Mulholland": "817-300-4287"
  },
  RAS: {
    "Royal Adjusting Service": "817-757-2094"
  },
  SCS: {
    "State Claim Service Inc.": "817-271-7756"
  },
  SEL: {
    "Select Claims Service": "817-300-4287"
  }
};

export const FieldAdjusterOptionsOld = Object.keys(ATLAS_FIELD_ADJUSTER).map(
  (key: string) => {
    const value = ATLAS_FIELD_ADJUSTER[key];
    return {
      value: key,
      label: `${key} - ${Object.keys(value)[0]}`
    };
  }
);

export const KindOfLossOptions = KindOfLoss;

export const AtlasClaimRepresentativeOptions = Object.entries(
  ATLAS_CLAIM_REPRESENTATIVE
).map((key: any) => {
  return {
    value: key[0],
    label: `${key[0]} - ${key[1]}`
  };
});

export const FieldAdjusterOptions = Object.entries(ATLAS_FIELD_ADJUSTER).map(
  (key: any) => {
    return {
      value: key[0],
      label: `${key[0]} - ${key[1]}`
    };
  }
);

export const LossStatusLabel = {
  C: "Closed",
  O: "Open",
  D: "Did not meet deductible",
  P: "Not covered Peril",
  S: "Subrogation",
  W: "Withdrawn",
  U: "Unknown"
};

export const contentsAmountMaxValue = 150000;

export const AGENCY_UPDATED_EMAIL = "marketing@atlasgeneral.com";

export type Site = "COLONIAL" | "ATLAS";
