
import MenuItem from "@/components/GlobalMenu/MenuItem.vue";
import { authMapState } from "@/store/modules/auth";

import Vue from "vue";
import { userTopMenu } from "./menuItemsUser";
import { adminTopMenu } from "./menuItemsAdmin";
import { agencyAdminTopMenu } from "./menuItemsAgencyAdmin";

export default Vue.extend({
  name: "user-menu",
  components: {
    MenuItem
  },
  props: {
    currentRole: {
      type: String,
      required: true
    }
  },
  computed: {
    ...authMapState(["initData"]),
    menuItems(): any {
      if (
        this.$isLoggedIn &&
        !this.$isCurrentUserAdmin &&
        (this.$getCurrentUser.status === "pendingTutorial" ||
          this.$getCurrentUser.status === "completedTutorial")
      )
        return [];
      if (!this.$canReadNotifications) return [];
      switch (this.currentRole) {
        case "admin":
          return adminTopMenu({
            unreadNotifications: this.unreadNotifications
          }).filter((it: any) => !!it);
        case "agent":
          return userTopMenu({
            unreadNotifications: this.unreadNotifications
          }).filter((it: any) => !!it);
        case "agencyAdmin":
        case "subAdmin":
          return agencyAdminTopMenu({
            unreadNotifications: this.unreadNotifications
          }).filter((it: any) => !!it);
        default:
          return [];
      }
    },
    unreadNotifications(): number {
      if (this.initData) {
        return (
          this.initData.unreadNotifications.length +
          this.initData.urgentUnreadNotifications.length
        );
      }
      return 0;
    }
  }
});
