import { getPathAPI } from "@/helpers";
import axios, { AxiosResponse } from "axios";
import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class AdjusterfileService extends BaseCollectionService {
  constructor() {
    super("agent-reports");
  }
  public async exportAgentReportFile(payload: any): Promise<AxiosResponse> {
    const path = `/agent-report/file/view`;
    const response = await axios({
      url: `${getPathAPI()}${path}?accessToken=${localStorage.getItem(
        "access"
      )}&agentCode=${payload.agentCode}&fileName=${payload.fileName}`,
      method: "GET",
      responseType: "blob"
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${payload.fileName}`);
    document.body.appendChild(link);
    link.click();
    return response;
  }

  async getUploadedAgentReports(): Promise<ResponseWrapper> {
    const path = `agent-reports/uploaded`;
    return await this.request(
      {
        method: "get",
        url: path
      },
      { useToken: "access" }
    );
  }
}

export default new AdjusterfileService();
