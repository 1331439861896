import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  AgentReportState,
  AgentReportGetters,
  AgentReportMutations,
  AgentReportActions
} from "./types";

const namespaced = true;
export const namespace = "agentreport";

export const {
  mapState: agentReportMapState,
  mapGetters: agentReportMapGetters,
  mapMutations: agentReportMapMutations,
  mapActions: agentReportMapActions
} = createNamespacedHelpers<
  AgentReportState,
  AgentReportGetters,
  AgentReportMutations,
  AgentReportActions
>(namespace);

export const agentreport: Module<AgentReportState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
