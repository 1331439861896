var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._l((_vm.data.sections),function(section,index){return _c('div',{key:index},[(
        section.hasOwnProperty('showSection') ? section.showSection : true
      )?_c('dl',{staticClass:"grid grid-cols-1 gap-x-4 gap-y-8 py-4",class:[
        section.showDivider ? 'border-t border-gray-200' : '',
        `sm:grid-cols-${section.sectionColumnSize}`
      ]},[_c('div',{class:`sm:col-span-${section.sectionColumnSize}`},[_c('dt',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(section.sectionTitle)+" ")])]),_vm._l((section.information),function(info,index){return _c('div',{key:index,class:`sm:col-span-${info.informationColumnSize}`},[_c('dt',{staticClass:"text-sm font-medium text-gray-500"},[_vm._v(" "+_vm._s(info.key)+" ")]),(!info.isArray)?_c('dd',{staticClass:"mt-1 text-sm text-gray-900"},[_vm._v(" "+_vm._s(info.value)+" ")]):_vm._e(),(info.isArray)?_c('dd',{staticClass:"mt-1 text-sm text-gray-900"},_vm._l((info.value),function(data,key){return _c('p',{key:key},[_vm._v(" "+_vm._s(data)+" ")])}),0):_vm._e()])})],2):_vm._e()])}),(_vm.data && _vm.data.attachments && _vm.data.attachments.length)?_c('div',{staticClass:"sm:col-span-2 mt-10"},[_c('dt',{staticClass:"text-sm font-medium text-gray-500"},[_vm._v(" Attachments ")]),_c('dd',{staticClass:"mt-1 text-sm text-gray-900"},[_c('ul',{staticClass:"border border-gray-200 rounded-md divide-y divide-gray-200"},_vm._l((_vm.data.attachments),function(attachment,index){return _c('li',{key:index,staticClass:"pl-3 pr-4 py-3 flex items-center justify-between text-sm"},[_c('div',{staticClass:"w-0 flex-1 flex items-center"},[_c('i',{staticClass:"el-icon-paperclip text-gray-400"}),_c('span',{staticClass:"ml-2 flex-1 w-0 truncate"},[_vm._v(" "+_vm._s(attachment.name)+" ")])]),_c('div',{staticClass:"ml-4 flex-shrink-0"},[_c('a',{staticClass:"font-medium text-indigo-600 hover:text-indigo-500",attrs:{"download":"","href":`${_vm.url()}/file/view/${
                  attachment._id
                }?accessToken=${_vm.accessToken}`,"target":"_blank"}},[_vm._v(" Download ")])])])}),0)])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }