export const simple = {
  createImport: "Create Import",
  user: "user",
  save: "save",
  cancel: "cancel",
  confirm: "confirm",
  edit: "edit",
  reset: "reset",
  unverified: "unverified",
  verify: "verify",
  resendCodeLink: "resend code",
  communication: "communication",
  profile: "profile",
  property: "property",
  investment: "investment",
  document: "document",
  upload: "upload",
  download: "download",
  discard: "discard changes",
  files: "files",
  appSettings: "app settings",
  theme: "theme",
  admin: "admin",
  exporter: "exporter",
  importer: "importer",
  imports: "imports",
  import: "import",
  note: "note",
  message: "message",
  next: "next",
  previous: "previous",
  page: "page",
  disabled: "disabled",
  enabled: "enabled",
  enable: "enable",
  selectAllText: "select all",
  deselectAllText: "deselect all",
  allText: "all",
  errorsText: "errors",
  yes: "yes",
  no: "no",
  list: "list",
  lead: "lead",
  create: "create",
  syndication: "syndication",
  responses: "responses",
  response: "response",
  question: "question",
  recipients: "recipients",
  summary: "Summary",
  update: "update",
  addNewCompany: "Add New Company",
  companies: "Companies",
  deleteCompany: "Delete Company",
  propertyChangeRequest: "Policy Change Request",
  billingChangeRequest: "Cancellation Request",
  payPlanChangeRequest: "Pay Plan Change Request"
};
