
//TO-DO: pass orientation as prop to determine if position should be horizontal or vertical
import Vue from "vue";
import { ISelectFieldOption } from "@/types";

import { checkVuelidateValidation } from "@/helpers/processValidation";

export default Vue.extend({
  name: "check-box-group-blocks",
  props: {
    preamble: {
      type: String,
      required: false,
      default: ""
    },
    id: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      activeItems: []
    };
  },
  methods: {
    isChecked(option: ISelectFieldOption): boolean {
      return this.value.includes(option.value);
    },
    itemCheckedHandler(option: ISelectFieldOption, checked: boolean): void {
      const { value } = option;

      if (checked && this.isChecked(option)) {
        this.$emit(
          "change",
          this.value.filter((val: any) => val !== value)
        );
      } else {
        this.$emit("change", [...this.value, value]);
      }
    }
  },
  computed: {
    validationResult() {
      return checkVuelidateValidation(this.validations as any, this.value);
    }
  }
});
