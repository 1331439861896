import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  AdjusterfileState,
  AdjusterfileGetters,
  AdjusterfileMutations,
  AdjusterfileActions
} from "./types";

const namespaced = true;
export const namespace = "adjusterfile";

export const {
  mapState: adjusterfileMapState,
  mapGetters: adjusterfileMapGetters,
  mapMutations: adjusterfileMapMutations,
  mapActions: adjusterfileMapActions
} = createNamespacedHelpers<
  AdjusterfileState,
  AdjusterfileGetters,
  AdjusterfileMutations,
  AdjusterfileActions
>(namespace);

export const adjusterfile: Module<AdjusterfileState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
