<template>
  <transition name="slide-fade">
    <modal
      ref="self"
      height="auto"
      :adaptive="true"
      :name="$attrs.name"
      :classes="boxClasses"
      @before-open="beforeOpen"
      @before-close="beforeClose"
      @opened="opened"
      @closed="closed"
      :class="modalWrapperClass"
      :clickToClose="clickToClose"
      :showClose="showClose"
      v-loading="loading"
    >
      <div class="absolute top-3 right-3" v-if="showClose">
        <button @click="$modal.hide($attrs.name)">
          <font-awesome-icon
            :icon="['fal', 'times']"
            class="text-gray-500 hover:text-gray-700 text-xl"
          />
        </button>
      </div>

      <div class="w-full -mt-18">
        <div v-if="title" class="text-xl font-medium">{{ title }}</div>
        <div v-else class="text-xl font-medium"><slot name="title"></slot></div>
        <div v-if="description">{{ description }}</div>

        <div v-if="error">{{ error }}</div>
      </div>

      <div class="w-full my-4">
        <slot></slot>
      </div>

      <div class="w-full flex justify-end">
        <IAButton
          type="text"
          v-if="bottomButtonSecondary && bottomButtonSecondary.key"
          class="mr-2"
          :click="() => $modal.hide($attrs.name)"
          :title="secondaryButtonLabel"
          :disabled="false"
        />
        <IAButton
          v-if="bottomButtonPrimary && bottomButtonPrimary.key"
          :click="() => $emit('primaryButtonClick')"
          :title="bottomButtonPrimary.label"
          :disabled="bottomButtonPrimary.disabled"
        />
      </div>
    </modal>
  </transition>
</template>
<script>
export default {
  name: "UIModal",
  props: {
    /*
      Not listed as a prop, but this component _requires_ a name attribute.
    */
    clickToClose: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "small",
      validator(v) {
        return ["small", "medium", "large", "full"].includes(v);
      }
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    error: {
      type: String,
      default: ""
    },
    bottomButtonPrimary: {
      type: Object,
      default() {
        return {};
      }
    },
    bottomButtonSecondary: {
      type: Object,
      default() {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    allowYScrollOnOverflow: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: "center"
    }
  },
  data() {
    return {
      animatedClass: ""
    };
  },
  methods: {
    manuallyHideModal() {
      this.beforeClose();
    },
    beforeOpen(/*event*/) {
      if (this.position === "top") {
        this.animatedClass = "-translate-y-full";
      } else {
        this.animatedClass = "opacity-0";
      }
    },
    opened() {
      const { name } = this.$attrs;
      // this.$nextTick(() => {
      if (this.position === "top") {
        this.animatedClass = "-translate-y-0";
      } else {
        this.animatedClass = "opacity-1";
      }
      // });
      document.body.setAttribute(`data-${name}`, "true");
    },
    beforeClose(/*event*/) {
      this.animatedClass = "";
      this.$emit("close");
    },
    doCloseAfterAnimation() {
      const { name } = this.$attrs;
      const { self } = this.$refs;
      self.close();
      this.$modal.close(name);
      // this.
    },
    closed() {
      const { name } = this.$attrs;
      document.body.removeAttribute(`data-${name}`);
      this.$emit("closed");
    }
  },
  computed: {
    secondaryButtonLabel() {
      return this.bottomButtonSecondary?.label || "Cancel";
    },
    sizeClasses() {
      switch (this.size) {
        case "full":
          return [
            "w-screen",
            "h-screen",
            "top-0",
            "left-0",
            "px-4",
            "pt-5",
            "pb-4",
            "sm:p-6"
          ];
        case "large":
          return [
            "w-full",
            "h-screen",
            "sm:w-2/3",
            "sm:h-2/3",
            "sm:top-1/4",
            "sm:px-12",
            "sm:pt-4",
            "sm:pb-4",
            "px-8",
            "pt-4",
            "pb-4",
            "overflow-auto"
          ];
        case "medium":
          return [
            "sm:max-w-2xl",
            "sm:w-full",
            "h-screen",
            "sm:h-auto",
            "-top-40",
            "sm:top-1/4",
            "px-4",
            "pt-5",
            "pb-4",
            "sm:p-6"
          ];
        default:
          // small, etc
          return [
            "sm:max-w-xl",
            "sm:w-full",
            "h-screen",
            "sm:h-72",
            "-top-40",
            "sm:top-1/4",
            "px-4",
            "pt-5",
            "pb-4",
            "sm:p-6"
          ];
      }
    },
    boxClasses() {
      const { sizeClasses } = this;
      const boxClasses = [
        "z-50",
        "bg-white",
        "text-left",
        "shadow-xl",
        "transform",
        "transition-all",
        "select-none",
        "flex",
        "justify-start",
        "items-center",
        "flex-col"
      ];

      if (this.position === "top") {
        boxClasses.push("rounded-b-lg rounded-t-none");
      } else {
        boxClasses.push("rounded-lg");
      }

      if (this.allowYScrollOnOverflow) {
        boxClasses.push("overflow-y-auto");
      }

      return [...sizeClasses, ...boxClasses, this.animatedClass];
    },
    modalWrapperClass() {
      const wrapperClass = [];
      if (this.position === "top") {
        wrapperClass.push("items-start");
      } else {
        wrapperClass.push("items-center");
      }
      return wrapperClass;
    }
  },
  mounted() {}
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
