export * from "./focusTextFieldByRef";
export * from "./validateFields";
export * from "./objectDifference";
export * from "./getFileIcon";
export * from "./downloadExportFile";
export * from "./removeEmptyFields";
export * from "./downloadImportFileTemplate";
export * from "./createElementId";

import { addDays, isAfter, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { cloneDeep, has, get } from "lodash";
import {
  atlasAdminDepartment,
  atlasAdminTitle,
  completedStatus,
  completedStatuses,
  permissionOptions,
  basePermissionOptions,
  userRoles
} from "./selectOptions";
import { FormOptionItem } from "@/components/FormBuilder/types";
import {
  IAddress,
  ITableWrapperColumns,
  IUserRoleStrings
} from "@/types/interfaces";
import VueRouter from "vue-router";
import { Session } from "@/helpers/processSession";

const { VUE_APP_API_HTTP_SERVER = "" } = process.env;

export function getPathAPI(): string {
  return VUE_APP_API_HTTP_SERVER;
}

export function getFilesPathAPI(): string {
  return VUE_APP_API_HTTP_SERVER;
}

export function getOrigin(): string {
  return window.location.origin;
}

export const loadingTimeout = (time: number): Promise<void> => {
  const timeout = time || 540;
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
};

export function hasRequiredFields(
  object: any,
  requiredFields: string[]
): boolean {
  let hasFields = true;
  const actualFields = cloneDeep(object);
  for (const field of requiredFields) {
    if (!has(actualFields, field) || !get(actualFields, field)) {
      hasFields = false;
      break;
    }
    if (
      Array.isArray(get(actualFields, field)) &&
      get(actualFields, field).length <= 0
    ) {
      hasFields = false;
      break;
    }
  }
  return hasFields;
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function truncateString(str: string, num: number) {
  if (!str || str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "…";
}

export function generatePassword() {
  const length = Math.floor(Math.random() * (36 - 24 + 1) + 24);
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let pw = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    pw += charset.charAt(Math.floor(Math.random() * n));
  }
  return pw;
}

export function validateLicenseDateIsNotInPast(value: string) {
  if (!value) {
    return false;
  }
  const currentDate = Date.now();
  const selectedDate = parseISO(value);
  return !isAfter(currentDate, selectedDate);
}

export function validateIsValidLicenseDate(value: Date) {
  if (!value) {
    return false;
  }
  //fieldValue  is after 5 days from today using date-fns
  if (!(value instanceof Date)) {
    value = new Date(value);
  }

  return isAfter(addDays(new Date(), 5), value);
}

export function sortArrayOfObjects(
  array: any[],
  propertyToSortBy: string
): any[] {
  array.sort((a: any, b: any) => {
    const first = a[propertyToSortBy];
    const second = b[propertyToSortBy];
    if (first > second) return 1;
    if (second > first) return -1;
    return 0;
  });
  return array;
}

export function getTimeZone(): string {
  let defaultTimeZone = "America/Chicago";
  const { VUE_APP_API_HTTP_SERVER = "" } = process.env;
  if (VUE_APP_API_HTTP_SERVER.includes("localhost")) {
    defaultTimeZone = "UTC";
  }
  const { TIME_ZONE = defaultTimeZone } = process.env;
  return TIME_ZONE;
}

export function getDateInDifferentTimeZone(date: Date, timeZone: string): Date {
  const UTCDate = date.getTime();
  return utcToZonedTime(UTCDate, timeZone);
}

export function hasBothCreditAndLoss(quote: {
  ratingValidations: { hasValidLossInformation: any; hasCreditRating: any };
}) {
  return (
    quote?.ratingValidations?.hasValidLossInformation &&
    quote?.ratingValidations?.hasCreditRating
  );
}

export function clearSelectionsNotInData(
  listViewData: any,
  selectedDocuments: any
) {
  const fetchedRequestsIds = listViewData.map((item: any) => {
    return item._id;
  });
  selectedDocuments = selectedDocuments.filter((document: any) => {
    return fetchedRequestsIds.includes(document._id.toString());
  });
  return selectedDocuments;
}

export function isExemptedUserRole(
  userRole: IUserRoleStrings | string
): boolean {
  const exemptedRoles = [
    "admin",
    "underwriting",
    "claims",
    "billing",
    "dataEntry"
  ];
  return exemptedRoles.includes(userRole);
}

export function extraAtlasAdminPosition(): FormOptionItem[] {
  return Object.values(atlasAdminTitle).map((position: string) => ({
    value: position,
    label: position
  }));
}
export function extraAtlasAdminDepartment(): FormOptionItem[] {
  return Object.values(atlasAdminDepartment).map((department: string) => ({
    value: department,
    label: department
  }));
}
export function getUserPermissionsByRole(role: IUserRoleStrings) {
  if (isExemptedUserRole(role)) {
    return permissionOptions;
  } else if (role === "agencyAdmin") {
    return [
      ...basePermissionOptions,
      { label: "Agent Reports", value: "agent_reports:read" }
    ];
  }
  return basePermissionOptions;
}

export function redirectTo(
  router: VueRouter,
  data: { url: string; params?: Record<string, any> }
) {
  router.push({
    name: data.url,
    params: data.params
  });
}

export function goToCoApplicantForm(router: VueRouter, quoteId: string) {
  redirectTo(router, {
    url: "risk-address-step",
    params: {
      showCoApplicant: true,
      quoteId
    }
  });
}

export function elementIsInViewPort(
  targetElement: HTMLElement,
  cb: Function,
  defaultOptions?: IntersectionObserverInit
) {
  const observer = new IntersectionObserver(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          cb();
          observer.unobserve(entry.target);
        }
      });
    },
    defaultOptions
  );
  observer.observe(targetElement);
}
export function isCompletedStatus(status: completedStatus): boolean {
  return completedStatuses.includes(status);
}

export function toTitleCaseFromCamelCase(text: string) {
  if (!text || typeof text !== "string") return "";
  return text.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
    return str.toUpperCase();
  });
}

export function convertValuesToLowerCase(
  input: string | string[] | Record<string, any>
): string | string[] | Record<any, any> {
  if (typeof input === "string") {
    return input.toLowerCase() as string;
  }

  if (Array.isArray(input)) {
    return input.map((item: any) => convertValuesToLowerCase(item));
  }

  if (typeof input === "object" && input !== null) {
    const result: any = {};
    for (const [key, value] of Object.entries(input)) {
      result[key] = convertValuesToLowerCase(value);
    }
    return result;
  }

  return input;
}
export function hasValidAddress(address: IAddress, isPoBox?: boolean): boolean {
  if (isPoBox) {
    return Boolean(
      address?.unitNumber && address?.state && address?.city && address?.zipCode
    );
  }
  return Boolean(
    address?.houseNumber &&
      address?.streetName &&
      address?.state &&
      address?.city &&
      address?.zipCode
  );
}

export function checkObjectForFalsyValues(
  obj: Record<string, any>,
  keyToCheck: string
): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (typeof value === "object" && value !== null) {
        if (!checkObjectForFalsyValues(value, keyToCheck)) {
          return false;
        }
      } else if (key === keyToCheck && value) {
        return false;
      }
    }
  }
  return true;
}

const session = new Session();

export function persistTableColumnsToLocalStorage(
  columns: ITableWrapperColumns,
  key: string
) {
  const columnsKeys = Object.keys(columns);
  const columnData = session.get("persistedColumns") || {};
  const parsedPersistedColumns = columnData[key] || {};

  columnsKeys.forEach(columnKey => {
    if (!parsedPersistedColumns[columnKey]) {
      parsedPersistedColumns[columnKey] = {
        visibleByDefault: columns[columnKey].visibleByDefault
      };
    }
  });

  columnsKeys.forEach(columnKey => {
    columns[columnKey].visibleByDefault =
      parsedPersistedColumns[columnKey].visibleByDefault;
  });

  session.set("persistedColumns", {
    ...columnData,
    [key]: parsedPersistedColumns
  });

  return { ...columns };
}

export function updateColumnSelectionFromLocalStorage(
  columns: string,
  key: string
) {
  const splittedColumns = columns ? columns.split(",") : [];
  const persistedColumns = session.get("persistedColumns");
  const data = persistedColumns[key];

  if (data) {
    Object.keys(data).forEach(column => {
      data[column].visibleByDefault = splittedColumns.includes(column);
    });

    session.set("persistedColumns", { ...persistedColumns, [key]: data });
  }
}

const { VUE_APP_ENV = "development", VUE_APP_STAGING_API_URL } = process.env;
export function getImageURL(documentId: string): string {
  const baseURL = ["production", "staging"].includes(VUE_APP_ENV)
    ? VUE_APP_API_HTTP_SERVER
    : VUE_APP_STAGING_API_URL;

  return `${baseURL}/file/preview/${documentId}?email=true`;
}

export type ImageName =
  | "view-loss-report"
  | "validate-address"
  | "underwriter-questions"
  | "select-rating"
  | "select-line-of-business"
  | "rate-quote"
  | "property-information"
  | "lookup-address"
  | "go-to-quotes-navigation"
  | "enter-underwriter-code"
  | "edit-quote"
  | "create-new-quote-button"
  | "applicant-details"
  | "policy-enquiry-view"
  | "policy-enquiry-menu"
  | "policy-enquiry-form"
  | "lookup-claim"
  | "lookup-claim-by-policy-number"
  | "lookup-claim-by-claim-number"
  | "lookup-claim-by-policy-number-result"
  | "lookup-claim-by-claim-number-result"
  | "file-a-claim-navigation"
  | "file-a-claim-fill-in-claim-form-details"
  | "file-a-claim-enter-policy-details"
  | "file-a-claim-by-using-file-a-claim-button"
  | "bill-pay-verifying-policy-info"
  | "bill-pay-verifying-amount-and-paying-bill"
  | "bill-pay-using-quick-pay"
  | "bill-pay-reviewing-payment-amount"
  | "bill-pay-navigation"
  | "bill-pay-entering-billing-information-credit-card"
  | "bill-pay-entering-billing-information-account"
  | "bill-pay-editing-amount"
  | "bill-pay-after-payment-actions"
  | "billing-inquiry"
  | "billing-inquiry-navigation"
  | "billing-inquiry-using-actions"
  | "billing-inquiry-making-an-inquiry"
  | "roofstr"
  | "resource-not-found"
  | "quote-validation"
  | "quote-home"
  | "quickpay-payment-icons"
  | "quickpay-bg"
  | "property-change"
  | "prohibit-binding"
  | "pay-plan"
  | "not-found-default"
  | "map-image"
  | "house"
  | "emergency-maintenance"
  | "claims"
  | "billing-change"
  | "bill-pay"
  | "bg-pattern1"
  | "select-territory"
  | "atlas-logo-icon-only"
  | "atlas-logo-o"
  | "404"
  | "atlas-logo"
  | "atlas-holiday-logo"
  | "401"
  | "AdobeStock_286745923"
  | "AdobeStock_2867459232"
  | "login-bg1"
  | "template_d-0ee18d63d93d4de8b1dad86a89cba54c"
  | "atlas-logo-independence";

type Env = "development" | "production";
export const getUploadedImageId = (platform: ImageName): string => {
  const platformEnvMapper: {
    [key in ImageName]: { [key in Env]: string };
  } = {
    "401": {
      development: "66584aa92f48a595cbf19d6d",
      production: "668ece649f5688dfa027463b"
    },
    "atlas-holiday-logo": {
      development: "6658598e2f48a595cbf1a0f1",
      production: "668ed0aa9f5688dfa027c03f"
    },
    "404": {
      development: "66586acd2f48a595cbf1a537",
      production: "668ece819f5688dfa027484d"
    },
    "view-loss-report": {
      development: "6659a9992f48a595cbf1e40f",
      production: "668edb199f5688dfa02893b2"
    },
    "validate-address": {
      development: "6659a9802f48a595cbf1e3f2",
      production: "668edb059f5688dfa0289349"
    },
    "underwriter-questions": {
      development: "6659a96c2f48a595cbf1e3d7",
      production: "668eda969f5688dfa028926e"
    },
    "select-rating": {
      development: "6659a95a2f48a595cbf1e3bc",
      production: "668eda239f5688dfa0288e3b"
    },
    "select-line-of-business": {
      development: "6659a9142f48a595cbf1e39b",
      production: "668ed9fe9f5688dfa0288d9a"
    },
    "rate-quote": {
      development: "6659a8ee2f48a595cbf1e37b",
      production: "668ed9ea9f5688dfa0288ce7"
    },
    "property-information": {
      development: "6659a8d72f48a595cbf1e360",
      production: "668ed9cf9f5688dfa0288c9b"
    },
    "lookup-address": {
      development: "6659a8c22f48a595cbf1e344",
      production: "668ed9769f5688dfa0287af5"
    },
    "go-to-quotes-navigation": {
      development: "6659a8aa2f48a595cbf1e329",
      production: "668ed95a9f5688dfa0287a8c"
    },
    "enter-underwriter-code": {
      development: "6659a8912f48a595cbf1e30d",
      production: "668ed92d9f5688dfa02879a4"
    },
    "edit-quote": {
      development: "6659a8812f48a595cbf1e2f3",
      production: "668ed9159f5688dfa0287877"
    },
    "create-new-quote-button": {
      development: "6659a8332f48a595cbf1e2c9",
      production: "668ed8f89f5688dfa0287590"
    },
    "applicant-details": {
      development: "6659a8222f48a595cbf1e2af",
      production: "668ed8df9f5688dfa02874ac"
    },
    "policy-enquiry-view": {
      development: "6659a8082f48a595cbf1e293",
      production: "668ed6979f5688dfa027ffb8"
    },
    "policy-enquiry-menu": {
      development: "6659a7f82f48a595cbf1e279",
      production: "668ed67e9f5688dfa027fe67"
    },
    "policy-enquiry-form": {
      development: "6659a7e02f48a595cbf1e25b",
      production: "668ed65c9f5688dfa027fc84"
    },
    "lookup-claim": {
      development: "6659a76f2f48a595cbf1e20b",
      production: "668ed89b9f5688dfa02870a3"
    },
    "lookup-claim-by-policy-number": {
      development: "6659a7532f48a595cbf1e1ee",
      production: "668ed8819f5688dfa028704e"
    },
    "lookup-claim-by-claim-number": {
      development: "6659a7372f48a595cbf1e1d2",
      production: "668ed7a09f5688dfa02868b6"
    },
    "lookup-claim-by-policy-number-result": {
      development: "6659a7112f48a595cbf1e1b3",
      production: "668ed8559f5688dfa0286c69"
    },
    "lookup-claim-by-claim-number-result": {
      development: "6659a6fb2f48a595cbf1e199",
      production: "668ed76e9f5688dfa028683b"
    },
    "file-a-claim-navigation": {
      development: "6659a6be2f48a595cbf1e177",
      production: "668ed74c9f5688dfa02866a6"
    },
    "file-a-claim-fill-in-claim-form-details": {
      development: "6659a6a02f48a595cbf1e15a",
      production: "668edc609f5688dfa02899a4"
    },
    "file-a-claim-enter-policy-details": {
      development: "6659a6822f48a595cbf1e13e",
      production: "668ed6de9f5688dfa02804a9"
    },
    "file-a-claim-by-using-file-a-claim-button": {
      development: "6659a66a2f48a595cbf1e121",
      production: "668ed6bc9f5688dfa02803f7"
    },
    "bill-pay-verifying-policy-info": {
      development: "6659a6342f48a595cbf1e100",
      production: "668ed61e9f5688dfa027f8d2"
    },
    "bill-pay-verifying-amount-and-paying-bill": {
      development: "6659a5e72f48a595cbf1e0b5",
      production: "668ed6069f5688dfa027f7bf"
    },
    "bill-pay-using-quick-pay": {
      development: "6659a5cb2f48a595cbf1e099",
      production: "668ed5f29f5688dfa027f78f"
    },
    "bill-pay-reviewing-payment-amount": {
      development: "6659a5aa2f48a595cbf1e07b",
      production: "668ed5cb9f5688dfa027f5d7"
    },
    "bill-pay-navigation": {
      development: "6659a58a2f48a595cbf1e05d",
      production: "668ed5ad9f5688dfa027f512"
    },
    "billing-inquiry-using-actions": {
      development: "665cd19381b7d4fc67a38a5d",
      production: "668ed40f9f5688dfa027e444"
    },
    "bill-pay-entering-billing-information-credit-card": {
      development: "6659a54f2f48a595cbf1e03d",
      production: "668ed5909f5688dfa027f3c8"
    },
    "bill-pay-entering-billing-information-account": {
      development: "6659a5252f48a595cbf1e01e",
      production: "668ed56a9f5688dfa027f315"
    },
    "bill-pay-editing-amount": {
      development: "6659a4742f48a595cbf1dfeb",
      production: "668ed47f9f5688dfa027ec3d"
    },
    "bill-pay-after-payment-actions": {
      development: "6659a4622f48a595cbf1dfd0",
      production: "668ed45c9f5688dfa027ea94"
    },
    "billing-inquiry": {
      development: "6659a4442f48a595cbf1dfb3",
      production: "668ed4429f5688dfa027e84e"
    },
    "billing-inquiry-navigation": {
      development: "6659a4212f48a595cbf1df7d",
      production: "668ed3f99f5688dfa027e39b"
    },
    "billing-inquiry-making-an-inquiry": {
      development: "6659a4012f48a595cbf1df60",
      production: "668ed3b89f5688dfa027e159"
    },
    roofstr: {
      development: "665875c22f48a595cbf1a9f6",
      production: "668ed35b9f5688dfa027ded3"
    },
    "resource-not-found": {
      development: "665875b02f48a595cbf1a9db",
      production: "668ed3389f5688dfa027dd4c"
    },
    "quote-validation": {
      development: "665875992f48a595cbf1a9c0",
      production: "668ed31e9f5688dfa027dcf3"
    },
    "quote-home": {
      development: "665875812f48a595cbf1a9a4",
      production: "668edbfa9f5688dfa0289729"
    },
    "select-territory": {
      development: "665cede181b7d4fc67a38fb1",
      production: "668eda419f5688dfa0288ec5"
    },
    "quickpay-payment-icons": {
      development: "665875692f48a595cbf1a989",
      production: "668ed2ff9f5688dfa027dbe5"
    },
    "quickpay-bg": {
      development: "6658754b2f48a595cbf1a96c",
      production: "668ed2de9f5688dfa027dba0"
    },
    "property-change": {
      development: "665874fa2f48a595cbf1a91f",
      production: "668ed2c69f5688dfa027da1d"
    },
    "prohibit-binding": {
      development: "665874df2f48a595cbf1a903",
      production: "668ed2a59f5688dfa027d7df"
    },
    "pay-plan": {
      development: "665873ed2f48a595cbf1a89f",
      production: "668ed2909f5688dfa027d6e3"
    },
    "not-found-default": {
      development: "665873c62f48a595cbf1a881",
      production: "668ed2719f5688dfa027d293"
    },
    "map-image": {
      development: "665873b12f48a595cbf1a866",
      production: "668ed24c9f5688dfa027d0a6"
    },
    house: {
      development: "665873912f48a595cbf1a849",
      production: "668ed2399f5688dfa027cf5e"
    },
    "emergency-maintenance": {
      development: "6658735a2f48a595cbf1a827",
      production: "668ed2219f5688dfa027cc23"
    },
    claims: {
      development: "6658733e2f48a595cbf1a80c",
      production: "668ed2059f5688dfa027ca6b"
    },
    "billing-change": {
      development: "665873222f48a595cbf1a7ef",
      production: "668ed1dd9f5688dfa027c842"
    },
    "bill-pay": {
      development: "6658727e2f48a595cbf1a7be",
      production: "668ed1ab9f5688dfa027c6fb"
    },
    "bg-pattern1": {
      development: "6658725e2f48a595cbf1a7a0",
      production: "668ed1899f5688dfa027c51a"
    },
    "atlas-logo-icon-only": {
      development: "66586ae52f48a595cbf1a552",
      production: "668ed0d29f5688dfa027c128"
    },
    "atlas-logo-o": {
      development: "66586b052f48a595cbf1a56f",
      production: "668ed0f89f5688dfa027c1cb"
    },
    "atlas-logo": {
      development: "66585e0d2f48a595cbf1a222",
      production: "668ed16c9f5688dfa027c410"
    },
    AdobeStock_2867459232: {
      development: "665f420581b7d4fc67a40397",
      production: "668ecd919f5688dfa027435c"
    },
    "login-bg1": {
      development: "665f421e81b7d4fc67a403b3",
      production: "668ecdb69f5688dfa027444c"
    },
    AdobeStock_286745923: {
      development: "665f41f181b7d4fc67a40360",
      production: "668ecd0e9f5688dfa0273b5a"
    },
    "template_d-0ee18d63d93d4de8b1dad86a89cba54c": {
      development: "665f475581b7d4fc67a405ea",
      production: "668ed37c9f5688dfa027df94"
    },
    "atlas-logo-independence": {
      development: "668fd11cc6a4dc5cd49240cf",
      production: "668fd1679f5688dfa02c9d29"
    }
  };

  if (["staging", "development"].includes(VUE_APP_ENV)) {
    return platformEnvMapper[platform]["development"];
  }
  return platformEnvMapper[platform][VUE_APP_ENV as Env];
};

export const getSystemFilesURL = (imgName: ImageName) => {
  const imageId = getUploadedImageId(imgName);
  return getImageURL(imageId);
};
export function extractCompletedStatusDocumentIds(
  documents: Record<string, any>[]
): string[] {
  return documents
    .filter(document => isCompletedStatus(document.status.toLowerCase()))
    .map(document => document._id);
}

export function getUserRoleOptions(userRole: IUserRoleStrings) {
  let roles = [{ label: "Agent", value: "agent" }];
  switch (userRole) {
    case "agencyAdmin":
      roles.push(
        { value: "agencyAdmin", label: "Agency Admin" },
        { value: "subAdmin", label: "Sub Admin" }
      );
      break;
    case "admin":
      roles = userRoles;
      break;
    case "subAdmin":
      roles.push({ value: "subAdmin", label: "Sub Admin" });
      break;
  }
  return roles;
}
