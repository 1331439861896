import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { AppState, AppGetters, AppMutations, AppActions } from "./types";

const namespaced = true;
export const namespace = "app";

export const {
  mapState: appMapState,
  mapGetters: appMapGetters,
  mapMutations: appMapMutations,
  mapActions: appMapActions
} = createNamespacedHelpers<AppState, AppGetters, AppMutations, AppActions>(
  "app"
);

export const app: Module<AppState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
