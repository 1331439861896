import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  NotificationState,
  NotificationMutations,
  StateKeys,
  INotification
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { getNotificationDefaults } from "@/helpers/defaultObjectGenerators";
import { defaultState } from "./state";

export const mutations: DefineMutations<
  NotificationMutations,
  NotificationState
> = {
  [types.SET_STATE]: GenericSetState<NotificationState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<NotificationState>(),
  [types.SET_EDIT]: GenericSetEdit<NotificationState, string | INotification>(
    "notifications",
    getNotificationDefaults
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    NotificationState,
    keyof INotification
  >(),
  [types.SET_ITEM]: GenericSetItem<NotificationState, INotification>(
    "notifications",
    getNotificationDefaults
  ), //TODO: WRITE A getXDefaultsFunction and pass it here in place of the anonymous function
  [types.DELETE_ITEM]: GenericDeleteItem<NotificationState>(
    "notifications",
    "_id"
  ),
  [types.CLEAR_NOTIFICATION_STORE]: GenericClearStore<NotificationState>(
    defaultState()
  )
};
