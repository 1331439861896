export function getFileIcon(file: { ext: string }): string {
  if (!file || !file.ext) return "file";
  switch (file.ext) {
    case "pdf":
      return "file-pdf";
    case "png":
    case "jpg":
    case "jpeg":
    case "bmp":
      return "file-image";
    case "doc":
    case "docx":
      return "file-word";
    case "csv":
      return "file-excel";
    default:
      return "file";
  }
}
