import {
  IMortgage,
  MortgageActions,
  MortgageState,
  MortgageMutations,
  MortgageGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericMakeRequest,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import MortgageService from "@/services/mortgage.service";

export const actions: DefineActions<
  MortgageActions,
  MortgageState,
  MortgageMutations,
  MortgageGetters
> = {
  createMortgage: GenericCreateOne<
    MortgageState,
    MortgageGetters,
    MortgageActions,
    MortgageMutations,
    IMortgage
  >(MortgageService, types.SET_ITEM as any),
  getMortgages: GenericGetMany<
    MortgageState,
    MortgageGetters,
    MortgageActions,
    MortgageMutations
  >(MortgageService, "mortgages", types.SET_ITEM as any),
  getMortgage: GenericMakeRequest<
    MortgageState,
    MortgageGetters,
    MortgageActions,
    MortgageMutations
  >(MortgageService.getOne, types.SET_ITEM as any),
  deleteMortgage: GenericDeleteOne<
    MortgageState,
    MortgageGetters,
    MortgageActions,
    MortgageMutations
  >(MortgageService, types.DELETE_ITEM as any),
  updateMortgage: GenericUpdateOne<
    MortgageState,
    MortgageGetters,
    MortgageActions,
    MortgageMutations,
    { id: string; update: Partial<IMortgage> }
  >(MortgageService, types.SET_ITEM as any)
};
