
import Vue from "vue";
import ValidationTooltip from "@/components/ValidationTooltip/ValidationTooltip.vue";
export default Vue.extend({
  components: {
    ValidationTooltip
  },
  name: "radio-button-group",
  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      required: false,
      default: ""
    },
    preamble: {
      type: String,
      required: false,
      default: ""
    },
    preambleClass: {
      type: String,
      required: false,
      default: ""
    },
    labelClass: {
      type: String,
      required: false,
      default: ""
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    border: {
      type: Boolean,
      default: false
    },
    isInline: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    },
    popoverContentTitle: {
      type: String,
      required: false,
      default: ""
    },
    popoverContent: {
      type: String,
      required: false,
      default: ""
    },
    popoverText: {
      type: String,
      required: false,
      default: ""
    },
    showPopover: {
      type: Boolean,
      required: false,
      default: false
    },
    emitRadioButtonChangeEvent: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isValidResponse: true,
      message: ""
    };
  },
  methods: {
    inputHandler($event: any) {
      this.$emit("input", $event);
      if (this.emitRadioButtonChangeEvent) this.$emit("change", $event);
    },
    checkValidation(newVal: string) {
      if (this.validations && this.validations.length) {
        this.message = (this.validations as any)[0].error;
        return (this.validations as any)[0].customValidator(newVal);
      } else {
        return true;
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.isValidResponse = this.checkValidation(newVal);
      }
    }
  },
  computed: {
    isValidRequired(): boolean {
      if (this.required) {
        if (this.value === undefined || this.value === null) return false;
        if (typeof this.value === "string") {
          return this.value.trim().length > 0;
        }
        if (typeof this.value === "number") {
          return !isNaN(this.value);
        }
        if (typeof this.value === "boolean") return true;
        return !!this.value;
      } else {
        return true;
      }
    }
  }
});
