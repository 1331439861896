import { DefineGetters } from "vuex-type-helper";
import { gbsHelpGetters, gbsHelpState, IGbsHelp } from "./types";

export const getters: DefineGetters<gbsHelpGetters, gbsHelpState> = {
  getTotalRecords: ({ links, gbsHelps }: gbsHelpState) =>
    links && links.totalRecords ? links.totalRecords : gbsHelps.length,
  getMaxQuerySize: ({ links, gbsHelps }: gbsHelpState) =>
    links && links.maxQueryLimit ? links.maxQueryLimit : gbsHelps.length,
  getGbsHelpById: (state: gbsHelpState) => (countyId: string) =>
    state.gbsHelps.find((county: IGbsHelp) => county._id === countyId),
  gbsHelp: (state: gbsHelpState) => state.gbsHelps,
  getGbsHelpLinks: (state: gbsHelpState) => state.links,
  getEditingGbsHelp: (state: gbsHelpState) => state.editing
};
