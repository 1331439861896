
import Vue from "vue";
import * as selectOptions from "@/helpers/selectOptions";

interface IData {
  loading: boolean;
  adjustingFirmOptions: any;
}

export default Vue.extend({
  name: "search-select-adjuster-firms",
  props: {
    selected: {
      type: [Number, String, Array, Boolean],
      default: ""
    },
    value: {
      type: [Number, String, Array, Boolean],
      default: ""
    },
    label: {
      type: String,
      default: "",
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data(): IData {
    return {
      loading: false,
      adjustingFirmOptions: []
    };
  },
  methods: {
    async remoteMethod(searchTerm: string) {
      if (searchTerm !== "") {
        this.loading = true;
        this.adjustingFirmOptions = selectOptions.fieldAdjustingFirmsOptions.filter(
          (item: any) => {
            return (
              item.label.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
            );
          }
        );
        this.loading = false;
      } else {
        this.adjustingFirmOptions = [];
      }
    }
  }
});
