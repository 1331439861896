import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  MortgageState,
  MortgageMutations,
  StateKeys,
  IMortgage
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { defaultState } from "./state";

export const mutations: DefineMutations<MortgageMutations, MortgageState> = {
  [types.SET_STATE]: GenericSetState<MortgageState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<MortgageState>(),
  [types.SET_EDIT]: GenericSetEdit<MortgageState, string | IMortgage>(
    "mortgages",
    (data: any) => ({ ...data })
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<MortgageState, keyof IMortgage>(),
  [types.SET_ITEM]: GenericSetItem<MortgageState, IMortgage>(
    "mortgages",
    (data: any) => ({ ...data })
  ), //TODO: WRITE A getXDefaultsFunction and pass it here in place of the anonymous function
  [types.DELETE_ITEM]: GenericDeleteItem<MortgageState>("mortgages", "_id"),
  [types.CLEAR_MORTGAGE_STORE]: GenericClearStore<MortgageState>(
    defaultState()
  ),
  [types.ADD_MORTGAGE_OTHER](state: MortgageState) {
    state.mortgages.unshift({
      address: "OTHER",
      city: "OTHER",
      mortgageName: "OTHER",
      state: "OTHER",
      zipCode: "OTHER"
    });
  }
};
