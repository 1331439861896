import { IAtlasFileCategory } from "@/store/modules/atlasFileCategory/types";
import defaultsDeep from "lodash/defaultsDeep";
import { cloneDeep } from "lodash";
import { baseAtlasFileCategory } from "./defaultObjects";

export function getAtlasFileCategoryDefault(
  atlasFileCategory: Partial<IAtlasFileCategory>
): IAtlasFileCategory {
  return defaultsDeep(
    cloneDeep(atlasFileCategory),
    Object.assign(baseAtlasFileCategory())
  );
}
