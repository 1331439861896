import { LEGACY_PHP_API_DATE_FORMAT } from "@/helpers/constants";
import { convertDashToSpace, formatAgencyCode } from "@/helpers/generalHelpers";
import { formatCurrencyFloat } from "@/plugins/filters";
import { format, parseISO } from "date-fns";
import { get } from "lodash";

export function doFormatDateMMDDYYYY(dateStr: string) {
  const date = parseISO(dateStr);
  return format(date, LEGACY_PHP_API_DATE_FORMAT);
}

const getAgencyName = (name: string) => name.split(":")[1];
export const extractPolicyInquiryData = (data: Record<string, any>) => {
  if (!data || !Object.keys(data).length) {
    return {};
  }

  const FLLimit1 = formatCurrencyFloat(get(data, "FL[limit1]", "0"));
  const FLLimit2 = formatCurrencyFloat(get(data, "FL[limit2]", "0"));
  return {
    ...data,
    insuredName: `${get(data, "insured[0]", "")} ${get(
      data,
      "insured[1]",
      ""
    )}`,
    mailAddress: `${get(data, "StreetNumber", "")} ${get(
      data,
      "StreetName",
      ""
    )}`,
    cityStateZip: `${get(data, "City", "")}, ${get(data, "State", "")} ${get(
      data,
      "Zip",
      ""
    )}`,
    policyNumber: `${convertDashToSpace(get(data, "PolicyNumber", ""))}`,
    effectiveDate: `${doFormatDateMMDDYYYY(
      get(data, "EffectiveDt", "")
    )} to ${doFormatDateMMDDYYYY(get(data, "ExpirationDt", ""))}`,
    agencyName: `${getAgencyName(get(data, "AgencyName", ""))}`,
    agentCode: `${formatAgencyCode(get(data, "AgentCode", ""))}`,
    locationAddress: `${get(data, "LocStreetNumber", "")} ${get(
      data,
      "LocStreetName",
      ""
    )}`,
    locationCityStateZip: `${get(data, "LocCity", "")}, ${get(
      data,
      "LocState",
      ""
    )} ${get(data, "LocZip", "")}`,
    texasPolicyFrom: `${get(data, "BuildingCoverage", "")} ${get(
      data,
      "flex[0]",
      ""
    )} ${get(data, "flex[1]", "")}`,
    FLLimit1,
    FLLimit2,
    PropSchedTotal: formatCurrencyFloat(get(data, "PropSchedTotal", "0.00")),
    PropSchedPrem: get(data, "PropSchedPrem", "0.00"),
    Mortgage1Name: `${get(data, "Mort1Name", "")} ${get(data, "AGA_Addr", "")}`,
    Mortgage2Name: `${get(data, "Mort2Name", "")} ${get(
      data,
      "AGA_Addr1",
      ""
    )}`,
    Mortgage1CityStateZip: `${get(data, "Mort1City", "")} ${get(
      data,
      "Mort1State",
      ""
    )} ${get(data, "Mort1Zip", "")}`,
    Mortgage2CityStateZip: `${get(data, "Mort2City", "")} ${get(
      data,
      "Mort2State",
      ""
    )} ${get(data, "Mort2Zip", "")}`
  };
};
