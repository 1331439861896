import { format } from "date-fns";
import { getDateInDifferentTimeZone, getTimeZone } from ".";

import { LEGACY_PHP_API_DATE_FORMAT } from "./constants";
export function getPHPAPIFormattedDate(date: any) {
  return date && date instanceof Date
    ? format(date, LEGACY_PHP_API_DATE_FORMAT)
    : "";
}

export function formatDate(d: any, includeTime: boolean = false): string {
  const formatString = "MM/dd/yyyy";

  return format(
    getDateInDifferentTimeZone(new Date(d), getTimeZone()),
    includeTime ? `${formatString} kk:mm` : formatString
  );
}
