import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  TransactionsState,
  TransactionsGetters,
  TransactionsMutations,
  TransactionsActions
} from "./types";

const namespaced = true;
export const namespace = "transactions";

export const {
  mapState: transactionsMapState,
  mapGetters: transactionsMapGetters,
  mapMutations: transactionsMapMutations,
  mapActions: transactionsMapActions
} = createNamespacedHelpers<
  TransactionsState,
  TransactionsGetters,
  TransactionsMutations,
  TransactionsActions
>(namespace);

export const transactions: Module<TransactionsState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
