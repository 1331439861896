import Vue from "vue";
import { capitalize } from "lodash";
import { format } from "date-fns";

Vue.filter("capitalize", (val: string) => capitalize(val));
Vue.filter("formatDateTime", (val: string) =>
  format(new Date(val), "M/d/yyyy h:mm:ss a")
);
Vue.filter("formatDate", (val: string) => format(new Date(val), "M/d/yyyy"));
Vue.filter("formatTime", (val: string) => format(new Date(val), "h:mm:ss a"));
export function formatCurrency(num: number): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(num);
}
Vue.filter("formatCurrency", formatCurrency);
export function formatCurrencyCompact(num: number): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: "compact",
    compactDisplay: "short"
  } as any).format(num);
}
Vue.filter("formatCurrencyCompact", formatCurrencyCompact);
export function formatCurrencyCompactLong(num: number): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    compactDisplay: "long",
    decimalPlaces: 2
  } as any).format(num);
}
Vue.filter("formatCurrencyCompactLong", formatCurrencyCompactLong);

export function formatCurrencyFloat(amount: any) {
  const decimalPlaces = 2;
  const decimalSeparator = ".";
  const thousandSeparator = ",";
  const sign = amount < 0 ? "-$" : "$";
  const i = String(
    parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalPlaces)))
  );
  let j = 0;
  if (i.length >= 3) {
    if (i.length % 3 === 0) {
      j = i.length - 3;
    } else {
      j = i.length - (i.length % 3);
    }
    j = j * -1;
  }
  return (
    sign +
    (j ? i.slice(0, j) + thousandSeparator : "") +
    i.slice(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandSeparator) +
    (decimalPlaces
      ? decimalSeparator +
        Math.abs(amount - parseInt(i))
          .toFixed(decimalPlaces)
          .slice(2)
      : "")
  );
}

Vue.filter("formatCurrencyFloat", formatCurrencyFloat);
