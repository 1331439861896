import { Route } from "vue-router";
import store from "@/store";

export const redirectMfaVerify = async function(
  this: any,
  toRoute: Route,
  fromRoute: Route,
  next: any
): Promise<void> {
  // If not login path, continue
  if (!toRoute.path.includes("/mfa/verify")) {
    return next();
  }

  const hasToken = localStorage.getItem("auth_mfa_verify");
  if (!hasToken) {
    return next("/login");
  }

  try {
    await store.dispatch("auth/authCheckMfaVerify");
    return next();
  } catch (error) {
    return next("/login");
  }
};
