import Vue from "vue";
import Router, { RawLocation, Route } from "vue-router";
import routes from "./routes";
import {
  getCurrentUser,
  canAccessRoute,
  setPageMeta,
  routerBeforeResolve,
  maybeRedirectOnboarding
} from "./middleware";
import store from "@/store";

if (!process || process.env.NODE_ENV !== "test") {
  Vue.use(Router);
}

const router = new Router({
  mode: "history",
  routes: [...routes]
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(
      this,
      location,
      () => {
        resolve(this.currentRoute);
      },
      error => {
        if (Router.isNavigationFailure(error)) {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      }
    );
  });
};
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(
  location: RawLocation
): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalReplace.call(
      this,
      location,
      () => {
        resolve(this.currentRoute);
      },
      error => {
        if (Router.isNavigationFailure(error)) {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      }
    );
  });
};

// This has to run first
router.beforeEach(getCurrentUser);
router.beforeEach(canAccessRoute);
router.beforeEach(setPageMeta);
router.beforeResolve(routerBeforeResolve);
router.beforeResolve(maybeRedirectOnboarding);

router.beforeEach((to: any, from: any, next: any) => {
  store.commit("app/SET_STATE", { key: "routeRendered", value: false });
  next();
});

router.afterEach(() => {
  store.commit("app/SET_STATE", { key: "routeRendered", value: true });
});

export default router;
