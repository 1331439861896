import { DefineGetters } from "vuex-type-helper";
import { PolicySignGetters, PolicySignState } from "./types";

export const getters: DefineGetters<PolicySignGetters, PolicySignState> = {
  signedPolicies: (state: PolicySignState) => state.signedPolicies,
  isLoading: (state: PolicySignState) => state.makingApiRequest,
  getSignedPolicyLinks: (state: PolicySignState) => state.links,
  getTotalRecords: ({ links, signedPolicies }: PolicySignState) =>
    links && links.totalRecords ? links.totalRecords : signedPolicies.length
};
