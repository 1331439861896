import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class UnderWritingQuestionService extends BaseCollectionService {
  constructor() {
    super("underwriting-questions");
  }
  public async deleteBulk(
    underwritingQuestionIds: string[]
  ): Promise<ResponseWrapper> {
    const path = `underwriting-questions/bulk-delete`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { underwritingQuestionIds }
      },
      { useToken: "access" }
    );
  }
}

export default new UnderWritingQuestionService();
