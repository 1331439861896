import BaseCollectionService from "./base.collection.service";

class QuoteCountyValidationService extends BaseCollectionService {
  constructor() {
    super("quoteCountyValidations");
  }
  async getQuoteCountyValidation(id: string) {
    const path = `quoteCountyValidation`;
    return await this.request(
      {
        method: "get",
        url: `${path}/${id}`
      },
      { useToken: "access" }
    );
  }
  async deleteQuoteCountyValidation(id: string) {
    const path = `quoteCountyValidation`;
    return await this.request(
      {
        method: "delete",
        url: `${path}/${id}`
      },
      { useToken: "access" }
    );
  }
  async updateQuoteCountyValidation(id: string, updates: Record<string, any>) {
    const path = `quoteCountyValidation`;
    return await this.request(
      {
        method: "put",
        url: `${path}/${id}`,
        data: updates
      },
      { useToken: "access" }
    );
  }
}

export default new QuoteCountyValidationService();
