import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  CompaniesState,
  CompaniesMutations,
  StateKeys,
  ICompany
} from "./types";
import {
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericClearStore
} from "@/store/helpers";

import { getCompanyDefaults } from "@/helpers/companies";
import { defaultState } from "./state";

export const mutations: DefineMutations<CompaniesMutations, CompaniesState> = {
  [types.SET_STATE]: GenericSetState<CompaniesState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<CompaniesState>(),
  [types.SET_ITEM]: GenericSetItem<CompaniesState, ICompany>(
    "companies",
    getCompanyDefaults
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<CompaniesState, keyof ICompany>(),
  [types.DELETE_ITEM]: GenericDeleteItem("companies", "_id"),
  [types.SET_EDIT]: GenericSetEdit<CompaniesState, string | Partial<ICompany>>(
    "companies",
    getCompanyDefaults
  ),
  [types.SET_COMPANY_FORM_TYPES](state: CompaniesState, payload: any) {
    if (state.editing && !state.editing.formTypes) {
      state.editing["formTypes"] = [
        {
          lineOfBusiness: payload.lineOfBusiness,
          formTypes: payload.value
        }
      ];
    } else {
      const v: any = state.editing?.formTypes.find((formType: any) => {
        return formType.lineOfBusiness === payload.lineOfBusiness;
      });
      if (v) {
        v.formTypes = payload.value;
      } else {
        state.editing?.formTypes.push({
          lineOfBusiness: payload.lineOfBusiness,
          formTypes: payload.value
        });
      }
    }
  },
  [types.CLEAR_COMPANIES_STORE]: GenericClearStore<CompaniesState>(
    defaultState()
  )
};
