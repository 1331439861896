import { DefineGetters } from "vuex-type-helper";
import {
  IPersonalLiabilityPolicy,
  PersonalLiabilityPolicyGetters,
  PersonalLiabilityPolicyState
} from "./types";

export const getters: DefineGetters<
  PersonalLiabilityPolicyGetters,
  PersonalLiabilityPolicyState
> = {
  getPersonalLiabilityPolicyById: (state: PersonalLiabilityPolicyState) => (
    id: string
  ) =>
    state.personalLiabilityPolicies.find(
      (application: IPersonalLiabilityPolicy) => application._id === id
    ),
  getTotalRecords: ({
    links,
    personalLiabilityPolicies
  }: PersonalLiabilityPolicyState) =>
    links && links.totalRecords
      ? links.totalRecords
      : personalLiabilityPolicies.length
};
