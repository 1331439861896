import {
  FieldTypes,
  FormBlockField,
  FormBlockFieldProperties
} from "../../types";

import { useField } from "./useField";
// just a specific version that sets `type` to `text`
export const useTextField = (
  field: Omit<FormBlockFieldProperties, "type">,
  classList: string[] = []
): FormBlockField => {
  const type: FieldTypes = "text";
  return useField(Object.assign({}, field, { type }), classList);
};
