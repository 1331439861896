import {
  IUnderWritingQuestion,
  UnderWritingQuestionActions,
  UnderWritingQuestionState,
  UnderWritingQuestionMutations,
  UnderWritingQuestionGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import UnderWritingQuestionService from "@/services/underWritingQuestion.service";

export const actions: DefineActions<
  UnderWritingQuestionActions,
  UnderWritingQuestionState,
  UnderWritingQuestionMutations,
  UnderWritingQuestionGetters
> = {
  createUnderWritingQuestion: GenericCreateOne<
    UnderWritingQuestionState,
    UnderWritingQuestionGetters,
    UnderWritingQuestionActions,
    UnderWritingQuestionMutations,
    IUnderWritingQuestion
  >(UnderWritingQuestionService, types.SET_ITEM as any),
  getUnderWritingQuestions: GenericGetMany<
    UnderWritingQuestionState,
    UnderWritingQuestionGetters,
    UnderWritingQuestionActions,
    UnderWritingQuestionMutations
  >(
    UnderWritingQuestionService,
    "underWritingQuestions",
    types.SET_ITEM as any
  ),
  getUnderWritingQuestion: GenericGetOne<
    UnderWritingQuestionState,
    UnderWritingQuestionGetters,
    UnderWritingQuestionActions,
    UnderWritingQuestionMutations
  >(UnderWritingQuestionService, types.SET_ITEM as any),
  deleteUnderWritingQuestion: GenericDeleteOne<
    UnderWritingQuestionState,
    UnderWritingQuestionGetters,
    UnderWritingQuestionActions,
    UnderWritingQuestionMutations
  >(UnderWritingQuestionService, types.DELETE_ITEM as any),
  updateUnderWritingQuestion: GenericUpdateOne<
    UnderWritingQuestionState,
    UnderWritingQuestionGetters,
    UnderWritingQuestionActions,
    UnderWritingQuestionMutations,
    { id: string; update: Partial<IUnderWritingQuestion> }
  >(UnderWritingQuestionService, types.SET_ITEM as any),
  deleteBulkUnderWritingQuestions: async ({ commit }, ids) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await UnderWritingQuestionService.deleteBulk(ids);
      return response.data;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
};
