import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  CommunicationState,
  CommunicationGetters,
  CommunicationMutations,
  CommunicationActions
} from "./types";

const namespaced = true;
export const namespace = "communication";

export const {
  mapState: communicationMapState,
  mapGetters: communicationMapGetters,
  mapMutations: communicationMapMutations,
  mapActions: communicationMapActions
} = createNamespacedHelpers<
  CommunicationState,
  CommunicationGetters,
  CommunicationMutations,
  CommunicationActions
>(namespace);

export const communication: Module<CommunicationState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
