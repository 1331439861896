import { DefineGetters } from "vuex-type-helper";
import {
  IQuoteCountyValidation,
  QuoteCountyValidationGetters,
  QuoteCountyValidationState
} from "./types";

export const getters: DefineGetters<
  QuoteCountyValidationGetters,
  QuoteCountyValidationState
> = {
  getQuoteCountyValidationById: (state: QuoteCountyValidationState) => (
    quoteCountyValidationId: string
  ) =>
    state.quoteCountyValidations.find(
      (quoteCountyValidation: IQuoteCountyValidation) =>
        quoteCountyValidation._id === quoteCountyValidationId
    ),
  editingQuoteCountyValidation: (state: QuoteCountyValidationState) =>
    state.editing,
  quoteCountyValidations: (state: QuoteCountyValidationState) =>
    state.quoteCountyValidations,
  isLoading: (state: QuoteCountyValidationState) => state.makingApiRequest,
  getQuoteCountyValidationsLinks: (state: QuoteCountyValidationState) =>
    state.links,
  getTotalRecords: ({
    links,
    quoteCountyValidations
  }: QuoteCountyValidationState) =>
    links && links.totalRecords
      ? links.totalRecords
      : quoteCountyValidations.length
};
