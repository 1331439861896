import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class NotificationService extends BaseCollectionService {
  constructor() {
    super("notifications");
  }

  public async acknowledgeNotification(
    payload: Record<string, any>
  ): Promise<ResponseWrapper> {
    const path = `${this.url}/${payload.id}/acknowledge`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { name: payload.name }
      },
      { useToken: "access" }
    );
  }
}

export default new NotificationService();
