import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  EndorsementState,
  EndorsementGetters,
  EndorsementMutations,
  EndorsementActions
} from "./types";

const namespaced = true;
export const namespace = "endorsements";

export const {
  mapState: endorsementMapState,
  mapGetters: endorsementMapGetters,
  mapMutations: endorsementMapMutations,
  mapActions: endorsementMapActions
} = createNamespacedHelpers<
  EndorsementState,
  EndorsementGetters,
  EndorsementMutations,
  EndorsementActions
>(namespace);

export const endorsements: Module<EndorsementState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
