//basically conditions on steroids
import { FormBlockField, FormBlockFieldCondition } from "../../types";
import { getFieldValueByKey } from "./getFieldValueByKey";

// if you find your conditions growing large and out of hand, theres probably a simpler one
// using de morgans law
// p  q    p && q    p || q    !(p && q)    !p || !q    !(p || q)   !p && !q
// ==========================================================================
// 0  0    0         0         1            1           1           1
// 0  1    0         1         1            1           0           0
// 1  0    0         1         1            1           0           0
// 1  1    1         1         0            0           0           0
// https://softwareengineering.stackexchange.com/questions/306881/how-to-properly-reverse-the-if-statement-when-you-have-two-conditions-in-it

const __testCondition = (
  condition: FormBlockFieldCondition,
  existingValue: string | number | Date | boolean
): boolean => {
  const { operator, value: compareToValue } = condition;
  switch (operator) {
    case "equals":
      if (
        typeof existingValue === "string" &&
        typeof compareToValue === "string"
      ) {
        return existingValue.toLowerCase() === compareToValue.toLowerCase();
      }
      return existingValue === compareToValue;
    case "gt":
      if (
        typeof existingValue === "number" &&
        typeof compareToValue == "number"
      ) {
        return compareToValue > existingValue;
      }
      break;
    case "lt":
      if (
        typeof existingValue === "number" &&
        typeof compareToValue == "number"
      ) {
        return compareToValue < existingValue;
      }
      break;
    case "not":
      if (
        typeof existingValue === "string" &&
        typeof compareToValue == "string"
      ) {
        return existingValue.toLowerCase() !== compareToValue.toLowerCase();
      }
      return existingValue !== compareToValue;
      break;
    case "includes":
      if (
        typeof existingValue === "string" &&
        typeof compareToValue == "string"
      ) {
        return existingValue
          .toLowerCase()
          .includes(compareToValue.toLowerCase());
      }
      if (Array.isArray(existingValue)) {
        return existingValue.includes(compareToValue);
      }
      break;
    case "exists":
      return !!existingValue === compareToValue;
    case "hasValue":
      if (typeof existingValue === "undefined") return false;
      if (typeof existingValue === "string") {
        if (existingValue.trim() === "") return false;
      }
      if (Array.isArray(existingValue)) {
        return existingValue.length > 0 === compareToValue;
      }
      return true;
  }
  return false;
};

const __evaluateConditions = (
  condition: FormBlockFieldCondition,
  formValues: Record<string, any>
) => {
  const { field } = condition;
  const value = getFieldValueByKey(field, formValues);
  return __testCondition(condition, value);
};

export const shouldShowFieldV2 = (
  fieldSchema: FormBlockField,
  formValues: Record<string, any>
): any => {
  const { conditions = {} } = fieldSchema.properties as any;
  return conditionsEvaluateToTrueV2(conditions, formValues);
};

export const conditionsEvaluateToTrueV2 = (
  conditions: { or: any[]; and: any[] },
  values: Record<string, any>
) => {
  const { or, and } = conditions;

  // no conditions
  if (!or && !and) {
    return true;
  }

  if (or && and) {
    return triggerAndEvaluation(and, values) && triggerOrEvaluation(or, values);
  }

  if (and) {
    return triggerAndEvaluation(and, values);
  }

  if (or) {
    return triggerOrEvaluation(or, values);
  }

  return true;
};

function triggerAndEvaluation(conditions: any, values: any): any {
  return conditions.every((condition: any) => {
    if (condition.or || condition.and) {
      return conditionsEvaluateToTrueV2(condition, values);
    } else {
      return __evaluateConditions(condition, values);
    }
  });
}

function triggerOrEvaluation(conditions: any, values: any): any {
  return conditions.some((condition: any) => {
    if (condition.or || condition.and) {
      return conditionsEvaluateToTrueV2(condition, values);
    } else {
      return __evaluateConditions(condition, values);
    }
  });
}
