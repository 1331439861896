import { DefineMutations } from "vuex-type-helper";
import { AppState, AppMutations, IAppWindowSize, SetAppState } from "./types";
import { getAppThemeAndSettingsDefaults } from "@/helpers/appCoreBaseSettings";
import * as types from "@/store/mutation-types";
import { GenericClearStore } from "@/store/helpers";
import { defaultState } from "./state";

import has from "lodash/has";

export const mutations: DefineMutations<AppMutations, AppState> = {
  SET_STATE(state: AppState, { key, value }: SetAppState): void {
    if (has(state, key)) {
      //@ts-ignore
      state[key] = value;
    }
  },
  SET_WINDOW(state: AppState, windowSize: IAppWindowSize): void {
    state.window = windowSize;
  },
  SET_WINDOW_VISIBILITY(state: AppState, isVisible: boolean): void {
    state.windowVisible = isVisible;
  },
  SET_PAGE_TITLE(state: AppState, title: string): void {
    state.pageTitle = title;
  },
  TOGGLE_MOBILE_MENU(state: AppState, force?: boolean): void {
    const toVal = typeof force === "boolean" ? force : !state.showMobileMenu;
    state.showMobileMenu = toVal;
  },
  SET_SCROLL_TOP(state: AppState, num: number): void {
    state.scrollTop = num;
  },
  SET_INIT_DATA(state: AppState, data: any): void {
    const { settings, theme } = getAppThemeAndSettingsDefaults(data);
    state.settings = settings;
    state.theme = theme;
  },
  SET_HIDE_NAVIGATION(state: AppState, hide: boolean): void {
    state.hideNavigation = hide;
  },
  [types.CLEAR_APP_STORE]: GenericClearStore<AppState>(defaultState())
};
