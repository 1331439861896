import { getPathAPI } from "@/helpers";
import axios, { AxiosResponse } from "axios";
import { capitalize } from "lodash";
import BaseCollectionService from "./base.collection.service";

class ChartService extends BaseCollectionService {
  constructor() {
    super("chart");
  }
  public async exportChart(payload: any): Promise<AxiosResponse> {
    const path = `/chart/export/${payload.id}`;
    const response = await axios({
      url: `${getPathAPI()}${path}`,
      params: {
        accessToken: localStorage.getItem("access"),
        ...payload
      },
      method: "GET",
      responseType: "blob"
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${capitalize(`${new Date().toUTCString()}`)}-chart.csv`
    );
    document.body.appendChild(link);
    link.click();
    return response;
  }
}

export default new ChartService();
