var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.isSettings)?_c('div',[_c('span',{staticClass:"text-red-400 text-xs"},[_vm._v(_vm._s(_vm.__getTextUcFirst("simple", "unverified")))])]):_c('div',[(!_vm.hasCode && !_vm.codeSent)?_c('span',{staticClass:"cursor-pointer text-red-400 text-xs",on:{"click":_vm.openSendCode}},[_vm._v(_vm._s(_vm.__getText("global", "verifyText")))]):_vm._e(),(_vm.hasCode || _vm.codeSent)?_c('span',{staticClass:"cursor-pointer text-red-400 text-xs",on:{"click":_vm.enterCode}},[_vm._v(_vm._s(_vm.__getText("global", "enterCodeText")))]):_vm._e()]),_c('ModalBase',{attrs:{"name":"sendVerificationCodeModal","size":"medium","clickToClose":false,"showClose":true,"title":"Send Verification Code","loading":_vm.makingApiRequest,"position":"center","bottomButtonPrimary":{
      key: 'save',
      disabled: _vm.disabled,
      label: _vm.__getTextUcFirst('simple', 'confirm')
    },"bottomButtonSecondary":{
      key: 'cancel',
      label: 'Cancel'
    },"error":_vm.error},on:{"primaryButtonClick":function($event){return _vm.sendVerificationCode(_vm.phone, _vm.phoneType)}}},[_c('div',{staticClass:"pb-8"},[_vm._v(" "+_vm._s(_vm.__getText("global", "sendverificationCodeText"))+" "),_c('p',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.phone))])])]),_c('ModalBase',{attrs:{"name":"verifyPhoneNumberModal","size":"medium","clickToClose":false,"showClose":true,"title":"Verify Phone Number","loading":_vm.makingApiRequest,"position":"center","bottomButtonPrimary":{
      key: 'save',
      label: _vm.__getTextUcFirst('simple', 'verify')
    },"bottomButtonSecondary":{
      key: 'cancel',
      label: 'Cancel'
    },"error":_vm.error},on:{"primaryButtonClick":_vm.verifyCode}},[_c('AuthCodeInput',{attrs:{"loading":false},on:{"input":function($event){_vm.verificationCode = $event},"hasFullCode":_vm.verifyCode}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }