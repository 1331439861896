var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative form-group",class:{
    [_vm.fieldGroupClass]: true
  }},[_c('div',{on:{"click":_vm.editDialog}},[_c('TextField',{ref:"field_phone",attrs:{"type":"phone","label":_vm.label,"placeholder":_vm.label,"required":_vm.required,"disabled":_vm.disableField,"fieldGroupClass":"inner-form-group"},on:{"input":_vm.onFieldChange},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),(!_vm.editingField)?_c('div',{staticClass:"flex absolute left-0"},[(!_vm.verified && _vm.value && _vm.value[_vm.phoneType] && !_vm.isNew)?_c('div',{staticClass:"ml-auto"},[_c('VerifyPhone',{attrs:{"phone":_vm.value[_vm.phoneType],"phoneType":_vm.phoneType,"isSettings":_vm.isSettings},on:{"setVerified":_vm.setVerify,"editPhone":_vm.setEditPhone}})],1):_vm._e()]):_vm._e(),_c('ModalMessage',{attrs:{"name":_vm.modalName,"icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Editing Phone Number","description":"The phone number has already been verified. You would have to reverify\n      if you edit the number. Continue?","showClose":true,"buttons":[
      {
        text: `Confirm`,
        classList: [''],
        click: () => _vm.enableField()
      },
      {
        text: `Cancel`,
        classList: [''],
        click: () => _vm.$modal.hide(this.modalName)
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }