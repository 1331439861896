import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  NotificationState,
  NotificationGetters,
  NotificationMutations,
  NotificationActions
} from "./types";

const namespaced = true;
export const namespace = "notification";

export const {
  mapState: notificationMapState,
  mapGetters: notificationMapGetters,
  mapMutations: notificationMapMutations,
  mapActions: notificationMapActions
} = createNamespacedHelpers<
  NotificationState,
  NotificationGetters,
  NotificationMutations,
  NotificationActions
>(namespace);

export const notification: Module<NotificationState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
