import { AgencyState } from "./types";

export const defaultState = (): AgencyState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    agencies: [],
    links: null
  };
};

export const state = defaultState();
