import {
  EndorsementActions,
  EndorsementState,
  EndorsementMutations,
  EndorsementGetters,
  IEndorsement
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import EndorsementsService from "@/services/endorsements.service";
import {
  GenericGetMany,
  GenericCreateOne,
  GenericMakeRequest,
  GenericDeleteOne
} from "../../helpers/actions";

export const actions: DefineActions<
  EndorsementActions,
  EndorsementState,
  EndorsementMutations,
  EndorsementGetters
> = {
  async getEndorsements(
    { commit }: any,
    query: { appliesTo: string; companyNumber: number }
  ) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const { data } = await EndorsementsService.getEndorsements(query);
      commit(types.SET_STATE, { key: "lastUpdate", value: new Date() });
      return data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  getAllEndorsements: GenericGetMany<
    EndorsementState,
    EndorsementGetters,
    EndorsementActions,
    EndorsementMutations
  >(EndorsementsService, "allEndorsements", types.SET_ITEM as any),
  createEndorsement: GenericCreateOne<
    EndorsementState,
    EndorsementGetters,
    EndorsementActions,
    EndorsementMutations,
    IEndorsement
  >(EndorsementsService, types.SET_ITEM as any),
  updateEndorsement: async ({ commit }, payload) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await EndorsementsService.updateEndorsement(payload);
      return response.data;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  getEndorsement: GenericMakeRequest<
    EndorsementState,
    EndorsementGetters,
    EndorsementActions,
    EndorsementMutations
  >(EndorsementsService.getOne, types.SET_ITEM as any),
  deleteEndorsement: GenericDeleteOne<
    EndorsementState,
    EndorsementGetters,
    EndorsementActions,
    EndorsementMutations
  >(EndorsementsService, types.DELETE_ITEM as any),
  deleteBulkEndorsements: async ({ commit }, ids) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await EndorsementsService.deleteBulk(ids);
      return response.data;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
};
