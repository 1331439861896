
import { christmasify, logoPath } from "@/helpers/generalHelpers";

export default {
  methods: {
    doChristmasify(): boolean {
      return christmasify();
    },
    getLogoPath() {
      return logoPath();
    }
  }
};
