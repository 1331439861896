import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  AtlasfileState,
  AtlasfileGetters,
  AtlasfileMutations,
  AtlasfileActions
} from "./types";

const namespaced = true;
export const namespace = "atlasfile";

export const {
  mapState: atlasfileMapState,
  mapGetters: atlasfileMapGetters,
  mapMutations: atlasfileMapMutations,
  mapActions: atlasfileMapActions
} = createNamespacedHelpers<
  AtlasfileState,
  AtlasfileGetters,
  AtlasfileMutations,
  AtlasfileActions
>(namespace);

export const atlasfile: Module<AtlasfileState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
