import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { GenericDeleteItem } from "../../helpers/mutations";
import {
  GenericSetState,
  GenericSetStates,
  GenericSetEditField,
  GenericSetItem,
  GenericSetEdit,
  GenericClearStore
} from "@/store/helpers";
import {
  EndorsementState,
  EndorsementMutations,
  StateKeys,
  IEndorsement
} from "./types";
import { defaultState } from "./state";

export const mutations: DefineMutations<
  EndorsementMutations,
  EndorsementState
> = {
  [types.SET_STATE]: GenericSetState<EndorsementState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<EndorsementState>(),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    EndorsementState,
    keyof IEndorsement
  >(),
  [types.SET_ENDORSEMENT_VALUE](state: EndorsementState, payload: any) {
    const matchedEndorsement = state.endorsements.find((e: any) => {
      return e.formNumber.toString() === payload.formNumber;
    });
    if (matchedEndorsement) {
      const matchedValue = matchedEndorsement.data.find((data: any) => {
        return data.ref.toString() === payload.valueRef;
      });
      if (matchedValue) {
        matchedValue.value = payload.actualValue;
      }
    }
  },
  [types.SET_ENDORSEMENT_WATERCRAFT_OPTIONS](state: EndorsementState) {
    const matchedEndorsement = state.endorsements.find((e: any) => {
      return e.formNumber.toString() === "HO-215";
    });
    if (matchedEndorsement) {
      const matchedValue = matchedEndorsement.data.find((data: any) => {
        return data.ref.toString() === "waterCraftNumber";
      });
      if (matchedValue) {
        matchedValue.options = state.waterCraftOptions;
      }
    }
  },
  [types.SET_ITEM]: GenericSetItem<EndorsementState, IEndorsement>(
    "allEndorsements",
    (data: any) => ({ ...data })
  ),
  [types.SET_EDIT]: GenericSetEdit<EndorsementState, string | IEndorsement>(
    "allEndorsements",
    (data: any) => ({ ...data })
  ),
  [types.DELETE_ITEM]: GenericDeleteItem<EndorsementState>(
    "allEndorsements",
    "_id"
  ),
  [types.CLEAR_ENDORSEMENT_STORE]: GenericClearStore<EndorsementState>(
    defaultState()
  )
};
