
import Vue from "vue";

import RadioInnerButton from "@/components/FormFields/RadioInnerButton.vue";

export default Vue.extend({
  components: {
    RadioInnerButton
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    displayDirection: {
      type: String,
      default: "vertical"
    },
    showRadio: {
      type: Boolean,
      required: false,
      default: true
    },
    showAsCheckbox: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showPermission: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      isMouseOver: false,
      isMouseFocus: false
    };
  },
  methods: {
    handleUpdate() {
      const { value } = this.$props;
      if (!this.disabled) {
        this.$emit("input", value);
        this.$emit("change", value);
      }
    }
  },
  computed: {
    outerClass(): any {
      const {
        isMouseOver: hover,
        isMouseFocus: focus,
        active,
        displayDirection
      } = this;

      const baseClasses = [
        "relative",
        "border",
        "border-gray-400",
        "rounded-md",
        "flex"
      ];

      const hoverClass = ["border-atlas-blue"];
      const focusClass = ["border-gray-300", "shadow-sm"];
      const selectedClass = [
        "bg-atlas-blue",
        "border-atlas-blue",
        "text-white",
        "scale-105"
      ];

      const isStyleVertical: string[] = ["py-4", "px-6"];
      const isStyleHorizontal: string[] = ["py-2", "px-4", "items-center"];

      return Object.assign(
        {},
        Object.fromEntries(baseClasses.map((i: string) => [i, true])),
        Object.fromEntries(hoverClass.map((i: string) => [i, hover])),
        Object.fromEntries(selectedClass.map((i: string) => [i, active])),
        Object.fromEntries(focusClass.map((i: string) => [i, focus])),
        Object.fromEntries(
          isStyleVertical.map((i: string) => [
            i,
            displayDirection === "vertical"
          ])
        ),
        Object.fromEntries(
          isStyleHorizontal.map((i: string) => [
            i,
            displayDirection === "horizontal"
          ])
        )
      );
    },
    innerClass(): any {
      const {
        isMouseOver: hover,
        isMouseFocus: focus,
        active,
        displayDirection
      } = this;

      const baseClasses = ["w-full", "flex"];

      const hoverClass: string[] = [];
      const focusClass: string[] = [];
      const selectedClass: string[] = [];

      const isStyleVertical = ["flex-col items-center", "justify-center"];
      const isStyleHorizontal = ["flex-row", "items-center", "justify-start"];

      return Object.assign(
        {},
        Object.fromEntries(baseClasses.map((i: string) => [i, true])),
        Object.fromEntries(hoverClass.map((i: string) => [i, hover])),
        Object.fromEntries(selectedClass.map((i: string) => [i, active])),
        Object.fromEntries(focusClass.map((i: string) => [i, focus])),
        Object.fromEntries(
          isStyleVertical.map((i: string) => [
            i,
            displayDirection === "vertical"
          ])
        ),
        Object.fromEntries(
          isStyleHorizontal.map((i: string) => [
            i,
            displayDirection === "horizontal"
          ])
        )
      );
    },
    labelClass(): any {
      const {
        isMouseOver: hover,
        isMouseFocus: focus,
        active,
        displayDirection
      } = this;

      const baseClasses: string[] = [
        "select-none",
        "whitespace-nowrap",
        "font-medium"
      ];

      const hoverClass: string[] = [];
      const focusClass: string[] = [];
      const selectedClass: string[] = [];

      const isStyleVertical: string[] = ["mt-3"];
      const isStyleHorizontal: string[] = ["ml-3"];

      return Object.assign(
        {},
        Object.fromEntries(baseClasses.map((i: string) => [i, true])),
        Object.fromEntries(hoverClass.map((i: string) => [i, hover])),
        Object.fromEntries(selectedClass.map((i: string) => [i, active])),
        Object.fromEntries(focusClass.map((i: string) => [i, focus])),
        Object.fromEntries(
          isStyleVertical.map((i: string) => [
            i,
            displayDirection === "vertical"
          ])
        ),
        Object.fromEntries(
          isStyleHorizontal.map((i: string) => [
            i,
            displayDirection === "horizontal"
          ])
        )
      );
    }
  }
});
