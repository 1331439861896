import { baseAppSettings } from "./defaultObjects";
import { IAppSettings } from "@/types";
import defaultsDeep from "lodash/defaultsDeep";
import cloneDeep from "lodash/cloneDeep";

export function getAppSettingsDefaults(
  appSettings: Partial<IAppSettings>
): IAppSettings {
  return defaultsDeep(cloneDeep(appSettings), baseAppSettings());
}
