import { DefineGetters } from "vuex-type-helper";
import { EndorsementGetters, EndorsementState, IEndorsement } from "./types";

export const getters: DefineGetters<EndorsementGetters, EndorsementState> = {
  endorsements: (state: EndorsementState) => state.endorsements,
  waterCraftOptions: (state: EndorsementState) => state.waterCraftOptions,
  allEndorsements: (state: EndorsementState) => state.allEndorsements,
  getEndorsementById: (state: EndorsementState) => (endorsementId: string) =>
    state.allEndorsements.find(
      (endorsement: IEndorsement) => endorsement._id === endorsementId
    ),
  editingEndorsement: (state: EndorsementState) => state.editing,
  getEndorsementLinks: (state: EndorsementState) => state.links,
  getTotalRecords: ({ links, allEndorsements }: EndorsementState) =>
    links && links.totalRecords ? links.totalRecords : allEndorsements.length
};
