
import Vue from "vue";
import { authMapActions, authMapGetters } from "@/store/modules/auth";
import { getPlatformNameFromAgencyCode } from "@/helpers/getPlatformNameFromAgencyCode";
import { capitalizeFirstLetter } from "@/helpers/generalHelpers";
export default Vue.extend({
  name: "topMenuUserDropdown",
  methods: {
    ...authMapActions(["logout"]),
    getPlatformNameFromAgencyCode,
    handleCommand(event: any): void {
      const profilePath = this.$isCurrentUserAdmin
        ? "/admin/profile"
        : "/profile";
      const notificationPath = this.$isCurrentUserAdmin
        ? "/admin/notifications"
        : "/notifications";

      switch (event) {
        case "profile":
          this.$router.push(profilePath);
          break;
        case "logout":
          this.$store.dispatch("auth/logout");
          break;
        case "notifications":
          this.$router.push(notificationPath);
          break;
        case "about":
          this.$router.push("/aboutUs");
          break;
        case "faq":
          this.$router.push("/faq");
          break;
        case "agencyCode":
          this.$modal.show("switch-agent-code-modal");
          break;
      }
    }
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    userName(): any {
      const { $getCurrentUser } = this;
      if (!$getCurrentUser) return "";
      const {
        firstName = "",
        lastName = "",
        displayName = ""
      } = $getCurrentUser;

      if (displayName) {
        return capitalizeFirstLetter(displayName);
      } else if (firstName || lastName) {
        return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
          lastName
        )}`;
      } else {
        return "User";
      }
    },
    activeAgentCode() {
      const { $getCurrentUser } = this;
      if (!$getCurrentUser) return "";
      const { agentCode = "" } = this.$getCurrentUser;
      return agentCode ? `- ${agentCode}` : "";
    },
    canEditAgencyCode(): boolean {
      return !!(
        this.getCurrentUser &&
        this.getCurrentUser.agentCode &&
        this.getCurrentUser.allAgencyCodes &&
        this.getCurrentUser.allAgencyCodes.length > 0
      );
    },
    userCode() {
      let { role } = this.$getCurrentUser;
      return role === "adjuster"
        ? this.$getCurrentUser.adjusterCode
        : this.$getCurrentUser.agentCode;
    }
  }
});
