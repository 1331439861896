import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  PolicySignState,
  PolicySignMutations,
  StateKeys,
  IPolicySign
} from "./types";
import {
  GenericSetEdit,
  GenericSetItem,
  GenericSetState,
  GenericSetStates
} from "@/store/helpers";
import { policySignDefault } from "@/helpers/policySignDefault";

export const mutations: DefineMutations<
  PolicySignMutations,
  PolicySignState
> = {
  [types.SET_STATE]: GenericSetState<PolicySignState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<PolicySignState>(),
  [types.SET_EDIT]: GenericSetEdit<
    PolicySignState,
    string | Partial<IPolicySign>
  >("signedPolicies", policySignDefault),
  [types.SET_ITEM]: GenericSetItem<PolicySignState, IPolicySign>(
    "signedPolicies",
    policySignDefault
  )
};
