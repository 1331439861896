export default [
  {
    path: "/form-builder/Examples/advanced",
    component: () => import("@/components/FormBuilder/examples/Example.vue"),
    meta: {
      pageTitle: "Example",
      requireAuth: false,
      metaTags: {
        title: "Example"
      }
    }
  },
  {
    path: "/form-builder/Examples/yesno",
    component: () =>
      import("@/components/FormBuilder/examples/YesNoQuestions.vue"),
    meta: {
      pageTitle: "Yes || No",
      requireAuth: false,
      metaTags: {
        title: "Yes || No"
      }
    }
  },
  {
    path: "/form-builder/Examples/login",
    component: () => import("@/components/FormBuilder/examples/Login.vue"),
    meta: {
      pageTitle: "Login",
      requireAuth: false,
      metaTags: {
        title: "Login"
      }
    }
  },
  {
    path: "/form-builder",
    component: () => import("@/components/FormBuilder/examples/Home.vue"),
    meta: {
      pageTitle: "Form Builder Documentation",
      requireAuth: false,
      metaTags: {
        title: "Form Builder Documentation"
      }
    }
  },
  {
    path: "/form-builder/examples/multi-step",
    component: () =>
      import("@/components/FormBuilder/examples/MultiStepForm.vue"),
    meta: {
      pageTitle: "Test Multi Step Form",
      requireAuth: false,
      metaTags: {
        title: "Test Multi Step Form"
      }
    }
  }
];
