import { FormBlockField, FormBlockFieldProperties } from "../../types";

export const useField = (
  field: FormBlockFieldProperties,
  classList: string[] = []
): FormBlockField => {
  return {
    element: "field",
    id: `field_${field.key}`,
    classList: classList,
    properties: { ...field }
  };
};
