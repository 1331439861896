import { AppState } from "./types";
import { getAppThemeAndSettingsDefaults } from "../../../helpers/appCoreBaseSettings";

export const defaultState = (): AppState => {
  return {
    loadedInitData: false,
    ...getAppThemeAndSettingsDefaults({}),
    scrollTop: 0,
    pageTitle: "",
    showMobileMenu: false,
    hideNavigation: false,
    isResizing: false,
    resizeTimer: null,
    screenSize: "",
    window: {
      width: 0,
      height: 0
    },
    windowVisible: true,
    lastActivity: new Date(),
    showUnreadNotificationPrompt: false,
    routeRendered: false,
    showLoggedOutModal: false
  };
};

export const state = defaultState();
