import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  PersonalLiabilityPolicyState,
  PersonalLiabilityPolicyGetters,
  PersonalLiabilityPolicyMutations,
  PersonalLiabilityPolicyActions
} from "./types";

const namespaced = true;
export const namespace = "personalLiabilityPolicy";

export const {
  mapState: personalLiabilityPolicyMapState,
  mapGetters: personalLiabilityPolicyMapGetters,
  mapMutations: personalLiabilityPolicyMapMutations,
  mapActions: personalLiabilityPolicyMapActions
} = createNamespacedHelpers<
  PersonalLiabilityPolicyState,
  PersonalLiabilityPolicyGetters,
  PersonalLiabilityPolicyMutations,
  PersonalLiabilityPolicyActions
>(namespace);

export const personalLiabilityPolicy: Module<
  PersonalLiabilityPolicyState,
  any
> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
