import { get } from "lodash";

export type LogLevel = "Error" | "Warn" | "Info" | "Debug" | "Log";

export type LogMessage =
  | string
  | {
      [key: string]: string | number | boolean | Date;
    };

function _transformMessage(value: string | number | boolean | Date) {
  if (typeof value === "boolean") {
    return value;
  }
  if (value instanceof Date) {
    return value;
  }
  return value;
}
function _formatMessage(message: LogMessage) {
  if (typeof message === "string") {
    return message;
  }

  const messageOut: any = {};
  for (const key of Object.keys(message)) {
    const value = get(message, key);
    if (typeof value !== "undefined") {
      messageOut[key] = _transformMessage(value);
    }
  }
  return JSON.stringify(messageOut);
}
function _logData(
  this: boolean,
  methodName: string,
  logLevel: LogLevel,
  message: LogMessage
): void {
  if (!this) return;
  switch (logLevel) {
    case "Error":
      console.error(logLevel, methodName, _formatMessage(message));
      break;
    case "Warn":
      console.warn(logLevel, methodName, _formatMessage(message));
      break;
    case "Info":
      console.info(logLevel, methodName, _formatMessage(message));
      break;
    case "Debug":
      console.debug(logLevel, methodName, _formatMessage(message));
      break;
    case "Log":
      console.log(logLevel, methodName, _formatMessage(message));
  }
}

export const atlasConsole = {
  dev: _logData.bind(process.env.NODE_ENV === "development"),
  test: _logData.bind(process.env.NODE_ENV === "test"),
  log: _logData.bind(process.env.NODE_ENV !== "test")
};
