import {
  BillingChangeRequestActions,
  BillingChangeRequestState,
  BillingChangeRequestMutations,
  BillingChangeRequestGetters
} from "./types";
import { IBillingChangeRequest } from "@/helpers/storageFormBaseInterface";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import BillingChangeRequestService from "@/services/billingchangerequest.service";
import { getBillingChangeRequestDefaults } from "@/helpers/getBillingChangeRequestDefaults";

export const actions: DefineActions<
  BillingChangeRequestActions,
  BillingChangeRequestState,
  BillingChangeRequestMutations,
  BillingChangeRequestGetters
> = {
  createBillingChangeRequest: GenericCreateOne<
    BillingChangeRequestState,
    BillingChangeRequestGetters,
    BillingChangeRequestActions,
    BillingChangeRequestMutations,
    IBillingChangeRequest
  >(BillingChangeRequestService, types.SET_ITEM as any),
  getBillingChangeRequests: GenericGetMany<
    BillingChangeRequestState,
    BillingChangeRequestGetters,
    BillingChangeRequestActions,
    BillingChangeRequestMutations
  >(
    BillingChangeRequestService,
    "billingChangeRequests",
    types.SET_ITEM as any
  ),
  getBillingChangeRequest: GenericGetOne<
    BillingChangeRequestState,
    BillingChangeRequestGetters,
    BillingChangeRequestActions,
    BillingChangeRequestMutations
  >(
    BillingChangeRequestService,
    types.SET_ITEM,
    getBillingChangeRequestDefaults
  ),
  deleteBillingChangeRequest: GenericDeleteOne<
    BillingChangeRequestState,
    BillingChangeRequestGetters,
    BillingChangeRequestActions,
    BillingChangeRequestMutations
  >(BillingChangeRequestService, types.DELETE_ITEM as any),
  updateBillingChangeRequest: GenericUpdateOne<
    BillingChangeRequestState,
    BillingChangeRequestGetters,
    BillingChangeRequestActions,
    BillingChangeRequestMutations,
    { id: string; update: Partial<IBillingChangeRequest> }
  >(BillingChangeRequestService, types.SET_ITEM as any),
  printBillingChangeRequest: async ({ commit }, payload) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await BillingChangeRequestService.printBillingChangeRequestById(
        payload
      );
      const responseData = response.data;
      if (response && response.data && response.data.changeRequests) {
        commit(types.SET_ITEM, response.data.changeRequests);
      }
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },

  async updateBillingChangeRequestsStatus({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await BillingChangeRequestService.updateRequestsStatus(
        payload
      );
      const responseData = response.data;
      commit(types.SET_ITEM, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },

  async getBillingInfo({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await BillingChangeRequestService.getBillingInfo(
        payload
      );
      const responseData = response.data.data;
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },

  async printBillingInfo({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await BillingChangeRequestService.printBillingInfo(
        payload
      );
      return response.data;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  deleteBulkChangeRequests: async ({ commit }, changeRequestsIds) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await BillingChangeRequestService.deleteChangeRequestsByIds(
        changeRequestsIds,
        "cancellation"
      );
      return response.data;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  async restoreBillingChangeRequests({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await BillingChangeRequestService.restoreBillingChangeRequests(
        payload
      );
      return response;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
};
