import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  PayPlanChangeRequestState,
  PayPlanChangeRequestGetters,
  PayPlanChangeRequestMutations,
  PayPlanChangeRequestActions
} from "./types";

const namespaced = true;
export const namespace = "payplanchangerequest";

export const {
  mapState: payPlanChangeRequestMapState,
  mapGetters: payPlanChangeRequestMapGetters,
  mapMutations: payPlanChangeRequestMapMutations,
  mapActions: payPlanChangeRequestMapActions
} = createNamespacedHelpers<
  PayPlanChangeRequestState,
  PayPlanChangeRequestGetters,
  PayPlanChangeRequestMutations,
  PayPlanChangeRequestActions
>(namespace);

export const payplanchangerequest: Module<PayPlanChangeRequestState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
