import { QuickPayState } from "./types";

export const defaultState = (): QuickPayState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    quickPay: [],
    links: null,
    policyInfo: {
      companyNumber: "",
      company: "",
      name: "",
      policy: "",
      currentAmtDue: "",
      minAmtDue: "",
      lastPayment: "",
      totalAmountDue: "",
      policyStatus: "",
      zip: { City: "", State: "", Zip: "" } as any,
      printAddress1: "",
      policyPrefix: "",
      policyNumber: "",
      currentDueDate: new Date(),
      computedCancellationDt: new Date(),
      paymentMethod: ""
    }
  };
};

export const state = defaultState();
