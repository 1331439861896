import {
  IQuote,
  QuoteActions,
  QuoteState,
  QuoteMutations,
  QuoteGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericDeleteOne,
  GenericUpdateOne,
  GenericGetOne,
  GenericMakeRequest
} from "@/store/helpers/actions";
import QuoteService from "@/services/quote.service";
import { getQuoteDefaults } from "@/helpers/defaultObjectGenerators";

export const actions: DefineActions<
  QuoteActions,
  QuoteState,
  QuoteMutations,
  QuoteGetters
> = {
  createQuote: GenericCreateOne<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations,
    IQuote
  >(QuoteService, types.SET_ITEM as any),
  getQuotes: GenericGetMany<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService, "quotes", types.SET_ITEM as any),
  getQuote: GenericGetOne<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService, types.SET_ITEM as any, getQuoteDefaults),
  deleteQuote: GenericDeleteOne<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService, types.DELETE_ITEM as any),
  updateQuote: GenericUpdateOne<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations,
    { id: string; update: Partial<IQuote> }
  >(QuoteService, types.SET_ITEM as any, getQuoteDefaults),
  scrubQuoteAddress: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.scrubQuoteAddress.bind(QuoteService)),
  saveTerritoryInformation: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.saveTerritoryInformation.bind(QuoteService)),
  getQuoteTerritoryInformation: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.getQuoteTerritoryInformation.bind(QuoteService)),
  saveStep2RiskAddress: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.saveStep2RiskAddress.bind(QuoteService)),
  saveQuotePropertyFields: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.saveQuotePropertyFields.bind(QuoteService)),
  rateQuote: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.rateQuote.bind(QuoteService)),
  selectRating: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.selectRating.bind(QuoteService)),
  async saveUnderwritingResponses({ commit }, payload) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const { data } = await QuoteService.saveUnderwritingResponses(payload);
      commit(types.SET_ITEM, data);
      commit(types.SET_STATE, { key: "lastUpdate", value: new Date() });
      return data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  updateQuoteApplication: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.updateQuoteApplication.bind(QuoteService)),
  step7RunQuoteCreditRating: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.runQuoteCredit.bind(QuoteService), types.SET_EDIT as any),
  step7RunQuoteLosses: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.runQuoteLosses.bind(QuoteService), types.SET_EDIT as any),
  step8SaveQuoteLossData: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.saveQuoteLossData.bind(QuoteService)),
  step9saveRemarksAndMortgages: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.saveRemarksAndMortgages.bind(QuoteService)),
  getSendingOrReceivingXML: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.getSendingOrReceivingXML.bind(QuoteService)),
  step9BindQuote: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.step9BindQuote.bind(QuoteService)),
  getPropertyFieldBuildingAmountValidations: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.getPropertyFieldBuildingAmountValidations.bind(QuoteService)),
  deleteBulkQuotes: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.deleteBulkQuotes.bind(QuoteService)),
  restoreBulkQuotes: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.restoreBulkQuotes.bind(QuoteService)),
  changeQuoteStatus: GenericMakeRequest<
    QuoteState,
    QuoteGetters,
    QuoteActions,
    QuoteMutations
  >(QuoteService.changeQuoteStatus.bind(QuoteService))
};
