import { IRouterConfig } from "@/types";
import { RouteConfig } from "vue-router";
import {
  redirectLoginRoute,
  redirectMfaRoute,
  redirectMfaVerify
} from "../../middleware";
import formBuilderRoutes from "./formBuilderRoutes";

const login: IRouterConfig = {
  path: "/login",
  component: () => import("@/views/public/Login.vue"),
  meta: {
    layout: "no-header",
    requireAuth: false,
    beforeResolve: redirectLoginRoute,
    pageTitle: "Login",
    metaTags: {
      title: "Login"
    }
  }
};

const register: IRouterConfig = {
  path: "/register",
  component: () => import("@/views/public/Register.vue"),
  meta: {
    layout: "no-header",
    requireAuth: false,
    beforeResolve: redirectLoginRoute,
    metaTags: {
      title: "Register"
    }
  }
};
const registerAdjuster: IRouterConfig = {
  path: "/register/:userType",
  component: () => import("@/views/public/Register.vue"),
  meta: {
    layout: "no-header",
    requireAuth: false,
    beforeResolve: redirectLoginRoute,
    metaTags: {
      title: "Register - Adjuster"
    }
  }
};
const welcome: IRouterConfig = {
  name: "register-welcome",
  path: "/register/welcome",
  component: () => import("@/views/public/Welcome.vue"),
  meta: {
    requireAuth: false,
    beforeResolve: redirectLoginRoute,
    metaTags: {
      title: "Welcome"
    }
  },
  props: true
};

const loginMfa: IRouterConfig = {
  path: "/mfa",
  component: () => import("@/views/public/LoginAuthenticate.vue"),
  meta: {
    layout: "no-header",
    requireAuth: false,
    beforeResolve: redirectMfaRoute,
    metaTags: {
      title: "MFA Request"
    }
  }
};

const loginMfaVerify: IRouterConfig = {
  path: "/mfa/verify",
  component: () => import("@/views/public/LoginAuthenticateVerify.vue"),
  meta: {
    layout: "no-header",
    requireAuth: false,
    beforeResolve: redirectMfaVerify,
    metaTags: {
      title: "MFA Verify"
    }
  },
  props: true,
  name: "mfa-verify"
};

const passwordReset: IRouterConfig = {
  path: "/password-reset",
  component: () => import("@/views/public/PasswordReset.vue"),
  meta: {
    layout: "no-header",
    requireAuth: false,
    metaTags: {
      title: "Reset Password"
    }
  }
};

const claimAccount: IRouterConfig = {
  path: "/claim-account",
  component: () => import("@/views/public/ClaimAccount.vue"),
  meta: {
    layout: "no-header",
    requireAuth: false,
    metaTags: {
      title: "Claim Account"
    }
  }
};

const claimAccountRequest: IRouterConfig = {
  path: "/claim",
  component: () => import("@/views/public/ClaimAccountRequest.vue"),
  meta: {
    layout: "no-header",
    requireAuth: false,
    metaTags: {
      title: "Claim Account Request"
    }
  }
};

const notAuthorized: IRouterConfig = {
  path: "/not-authorized",
  component: () =>
    import(/* webpackChunkName: "notAuthorized" */ "@/views/error/401.vue"),
  meta: {
    requireAuth: false,
    metaTags: {
      title: "Not Authorized"
    }
  }
};

const emergencyMaintenance: IRouterConfig = {
  path: "/emergency-maintenance",
  component: () =>
    import(
      /* webpackChunkName: "emergency maintenance" */ "@/views/error/EmergencyMaintenanceMode.vue"
    ),
  meta: {
    // layout: "no-header",
    requireAuth: false,
    metaTags: {
      title: "Emergency Maintenance Mode"
    }
  }
};

const p403: IRouterConfig = {
  path: "/403",
  component: () =>
    import(/* webpackChunkName: "forbidden" */ "@/views/error/403.vue"),
  meta: {
    layout: "no-header",
    requireAuth: false,
    metaTags: {
      title: "Forbidden"
    }
  }
};

const confirmAccount: IRouterConfig = {
  path: "/confirm-account",
  component: () => import("@/views/public/ConfirmAccount.vue"),
  meta: {
    layout: "no-header",
    requireAuth: false,
    metaTags: {
      title: "Confirm Account"
    }
  }
};

const quickpay: IRouterConfig = {
  path: "/quickbillpay",
  component: () => import("@/views/public/QuickPay/QuickPay.vue"),
  meta: {
    layout: "no-header",
    requireAuth: false,
    metaTags: {
      title: "Quick Pay"
    }
  }
};
const docuSign: IRouterConfig = {
  path: "/policy-sign",
  component: () => import("@/views/public/PolicySign/PolicySign.vue"),
  meta: {
    layout: "no-header",
    requireAuth: false,
    metaTags: {
      title: "Policy Doc Sign"
    }
  }
};

const createAgentApplication: IRouterConfig = {
  path: "/agent-application",
  component: () =>
    import("@/views/public/agentApplication/CreateAgentApplication.vue"),
  meta: {
    layout: "no-header",
    pageTitle: "Agency Application",
    requireAuth: false,
    metaTags: {
      title: "Create Agency Application"
    }
  }
};

const agentApplicationSuccess: IRouterConfig = {
  path: "/agent-application/success",
  component: () =>
    import("@/views/public/agentApplication/AgentApplicationSuccess.vue"),
  meta: {
    layout: "no-header",
    pageTitle: "Successful Registration",
    requireAuth: false,
    metaTags: {
      title: "Create Agent Application"
    }
  }
};
const aboutUs: IRouterConfig = {
  path: "/aboutUs",
  component: () => import("@/views/public/about/AboutUs.vue"),
  meta: {
    pageTitle: "About Us",
    requireAuth: false,
    metaTags: {
      title: "About Us"
    },
    breadcrumbs: [
      {
        link: "/aboutUs",
        title: "About Us"
      }
    ]
  }
};
const contactUs: IRouterConfig = {
  path: "/contactUs",
  component: () => import("@/views/public/about/ContactUs.vue"),
  meta: {
    pageTitle: "Contact Us",
    requireAuth: false,
    metaTags: {
      title: "Contact Us"
    },
    breadcrumbs: [
      {
        link: "/contactUs",
        title: "Contact Us"
      }
    ]
  }
};

const publicRoutes: RouteConfig[] = [
  createAgentApplication,
  agentApplicationSuccess,
  quickpay,
  docuSign,
  login,
  loginMfa,
  loginMfaVerify,
  passwordReset,
  claimAccount,
  notAuthorized,
  confirmAccount,
  claimAccountRequest,
  register,
  welcome,
  emergencyMaintenance,
  p403,
  registerAdjuster,
  aboutUs,
  contactUs,
  ...formBuilderRoutes
];

export default publicRoutes;
