import { ClaimsState } from "./types";

export const defaultState = (): ClaimsState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    claims: [],
    links: {},
    fiservClaimsData: null
  };
};

export const state = defaultState();
