var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'relative h-screen w-full': _vm.loadingFullApp || _vm.loadingVisibilityCheck
  },attrs:{"id":"app"}},[(_vm.loadingFullApp || _vm.loadingVisibilityCheck)?_c('AppLoading'):_vm._e(),(
      _vm.$isInEmergencyMaintenanceMode || _vm.$isInFiservScheduledMaintenanceMode
    )?_c('p',{staticClass:"text-white bg-red-500 border-2 border-red-400 text-center w-max fixed right-2 bottom-2 z-50 px-2 pointer-events-none"},[_vm._v(" "+_vm._s(_vm.maintenanceModeString)+" ")]):_vm._e(),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingVisibilityCheck),expression:"loadingVisibilityCheck"}],staticClass:"site-container",class:[
      _vm.pageClass,
      _vm.emergencyMaintenanceModeClass,
      _vm.fiservScheduledMaintenanceModeClass
    ]},[_c(_vm.layoutComponent,{tag:"component"},[_c('router-view',{on:{"scrollTop":_vm.scrollAppToTop,"scrollCenter":_vm.scrollAppToCenter}})],1)],1),_c('ModalBase',{attrs:{"name":"switch-agent-code-modal","clickToClose":false,"showClose":true,"allowYScrollOnOverflow":true,"title":"Select Agency Code","description":"Select active agent code","position":"center"}},[_c('AgencyCodeSwitcher',{on:{"agencyCodeSwitching":_vm.handleAgencyCodeSwitching}})],1),_c('ModalMessage',{attrs:{"name":"app-modal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":_vm.modalTitle,"description":_vm.modalDescription,"buttons":_vm.modalButtons}}),_c('ModalMessage',{attrs:{"name":"show-agency-switch-alert","icon":['fal', 'exclamation-circle'],"buttons":_vm.modalButtons,"iconClass":"text-red-600 text-6xl","title":"Switching  Agency Code","description":"Page will be reloaded. Please save all data before you proceed "}}),_c('ModalMessage',{attrs:{"name":"inactivity-modal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"You've been inactive for over 10 minutes.","description":"Do you want to stay logged in?","buttons":[
      {
        text: `Logout`,
        classList: [''],
        click: () => _vm.doInactivityLogout()
      },
      {
        text: `Continue`,
        classList: [''],
        click: () => _vm.hideInactivityModal()
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }