import { FormBlock, FormBlockField } from "../../types";

//extracts schema of field with given key
//returns FormBlockField or undefined
export function getFieldSchema(
  blocks: FormBlock[],
  key: string
): FormBlockField | undefined {
  for (const block of blocks) {
    if (block.element === "field") {
      if (block.properties.key === key) {
        return block;
      }
    } else {
      const { children } = block as any;
      if (children) {
        return getFieldSchema(children, key);
      }
    }
  }
  return undefined;
}
