import defaultsDeep from "lodash/defaultsDeep";
import cloneDeep from "lodash/cloneDeep";

import { IClaims, IClaimsFiservData } from "@/store/modules/claims/types";
import { baseClaimsFiservData, baseClaims } from "./defaultObjects";
import { get, set } from "lodash";

export function getClaimsDefaults(claim: Partial<IClaims>): IClaims {
  return defaultsDeep(cloneDeep(claim), Object.assign({}, baseClaims()));
}

export function getClaimsFiservDataDefault(
  claim: Partial<IClaimsFiservData>
): IClaimsFiservData {
  return defaultsDeep(
    cloneDeep(claim),
    Object.assign({}, baseClaimsFiservData())
  );
}

export function processEcheckPaymentOptionSelected(
  this: Vue,
  lossFormData: Record<string, any>
) {
  const insuredEmail = get(
    lossFormData,
    "lossFormOtherInfo.Client.OtherAuthConcact"
  );
  if (!insuredEmail) {
    const emailFieldId =
      "field_field_lossFormOtherInfo.Client.OtherAuthConcact";
    const emailComponentContainer = document.getElementById(emailFieldId);
    const emailInputField = emailComponentContainer?.querySelector("input");
    if (emailInputField) {
      emailInputField.focus();
      this.$appNotifyInfo("Please enter Insured's email");
    }
  }
  //make insured email and confirm email required on form-builder
}

export async function processPaymentOptionEdit(
  this: Vue,
  key: string,
  value: any,
  lossFormData: Record<string, any>
): Promise<void> {
  if (value === "E") {
    try {
      await this.$confirm(
        "Be advised that e-checks will be delivered directly to the provided email address and will need to be printed. Would you like to continue?",
        "Info",
        {
          distinguishCancelAndClose: true,
          confirmButtonText: "YES",
          cancelButtonText: "NO"
        }
      );
      set(lossFormData, key, value);
      processEcheckPaymentOptionSelected.bind(this)(lossFormData);
    } catch {
      set(lossFormData, key, "I");
    }
  } else {
    set(lossFormData, key, value);
  }
}
