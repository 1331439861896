import { FormBlock, FormFieldSectionOptions } from "../../types";
import { useTitle } from "./useTitle";
// to wrap a set of field groups?
export const useFormSection = (
  children: FormBlock[] = [],
  options: FormFieldSectionOptions = {}
): FormBlock[] => {
  const defaults = {
    title: "",
    description: "",
    rightSlot: {}
  };
  const o = Object.assign({}, defaults, options);

  return [
    {
      element: "html",
      tagName: "div",
      classList: [
        "form-section",
        "mb-8",
        "pb-4",
        "border-dashed",
        "border-gray-200",
        "border-b-2"
      ],
      children: o.title
        ? useTitle(o.title, o.description, o.rightSlot, children)
        : children
    }
  ];
};
