import {
  QuickPayActions,
  QuickPayState,
  QuickPayMutations,
  QuickPayGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import QuickPayService from "@/services/quickpay.service";
import { extraInsuredInfoForPolicySIgn, extractPayInfo } from "./utils";
import TransactionsService from "@/services/transactions.service";
import { Site } from "@/helpers/constants";

export const actions: DefineActions<
  QuickPayActions,
  QuickPayState,
  QuickPayMutations,
  QuickPayGetters
> = {
  async createQuickPay({ commit }, payload) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const response = await QuickPayService.createOne(payload);
      return response.data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  async getPolicyInfo({ commit }, payload) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const response = await QuickPayService.getQuickPayPolicyInfo(payload);
      if (response.data) {
        commit(types.SET_STATE, {
          key: "policyInfo",
          value: extractPayInfo(response)
        });
        return response.data.data;
      }
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  async getSessionId({ commit }, site: Site) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const response = await QuickPayService.getSessionId(site);
      return response.data.IsSuccessful ? response.data.SessionKey : "";
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  async saveTransaction({ commit }, payload) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const response = await TransactionsService.createOne(payload);

      return response.data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  async fetchCompaniesPrefixes({ commit }, query) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const response = await QuickPayService.getCompaniesPrefixes(query);

      return response.data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  async verifyInsuredInfo({ commit }, payload) {
    try {
      commit(types.SET_STATES, {
        makingApiRequest: true
      });
      const response = await QuickPayService.verifyInsuredInfo(payload);
      commit(types.SET_STATE, {
        key: "policyInfo",
        value: extraInsuredInfoForPolicySIgn(
          response,
          payload.policyNumber,
          payload.companyPrefix
        )
      });
      return response.data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  async savePolicySignature({ commit }, payload) {
    try {
      commit(types.SET_STATES, {
        makingApiRequest: true
      });
      const response = await QuickPayService.doSavePolicySignature(payload);
      return response.data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  async readPolicyPDF({ commit }, payload) {
    try {
      commit(types.SET_STATES, {
        makingApiRequest: true
      });
      const response = await QuickPayService.doReadPolicyPDF(payload);
      return response.data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  }
};
