
import MenuItem from "@/components/GlobalMenu/MenuItem.vue";
import { authMapGetters, authMapState } from "@/store/modules/auth";

import Vue from "vue";
import { userPrimaryMenu } from "./menuItemsUser";
import { adminPrimaryMenu } from "./menuItemsAdmin";
import { agencyAdminPrimaryMenu } from "./menuItemsAgencyAdmin";
import { adjusterPrimaryMenu } from "./menuItemsAdjuster";
import { underwriterPrimaryMenu } from "./menuItemsUnderwriter";
import { claimsPrimaryMenu } from "./menuItemsClaims";
import { billingPrimaryMenu } from "./menuItemsBilling";
import { dataEntryPrimaryMenu } from "./menuItemsDataEntry";

export default Vue.extend({
  name: "user-menu",
  components: {
    MenuItem
  },
  props: {
    currentRole: {
      type: String,
      required: true
    }
  },
  computed: {
    ...authMapState(["initData"]),
    ...authMapGetters(["userHasRoutePermissionDisabled"]),
    menuItems(): any {
      if (
        this.$isLoggedIn &&
        !this.$isCurrentUserAdmin &&
        (this.$getCurrentUser.status === "pendingTutorial" ||
          this.$getCurrentUser.status === "completedTutorial")
      ) {
        if (
          this.$getCurrentUser.role === "agencyAdmin" ||
          this.$getCurrentUser.role === "subAdmin"
        ) {
          return [
            {
              to: "/agencyAdmin/agency/",
              text: this.__getText("menu", "agencyAdminAgencyLinkText")
            }
          ];
        } else {
          return [];
        }
      }
      switch (this.currentRole) {
        case "admin":
          return adminPrimaryMenu();
        case "agent":
        case "ezlynx":
          return userPrimaryMenu(this.currentRole);
        case "agencyAdmin":
        case "subAdmin":
          return agencyAdminPrimaryMenu();
        case "adjuster":
          return adjusterPrimaryMenu();
        case "underwriting":
          return underwriterPrimaryMenu();
        case "claims":
          return claimsPrimaryMenu();
        case "billing":
          return billingPrimaryMenu();
        case "dataEntry":
          return dataEntryPrimaryMenu();
        default:
          return [];
      }
    },
    unreadNotifications(): number {
      if (this.initData) {
        return (
          this.initData.unreadNotifications.length +
          this.initData.urgentUnreadNotifications.length
        );
      }
      return 0;
    },
    filteredMenuItems(): any[] {
      if (!this.menuItems) return this.menuItems;
      return this.menuItems.filter((menuItem: any) => this.showMenu(menuItem));
    }
  },
  methods: {
    showMenu(menuItem: any): boolean {
      const permission = menuItem.permission ?? "";
      return !this.userHasRoutePermissionDisabled(permission);
    },
    showSubLinks(subLinks: any[]): any[] {
      if (!subLinks) return subLinks;
      return subLinks.filter((subLink: any) => this.showMenu(subLink));
    }
  }
});
