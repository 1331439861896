
import { userMapActions, userMapGetters } from "@/store/modules/user";
import { cloneDeep } from "lodash";
import Vue from "vue";
import UserSearchOptionComponent from "@/components/FormFields/SelectFieldOptions/UserSearchOptionComponent.vue";
import { capitalizeFirstLetter } from "@/helpers/generalHelpers";
import { userRoles } from "@/helpers/selectOptions";

interface IData {
  loading: boolean;
  options: { label: string; value: any }[];
}

export default Vue.extend({
  name: "search-select-users",
  props: {
    selected: {
      type: [Number, String, Array, Boolean],
      default: ""
    },
    label: {
      type: String,
      default: "",
      required: false
    },
    selectedLabel: {
      type: String,
      required: false,
      default: ""
    },
    roles: {
      type: Array,
      required: false,
      default: () => userRoles.map(e => e.value)
    },
    preFetch: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: "Type to search users"
    },
    returnWithout: {
      type: String,
      required: false,
      default: ""
    }
  },
  mounted() {
    const hasSelectedUsers =
      (typeof this.selected === "string" && this.selected.length >= 24) ||
      (Array.isArray(this.selected) && this.selected.length > 0);
    if (hasSelectedUsers) {
      const selected: any =
        typeof this.selected === "string" ? [this.selected] : this.selected;
      this.makeOptionsForSelectedUsers(selected);
    }
    if (this.preFetch) {
      this.remoteMethod("");
    }
  },
  data(): IData {
    return {
      loading: false,
      options: []
    };
  },
  methods: {
    optionsComponent() {
      return UserSearchOptionComponent;
    },
    ...userMapActions(["getUsers"]),
    async remoteMethod(searchTerm: string) {
      try {
        this.loading = true;
        await this.getUsers({
          query: {
            __query: searchTerm
          }
        });
      } catch (error) {
        this.$appNotifyError("Error fetching users");
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = true;
      }
    },
    async makeOptionsForSelectedUsers(selected: string[]): Promise<void> {
      const user = await this.getUsers({ query: { _id__in: selected } });
      this.makeUserOptions([user]);
    },
    makeUserOptions(users: any[]) {
      return users.map((user: any) => {
        const { _id, firstName, lastName } = user;
        let name = `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
          lastName
        )}`;
        return {
          label: name,
          key: _id,
          value: _id,
          name,
          email: `${user.email}`,
          role: `${user.role}`
        };
      });
    }
  },
  computed: {
    ...userMapGetters(["getUsersFromStore"]),
    userListOptions(): any {
      const users = cloneDeep(this.getUsersFromStore);
      // filter users where role is not this.returnWithout
      let filteredUsers = users;
      if (this.returnWithout) {
        filteredUsers = users.filter((user: any) => {
          return user.role !== this.returnWithout;
        });
      }
      return this.makeUserOptions(filteredUsers);
    }
  }
});
