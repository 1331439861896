import { PropertyChangeRequestState } from "./types";

export const defaultState = (): PropertyChangeRequestState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    propertyChangeRequests: [],
    links: {}
  };
};

export const state = defaultState();
