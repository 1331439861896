import { createNamespacedHelpers, Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { state } from "./state";
import {
  AtlasFileCategoryActions,
  AtlasFileCategoryGetters,
  AtlasFileCategoryMutations,
  AtlasFileCategoryState
} from "./types";

const namespaced = true;
export const namespace = "atlasFileCategory";

export const {
  mapState: atlasFileCategoryMapState,
  mapGetters: atlasFileCategoryMapGetters,
  mapMutations: atlasFileCategoryMapMutations,
  mapActions: atlasFileCategoryMapActions
} = createNamespacedHelpers<
  AtlasFileCategoryState,
  AtlasFileCategoryGetters,
  AtlasFileCategoryMutations,
  AtlasFileCategoryActions
>(namespace);

export const atlasFileCategory: Module<AtlasFileCategoryState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
