import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  MortgageState,
  MortgageGetters,
  MortgageMutations,
  MortgageActions
} from "./types";

const namespaced = true;
export const namespace = "mortgage";

export const {
  mapState: mortgageMapState,
  mapGetters: mortgageMapGetters,
  mapMutations: mortgageMapMutations,
  mapActions: mortgageMapActions
} = createNamespacedHelpers<
  MortgageState,
  MortgageGetters,
  MortgageMutations,
  MortgageActions
>(namespace);

export const mortgage: Module<MortgageState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
