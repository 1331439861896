import {
  ICounty,
  CountyActions,
  CountyState,
  CountyMutations,
  CountyGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import CountyService from "@/services/county.service";
import GbsService from "@/services/gbs.service";

export const actions: DefineActions<
  CountyActions,
  CountyState,
  CountyMutations,
  CountyGetters
> = {
  createCounty: GenericCreateOne<
    CountyState,
    CountyGetters,
    CountyActions,
    CountyMutations,
    ICounty
  >(CountyService, types.SET_ITEM as any),
  getCounties: GenericGetMany<
    CountyState,
    CountyGetters,
    CountyActions,
    CountyMutations
  >(CountyService, "counties", types.SET_ITEM as any),
  getGbsCounties: GenericGetMany<
    CountyState,
    CountyGetters,
    CountyActions,
    CountyMutations
  >(GbsService, "counties", types.SET_ITEM as any),
  getCounty: GenericGetOne<
    CountyState,
    CountyGetters,
    CountyActions,
    CountyMutations
  >(CountyService, types.SET_ITEM as any),
  deleteCounty: GenericDeleteOne<
    CountyState,
    CountyGetters,
    CountyActions,
    CountyMutations
  >(CountyService, types.DELETE_ITEM as any),
  updateCounty: GenericUpdateOne<
    CountyState,
    CountyGetters,
    CountyActions,
    CountyMutations,
    { id: string; update: Partial<ICounty> }
  >(CountyService, types.SET_ITEM as any),
  prohibitBindingForCounty: async ({ commit }, countyCode) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await CountyService.prohibitBindingByCode(countyCode);
      const responseData = response.data;
      commit(types.SET_ITEM, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  cancelProhibitBindingForCounty: async ({ commit }, countyCode) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await CountyService.cancelProhibitBindingByCode(
        countyCode
      );
      const responseData = response.data;
      commit(types.SET_ITEM, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  deleteCountiesByCountyCode: async ({ commit }, countyCodes) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await CountyService.deleteCountiesByCountyCodes(
        countyCodes
      );
      return response.data;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  prohibitBindingForCounties: async ({ commit }, countyCodes) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await CountyService.prohibitBindingByCodes(countyCodes);
      const responseData = response.data;
      commit(types.SET_ITEM, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },
  toggleBulkCountyBindingProhibition: async ({ commit }, payload) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await CountyService.toggleBulkCountyBindingProhibition(
        payload
      );
      const responseData = response.data;
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
};
