import * as types from "@/store/mutation-types";
import { InterfaceMap } from "@/store/helpers";
import { ILinks } from "@/types";
import { IActionQueryPayload } from "@/store/types";
import {
  AcknowledgmentType,
  DeliveryMethod,
  ICommunication
} from "../communication/types";

export type NotificationPriority = "high" | "medium" | "low";
export const NotificationPriorities: NotificationPriority[] = [
  "high",
  "medium",
  "low"
];

export interface NotificationState {
  editing: any; //TODO: TYPE ME PLEASE?.
  makingApiRequest: boolean;
  lastUpdate: Date;
  notifications: any[]; //TODO: TYPE ME TOO PLEASE!
  links: ILinks | null;
}

export type StateKeys = keyof NotificationState;

export interface ISetStatePayload {
  key: StateKeys;
  value: any;
}

export interface NotificationGetters {
  getTotalRecords: number;
}

export interface IAcknowledgment {
  acknowledgedBy: string;
  acknowledgedOn: Date;
}

export interface INotificationAction {
  link: string;
  text: string;
  type: string;
}

export interface INotification {
  //TODO: type me please and don't go fooling about
  _id: string;
  title: string;
  message: string;
  priority: NotificationPriority;
  userId: string;
  createdOn: Date;
  deleted: boolean;
  actions: INotificationAction[];
  deliveryMethods: DeliveryMethod[];
  expirationDate?: Date;

  acknowledgments: AcknowledgmentType;
  acknowledgment: IAcknowledgment;
  attachments: any[];
  communicationId: string;

  //populated
  communicationData?: ICommunication; // gets populated and selects only fields needed
  readAt: Date; // populated from activities whose notificationId === this._id sorted by date and onlyOne: true
  hasRead: boolean; // COUNT from activities whose notificationId === this._id
  activityData: any[]; // populated from activities whose notificationId === this._id //TODO: type activity and then update ME!!
  attachmentsReadActivityData: any[];
}

export interface ISetINotificationEditField {}

export interface NotificationMutations {
  [types.SET_STATE]: ISetStatePayload;
  [types.SET_STATES]: Partial<InterfaceMap<NotificationState>>;
  [types.SET_ITEM]: INotification | INotification[];
  [types.SET_EDIT]: string | INotification;
  [types.SET_EDIT_FIELD]: { key: keyof INotification; value: any };
  [types.DELETE_ITEM]: string;
  [types.CLEAR_NOTIFICATION_STORE]: NotificationState;
}

export interface NotificationActions {
  createNotification: any; //TYPE ME
  updateNotification: { id: string; update: Partial<INotification> }; //TYPE ME
  getNotification: string;
  getNotifications: IActionQueryPayload;
  deleteNotification: string;
  acknowledgeNotification: { id: string; name: string };
}
