import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class AtlasfileService extends BaseCollectionService {
  constructor() {
    super("atlas-files");
  }

  async createOne(data: FormData): Promise<ResponseWrapper> {
    const path = `${this.url}`;
    return await this.request(
      {
        method: "post",
        url: path,
        data
      },
      {
        useToken: "access",
        contentType: "multipart/form-data"
      }
    );
  }

  async exportCollection(data: any): Promise<ResponseWrapper> {
    //TODO: unused, rethink and delete?
    const path = `${this.url}/export`;
    return await this.request(
      {
        method: "post",
        url: path,
        data,
        responseType: "blob"
      },
      {
        useToken: "access"
      }
    );
  }
}

export default new AtlasfileService();
