
//TO-DO: pass orientation as prop to determine if position should be horizontal or vertical
import Vue from "vue";

import { checkVuelidateValidation } from "@/helpers/processValidation";

export default Vue.extend({
  name: "check-box-group",
  props: {
    preamble: {
      type: String,
      required: false,
      default: ""
    },
    id: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    validationResult() {
      return checkVuelidateValidation(this.validations as any, this.value);
    }
  }
});
