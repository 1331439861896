import { DefineGetters } from "vuex-type-helper";
import {
  AtlasPlatformGetters,
  AtlasPlatformState,
  IAtlasPlatform
} from "./types";

export const getters: DefineGetters<
  AtlasPlatformGetters,
  AtlasPlatformState
> = {
  getAtlasPlatformById: (state: AtlasPlatformState) => (faqId: string) =>
    state.atlasPlatforms.find((faq: IAtlasPlatform) => faq._id === faqId),
  editingAtlasPlatform: (state: AtlasPlatformState) => state.editing,
  atlasPlatforms: (state: AtlasPlatformState) => state.atlasPlatforms,
  isLoading: (state: AtlasPlatformState) => state.makingApiRequest,
  getAtlasPlatformsLinks: (state: AtlasPlatformState) => state.links,
  getTotalRecords: ({ links, atlasPlatforms }: AtlasPlatformState) =>
    links && links.totalRecords ? links.totalRecords : atlasPlatforms.length
};
