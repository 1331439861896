import { DefineGetters } from "vuex-type-helper";
import {
  PropertyChangeRequestGetters,
  PropertyChangeRequestState
} from "./types";

export const getters: DefineGetters<
  PropertyChangeRequestGetters,
  PropertyChangeRequestState
> = {
  getPropertyChangeRequestById: (state: PropertyChangeRequestState) => (
    propertyChangeRequestId: string
  ) =>
    state.propertyChangeRequests.find(
      (propertyChangeRequest: any) =>
        propertyChangeRequest._id === propertyChangeRequestId
    ),
  getPropertyChangeRequestsFromStore: (state: PropertyChangeRequestState) =>
    state.propertyChangeRequests,
  getPropertyChangeRequestsLinks: (state: PropertyChangeRequestState) =>
    state.links,
  getEditingPropertyChangeRequest: (state: PropertyChangeRequestState) =>
    state.editing,
  getTotalRecords: ({
    links,
    propertyChangeRequests
  }: PropertyChangeRequestState) =>
    links && links.totalRecords
      ? links.totalRecords
      : propertyChangeRequests.length,
  getMaxQuerySize: ({
    links,
    propertyChangeRequests
  }: PropertyChangeRequestState) =>
    links && links.maxQueryLimit
      ? links.maxQueryLimit
      : propertyChangeRequests.length
};
