import { DefineGetters } from "vuex-type-helper";
import { CompaniesGetters, CompaniesState, ICompany } from "./types";

export const getters: DefineGetters<CompaniesGetters, CompaniesState> = {
  getCompanyById: (state: CompaniesState) => (companyId: string) =>
    state.companies.find((company: ICompany) => company._id === companyId),
  editingCompany: (state: CompaniesState) => state.editing,
  companies: (state: CompaniesState) => state.companies,
  isLoading: (state: CompaniesState) => state.makingApiRequest,
  getCompaniesLinks: (state: CompaniesState) => state.links,
  getTotalRecords: ({ links, companies }: CompaniesState) =>
    links && links.totalRecords ? links.totalRecords : companies.length
};
