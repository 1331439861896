import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  AgentApplicationsState,
  AgentApplicationsGetters,
  AgentApplicationsMutations,
  AgentApplicationsActions
} from "./types";

const namespaced = true;
export const namespace = "agentApplications";

export const {
  mapState: agentApplicationsMapState,
  mapGetters: agentApplicationsMapGetters,
  mapMutations: agentApplicationsMapMutations,
  mapActions: agentApplicationsMapActions
} = createNamespacedHelpers<
  AgentApplicationsState,
  AgentApplicationsGetters,
  AgentApplicationsMutations,
  AgentApplicationsActions
>(namespace);

export const agentApplications: Module<AgentApplicationsState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
