import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class BillingChangeRequestService extends BaseCollectionService {
  constructor() {
    super("storage-forms/cancellation-change-requests");
  }

  public async printBillingChangeRequestById(
    payload: any
  ): Promise<ResponseWrapper> {
    const path = `/storage-forms/print-billing-change-request`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async updateRequestsStatus(payload: any): Promise<ResponseWrapper> {
    const path = `/storage-forms/cancellation-change-requests/update-status`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async getBillingInfo(payload: any): Promise<ResponseWrapper> {
    const path = `/billing-info`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async printBillingInfo(payload: any): Promise<ResponseWrapper> {
    const path = `/billing-info/print`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "access" }
    );
  }

  async deleteChangeRequestsByIds(
    _ids: string[],
    type: string
  ): Promise<ResponseWrapper> {
    const path = `/storage-forms`;
    return await this.request(
      {
        method: "delete",
        url: path,
        data: { _ids, type }
      },
      { useToken: "access" }
    );
  }

  async restoreBillingChangeRequests(data: Record<string, any>) {
    const path = `/storage-forms/restore`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: data
      },
      { useToken: "access" }
    );
  }
}

export default new BillingChangeRequestService();
