import { IFAQ, FaqActions, FaqState, FaqMutations, FaqGetters } from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericDeleteOne,
  GenericUpdateOne,
  GenericGetOne
} from "@/store/helpers/actions";
import FaqService from "@/services/faq.service";

export const actions: DefineActions<
  FaqActions,
  FaqState,
  FaqMutations,
  FaqGetters
> = {
  createFaq: GenericCreateOne<
    FaqState,
    FaqGetters,
    FaqActions,
    FaqMutations,
    IFAQ
  >(FaqService, types.SET_ITEM as any),
  getFaq: GenericGetOne<FaqState, FaqGetters, FaqActions, FaqMutations>(
    FaqService,
    types.SET_ITEM
  ),
  getFAQs: GenericGetMany<FaqState, FaqGetters, FaqActions, FaqMutations>(
    FaqService,
    "faqs",
    types.SET_ITEM
  ),
  deleteFaq: GenericDeleteOne<FaqState, FaqGetters, FaqActions, FaqMutations>(
    FaqService,
    types.DELETE_ITEM
  ),
  updateFaq: GenericUpdateOne<
    FaqState,
    FaqGetters,
    FaqActions,
    FaqMutations,
    { id: string; update: Partial<IFAQ> }
  >(FaqService, types.SET_ITEM as any)
};
