import { createNamespacedHelpers, Module } from "vuex";
import {
  EmergencyActions,
  EmergencyGetters,
  EmergencyMutations,
  EmergencyState
} from "./types";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const namespaced = true;
export const namespace = "emergency";

export const {
  mapState: emergencyMapState,
  mapGetters: emergencyMapGetters,
  mapMutations: emergencyMapMutations,
  mapActions: emergencyMapActions
} = createNamespacedHelpers<
  EmergencyState,
  EmergencyGetters,
  EmergencyMutations,
  EmergencyActions
>("emergency");

export const emergency: Module<EmergencyState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
