import * as singleUserChildren from "../admin/children.users";
import quoteRoutes from "./quotes";
import ClaimsLookupResults from "@/views/shared/lossForm/ClaimsLookup/ClaimsLookupResults.vue";

export default [
  ...quoteRoutes,
  {
    path: "/faq",
    component: () => import("@/views/public/FAQ.vue"),
    meta: {
      requireAuth: true,
      pageTitle: "FAQ",
      allowedRoles: "anyone"
    }
  },
  {
    path: "/admin/notifications",
    component: () =>
      import(
        "@/views/shared/notifications/ListNotifications/ListNotifications.vue"
      ),
    meta: {
      pageTitle: "Notifications",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Notifications"
      }
    }
  },
  {
    path: "/admin/notifications/:notification_id",
    component: () =>
      import(
        "@/views/shared/notifications/ListNotifications/ReadNotification.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/notifications",
          title: "Notifications"
        }
      ],
      pageTitle: "View Notification",
      requireAuth: true,
      allowedRoles: "anyone",
      metaTags: {
        title: "View Notification"
      },
      permissions: ["notifications:read"]
    }
  },
  {
    path: "/notifications/:notification_id",
    component: () =>
      import(
        "@/views/shared/notifications/ListNotifications/ReadNotification.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/notifications",
          title: "Notifications"
        }
      ],
      pageTitle: "View Notification",
      requireAuth: true,
      allowedRoles: "anyone",
      metaTags: {
        title: "View Notification"
      },
      permissions: ["notifications:read"]
    }
  },
  {
    path: "/notifications",
    component: () =>
      import(
        "@/views/shared/notifications/ListNotifications/ListNotifications.vue"
      ),
    meta: {
      pageTitle: "Notifications",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "underwriting",
        "agencyAdmin",
        "subAdmin",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Notifications"
      },
      permissions: ["notifications:read"]
    }
  },
  {
    path: "/propertychangerequests",
    component: () =>
      import(
        "@/views/shared/PropertyChangeRequest/ListPropertyChangeRequests/ListPropertyChangeRequests.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        }
      ],
      pageTitle: "Policy Change Requests",
      requireAuth: true,
      allowedRoles: [
        "agent",
        "admin",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Policy Change Requests"
      }
    }
  },
  {
    path: "/propertychangerequest/new",
    component: () =>
      import(
        "@/views/shared/PropertyChangeRequest/SinglePropertyChangeRequests/AddPropertyChangeRequest.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/propertychangerequests",
          title: "Policy Change Requests"
        }
      ],
      pageTitle: "Create Policy Change Request",
      requireAuth: true,
      allowedRoles: [
        "agent",
        "admin",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Create Policy Change Request"
      }
    }
  },
  {
    path: "/propertyChangeRequest/:propertychangerequest_id",
    component: () =>
      import(
        "@/views/shared/PropertyChangeRequest/SinglePropertyChangeRequests/ViewPropertyChangeRequests.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/propertychangerequests",
          title: "Policy Change Requests"
        }
      ],
      pageTitle: "View Policy Change Request",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "View Policy Change Request"
      }
    }
  },
  {
    path: "/propertyChangeRequest/:propertychangerequest_id/legacy",
    component: () =>
      import(
        "@/views/shared/PropertyChangeRequest/SinglePropertyChangeRequests/ViewPropertyChangeRequestsLegacy.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/propertychangerequests",
          title: "Policy Change Requests"
        }
      ],
      pageTitle: "View Legacy Policy Change Request",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "View Legacy Policy Change Request"
      }
    }
  },
  {
    path: "/propertyChangeRequest/edit/:propertychangerequest_id",
    component: () =>
      import(
        "@/views/shared/PropertyChangeRequest/SinglePropertyChangeRequests/SinglePropertyChangeRequest.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/propertychangerequests",
          title: "Policy Change Requests"
        }
      ],
      pageTitle: "Edit Policy Change Request",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Edit Policy Change Request"
      }
    }
  },
  {
    path: "/billingchangerequests",
    component: () =>
      import(
        "@/views/shared/BillingChangeRequest/ListBillingChangeRequests/ListBillingChangeRequests.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        }
      ],
      pageTitle: "Cancellation Change Requests",
      requireAuth: true,
      allowedRoles: [
        "agent",
        "admin",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Cancellation Change Requests"
      }
    }
  },
  {
    path: "/billingchangerequest/new",
    component: () =>
      import(
        "@/views/shared/BillingChangeRequest/SingleBillingChangeRequests/AddBillingChangeRequest.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/billingchangerequests",
          title: "Cancellation Change Requests"
        }
      ],
      pageTitle: "Create Cancellation Change Request",
      requireAuth: true,
      allowedRoles: [
        "agent",
        "admin",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Create Cancellation Change Request"
      }
    }
  },
  {
    path: "/policy-inquiry",
    component: () => import("@/views/shared/lossForm/PolicyInquiry.vue"),
    meta: {
      pageTitle: "Policy Inquiry",
      requireAuth: true,
      allowedRoles: [
        "agent",
        "admin",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Policy Inquiry"
      }
    }
  },
  {
    path: "/billingchangerequest/:billingchangerequest_id",
    component: () =>
      import(
        "@/views/shared/BillingChangeRequest/SingleBillingChangeRequests/ViewBillingChangeRequests.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/billingchangerequests",
          title: "Cancellation Change Requests"
        }
      ],
      pageTitle: "View Cancellation Change Request",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "View Cancellation Change Request"
      }
    }
  },
  {
    path: "/billingchangerequest/:billingchangerequest_id/legacy",
    component: () =>
      import(
        "@/views/shared/BillingChangeRequest/SingleBillingChangeRequests/ViewBillingChangeRequestsLegacy.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/billingchangerequests",
          title: "Cancellation Change Requests"
        }
      ],
      pageTitle: "View Legacy Cancellation Change Request",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "View Legacy Cancellation Change Request"
      }
    }
  },
  {
    path: "/billingchangerequest/edit/:billingchangerequest_id",
    component: () =>
      import(
        "@/views/shared/BillingChangeRequest/SingleBillingChangeRequests/SingleBillingChangeRequest.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/billingchangerequests",
          title: "Cancellation Change Requests"
        }
      ],
      pageTitle: "Edit Cancellation Change Request",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Edit Cancellation Change Request"
      }
    }
  },
  {
    path: "/payplanchangerequests",
    component: () =>
      import(
        "@/views/shared/PayPlanChangeRequest/ListPayPlanChangeRequests/ListPayPlanChangeRequests.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        }
      ],
      pageTitle: "Pay Plan Change Requests",
      requireAuth: true,
      allowedRoles: [
        "agent",
        "admin",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Pay Plan Change Requests"
      }
    }
  },
  {
    path: "/payplanchangerequest/new",
    component: () =>
      import(
        "@/views/shared/PayPlanChangeRequest/SinglePayPlanChangeRequests/AddPayPlanChangeRequest.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/payplanchangerequests",
          title: "Pay Plan Change Requests"
        }
      ],
      pageTitle: "Create Pay Plan Change Request",
      requireAuth: true,
      allowedRoles: [
        "agent",
        "admin",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Create Pay Plan Change Request"
      }
    }
  },
  {
    path: "/payplanchangerequest/:payplanchangerequest_id",
    component: () =>
      import(
        "@/views/shared/PayPlanChangeRequest/SinglePayPlanChangeRequests/ViewPayPlanChangeRequest.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/payplanchangerequests",
          title: "Pay Plan Change Requests"
        }
      ],
      pageTitle: "View Pay Plan Change Request",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "View Pay Plan Change Request"
      }
    }
  },
  {
    path: "/payplanchangerequest/:payplanchangerequest_id/legacy",
    component: () =>
      import(
        "@/views/shared/PayPlanChangeRequest/SinglePayPlanChangeRequests/ViewPayPlanChangeRequestLegacy.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/payplanchangerequests",
          title: "View Legacy Pay Plan Change Requests"
        }
      ],
      pageTitle: "View Legacy Pay Plan Change Request",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "View Legacy Pay Plan Change Request"
      }
    }
  },
  {
    path: "/payplanchangerequest/edit/:payplanchangerequest_id",
    component: () =>
      import(
        "@/views/shared/PayPlanChangeRequest/SinglePayPlanChangeRequests/SinglePayPlanChangeRequest.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/payplanchangerequests",
          title: "Pay Plan Change Requests"
        }
      ],
      pageTitle: "Edit Pay Plan Change Request",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Edit Pay Plan Change Request"
      }
    }
  },
  {
    path: "/lossformadmin/new/:policyData",
    component: () => import("@/views/shared/lossForm/AddNewLossForm.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/claims",
          title: "Claims"
        }
      ],
      pageTitle: "Property Loss Notice",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Property Loss Notice"
      }
    }
  },
  {
    path: "/lossformadmin/edit/:_id",
    component: () => import("@/views/shared/lossForm/EditLossForm.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/claims",
          title: "Claims"
        }
      ],
      pageTitle: "Property Loss Notice",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Property Loss Notice"
      }
    }
  },
  {
    path: "/lossformadmin/view/:_id",
    component: () => import("@/views/shared/lossForm/ViewLossForm.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/claims",
          title: "Claims"
        }
      ],
      pageTitle: "Property Loss Notice",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Property Loss Notice"
      }
    }
  },
  {
    path: "/policy_inquiry",
    component: () => import("@/views/shared/lossForm/PolicyInquiry.vue"),
    meta: {
      pageTitle: "Policy Information",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agencyAdmin",
        "subAdmin",
        "agent",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Policy Inquiry"
      }
    }
  },
  {
    path: "/claims",
    component: () =>
      import("@/views/shared/lossForm/ClaimsListView/ClaimsFormList.vue"),
    meta: {
      pageTitle: "Claims",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Claims"
      }
    }
  },
  {
    path: "/file-claim",
    component: () => import("@/views/shared/lossForm/FileAClaim.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/claims",
          title: "Claims"
        }
      ],
      pageTitle: "File A Claim",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "File A Claim"
      }
    }
  },
  {
    path: "/claims-lookup",
    component: () => import("@/views/shared/lossForm/ClaimsLookupInitial.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/claims",
          title: "Claims"
        }
      ],
      pageTitle: "Claims Status Lookup",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Claims Status Lookup"
      }
    }
  },
  {
    path: "/claims/lookup/details",
    component: () =>
      import("@/views/shared/lossForm/ClaimsLookup/ClaimStatusDetails.vue"),
    meta: {
      pageTitle: "Claim Status Information",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agencyAdmin",
        "subAdmin",
        "agent",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Claim Status Information"
      }
    }
  },
  {
    path: "/claims/lookup/results",
    component: ClaimsLookupResults,
    meta: {
      pageTitle: "Claim Status Results",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Claim Status Results"
      }
    }
  },
  {
    path: "/agencyAdmin/users",
    component: () => import("@/views/shared/users/ListUsers.vue"),
    meta: {
      pageTitle: "Agents",
      requireAuth: true,
      allowedRoles: ["admin", "agencyAdmin", "subAdmin"],
      metaTags: {
        title: "Agents"
      }
    }
  },
  {
    path: "/agencyAdmin/users/new",
    component: () => import("@/views/shared/users/AddNewUser.vue"),
    meta: {
      pageTitle: "Add New Agent",
      requireAuth: true,
      allowedRoles: ["admin", "agencyAdmin", "subAdmin"],
      metaTags: {
        title: "Add New Agent"
      }
    }
  },
  {
    path: "/agencyAdmin/users/:user_id",
    component: () => import("@/views/shared/users/SingleUser.vue"),
    props: (route: any) => ({
      userId: route.params.user_id
    }),
    meta: {
      pageTitle: "Edit Agent",
      requireAuth: true,
      allowedRoles: ["admin", "agencyAdmin", "subAdmin"],
      metaTags: {
        title: "Edit Agent"
      }
    },
    children: [singleUserChildren.agencyUserEdit]
  },
  {
    path: "/users",
    component: () => import("@/views/shared/users/ListUsers.vue"),
    meta: {
      pageTitle: "Users",
      requireAuth: true,
      allowedRoles: ["underwriting", "claims", "billing", "dataEntry"],
      metaTags: {
        title: "Users"
      }
    }
  },
  {
    path: "/users/:user_id",
    component: () => import("@/views/shared/users/SingleUser.vue"),
    props: (route: any) => ({
      userId: route.params.user_id
    }),
    meta: {
      pageTitle: "View User",
      requireAuth: true,
      allowedRoles: ["underwriting", "claims", "billing", "dataEntry"],
      metaTags: {
        title: "View User"
      }
    },
    children: [singleUserChildren.otherUserEdit]
  },
  {
    path: "/admin/documents",
    component: () =>
      import("@/views/shared/atlasFiles/ListAtlasFiles/ListAtlasFiles.vue"),
    meta: {
      pageTitle: "Documents",
      requireAuth: true,
      allowedRoles: "anyone",
      metaTags: {
        title: "Documents"
      },
      permissions: ["documents:read"]
    }
  },
  {
    path: "/admin/documents/activities",
    component: () =>
      import(
        "@/views/shared/atlasFiles/ListAtlasFiles/Activities/AtlasFilesAllActivities.vue"
      ),
    meta: {
      pageTitle: "Documents Activities",
      requireAuth: true,
      allowedRoles: "admin",
      metaTags: {
        title: "Documents Activities"
      }
    }
  },
  {
    path: "/billing",
    component: () => import("@/views/shared/billing/Billing.vue"),
    meta: {
      pageTitle: "Billing",
      requireAuth: true,
      allowedRoles: [
        "agent",
        "admin",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Billing"
      }
    }
  },
  {
    path: "/adjuster-files",
    component: () => import("@/views/admin/adjusterPortal/AdjusterPortal.vue"),
    meta: {
      pageTitle: "Adjuster Portal",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "adjuster",
        "agencyAdmin",
        "subAdmin",
        "claims"
      ],
      metaTags: {
        title: "Adjuster Portal"
      }
    }
  },
  {
    path: "/agent-reports",
    component: () =>
      import("@/views/admin/agentsReportsPortal/AgentReportsPortal.vue"),
    meta: {
      pageTitle: "Agent Reports",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Agent Reports"
      },
      permissions: ["agent_reports:read"]
    }
  },
  {
    path: "/agent-report/activities",
    component: () =>
      import(
        "@/views/admin/agentsReportsPortal/activities/AgentReportActivity.vue"
      ),
    meta: {
      pageTitle: "Agent Reports Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Agent Reports Activities"
      }
    }
  },
  {
    path: "/documents",
    component: () =>
      import("@/views/shared/atlasFiles/ListAtlasFiles/ListAtlasFiles.vue"),
    meta: {
      pageTitle: "Documents",
      requireAuth: true,
      allowedRoles: "anyone",
      metaTags: {
        title: "Documents"
      },
      permissions: ["documents:read"]
    }
  },
  {
    path: "/loading-preview/:loadingString",
    component: () => import("@/views/public/LoadingPreview.vue"),
    meta: {
      pageTitle: "Loading Preview",
      metaTags: {
        title: "Loading preview"
      },
      requireAuth: false
    }
  },
  {
    path: "/agencies",
    component: () => import("@/views/admin/agencies/Agencies.vue"),
    meta: {
      pageTitle: "Agencies",
      requireAuth: true,
      allowedRoles: ["underwriting", "claims", "billing", "dataEntry"],
      metaTags: {
        title: "Agencies"
      }
    }
  },
  {
    path: "/agencies/:agencyId",
    component: () => import("@/views/admin/agencies/SingleAgency.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/agencies",
          title: "Agencies"
        }
      ],
      pageTitle: "View Agency",
      requireAuth: true,
      allowedRoles: [
        "subAdmin",
        "agencyAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title: "Agency"
      }
    }
  },
  {
    path: "/transactions",
    component: () => import("@/views/admin/transactions/Transactions.vue"),
    meta: {
      pageTitle: "Transactions",
      requireAuth: true,
      allowedRoles: ["billing"],
      metaTags: {
        title: "Transactions"
      }
    }
  },
  {
    path: "/imports",
    component: () =>
      import(
        /* webpackChunkName: "Imports" */ "@/views/admin/imports/ListImports/ListViewImports.vue"
      ),
    meta: {
      pageTitle: "Imports",
      requireAuth: true,
      allowedRoles: ["dataEntry"]
    }
  },
  {
    path: "/imports/:importId",
    component: () =>
      import(
        /* webpackChunkName: "Imports" */ "@/views/admin/imports/SingleImport/SingleImport.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/imports",
          title: "Imports"
        }
      ],
      requireAuth: true,
      allowedRoles: ["dataEntry"]
    }
  },
  {
    path: "/mortgages",
    component: () =>
      import(
        /* webpackChunkName: "ListViewMortgages" */ "@/views/admin/mortgages/ListViewMortgages/ListViewMortgages.vue"
      ),
    meta: {
      pageTitle: "Mortgages",
      requireAuth: true,
      allowedRoles: ["dataEntry"]
    }
  },
  {
    path: "/mortgages/:mortgageId/edit",
    component: () =>
      import(
        /* webpackChunkName: "EditMortgage" */ "@/views/admin/mortgages/EditMortgage.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/mortgages",
          title: "Mortgages"
        }
      ],
      pageTitle: "Edit Mortgage",
      requireAuth: true,
      allowedRoles: ["dataEntry"],
      metaTags: {
        title: "Edit Mortgage",
        description: "Edit Mortgage Details",
        keywords: "Edit Mortgage"
      }
    }
  }
];
