import {
  PayPlanChangeRequestActions,
  PayPlanChangeRequestState,
  PayPlanChangeRequestMutations,
  PayPlanChangeRequestGetters
} from "./types";
import { IPayPlanChangeRequest } from "@/helpers/storageFormBaseInterface";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericDeleteOne,
  GenericUpdateOne,
  GenericGetOne
} from "@/store/helpers/actions";
import PayPlanChangeRequestService from "@/services/payplanchangerequest.service";

export const actions: DefineActions<
  PayPlanChangeRequestActions,
  PayPlanChangeRequestState,
  PayPlanChangeRequestMutations,
  PayPlanChangeRequestGetters
> = {
  createPayPlanChangeRequest: GenericCreateOne<
    PayPlanChangeRequestState,
    PayPlanChangeRequestGetters,
    PayPlanChangeRequestActions,
    PayPlanChangeRequestMutations,
    IPayPlanChangeRequest
  >(PayPlanChangeRequestService, types.SET_ITEM as any),
  getPayPlanChangeRequests: GenericGetMany<
    PayPlanChangeRequestState,
    PayPlanChangeRequestGetters,
    PayPlanChangeRequestActions,
    PayPlanChangeRequestMutations
  >(
    PayPlanChangeRequestService,
    "payPlanChangeRequests",
    types.SET_ITEM as any
  ),
  getPayPlanChangeRequest: GenericGetOne<
    PayPlanChangeRequestState,
    PayPlanChangeRequestGetters,
    PayPlanChangeRequestActions,
    PayPlanChangeRequestMutations
  >(PayPlanChangeRequestService, types.SET_ITEM as any),
  deletePayPlanChangeRequest: GenericDeleteOne<
    PayPlanChangeRequestState,
    PayPlanChangeRequestGetters,
    PayPlanChangeRequestActions,
    PayPlanChangeRequestMutations
  >(PayPlanChangeRequestService, types.DELETE_ITEM as any),
  updatePayPlanChangeRequest: GenericUpdateOne<
    PayPlanChangeRequestState,
    PayPlanChangeRequestGetters,
    PayPlanChangeRequestActions,
    PayPlanChangeRequestMutations,
    { id: string; update: Partial<IPayPlanChangeRequest> }
  >(PayPlanChangeRequestService, types.SET_ITEM as any),
  printPayPlanChangeRequest: async ({ commit }, payload) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await PayPlanChangeRequestService.printPayPlanChangeRequestById(
        payload
      );
      const responseData = response.data;
      if (response && response.data && response.data.changeRequests) {
        commit(types.SET_ITEM, response.data.changeRequests);
      }
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },

  async updatePayPlanChangeRequestsStatus({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await PayPlanChangeRequestService.updateRequestsStatus(
        payload
      );
      const responseData = response.data;
      commit(types.SET_ITEM, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },

  deleteBulkChangeRequests: async ({ commit }, changeRequestsIds) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await PayPlanChangeRequestService.deleteChangeRequestsByIds(
        changeRequestsIds,
        "payPlan"
      );
      return response.data;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  },

  async restorePayPlanChangeRequests({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await PayPlanChangeRequestService.restorePayPlanChangeRequests(
        payload
      );
      return response;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
};
