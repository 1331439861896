import {
  IAtlasPlatform,
  AtlasPlatformActions,
  AtlasPlatformState,
  AtlasPlatformMutations,
  AtlasPlatformGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne,
  GenericMakeRequest
} from "@/store/helpers/actions";
import AtlasPlatformService from "@/services/atlasPlatform.service";

export const actions: DefineActions<
  AtlasPlatformActions,
  AtlasPlatformState,
  AtlasPlatformMutations,
  AtlasPlatformGetters
> = {
  createAtlasPlatform: GenericCreateOne<
    AtlasPlatformState,
    AtlasPlatformGetters,
    AtlasPlatformActions,
    AtlasPlatformMutations,
    IAtlasPlatform
  >(AtlasPlatformService, types.SET_ITEM as any),
  async getAtlasPlatforms({ commit }, query) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const { data } = await AtlasPlatformService.getAtlasPlatforms(query);
      commit(types.SET_STATES, {
        atlasPlatforms: data
      });
      commit(types.SET_STATE, { key: "lastUpdate", value: new Date() });
      return data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  getAtlasPlatform: GenericGetOne<
    AtlasPlatformState,
    AtlasPlatformGetters,
    AtlasPlatformActions,
    AtlasPlatformMutations
  >(AtlasPlatformService, types.SET_ITEM as any),
  deleteAtlasPlatform: GenericDeleteOne<
    AtlasPlatformState,
    AtlasPlatformGetters,
    AtlasPlatformActions,
    AtlasPlatformMutations
  >(AtlasPlatformService, types.DELETE_ITEM as any),
  updateAtlasPlatform: GenericUpdateOne<
    AtlasPlatformState,
    AtlasPlatformGetters,
    AtlasPlatformActions,
    AtlasPlatformMutations,
    { id: string; update: Partial<IAtlasPlatform> }
  >(AtlasPlatformService, types.SET_ITEM as any),
  deleteAtlasPlatforms: GenericMakeRequest<
    AtlasPlatformState,
    AtlasPlatformGetters,
    AtlasPlatformActions,
    AtlasPlatformMutations
  >(AtlasPlatformService.deleteBulkAtlasPlatforms.bind(AtlasPlatformService))
};
