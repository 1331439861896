import { DefineGetters } from "vuex-type-helper";
import {
  BillingChangeRequestGetters,
  BillingChangeRequestState
} from "./types";

export const getters: DefineGetters<
  BillingChangeRequestGetters,
  BillingChangeRequestState
> = {
  getBillingChangeRequestById: (state: BillingChangeRequestState) => (
    billingChangeRequestId: string
  ) =>
    state.billingChangeRequests.find(
      (billingChangeRequest: any) =>
        billingChangeRequest._id === billingChangeRequestId
    ),
  getBillingChangeRequestsFromStore: (state: BillingChangeRequestState) =>
    state.billingChangeRequests,
  getBillingChangeRequestsLinks: (state: BillingChangeRequestState) =>
    state.links,
  getTotalRecords: ({
    links,
    billingChangeRequests
  }: BillingChangeRequestState) =>
    links && links.totalRecords
      ? links.totalRecords
      : billingChangeRequests.length,
  getMaxQuerySize: ({
    links,
    billingChangeRequests
  }: BillingChangeRequestState) =>
    links && links.maxQueryLimit
      ? links.maxQueryLimit
      : billingChangeRequests.length
};
