import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { ImportState, ImportMutations, StateKeys, IImport } from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { getImportDefaults } from "@/helpers/defaultObjectGenerators";
import { defaultState } from "./state";

export const mutations: DefineMutations<ImportMutations, ImportState> = {
  [types.SET_STATE]: GenericSetState<ImportState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<ImportState>(),
  [types.SET_EDIT]: GenericSetEdit<ImportState, string | IImport>(
    "imports",
    getImportDefaults
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<ImportState, keyof IImport>(),
  [types.SET_ITEM]: GenericSetItem<ImportState, IImport>(
    "imports",
    getImportDefaults
  ),
  [types.DELETE_ITEM]: GenericDeleteItem<ImportState>("imports", "_id"),
  [types.CLEAR_IMPORT_STORE]: GenericClearStore<ImportState>(defaultState())
};
