import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { FaqState, FaqGetters, FaqMutations, FaqActions } from "./types";

const namespaced = true;
export const namespace = "faq";

export const {
  mapState: faqMapState,
  mapGetters: faqMapGetters,
  mapMutations: faqMapMutations,
  mapActions: faqMapActions
} = createNamespacedHelpers<FaqState, FaqGetters, FaqMutations, FaqActions>(
  namespace
);

export const faq: Module<FaqState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
