<template>
  <div>
    <ApplicationMobileTopBar />
    <ApplicationSiteHeaderWrap />
    <transition name="fade">
      <div class="site-inner">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import ApplicationSiteHeaderWrap from "@/components/AppRootWrapper/Header/ApplicationSiteHeaderWrap.vue";
import ApplicationMobileTopBar from "@/components/AppRootWrapper/Header/ApplicationMobileTopBar.vue";

export default {
  components: {
    ApplicationSiteHeaderWrap,
    ApplicationMobileTopBar
  }
};
</script>
