
// import RadioButtonGroup from "@/components/FormBuilder/Components/RadioButtonGroup.vue";
import Vue from "vue";
import { isInteger } from "@/helpers/customValidators";
import { claimsMapActions, claimsMapState } from "@/store/modules/claims";
import { Session } from "@/helpers/processSession";
import { authMapState } from "@/store/modules/auth";
import { getCompaniesPrefixes } from "@/helpers/companies";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";

export default Vue.extend({
  // components: { RadioButtonGroup },
  name: "ClaimStatusLookup",
  components: {
    CustomAlert
  },
  data() {
    return {
      options: [
        { label: "Claim number", value: "claimNumber" },
        { label: "Policy number", value: "policyNumber" }
      ],
      lookupMethod: "claimNumber",
      policyPrefix: "",
      policyNumber: "",
      claimNumber: "",
      response: { data: {} },
      policyPrefixes: getCompaniesPrefixes("quickPayPolicyInfo"),
      session: new Session(),
      error: "",
      keepData: false
    };
  },
  methods: {
    ...claimsMapActions(["lookupClaimByNumber", "lookupClaimByPolicyNumber"]),
    async processClaimsLookup(): Promise<void> {
      if (this.lookupMethod === "claimNumber") {
        return this.processClaimsLookupByClaimNumber();
      } else {
        return this.processClaimsLookupByPolicyNumber();
      }
    },
    async processClaimsLookupByClaimNumber(): Promise<void> {
      this.$emit("loadingText", "Fetching claim. Please wait...");
      try {
        this.error = "";
        this.response = { data: {} };
        const response = await this.lookupClaimByNumber({
          site: "ATLAS",
          claimNumber: this.claimNumber
        });
        this.response = response.data;
        if (!this.response) {
          this.error = "Error fetching claims information. Try again later";
        } else {
          this.$emit(
            "sendBackClaimsLookUpByClaimsNumberInformation",
            this.response.data
          );
          this.$emit("sendBackClaimsLookUpByPolicyNumberInformation", {});
          this.error = "";
        }
      } catch (error) {
        this.error = error.message;
        this.resetData();
        this.$bugSnagClient.notify(error);
      }
    },
    async processClaimsLookupByPolicyNumber(): Promise<void> {
      this.$emit("loadingText", "Fetching claim. Please wait...");
      try {
        this.error = "";
        this.response = { data: {} };
        const response = await this.lookupClaimByPolicyNumber({
          site: "ATLAS",
          policyPrefix: this.policyPrefix,
          policyNumber: this.policyNumber
        });
        this.response = response.data;

        if (!this.response) {
          this.error = "Error fetching claims information. Try again later";
        } else {
          this.$emit(
            "sendBackClaimsLookUpByPolicyNumberInformation",
            this.response
          );
          this.$emit("sendBackClaimsLookUpByClaimsNumberInformation", {});
          this.error = "";
        }
      } catch (error) {
        this.error = error.message;
        this.resetData();
        this.$bugSnagClient.notify(error);
      }
    },
    resetData() {
      this.$emit("sendBackClaimsLookUpByClaimsNumberInformation", {});
      this.$emit("sendBackClaimsLookUpByPolicyNumberInformation", {});
      this.policyPrefix = "";
      this.policyNumber = "";
      this.claimNumber = "";
    }
  },
  computed: {
    ...claimsMapState(["makingApiRequest"]),
    ...authMapState(["initData"]),
    hasValidLookupInformation(): boolean {
      if (this.lookupMethod === "claimNumber") {
        return this.hasValidClaimNumber;
      } else if (this.lookupMethod === "policyNumber") {
        return this.hasValidPolicyInfo;
      }
      return false;
    },
    hasValidClaimNumber(): boolean {
      return !!(this.claimNumber.length > 0 && isInteger(this.claimNumber));
    },
    hasValidPolicyInfo(): boolean {
      return (
        !!(this.policyNumber.length > 0 && isInteger(this.policyNumber)) &&
        this.policyPrefix.length > 0
      );
    }
  },
  watch: {
    claimNumber: {
      immediate: true,
      handler() {
        if (!this.claimNumber || this.claimNumber === "") {
          this.$emit("primaryButtonChange", true);
        } else {
          this.$emit("primaryButtonChange", false);
        }
      }
    },
    policyNumber: {
      immediate: true,
      handler() {
        if (!this.policyNumber || this.policyNumber === "") {
          this.$emit("primaryButtonChange", true);
        } else {
          this.$emit("primaryButtonChange", false);
        }
      }
    },
    lookupMethod: {
      immediate: true,
      handler(newValue) {
        if (newValue === "claimNumber") {
          this.policyNumber = "";
        } else if (newValue === "policyNumber") {
          this.claimNumber = "";
        }
        this.$emit("lookupMethod", newValue);
        if (!this.keepData) {
          this.resetData();
        }
        this.keepData = false;
      }
    }
  }
});
