import { ActivityState } from "./types";

export const defaultState = (): ActivityState => {
  return {
    activities: [],
    makingApiRequest: false,
    lastUpdate: new Date(),
    activityLinks: {}
  };
};

export const state = defaultState();
