import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { ChartState, ChartMutations, StateKeys, IChart } from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { defaultState } from "./state";

export const mutations: DefineMutations<ChartMutations, ChartState> = {
  [types.SET_STATE]: GenericSetState<ChartState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<ChartState>(),
  [types.SET_EDIT]: GenericSetEdit<ChartState, string | IChart>(
    "charts",
    (data: any) => ({ ...data })
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<ChartState, keyof IChart>(),
  [types.SET_ITEM]: GenericSetItem<ChartState, IChart>(
    "charts",
    (data: any) => ({ ...data })
  ), //TODO: WRITE A getXDefaultsFunction and pass it here in place of the anonymous function
  [types.DELETE_ITEM]: GenericDeleteItem<ChartState>("charts", "_id"),
  [types.CLEAR_CHART_STORE]: GenericClearStore<ChartState>(defaultState())
};
