import io from "socket.io-client";
import { getPathAPI } from "@/helpers";
import { getAuthorizationBearerStringFor } from "./base.service";

const socket = io(getPathAPI(), {
  path: "/socket",
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionAttempts: Infinity,
  autoConnect: false,
  auth: (cb: Function) => {
    cb({
      token: getAuthorizationBearerStringFor("access")
    });
  }
});

export default socket;
