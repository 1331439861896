import { DefineGetters } from "vuex-type-helper";
import { ClaimsGetters, ClaimsState } from "./types";

export const getters: DefineGetters<ClaimsGetters, ClaimsState> = {
  getClaimById: (state: ClaimsState) => (claimId: string) =>
    state.claims.find((claim: any) => claim.id === claimId),
  getTotalRecords: ({ links, claims }: ClaimsState) =>
    links && links.totalRecords ? links.totalRecords : claims.length,
  getClaimsLinks: (state: ClaimsState) => state.links,
  getLossFormData: (state: ClaimsState) => state.fiservClaimsData
};
