
import Vue from "vue";
import GoogleAutoCompleteModal from "@/components/User/GoogleAutoCompleteModal.vue";

import {
  propertyChangeRequestMapActions,
  propertyChangeRequestMapMutations
} from "@/store/modules/propertychangerequest";

import {
  addressForm,
  addressFormPOBox,
  mailingAddressForm
} from "@/forms/shared/address";
import { FormBlock, IAddressType } from "@/components/FormBuilder/types";
import { useFormSection } from "@/components/FormBuilder/Helpers";
import * as selectOptions from "@/helpers/selectOptions";
import { get } from "lodash";
import { doToUpperCase } from "@/helpers/generalHelpers";
import { FormFieldSectionOptionsRightSlot } from "../FormBuilder/types";

export default Vue.extend({
  name: "UserPhysicalAddressFields",
  components: { GoogleAutoCompleteModal },
  props: {
    value: { type: Object },
    addressType: String,
    label: {
      type: String,
      required: false
    },
    validateAddress: {
      type: Boolean,
      required: false,
      default: true
    },
    allowSyncingWithPhysicalAddress: {
      type: Boolean,
      required: false,
      default: false
    },
    isPOBOXMailingAddress: {
      type: String,
      required: false,
      default: "false"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    useFormChangedVisibly: {
      default: false,
      type: Boolean
    },
    disableState: {
      type: Boolean,
      required: false,
      default: false
    },
    limitToTexas: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mounted() {},
  data() {
    return {
      closeModal: false,
      error: "",
      scrubbingAddressStatus: false,
      addressEnteredInGoogleAutoCompleteField: "",
      possibleGoogleFormattedAddress: {},
      modalName: `googleAutoCompleteModal_${Math.random()
        .toString(36)
        .substring(2, 15)}`
    };
  },
  methods: {
    makingAChange(type: string, data: any) {
      if (this.useFormChangedVisibly && type === "formFieldChanged") return;
      const { value = "", fieldKey } = data;
      const defaultValue = typeof value === "boolean" ? value : "";
      const sanitizedValue = value
        ? this.capitalizeAddressFields(value)
        : defaultValue;
      this.$emit("change", { fieldKey, value: sanitizedValue });
    },
    onClickElement() {
      this.$modal.show(this.modalName);
    },
    ...propertyChangeRequestMapActions(["scrubAddress"]),
    ...propertyChangeRequestMapMutations(["SET_EDIT_PROPERTY_CHANGE_REQUEST"]),
    storeAddressInStorage(_possibleGoogleFormattedAddress: any) {
      if (_possibleGoogleFormattedAddress) {
        this.possibleGoogleFormattedAddress = _possibleGoogleFormattedAddress;
      }
    },
    async doAddressScrubbing() {
      const newAddress =
        this.possibleGoogleFormattedAddress ||
        ({
          formatted_address: this.addressEnteredInGoogleAutoCompleteField
        } as any);

      let {
        formatted_address,
        street_number = "",
        street_abbr = "",
        city = "",
        state = "",
        postal_code = "",
        street = ""
      } = newAddress;
      if (["TX", "TEXAS"].includes(state) || !this.limitToTexas) {
        const splitStreet = street.split(" ");
        const streetTypeNewAddress = splitStreet[splitStreet.length - 1];
        const streetDirectionNewAddress = splitStreet[0];

        const findStreetType = selectOptions.streetTypes.find(
          (streetType: any) =>
            streetType.label.toLowerCase() ===
            streetTypeNewAddress.toLowerCase()
        );
        const streetType = findStreetType ? findStreetType.value : "";

        const findStreetDirection = selectOptions.streetDirections.find(
          (streetDirection: any) =>
            streetDirection.label.toLowerCase() ===
            streetDirectionNewAddress.toLowerCase()
        );
        const streetDirection = findStreetDirection
          ? findStreetDirection.value
          : "";

        let streetName = "";
        if (findStreetType || findStreetDirection) {
          const getStreetName = splitStreet.filter(
            (name: string) =>
              name.toLowerCase() !==
                get(findStreetType, "label")?.toLowerCase() &&
              name.toLowerCase() !==
                get(findStreetDirection, "label")?.toLowerCase()
          );

          streetName = getStreetName.join(" ");
        }

        const googleVerifiedAddress = {
          unitNumber: "",
          streetName: streetName ? streetName : street_abbr,
          houseNumber: street_number,
          streetDirection: streetDirection,
          streetType: streetType,
          city: city,
          state: state,
          zipCode: postal_code
        };
        let address: {};
        this.scrubbingAddressStatus = true;
        try {
          if (this.validateAddress) {
            address = await this.scrubAddress({
              formattedAddress: formatted_address
            });
          } else {
            address = googleVerifiedAddress;
          }
        } catch (error) {
          this.error =
            "Address couldn't be scrubbed. Please check that the address is correct";
          address = googleVerifiedAddress;
          this.$bugSnagClient.notify(error);
        }
        this.possibleGoogleFormattedAddress = {};
        this.scrubbingAddressStatus = false;
        this.setAddressData(address);
        this.$modal.hide(this.modalName);
        if (this.$refs.field_unitNumber) {
          (this.$refs?.field_unitNumber as any).focusFieldRef();
        }
      } else {
        this.error =
          "Sorry, Atlas General Agency's services are only available in Texas";
      }
    },
    setAddressData(address: any) {
      if (address) {
        for (const fieldKey of Object.keys(address)) {
          const fieldValue = address[fieldKey];
          const requiredFields = [
            "houseNumber",
            "streetName",
            "zipCode",
            "city"
          ];
          if (requiredFields.includes(fieldKey) && !fieldValue) {
            // console.log({ fieldKey });
          } else {
            this.$emit("change", {
              fieldKey,
              value: this.capitalizeAddressFields(fieldValue)
            });
          }
        }
      }
    },
    capitalizeAddressFields(value: string) {
      return doToUpperCase(value);
    },
    toggleLookupAddress(): FormFieldSectionOptionsRightSlot | undefined {
      if (
        (this.value?.isSameAsPhysicalAddress &&
          this.addressType === "mailingAddress") ||
        (!this.value?.isSameAsPhysicalAddress && this.value?.isPOBoXFormat)
      ) {
        return undefined;
      }
      return {
        key: "mailingAddress",
        innerText: "| Lookup Address",
        classList: [
          "lookupAddressMarginLeft",
          "cursor-pointer",
          "text-md",
          "text-atlas-blue",
          "hover:font-bold",
          "font-semibold"
        ]
      };
    }
  },
  computed: {
    addressFields(): FormBlock[] {
      const title = this.label || this.title;
      const formToUse =
        this.addressType === "mailingAddress" &&
        this.allowSyncingWithPhysicalAddress &&
        this.isPOBOXMailingAddress === "false"
          ? this.mailingAddressForm
          : this.addressType === "mailingAddress" &&
            this.isPOBOXMailingAddress === "true"
          ? this.poBoxMailingAddressForm
          : addressForm({
              disableState: this.disableState,
              addressType: this.addressType as IAddressType
            });

      return useFormSection(formToUse, {
        title,
        description: `Enter the ${title.toLowerCase()}`,
        rightSlot: this.toggleLookupAddress()
      });
    },
    addressFormPOBoxFields(): FormBlock[] {
      return addressFormPOBox;
    },
    mailingAddressForm(): FormBlock[] {
      let form = mailingAddressForm;
      if (
        this.addressType === "mailingAddress" &&
        this.value &&
        !this.value.isSameAsPhysicalAddress
      ) {
        form = [
          ...form,
          ...addressForm({
            disableState: this.disableState,
            addressType: this.addressType as IAddressType
          })
        ];
      }
      return form;
    },
    poBoxMailingAddressForm(): FormBlock[] {
      return [...addressFormPOBox, ...this.mailingAddressForm];
    },
    title(): string {
      if (this.addressType === "physicalAddress") {
        return "Physical Address";
      } else {
        return "Mailing Address";
      }
    }
  }
});
