import {
  INotification,
  NotificationActions,
  NotificationState,
  NotificationMutations,
  NotificationGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import NotificationService from "@/services/notification.service";
import { getNotificationDefaults } from "@/helpers/defaultObjectGenerators";
import store from "@/store";

export const actions: DefineActions<
  NotificationActions,
  NotificationState,
  NotificationMutations,
  NotificationGetters
> = {
  createNotification: GenericCreateOne<
    NotificationState,
    NotificationGetters,
    NotificationActions,
    NotificationMutations,
    INotification
  >(NotificationService, types.SET_ITEM as any),
  getNotifications: GenericGetMany<
    NotificationState,
    NotificationGetters,
    NotificationActions,
    NotificationMutations
  >(NotificationService, "notifications", types.SET_ITEM as any),
  getNotification: GenericGetOne<
    NotificationState,
    NotificationGetters,
    NotificationActions,
    NotificationMutations
  >(NotificationService, types.SET_ITEM as any, getNotificationDefaults, {
    after: () => store.dispatch("auth/init")
  }),
  deleteNotification: GenericDeleteOne<
    NotificationState,
    NotificationGetters,
    NotificationActions,
    NotificationMutations
  >(NotificationService, types.DELETE_ITEM as any),
  updateNotification: GenericUpdateOne<
    NotificationState,
    NotificationGetters,
    NotificationActions,
    NotificationMutations,
    { id: string; update: Partial<INotification> }
  >(NotificationService, types.SET_ITEM as any),
  async acknowledgeNotification({ commit }, payload) {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await NotificationService.acknowledgeNotification(
        payload
      );
      commit(types.SET_ITEM as any, response.data);
      commit(
        types.SET_STATE as any,
        { key: "editing", value: response.data } as any
      );
      return response.data;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
};
