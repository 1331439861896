import { DefineGetters } from "vuex-type-helper";
import {
  CommunicationGetters,
  CommunicationState,
  ICommunication
} from "./types";

export const getters: DefineGetters<
  CommunicationGetters,
  CommunicationState
> = {
  getTotalRecords: ({ links, communications }: CommunicationState) =>
    links && links.totalRecords ? links.totalRecords : communications.length,
  getMaxQuerySize: ({ links, communications }: CommunicationState) =>
    links && links.maxQueryLimit ? links.maxQueryLimit : communications.length,
  getCommunicationById: (state: CommunicationState) => (
    communicationId: string
  ) =>
    state.communications.find(
      (communication: ICommunication) => communication._id === communicationId
    )
};
