import { Route } from "vue-router";
import store from "@/store";

export const redirectHomeRoute = async (
  toRoute: Route,
  fromRoute: Route,
  next: any
): Promise<void> => {
  const { path } = toRoute;
  const { redirectFrom } = fromRoute.query;

  if (redirectFrom && path !== redirectFrom) {
    if (toRoute.path === "/login") {
      return next();
    }
    if (toRoute.path !== "/") {
      return next(redirectFrom);
    }
  }
  if (!store.getters["auth/isLoggedIn"]) {
    return next();
  }
  const { role } = store.getters["auth/getCurrentUser"];
  if (role === "admin") {
    return next("/admin/dashboard");
  } else {
    return next("/dashboard");
  }
};
