import {
  AppSettingsActions,
  AppSettingsState,
  AppSettingsMutations,
  AppSettingsGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import { IAppSettings } from "@/types";
import { appSettingsService } from "@/services/appSettings.service";
export const actions: DefineActions<
  AppSettingsActions,
  AppSettingsState,
  AppSettingsMutations,
  AppSettingsGetters
> = {
  async createAppSettings({ commit }, payload): Promise<Partial<IAppSettings>> {
    try {
      commit(types.SET_APP_SETTINGS_STATE, {
        key: "loadingCreate",
        value: true
      });
      const createAppSettingsResponse = await appSettingsService.createOne(
        payload
      );
      const appSettingsData = createAppSettingsResponse.data;
      commit(types.SET_APP_SETTINGS, appSettingsData);
      return appSettingsData;
    } finally {
      commit(types.SET_APP_SETTINGS_STATE, {
        key: "loadingCreate",
        value: false
      });
    }
  },
  async getAppSetting(
    { commit },
    appSettingsID: string
  ): Promise<IAppSettings> {
    try {
      commit(types.SET_APP_SETTINGS_STATE, { key: "loadingGet", value: true });
      const getAppSettingsResponse = await appSettingsService.getOne(
        appSettingsID
      );
      const appSettings = getAppSettingsResponse.data;
      commit(types.SET_APP_SETTINGS, appSettings);
      commit(types.SET_APP_SETTINGS_STATE, {
        key: "lastUpdate",
        value: new Date()
      });
      return appSettings;
    } finally {
      commit(types.SET_APP_SETTINGS_STATE, { key: "loadingGet", value: false });
    }
  },
  async getAppSettings(
    { commit },
    { drop_store = false }
  ): Promise<IAppSettings[]> {
    try {
      commit(types.SET_APP_SETTINGS_STATE, { key: "loadingGet", value: true });
      const allAppSettings = await appSettingsService.getAppSettings();
      const appSettings = allAppSettings.data;
      if (drop_store) {
        commit(types.DROP_APP_SETTINGS);
      }
      commit(types.SET_APP_SETTINGS, appSettings);
      commit(types.SET_APP_SETTINGS_LINKS, allAppSettings.links);
      commit(types.SET_APP_SETTINGS_STATE, {
        key: "lastUpdate",
        value: new Date()
      });
      return appSettings;
    } finally {
      commit(types.SET_APP_SETTINGS_STATE, { key: "loadingGet", value: false });
    }
  },
  async updateAppSettings({ commit }, { id, update }): Promise<IAppSettings> {
    try {
      commit(types.SET_APP_SETTINGS_STATE, { key: "lastUpdate", value: true });
      const updateOne = await appSettingsService.updateOne(id, update);
      const appSettings: IAppSettings = updateOne.data;
      commit(types.SET_APP_SETTINGS, appSettings);
      commit(types.SET_APP_SETTINGS_STATE, {
        key: "lastUpdate",
        value: new Date()
      });
      return appSettings;
    } finally {
      commit(types.SET_APP_SETTINGS_STATE, { key: "lastUpdate", value: false });
    }
  },
  async deleteAppSettings(
    { commit },
    appSettingsID: string
  ): Promise<IAppSettings> {
    try {
      commit(types.SET_APP_SETTINGS_STATE, {
        key: "loadingDelete",
        value: true
      });
      const deleteOne = await appSettingsService.deleteOne(appSettingsID);
      const appSettings: IAppSettings = deleteOne.data;
      const removedId = appSettings._id;
      if (removedId) {
        commit(types.REMOVE_APP_SETTINGS, removedId);
      }
      return appSettings;
    } finally {
      commit(types.SET_APP_SETTINGS_STATE, {
        key: "loadingDelete",
        value: false
      });
    }
  }
};
