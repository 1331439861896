import {
  CompaniesActions,
  CompaniesState,
  CompaniesMutations,
  CompaniesGetters,
  ICompany,
  ICompanyUpdatedPayload
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import CompaniesService from "@/services/companies.service";
import {
  GenericCreateOne,
  GenericDeleteOne,
  GenericGetMany,
  GenericUpdateOne
} from "@/store/helpers/actions";

export const actions: DefineActions<
  CompaniesActions,
  CompaniesState,
  CompaniesMutations,
  CompaniesGetters
> = {
  createCompanies: GenericCreateOne<
    CompaniesState,
    CompaniesGetters,
    CompaniesActions,
    CompaniesMutations,
    ICompany
  >(CompaniesService, types.SET_ITEM as any),

  async getOneCompany({ commit }, companyId) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const { data } = await CompaniesService.getOneCompany({ _id: companyId });
      commit(types.SET_ITEM, data);
      commit(types.SET_STATE, { key: "lastUpdate", value: new Date() });
      return data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  // get all companies
  getCompanies: GenericGetMany<
    CompaniesState,
    CompaniesGetters,
    CompaniesActions,
    CompaniesMutations
  >(CompaniesService, "companies", types.SET_ITEM),
  updateCompany: GenericUpdateOne<
    CompaniesState,
    CompaniesGetters,
    CompaniesActions,
    CompaniesMutations,
    ICompanyUpdatedPayload
  >(CompaniesService, types.SET_ITEM),
  deleteCompany: GenericDeleteOne<
    CompaniesState,
    CompaniesGetters,
    CompaniesActions,
    CompaniesMutations
  >(CompaniesService, types.DELETE_ITEM)
};
