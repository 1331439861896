import { AxiosResponse } from "axios";
import { ILinks } from "@/types/interfaces";

export class ResponseWrapper {
  public data: any;
  public success: string;
  public status: number;
  public links: ILinks;
  constructor(response: AxiosResponse) {
    this.data = response.data.response || response.data;
    this.success = response.data.success;
    this.status = response.status;
    this.links = response.data.links;
  }
}
