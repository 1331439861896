export const maybeParseJSON = <T = any>(objOrMaybeJSON: any): T => {
  if (!objOrMaybeJSON) return {} as T;
  if (typeof objOrMaybeJSON === "string") {
    try {
      const result = JSON.parse(objOrMaybeJSON);
      return result as T;
    } catch (error) {
      console.log("maybeParseJSON", (<Error>error).message);
    }
  }
  if (typeof objOrMaybeJSON === "object") {
    return objOrMaybeJSON as T;
  }
  return {} as any;
};
