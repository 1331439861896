import { IQuoteCountyValidation } from "@/store/modules/quoteCountyValidation/types";
import { cloneDeep, defaultsDeep } from "lodash";
import { baseQuoteCountyValidation } from "./defaultObjects";

export function getQuoteCountyValidationsDefaults(
  quoteCountyValidation: Partial<IQuoteCountyValidation>
): IQuoteCountyValidation {
  return defaultsDeep(
    cloneDeep(quoteCountyValidation),
    Object.assign({}, baseQuoteCountyValidation)
  );
}
