import { Route } from "vue-router";

export const setPageMeta = async (
  toRoute: Route,
  _fromRoute: Route,
  next: any
) => {
  try {
    const nearestWithMeta = toRoute.matched
      .slice()
      .reverse()
      .find(r => r.meta && r.meta.metaTags);
    if (nearestWithMeta) {
      document.title = nearestWithMeta.meta.metaTags.title;
    } else {
      document.title = "";
    }
  } catch (e) {
    document.title = "";
  } finally {
    next();
  }
};
