import { AppSettingsState } from "./types";

export const defaultState = (): AppSettingsState => {
  return {
    appSettings: [],
    editing: {},
    loadingGet: false,
    loadingCreate: false,
    loadingDelete: false,
    loadingUpdate: false,
    lastUpdate: new Date(),
    appSettingsLinks: {}
  };
};

export const state = defaultState();
