
import Vue from "vue";

export default Vue.extend({
  name: "ValidationTooltip",
  props: {
    isValid: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false,
      default: ""
    },
    content: {
      type: String,
      required: false,
      default: "Required"
    }
  },
  computed: {
    displayIcon(): string {
      if (!this.isValid) {
        return this.icon ? this.icon : "asterisk";
      } else {
        return "check";
      }
    }
  }
});
