import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  PropertyChangeRequestState,
  PropertyChangeRequestGetters,
  PropertyChangeRequestMutations,
  PropertyChangeRequestActions
} from "./types";

const namespaced = true;
export const namespace = "propertychangerequest";

export const {
  mapState: propertyChangeRequestMapState,
  mapGetters: propertyChangeRequestMapGetters,
  mapMutations: propertyChangeRequestMapMutations,
  mapActions: propertyChangeRequestMapActions
} = createNamespacedHelpers<
  PropertyChangeRequestState,
  PropertyChangeRequestGetters,
  PropertyChangeRequestMutations,
  PropertyChangeRequestActions
>(namespace);

export const propertychangerequest: Module<PropertyChangeRequestState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
