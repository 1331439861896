
import Vue from "vue";

export default Vue.extend({
  inheritAttrs: false,
  name: "color-select",
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    id: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: String,
      required: false,
      default: ""
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      val: ""
    };
  }
});
