import { DefineGetters } from "vuex-type-helper";
import { MortgageGetters, MortgageState, IMortgage } from "./types";

export const getters: DefineGetters<MortgageGetters, MortgageState> = {
  getMortgageById: (state: MortgageState) => (mortgageId: string) =>
    state.mortgages.find((mortgage: IMortgage) => mortgage._id === mortgageId),
  getMortgages: (state: MortgageState) => state.mortgages,
  getLinks: (state: MortgageState) => state.links,
  getTotalRecords: ({ links, mortgages }: MortgageState) =>
    links && links.totalRecords ? links.totalRecords : mortgages.length,
  getEditingMortgage: (state: MortgageState) => state.editing
};
