import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class SettingService extends BaseCollectionService {
  constructor() {
    super("settings");
  }

  public async updateDefaultSettings(
    data: Record<string, any>
  ): Promise<ResponseWrapper> {
    const path = `${this.url}`;
    return await this.request(
      {
        method: "put",
        url: path,
        data
      },
      { useToken: "access" }
    );
  }

  public async updateInfoMessageSettings(
    data: Record<string, any>
  ): Promise<ResponseWrapper> {
    const path = `${this.url}/info-message`;
    return await this.request(
      {
        method: "put",
        url: path,
        data
      },
      { useToken: "access" }
    );
  }
}

export default new SettingService();
