export default [
  {
    path: "/admin/counties",
    component: () =>
      import("@/views/admin/counties/ListCounties/ListCounties.vue"),
    meta: {
      pageTitle: "Counties",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Counties"
      }
    }
  },
  {
    path: "/counties/activities/:countyId",
    component: () => import("@/views/admin/counties/CountiesActivities.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/counties",
          title: "Counties"
        }
      ],
      pageTitle: "Counties Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Counties Activities"
      }
    }
  }
];
