
import Vue from "vue";
import {
  userMapActions,
  userMapMutations,
  userMapGetters
} from "@/store/modules/user";
import VerifyPhone from "@/components/User/VerifyPhone.vue";
import get from "lodash/get";

export default Vue.extend({
  components: {
    VerifyPhone
  },
  props: {
    value: {
      type: [String, Object],
      default: undefined
    },
    label: {
      type: String,
      default: ""
    },
    phoneType: {
      type: String,
      default: ""
    },
    fieldGroupClass: {
      type: String,
      default: "pb-0"
    },
    isSettings: {
      type: Boolean,
      required: true,
      default: false
    },
    required: {
      type: Boolean,
      required: true
    }
  },
  created() {
    if (this.value[this.phoneType].trim().length === 0) {
      this.isNew = true;
    }
  },
  data() {
    return {
      dialogVisible: false,
      verificationCode: "",
      error: "",
      setVerified: true,
      phone: "",
      editPhone: false,
      isNew: false,
      modalName: `editingPhoneNumberModal-${Math.random()
        .toString(36)
        .substring(2, 15)}`
    };
  },
  methods: {
    ...userMapActions(["requestVerifyPhoneCode", "verifyPhone"]),
    ...userMapMutations(["SET_EDIT_USER"]),
    async enableField(): Promise<void> {
      this.setVerified = false;
      this.$modal.hide(this.modalName);
    },
    async editDialog(): Promise<void> {
      if (this.editPhone === false && this.verified && !this.isNew) {
        this.$modal.show(this.modalName);
      }
      this.editPhone = true;
    },
    onFieldChange(): void {
      if (!this.phone && this.value) {
        this.value[`${this.phoneType}Verified`]["verified"] = false;
        this.value[`${this.phoneType}Verified`]["resendCodeDate"] = "";
      }

      const update = { ...this.value, [this.phoneType]: this.phone };
      this.$emit("input", update);
    },
    setVerify(value: any) {
      this.setVerified = value;
    },
    setEditPhone(value: any) {
      this.editPhone = value[this.phoneType];
    }
  },
  watch: {
    value: {
      handler(v) {
        this.phone = v ? v[this.phoneType] : "";
      },
      immediate: true
    }
  },
  computed: {
    ...userMapGetters(["getEditingUser", "getUserById"]),
    disableField(): any {
      return this.verified && this.setVerified && !this.isNew ? true : false;
    },
    saved(): any {
      if (!this.getEditingUser) return {};
      const { _id } = this.getEditingUser;
      if (_id) {
        return this.getUserById(_id);
      }
      return {};
    },
    editingField(): boolean {
      return this.getEditingUser &&
        this.getEditingUser["phone"] &&
        this.saved["phone"]
        ? get(this.getEditingUser.phone, this.phoneType) !==
            get(this.saved.phone, this.phoneType)
        : false;
    },
    verified(): boolean {
      if (typeof this.value === "object") {
        return this.value[`${this.phoneType}Verified`]["verified"];
      }
      return false;
    }
  }
});
