import * as types from "@/store/mutation-types";
import { InterfaceMap } from "@/store/helpers";
import { ILinks } from "@/types";
import { IActionQueryPayload } from "@/store/types";

export interface ImportState {
  editing: any; //TODO: TYPE ME PLEASE?.
  makingApiRequest: boolean;
  lastUpdate: Date;
  imports: any[]; //TODO: TYPE ME TOO PLEASE!
  links: ILinks | null;
}

export type StateKeys = keyof ImportState;

export interface ISetStatePayload {
  key: StateKeys;
  value: any;
}

export interface ImportGetters {
  getTotalRecords: number;
  getImportById: (id: string) => IImport;
}

export type ImportType = "users" | "mortgages" | "endorsements";
export const ImportTypeArr: ImportType[] = [
  "users",
  "mortgages",
  "endorsements"
];

export type ImportStatus =
  | "draft"
  | "ready for processing"
  | "processing"
  | "complete"
  | "error";

export const ImportStatusArr: ImportStatus[] = [
  "draft",
  "ready for processing",
  "processing",
  "complete",
  "error"
];

export interface IImportHeadersMetadata {
  matched: boolean;
  header: string;
  preview: any[] | string;
  property: string;
  defaultValue?: any;
}

export interface IImportResult {
  totalRowsProcessed: number;
  totalRowsWithErrors: number;
  totalRowsAlreadyExisting: number;
  errors: string[];
  createdIds: string[];
  successMessages?: string[];
}

export interface IImport {
  _id: string;
  type: ImportType;
  status: ImportStatus;
  deleted: boolean;
  documentId: string;
  createdBy: string;
  name: string;
  result: IImportResult;
  importHeadersMetadata: IImportHeadersMetadata[];

  createdOn: Date;
  modifiedOn: Date;
}

export interface ISetIImportEditField {}

export interface ImportMutations {
  [types.SET_STATE]: ISetStatePayload;
  [types.SET_STATES]: Partial<InterfaceMap<ImportState>>;
  [types.SET_ITEM]: IImport | IImport[];
  [types.SET_EDIT]: string | IImport;
  [types.SET_EDIT_FIELD]: { key: keyof IImport; value: any };
  [types.DELETE_ITEM]: string;
  [types.CLEAR_IMPORT_STORE]: ImportState;
}

export interface ImportActions {
  createImport: any; //TYPE ME
  updateImport: { id: string; update: Partial<IImport> }; //TYPE ME
  getImport: string;
  getImports: IActionQueryPayload;
  deleteImport: string;
  completeImport: string;
}
