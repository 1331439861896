import { DefineActions } from "vuex-type-helper";
import {
  AtlasFileCategoryActions,
  AtlasFileCategoryGetters,
  AtlasFileCategoryMutations,
  AtlasFileCategoryState,
  IAtlasFileCategory
} from "./types";
import {
  GenericGetMany,
  GenericCreateOne,
  GenericUpdateOne,
  GenericDeleteOne,
  GenericGetOne
} from "../../helpers/actions";
import atlasFileCategoryService from "@/services/atlasFileCategory.service";
import * as types from "@/store/mutation-types";

export const actions: DefineActions<
  AtlasFileCategoryActions,
  AtlasFileCategoryState,
  AtlasFileCategoryMutations,
  AtlasFileCategoryGetters
> = {
  getAtlasFileCategories: GenericGetMany<
    AtlasFileCategoryState,
    AtlasFileCategoryGetters,
    AtlasFileCategoryActions,
    AtlasFileCategoryMutations
  >(atlasFileCategoryService, "atlasFileCategories", types.SET_ITEM as any),
  createAtlasFileCategory: GenericCreateOne<
    AtlasFileCategoryState,
    AtlasFileCategoryGetters,
    AtlasFileCategoryActions,
    AtlasFileCategoryMutations,
    IAtlasFileCategory
  >(atlasFileCategoryService, types.SET_ITEM as any),
  updateAtlasFileCategory: GenericUpdateOne<
    AtlasFileCategoryState,
    AtlasFileCategoryGetters,
    AtlasFileCategoryActions,
    AtlasFileCategoryMutations,
    { id: string; update: Partial<IAtlasFileCategory> }
  >(atlasFileCategoryService, types.SET_ITEM as any),
  deleteAtlasFileCategory: GenericDeleteOne<
    AtlasFileCategoryState,
    AtlasFileCategoryGetters,
    AtlasFileCategoryActions,
    AtlasFileCategoryMutations
  >(atlasFileCategoryService, types.DELETE_ITEM as any),
  getOneAtlasFileCategory: GenericGetOne<
    AtlasFileCategoryState,
    AtlasFileCategoryGetters,
    AtlasFileCategoryActions,
    AtlasFileCategoryMutations
  >(atlasFileCategoryService, types.SET_ITEM as any)
};
