import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  alpha,
  alphaNum,
  integer,
  decimal,
  email
} from "vuelidate/lib/validators";

export function getVuelidateValidationFunction(validation: {
  [key: string]: any;
}): Function {
  const val = validation.value;
  const validationParam: { [key: string]: any } = {};
  switch (validation.method) {
    case "required":
      return required;
    case "minLength":
      return minLength(val);
    case "maxLength":
      return maxLength(val);
    case "minValue":
      return minValue(val);
    case "maxValue":
      return maxValue(val);
    case "integer":
      return (validationParam["integer"] = integer);
    case "alpha":
      return alpha;
    case "alphaNum":
      return alphaNum;
    case "decimal":
      return decimal;
    case "email":
      return (theEmail: string) =>
        theEmail ? email(theEmail.toLowerCase()) : theEmail;
    case "custom":
      return validation.customValidator;
    default:
      throw new Error("Unsupported vuelidate method");
  }
}

export function checkVuelidateValidation(
  validations: any[],
  value: any
): { isValid: boolean; error: string } {
  for (const validation of validations) {
    const vuelidateMethod = getVuelidateValidationFunction(validation);
    if (!vuelidateMethod(value)) {
      return {
        isValid: false,
        error: validation.error || getDefaultValidationErrorMessage(validation)
      };
    }
  }
  return { isValid: true, error: "" };
}

export function getDefaultValidationErrorMessage(validation: any) {
  const { method, value } = validation;
  const defaultMessages: { [key: string]: any } = {
    email: () => "Invalid email address",
    required: () => "Text Field is required",
    minLength: (val: any) => `Minimum length is ${val}`,
    maxLength: (val: any) => `Maximum length is ${val}`,
    minValue: (val: any) => `Minimum Value is ${val}`,
    maxValue: (val: any) => `Maximum Value is ${val}`,
    alpha: () => "Only alphabetical characters are allowed",
    alphaNum: () => "Only alpha numerics are allowed",
    decimal: () => "Only decimals",
    integer: () => "Only Integers",
    custom: () => "Error"
  };
  return defaultMessages[method](value);
}
