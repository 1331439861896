import { DefineGetters } from "vuex-type-helper";
import { AdjusterfileGetters, AdjusterfileState } from "./types";

export const getters: DefineGetters<AdjusterfileGetters, AdjusterfileState> = {
  adjusterfiles(state: AdjusterfileState) {
    return state.adjusterfiles;
  },
  getTotalRecords: ({ links, adjusterfiles }: AdjusterfileState) =>
    links && links.totalRecords ? links.totalRecords : adjusterfiles.length,
  getAdjusterLinks: ({ links }) => links
};
