import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  QuickPayState,
  QuickPayGetters,
  QuickPayMutations,
  QuickPayActions
} from "./types";

const namespaced = true;
export const namespace = "quickpay";

export const {
  mapState: quickpayMapState,
  mapGetters: quickpayMapGetters,
  mapMutations: quickpayMapMutations,
  mapActions: quickpayMapActions
} = createNamespacedHelpers<
  QuickPayState,
  QuickPayGetters,
  QuickPayMutations,
  QuickPayActions
>(namespace);

export const quickpay: Module<QuickPayState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
