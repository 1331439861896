
import { userMapActions } from "@/store/modules/user";
import { getPlatformNameFromAgencyCode } from "@/helpers/getPlatformNameFromAgencyCode";
import Vue from "vue";
import { authMapMutations } from "@/store/modules/auth";
export default Vue.extend({
  data() {
    return {
      agentCode: this.$getCurrentUser?.agentCode,
      error: ""
    };
  },
  methods: {
    ...userMapActions(["switchUserAgencyCode"]),
    ...authMapMutations(["SET_STATE"]),
    showAlertModal() {
      this.$modal.show("show-agency-switch-alert");
      this.$emit("agencyCodeSwitching", {
        updateUserAgentCode: this.updateUserAgentCode
      });
      this.$modal.hide("switch-agent-code-modal");
    },
    async updateUserAgentCode(): Promise<void> {
      try {
        await this.switchUserAgencyCode({
          userId: this.$getCurrentUser?._id,
          agentCode: this.agentCode
        });
        this.SET_STATE({
          key: "user.agentCode" as any,
          value: this.agentCode
        });
        this.$appNotifySuccess("Agent Code Updated Successfully");
      } catch (error) {
        this.error = (error as any).message;
        this.$appNotifyError(this.error);
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    agentCodeOptions(): any[] {
      const agentCodes = this.$getCurrentUser?.uniqueAllAgencyCodes;
      return agentCodes.map((agentCode: string) => ({
        label: getPlatformNameFromAgencyCode(agentCode),
        agentCode
      }));
    }
  }
});
