import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  AgencyState,
  AgencyGetters,
  AgencyMutations,
  AgencyActions
} from "./types";

const namespaced = true;
export const namespace = "agency";

export const {
  mapState: agencyMapState,
  mapGetters: agencyMapGetters,
  mapMutations: agencyMapMutations,
  mapActions: agencyMapActions
} = createNamespacedHelpers<
  AgencyState,
  AgencyGetters,
  AgencyMutations,
  AgencyActions
>(namespace);

export const agency: Module<AgencyState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
