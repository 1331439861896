import BaseCollectionService from "./base.collection.service";

class PolicySignService extends BaseCollectionService {
  constructor() {
    super("policySign");
  }

  async getAllSignedPolicies(query: Record<string, any>) {
    return await this.request(
      {
        method: "get",
        url: "/signed-policies",
        params: query
      },
      { useToken: "access" }
    );
  }
}

export default new PolicySignService();
