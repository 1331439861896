import {
  FormBlock,
  FormBlockHTML,
  FormFieldSectionOptionsRightSlot
} from "../../types";

export const __getRightSlot = (
  rightSlot: FormFieldSectionOptionsRightSlot
): FormBlockHTML[] => {
  return [
    {
      element: "html",
      tagName: "div",

      children: [
        {
          element: "html",
          tagName: "div",
          id: rightSlot.key,
          innerText: rightSlot.innerText,
          classList: rightSlot.classList
        }
      ]
    }
  ];
};

const __getTitleAndDescription = (
  title: string,
  description: string
): FormBlockHTML[] => {
  const output = [];

  const titleBlock: FormBlockHTML = {
    element: "html",
    tagName: "h3",
    innerText: title,
    classList: ["text-xl", "leading-6", "font-medium", "text-gray-900"]
  };

  const descriptionBlock: FormBlockHTML = {
    element: "html",
    tagName: "p",
    innerText: description,
    classList: ["mt-1", "text-base", "text-gray-500"]
  };

  if (title) {
    output.push(titleBlock);
  }

  if (description) {
    output.push(descriptionBlock);
  }

  return output;
};

export const useTitle = (
  title: string,
  description: string,
  rightSlot: FormFieldSectionOptionsRightSlot,
  children: FormBlock[] = []
): any => {
  const sectionHeader: FormBlockHTML = {
    element: "html",
    tagName: "div",
    classList: ["mb-8 md:mb-12 flex justify-between border-b  border-gray-50"],
    children: []
  };

  const titleWrapper: FormBlockHTML = {
    element: "html",
    tagName: "div",
    classList: [],
    children: __getTitleAndDescription(title, description)
  };

  sectionHeader.children?.push(titleWrapper);

  if (rightSlot && rightSlot.key) {
    const rightWrapper: FormBlockHTML = {
      element: "html",
      tagName: "div",
      classList: [],
      children: __getRightSlot(rightSlot)
    };
    sectionHeader.children?.push(rightWrapper);
  }

  return [
    {
      element: "section",
      tagName: "div",
      children: [sectionHeader, ...children]
    }
  ];
};
