import { DefineGetters } from "vuex-type-helper";
import { AgencyGetters, AgencyState, IAgency } from "./types";

export const getters: DefineGetters<AgencyGetters, AgencyState> = {
  getAgencyById: (state: AgencyState) => (agencyId: string) =>
    state.agencies.find((agency: IAgency) => agency._id === agencyId),
  getEditingAgency: (state: AgencyState) => state.editing,
  agencies: (state: AgencyState) => state.agencies,
  getAgencyLinks: (state: AgencyState) => state.links,
  getTotalRecords: ({ links, agencies }: AgencyState) =>
    links && links.totalRecords ? links.totalRecords : agencies.length,
  getAgencyByAgencyCode: (state: AgencyState) => (agencyCode: string) =>
    state.agencies.find((agency: IAgency) => agency.agencyCode === agencyCode)
};
