import { RouteConfig } from "vue-router";
import * as singleUserChildren from "./children.users";
import communicationRoutes from "./communication";
import countyRoutes from "./county";
import atlasFileRoutes from "./atlasFiles";
import importsRoutes from "./imports";
import changeRequests from "./changeRequests";
import mortgageRoutes from "./mortgages";

const agentApplicationRequestTitle = "Agent Applications";
const agentApplicationRequestPath = "/admin/agent-applications";
const adminRoutes: RouteConfig[] = [
  ...mortgageRoutes,
  ...importsRoutes,
  ...atlasFileRoutes,
  ...communicationRoutes,
  ...countyRoutes,
  ...changeRequests,
  {
    path: "/admin/companies",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/views/admin/companies/Companies.vue"
      ),
    meta: {
      pageTitle: "Companies",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Companies"
      }
    }
  },
  {
    path: "/admin/accreditedBuilder",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/views/admin/accreditedBuilder/AccreditedBuilder.vue"
      ),
    meta: {
      pageTitle: "Accredited Builder",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Accredited Builder"
      }
    }
  },
  {
    path: agentApplicationRequestPath,
    component: () =>
      import(
        /* webpackChunkName: "AgentApplications" */ "@/views/admin/agentApplications/AgentApplications.vue"
      ),
    meta: {
      pageTitle: agentApplicationRequestTitle,
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: agentApplicationRequestTitle
      }
    }
  },
  {
    path: "/admin/set-emergency",
    component: () =>
      import(
        /* webpackChunkName: "setEmergency" */ "@/components/User/MaintenanceModeEditor.vue"
      ),
    meta: {
      breadcrumbs: [],
      pageTitle: "Turn On Emergency Maintenance Mode",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Turn On Emergency Maintenance Mode"
      }
    }
  },
  {
    path: "/admin/agent_application/:agentApplicationID",
    component: () =>
      import(
        /* webpackChunkName: "AgentApplications" */ "@/views/admin/agentApplications/agentApplicationDetails.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: agentApplicationRequestPath,
          title: agentApplicationRequestTitle
        }
      ],
      pageTitle: "Agent Application Details",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Agent Application Details"
      }
    }
  },
  {
    path: "/admin/companies/create",
    component: () =>
      import(
        /* webpackChunkName: "CreateCompany" */ "@/views/admin/companies/CreateCompany.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/companies",
          title: "Companies"
        }
      ],
      pageTitle: "Add Company",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Add Company"
      }
    }
  },
  {
    path: "/admin/accreditedBuilder/create",
    component: () =>
      import(
        /* webpackChunkName: "AccreditedBuilder" */ "@/views/admin/accreditedBuilder/CreateAccreditedBuilder.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/accreditedBuilder",
          title: "Accredited Builder"
        }
      ],
      pageTitle: "Add Accredited Builder",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Add Accredited Builder"
      }
    }
  },
  {
    path: "/admin/:accreditedBuilderId/accreditedBuilder",
    component: () =>
      import(
        /* webpackChunkName: "AccreditedBuilder" */ "@/views/admin/accreditedBuilder/SingleAccreditedBuilder.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/accreditedBuilder",
          title: "Accredited Builder"
        }
      ],
      pageTitle: "Edit Accredited Builder",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Edit Accredited Builder"
      }
    }
  },
  {
    path: "/admin/accreditedBuilder/activities",
    component: () =>
      import(
        "@/views/admin/accreditedBuilder/AccreditedBuilderActivities/AccreditedBuilderAllActivities.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/accreditedBuilder",
          title: "Accredited Builder"
        }
      ],
      pageTitle: "Accredited Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Accredited Activities"
      }
    }
  },
  {
    path: "/admin/quoteCountyValidations/activities",
    component: () =>
      import(
        "@/views/admin/quoteCountyValidations/QuoteCountyValidationsActivities/QuoteCountyValidationsAllActivities.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/quoteCountyValidations",
          title: "Quote Validations"
        }
      ],
      pageTitle: "Quote Validation Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Quote Validation Activities"
      }
    }
  },
  {
    path: "/admin/companies/:companyId",
    component: () =>
      import(
        /* webpackChunkName: "SingleCompany" */ "@/views/admin/companies/SingleCompany.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/companies",
          title: "Companies"
        }
      ],
      pageTitle: "Company:",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Company #"
      }
    }
  },
  {
    path: "/admin/dashboard",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/views/admin/dashboard/Dashboard.vue"
      ),
    meta: {
      pageTitle: "Dashboard",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Dashboard"
      }
    }
  },
  {
    path: "/admin/users",
    component: () => import("@/views/shared/users/ListUsers.vue"),
    meta: {
      pageTitle: "Users",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Users"
      }
    }
  },
  {
    path: "/admin/users/new",
    component: () => import("@/views/shared/users/AddNewUser.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/users",
          title: "Users"
        }
      ],
      pageTitle: "Add New User",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Add New User"
      }
    }
  },
  {
    path: "/admin/users/:user_id",
    component: () => import("@/views/shared/users/SingleUser.vue"),
    props: (route: any) => ({
      userId: route.params.user_id
    }),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/users",
          title: "Users"
        }
      ],
      pageTitle: "Edit User",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Edit User"
      }
    },
    children: [singleUserChildren.UserEdit, singleUserChildren.UserActivity]
  },
  {
    path: "/admin/profile",
    component: () => import("@/views/user/Profile.vue"),
    meta: {
      pageTitle: "Profile",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Profile"
      }
    }
  },
  {
    path: "/admin/agencies",
    component: () => import("@/views/admin/agencies/Agencies.vue"),
    meta: {
      pageTitle: "Agencies",
      requireAuth: true,
      allowedRoles: ["admin", "underwriting"],
      metaTags: {
        title: "Agencies"
      }
    }
  },
  {
    path: "/admin/agencies/create",
    component: () => import("@/views/admin/agencies/CreateNewAgency.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/agencies",
          title: "Agencies"
        }
      ],
      pageTitle: "Create Agency",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Create Agency"
      }
    }
  },
  {
    path: "/admin/agencies/:agencyId/edit",
    component: () => import("@/views/admin/agencies/EditAgency.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/agencies",
          title: "Agencies"
        }
      ],
      pageTitle: "Edit Agency",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Edit Agency"
      }
    }
  },
  {
    path: "/admin/agencies/:agencyId",
    component: () => import("@/views/admin/agencies/SingleAgency.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/agencies",
          title: "Agencies"
        }
      ],
      pageTitle: "View Agency",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Agency"
      }
    }
  },
  {
    path: "/admin/text-multi-step-form",
    component: () =>
      import("@/components/FormBuilder/examples/MultiStepForm.vue"),
    meta: {
      pageTitle: "Test Multi Step Form",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Test Multi Step Form"
      }
    }
  },
  {
    path: "/admin/settings",
    component: () => import("@/views/admin/settings/Settings.vue"),
    meta: {
      pageTitle: "Settings",
      requireAuth: true,
      allowedRoles: ["admin"]
    }
  },
  {
    path: "/admin/transactions",
    component: () => import("@/views/admin/transactions/Transactions.vue"),
    meta: {
      pageTitle: "Transactions",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Transactions"
      }
    }
  },
  {
    path: "/admin/transactions/activities",
    component: () =>
      import(
        "@/views/admin/transactions/activities/AllTransactionActivities.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/transactions",
          title: "Transactions"
        }
      ],
      pageTitle: "Transactions Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Transactions Activities"
      }
    }
  },
  {
    path: "/admin/upload/agent-report",
    component: () =>
      import("@/views/admin/agentsReportsPortal/UploadAgentReports.vue"),
    meta: {
      pageTitle: "Uploaded Agent Reports",
      requireAuth: true,
      allowedRoles: ["admin"],
      breadcrumbs: [
        {
          link: "/agent-reports",
          title: "Agent Reports"
        }
      ]
    }
  },
  {
    path: "/admin/endorsements",
    component: () => import("@/views/admin/endorsements/Endorsements.vue"),
    meta: {
      pageTitle: "Endorsements",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Endorsements"
      }
    }
  },
  {
    path: "/admin/ppc",
    component: () => import("@/views/admin/gbsHelp/GbsHelp.vue"),
    meta: {
      pageTitle: "PPC",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "PPC"
      }
    }
  },
  {
    path: "/admin/underwriting-questions",
    component: () =>
      import("@/views/admin/underWritingQuestions/UnderWritingQuestions.vue"),
    meta: {
      pageTitle: "Underwriting Questions",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Underwriting Questions"
      }
    }
  },
  {
    path: "/admin/endorsements/create",
    component: () => import("@/views/admin/endorsements/CreateEndorsement.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/endorsements",
          title: "Endorsements"
        }
      ],
      pageTitle: "Create Endorsement",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Create Endorsement"
      }
    }
  },
  {
    path: "/admin/ppc/create",
    component: () => import("@/views/admin/gbsHelp/GbsHelpAddEditSingle.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/ppc",
          title: "PPC"
        }
      ],
      pageTitle: "Add PPC",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Add PPC"
      }
    }
  },
  {
    path: "/admin/underwriting-questions/create",
    component: () =>
      import("@/views/admin/underWritingQuestions/AddUnderWritingQuestion.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/underwriting-questions/",
          title: "Underwriting Questions"
        }
      ],
      pageTitle: "Add Underwriting Question",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Add Underwriting Question"
      }
    }
  },
  {
    path: "/admin/underwriting-questions/:underWritingQuestionId/edit",
    component: () =>
      import(
        "@/views/admin/underWritingQuestions/EditUnderWritingQuestion.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/underwriting-questions/",
          title: "Underwriting Questions"
        }
      ],
      pageTitle: "Edit Underwriting Question",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Edit Underwriting Question"
      }
    }
  },
  {
    path: "/admin/endorsements/:endorsementId/edit",
    component: () => import("@/views/admin/endorsements/EditEndorsement.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/endorsements",
          title: "Endorsements"
        }
      ],
      pageTitle: "Edit Endorsement",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Edit Endorsement"
      }
    }
  },
  {
    path: "/admin/ppc/:id",
    component: () => import("@/views/admin/gbsHelp/GbsHelpAddEditSingle.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/ppc",
          title: "PPC"
        }
      ],
      pageTitle: "Edit PPC",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Edit PPC"
      }
    }
  },
  {
    path: "/quotes/:quoteId/activities",
    component: () =>
      import("@/views/shared/quotes/QuotesActivities/QuotesActivities.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/quotes",
          title: "Quotes"
        }
      ],
      pageTitle: "Quote Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Quote Activities"
      }
    }
  },
  {
    path: "/admin/endorsements/activities",
    component: () =>
      import(
        "@/views/admin/endorsements/EndorsementActivities/EndorsementActivities.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/endorsements",
          title: "Endorsements"
        }
      ],
      pageTitle: "Endorsement Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Endorsement Activities"
      }
    }
  },
  {
    path: "/admin/underwriting-question/activities",
    component: () =>
      import(
        "@/views/admin/underWritingQuestions/UnderWritingQuestionsActivities/UnderWritingQuestionsActivities.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/endorsements",
          title: "UnderWritingQuestions"
        }
      ],
      pageTitle: "UnderWriting Question Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "UnderWriting Question Activities"
      }
    }
  },
  {
    path: "/admin/counties/activities",
    component: () =>
      import("@/views/admin/counties/CountyActivities/CountyActivities.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/counties",
          title: "Counties"
        }
      ],
      pageTitle: "County Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "County Activities"
      }
    }
  },
  {
    path: "/admin/users-activities",
    component: () =>
      import("@/views/shared/users/UserActivities/UserActivities.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/users",
          title: "Users"
        }
      ],
      pageTitle: "User Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "User Activities"
      }
    }
  },
  {
    path: "/admin/claims-activities",
    component: () =>
      import("@/views/shared/lossForm/ClaimsListView/ClaimsActivities.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/claims",
          title: "Claims"
        }
      ],
      pageTitle: "Claims Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Claims Activities"
      }
    }
  },
  {
    path: "/admin/quotes/activities",
    component: () =>
      import("@/views/shared/quotes/QuotesActivities/QuotesAllActivities.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/quotes",
          title: "Quotes"
        }
      ],
      pageTitle: "Quote Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Quote Activities"
      }
    }
  },
  {
    path: "/admin/propertyChangeRequest/activities",
    component: () =>
      import(
        "@/views/shared/PropertyChangeRequest/PropertyChangeRequestActivities/PropertyChangeRequestActivities.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/billingchangerequests",
          title: "Policy Change Requests"
        }
      ],
      pageTitle: "Policy Change Request Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Policy Change Request Activities"
      }
    }
  },
  {
    path: "/admin/billingchangerequests/activities",
    component: () =>
      import(
        "@/views/shared/BillingChangeRequest/BillingChangeRequestActivities/BillingChangeRequestActivities.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/propertychangerequests",
          title: "Billing Change Requests"
        }
      ],
      pageTitle: "Billing Change Request Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Billing Change Request Activities"
      }
    }
  },
  {
    path: "/admin/payplanchangerequests/activities",
    component: () =>
      import(
        "@/views/shared/PayPlanChangeRequest/PayPlanActivities/PayPlanActivities.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/change-requests",
          title: "Change Requests"
        },
        {
          link: "/payplanchangerequests",
          title: "Pay Plan Change Requests"
        }
      ],
      pageTitle: "Pay Plan Change Request Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Pay Plan Change Request Activities"
      }
    }
  },
  {
    path: "/admin/activities/ppc",
    component: () => import("@/views/admin/gbsHelp/GbsHelpActivities.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/ppc",
          title: "PPC"
        }
      ],
      pageTitle: "PPC Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "PPC Activities"
      }
    }
  },
  {
    path: "/admin/activities/ppc/viewImportData",
    component: () => import("@/views/admin/gbsHelp/GbsHelpViewBulkImport.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/ppc",
          title: "PPC"
        },
        {
          link: "/admin/activities/ppc",
          title: "PPC Activities"
        }
      ],
      pageTitle: "Imported PPC Data",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Imported PPC Data"
      }
    }
  },
  {
    path: "/admin/quoteCountyValidations/create",
    component: () =>
      import("@/views/admin/quoteCountyValidations/CountyValidation.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/quoteCountyValidations",
          title: "Quote Validations"
        }
      ],
      pageTitle: "Create Quote Validation",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Create Quote Validation"
      }
    }
  },
  {
    path: "/admin/quoteCountyValidations",
    component: () =>
      import("@/views/admin/quoteCountyValidations/CountyValidationList.vue"),
    meta: {
      pageTitle: "Quote Validations",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Quote Validations"
      }
    }
  },
  {
    path: "/admin/quoteCountyValidation/:quoteCountyValidationId",
    component: () =>
      import("@/views/admin/quoteCountyValidations/EditCountyValidation.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/quoteCountyValidations",
          title: "Quote Validations"
        }
      ],
      pageTitle: "Update Quote Validation",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Update Quote Validation"
      }
    }
  },
  {
    path: "/admin/blocking",
    component: () => import("@/views/admin/blocking/Blocking.vue"),
    meta: {
      breadcrumbs: [],
      pageTitle: "Restrict access",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Restrict access"
      }
    }
  },
  {
    path: "/admin/faq/create",
    component: () => import("@/views/admin/faq/CreateFaq.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/faqs",
          title: "FAQs"
        }
      ],
      pageTitle: "Create FAQ",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Create FAQ"
      }
    }
  },
  {
    path: "/admin/faqs",
    component: () => import("@/views/admin/faq/FaqListView.vue"),
    meta: {
      pageTitle: "FAQs",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "FAQs"
      }
    }
  },
  {
    path: "/admin/faq/:faqId",
    component: () => import("@/views/admin/faq/EditFaq.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/faqs",
          title: "FAQs"
        }
      ],
      pageTitle: "Update FAQ",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Update FAQ"
      }
    }
  },
  {
    path: "/admin/faqs/activities",
    component: () =>
      import("@/views/admin/faq/Activities/FAQAllActivities.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/faqs",
          title: "FAQs"
        }
      ],
      pageTitle: "FAQs",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "FAQs"
      }
    }
  },
  {
    path: "/admin/atlasPlatform/create",
    component: () =>
      import("@/views/admin/atlasPlatform/CreateAtlasPlatform.vue"),
    meta: {
      pageTitle: "Atlas Platform",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Create Atlas Platform"
      }
    }
  },
  {
    path: "/admin/atlasPlatforms",
    component: () =>
      import("@/views/admin/atlasPlatform/ListViewAtlasPlatform.vue"),
    meta: {
      pageTitle: "Atlas Platforms",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Atlas Platforms"
      }
    }
  },
  {
    path: "/admin/:atlasPlatformId/atlasPlatform",
    component: () =>
      import("@/views/admin/atlasPlatform/UpdateAtlasPlatform.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/atlasPlatforms",
          title: "Atlas Platform"
        }
      ],
      pageTitle: "Update Atlas Platform",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Update Atlas Platform"
      }
    }
  },
  {
    path: "/admin/atlasPlatforms/activities",
    component: () =>
      import(
        "@/views/admin/atlasPlatform/Activities/AtlasPlatformAllActivities.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/atlasPlatforms",
          title: "Atlas Platforms Activities"
        }
      ],
      pageTitle: "Atlas Platforms Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Atlas Platforms Activities"
      }
    }
  },
  {
    path: "/admin/signed-policies",
    component: () =>
      import("@/views/admin/policySign/SignedPoliciesListView.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/signed-policies",
          title: "Signed Policies"
        }
      ],
      pageTitle: "Signed Policies",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Signed Policies"
      }
    }
  },
  {
    path: "/admin/policy-sign/activities",
    component: () =>
      import("@/views/admin/policySign/activities/PolicySignAllActivities.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/signed-policies",
          title: "Signed Policies"
        }
      ],
      pageTitle: "Policy Sign Activities",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Policy Sign Activities"
      }
    }
  }
];

export default adminRoutes;
