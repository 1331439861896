import { IRouterConfig } from "@/types";

export const UserEdit: IRouterConfig = {
  path: "edit",
  component: () => import("@/views/shared/users/UserEdit.vue"),
  props: (route: any) => ({
    userId: route.params.user_id
  }),
  meta: {
    breadcrumbs: [
      {
        link: "/admin/users",
        title: "Users"
      }
    ],
    pageTitle: "User",
    requireAuth: true,
    allowedRoles: ["admin", "agencyAdmin", "subAdmin"],
    metaTags: {
      title: "User Info"
    }
  }
};
export const agencyUserEdit: IRouterConfig = {
  path: "edit",
  component: () => import("@/views/shared/users/UserEdit.vue"),
  props: (route: any) => ({
    userId: route.params.user_id
  }),
  meta: {
    breadcrumbs: [
      {
        link: "/agencyAdmin/users",
        title: "Users"
      }
    ],
    pageTitle: "User",
    requireAuth: true,
    allowedRoles: ["admin", "agencyAdmin", "subAdmin"],
    metaTags: {
      title: "User Info"
    }
  }
};
export const otherUserEdit: IRouterConfig = {
  path: "edit",
  component: () => import("@/views/shared/users/UserEdit.vue"),
  props: (route: any) => ({
    userId: route.params.user_id
  }),
  meta: {
    breadcrumbs: [
      {
        link: "/users",
        title: "Users"
      }
    ],
    pageTitle: "User",
    requireAuth: true,
    allowedRoles: ["underwriting", "claims", "billing", "dataEntry"],
    metaTags: {
      title: "User Info"
    }
  }
};

export const UserActivity: IRouterConfig = {
  path: "activities",
  component: () =>
    import("@/views/admin/users/SingleUser/Activity/Activity.vue"),
  props: (route: any) => ({
    userId: route.params.user_id
  }),
  meta: {
    breadcrumbs: [
      {
        link: "/admin/users",
        title: "Users"
      }
    ],
    pageTitle: "User Activities",
    requireAuth: true,
    allowedRoles: ["admin"],
    metaTags: {
      title: "User Activities"
    }
  }
};
