import { DefineGetters } from "vuex-type-helper";
import {
  AgentApplicationsGetters,
  AgentApplicationsState,
  IAgentApplications
} from "./types";

export const getters: DefineGetters<
  AgentApplicationsGetters,
  AgentApplicationsState
> = {
  agentApplications: (state: AgentApplicationsState) => state.agentApplications,
  getAgentApplicationById: (state: AgentApplicationsState) => (
    agentApplicationId: string
  ) =>
    state.agentApplications.find(
      (agentApplication: IAgentApplications) =>
        agentApplication._id === agentApplicationId
    ),
  editingAgentApplication: (state: AgentApplicationsState) => state.editing,
  getAgentApplicationLinks: (state: AgentApplicationsState) => state.links,
  getTotalRecords: ({ links, agentApplications }: AgentApplicationsState) =>
    links && links.totalRecords ? links.totalRecords : agentApplications.length
};
