import { IAtlasPlatform } from "@/store/modules/atlasPlatform/types";
import { baseAtlasPlatform } from "./defaultObjects";
import { cloneDeep, defaultsDeep } from "lodash";

export function atlasPlatformDefault(
  atlasPlatform: Partial<IAtlasPlatform>
): IAtlasPlatform {
  return defaultsDeep(
    cloneDeep(atlasPlatform),
    Object.assign({}, baseAtlasPlatform())
  );
}
