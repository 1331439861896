import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  AccreditedBuilderState,
  AccreditedBuilderMutations,
  StateKeys,
  IAccreditedBuilder
} from "./types";
import {
  GenericClearStore,
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates
} from "@/store/helpers";
import { defaultState } from "./state";
import { accreditedBuilderDefault } from "@/helpers/accreditedBuilder";

export const mutations: DefineMutations<
  AccreditedBuilderMutations,
  AccreditedBuilderState
> = {
  [types.SET_STATE]: GenericSetState<AccreditedBuilderState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<AccreditedBuilderState>(),
  [types.SET_EDIT]: GenericSetEdit<
    AccreditedBuilderState,
    string | Partial<IAccreditedBuilder>
  >("accreditedBuilders", accreditedBuilderDefault),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    AccreditedBuilderState,
    keyof IAccreditedBuilder
  >(),
  [types.SET_ITEM]: GenericSetItem<AccreditedBuilderState, IAccreditedBuilder>(
    "accreditedBuilders",
    accreditedBuilderDefault
  ), //TODO: WRITE A getXDefaultsFunction and pass it here in place of the anonymous function
  [types.DELETE_ITEM]: GenericDeleteItem<AccreditedBuilderState>(
    "accreditedBuilders",
    "_id"
  ),
  [types.CLEAR_ACCREDITED_BUILDER_STORE]: GenericClearStore<
    AccreditedBuilderState
  >(defaultState())
};
