
import Vue from "vue";

export default Vue.extend({
  name: "BackToLink",
  props: {
    to: {
      type: String
    },
    text: {
      type: String
    }
  },
  methods: {
    hasSlot(name: string): boolean {
      return !!this.$slots[name];
    }
  },
  computed: {
    fromPage: function(): string {
      return "";
      // const page = this.$route
    }
  }
});
