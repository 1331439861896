import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  PropertyanalyticsState,
  PropertyanalyticsGetters,
  PropertyanalyticsMutations,
  PropertyanalyticsActions
} from "./types";

const namespaced = true;
export const namespace = "propertyanalytics";

export const {
  mapState: propertyanalyticsMapState,
  mapGetters: propertyanalyticsMapGetters,
  mapMutations: propertyanalyticsMapMutations,
  mapActions: propertyanalyticsMapActions
} = createNamespacedHelpers<
  PropertyanalyticsState,
  PropertyanalyticsGetters,
  PropertyanalyticsMutations,
  PropertyanalyticsActions
>(namespace);

export const propertyanalytics: Module<PropertyanalyticsState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
