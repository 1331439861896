export const companies = {
  companyName: "Company Name",
  NAICCD: "NAIC Number",
  companyNumber: "Company Number",
  site: "Select Site",
  companyLineOfBusiness: "Line of Business",
  mCompany: "mCompany Number", //this refers to the mCompany and not company number
  selectCompany: "Select Company",
  selectAllCompanies: "Select all companies",
  propertyRatingSelectCompany:
    "Select all Companies or Check the companies you want to rate in",
  selectedCompanies: "Selected Companies",
  structureCompany: "Structure new company after?"
};
