import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { CountyState, CountyMutations, StateKeys, ICounty } from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { getCountyDefaults } from "@/helpers/defaultObjectGenerators";
import { defaultState } from "./state";

export const mutations: DefineMutations<CountyMutations, CountyState> = {
  [types.SET_STATE]: GenericSetState<CountyState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<CountyState>(),
  [types.SET_EDIT]: GenericSetEdit<CountyState, string | ICounty>(
    "counties",
    getCountyDefaults
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<CountyState, keyof ICounty>(),
  [types.SET_ITEM]: GenericSetItem<CountyState, ICounty>(
    "counties",
    getCountyDefaults
  ),
  [types.DELETE_ITEM]: GenericDeleteItem<CountyState>("counties", "_id"),
  [types.CLEAR_COUNTY_STORE]: GenericClearStore<CountyState>(defaultState())
};
