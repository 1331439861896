import { CompaniesState } from "./types";

export const defaultState = (): CompaniesState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    companies: [],
    links: {}
  };
};

export const state = defaultState();
