import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  ImportState,
  ImportGetters,
  ImportMutations,
  ImportActions
} from "./types";

const namespaced = true;
export const namespace = "imports";

export const {
  mapState: importMapState,
  mapGetters: importMapGetters,
  mapMutations: importMapMutations,
  mapActions: importMapActions
} = createNamespacedHelpers<
  ImportState,
  ImportGetters,
  ImportMutations,
  ImportActions
>(namespace);

export const imports: Module<ImportState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
