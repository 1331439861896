
import { formatDate } from "@/helpers/dateProcessor";
import Vue from "vue";
export default Vue.extend({
  name: "custom-data-view",
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    formatDate: {
      type: Boolean
    }
  },
  methods: {
    doFormatDate(date: string) {
      return formatDate(date);
    }
  }
});
