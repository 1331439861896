import {
  IChart,
  ChartActions,
  ChartState,
  ChartMutations,
  ChartGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import ChartService from "@/services/chart.service";
export const actions: DefineActions<
  ChartActions,
  ChartState,
  ChartMutations,
  ChartGetters
> = {
  createChart: GenericCreateOne<
    ChartState,
    ChartGetters,
    ChartActions,
    ChartMutations,
    IChart
  >(ChartService, types.SET_ITEM as any),
  getCharts: GenericGetMany<
    ChartState,
    ChartGetters,
    ChartActions,
    ChartMutations
  >(ChartService, "charts", types.SET_ITEM as any),
  getChart: GenericGetOne<
    ChartState,
    ChartGetters,
    ChartActions,
    ChartMutations
  >(ChartService, types.SET_ITEM as any),
  deleteChart: GenericDeleteOne<
    ChartState,
    ChartGetters,
    ChartActions,
    ChartMutations
  >(ChartService, types.DELETE_ITEM as any),
  updateChart: GenericUpdateOne<
    ChartState,
    ChartGetters,
    ChartActions,
    ChartMutations,
    { id: string; update: Partial<IChart> }
  >(ChartService, types.SET_ITEM as any),
  async exportChart({ commit }, payload) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      await ChartService.exportChart(payload);
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  }
};
