export default [
  {
    path: "/admin/mortgages",
    component: () =>
      import(
        /* webpackChunkName: "ListViewMortgages" */ "@/views/admin/mortgages/ListViewMortgages/ListViewMortgages.vue"
      ),
    meta: {
      pageTitle: "Mortgages",
      requireAuth: true,
      allowedRoles: ["underwriting", "admin"]
    }
  },
  {
    path: "/admin/mortgages/:mortgageId/edit",
    component: () =>
      import(
        /* webpackChunkName: "EditMortgage" */ "@/views/admin/mortgages/EditMortgage.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/mortgages",
          title: "Mortgages"
        }
      ],
      pageTitle: "Edit Mortgage",
      requireAuth: true,
      allowedRoles: ["underwriting", "admin"],
      metaTags: {
        title: "Edit Mortgage",
        description: "Edit Mortgage Details",
        keywords: "Edit Mortgage"
      }
    }
  },
  {
    path: "/admin/mortgages/create",
    component: () =>
      import(
        /* webpackChunkName: "CreateMortgage" */ "@/views/admin/mortgages/CreateMortgage.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/admin/mortgages",
          title: "Mortgages"
        }
      ],
      pageTitle: "Create Mortgage",
      requireAuth: true,
      allowedRoles: ["underwriting", "admin"],
      metaTags: {
        title: "Create Mortgage",
        description: "Create Mortgage",
        keywords: "Create Mortgage"
      }
    }
  }
];
