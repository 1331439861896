import { FormBlock, IAddressType } from "@/components/FormBuilder/types";
import {
  // useFormSection,
  useFormFieldGroup,
  useTextField,
  useSelectField,
  useField
} from "@/components/FormBuilder/Helpers";
import * as selectOptions from "@/helpers/selectOptions";
import { hasBothCreditAndLoss } from "@/helpers";
import store from "@/store";

export const addressFormPOBox: FormBlock[] = [
  ...useFormFieldGroup([
    useField(
      {
        disabled: disableAddressFields("isPOBoXFormat", "mailingAddress"),
        key: "isPOBoXFormat",
        type: "checkbox",
        label: "Is P.O. Box?",
        conditions: {
          and: [
            {
              field: "isSameAsPhysicalAddress",
              value: false,
              operator: "equals"
            }
          ]
        }
      },
      [".el-checkbox__label"]
    )
  ])
];

export const unitNumberAsUitNumber = useTextField({
  key: "unitNumber",
  label: ["fields", "PostOfficeBoxLabel"],
  placeholder: ["fields", "PostOfficeBoxLabel"],
  required: false,
  conditions: {
    and: [
      {
        field: "isPOBoXFormat",
        value: true,
        operator: "equals"
      }
    ]
  }
});

export function addressForm({
  disableState = false,
  addressType
}: {
  disableState: boolean;
  addressType: Partial<IAddressType>;
}): FormBlock[] {
  return [
    ...useFormFieldGroup(
      [
        useTextField({
          key: "houseNumber",
          disabled: disableAddressFields("houseNumber", addressType),
          label: ["fields", "houseNumberFieldLabel"],
          placeholder: ["fields", "houseNumberFieldLabel"],
          conditions: {
            and: [
              {
                field: "isPOBoXFormat",
                value: false,
                operator: "equals"
              }
            ]
          },
          required: true,
          validations: [
            {
              method: "integer",
              error: "Must be a number"
            }
          ]
        }),
        useSelectField({
          disabled: disableAddressFields("streetDirection", addressType),
          key: "streetDirection",
          label: ["fields", "streetDirectionLabel"],
          required: false,
          clearable: true,
          options: selectOptions.streetDirections,
          filterable: true,
          allowFirstOption: true,
          conditions: {
            and: [
              {
                field: "isPOBoXFormat",
                value: false,
                operator: "equals"
              }
            ]
          }
        }),
        useTextField({
          key: "unitNumberLabel",
          placeholder: ["fields", "postOfficeDisabledValue"],
          required: false,
          disabled: true,
          conditions: {
            and: [
              {
                field: "isPOBoXFormat",
                value: true,
                operator: "equals"
              }
            ]
          }
        }),
        useTextField({
          disabled: disableAddressFields("unitNumber", addressType),
          key: "unitNumber",
          label: ["fields", "PostOfficeBoxLabel"],
          placeholder: ["fields", "PostOfficeBoxLabel"],
          required: false,
          conditions: {
            and: [
              {
                field: "isPOBoXFormat",
                value: true,
                operator: "equals"
              }
            ]
          },
          validations: [
            {
              method: "integer",
              error: "Must be a number"
            }
          ]
        }),
        useTextField({
          disabled: disableAddressFields("unitNumber", addressType),
          key: "unitNumber",
          label: ["fields", "unitNumberFieldLabel"],
          placeholder: ["fields", "unitNumberFieldLabel"],
          required: false,
          prepend: ["fields", "PostOfficeBoxLabel"],
          conditions: {
            and: [
              {
                field: "isPOBoXFormat",
                value: false,
                operator: "equals"
              }
            ]
          }
        })
      ],
      { layout: "3-col" }
    ),
    ...useFormFieldGroup(
      [
        useTextField({
          disabled: disableAddressFields("streetName", addressType),
          key: "streetName",
          label: ["fields", "streetNameFieldLabel"],
          placeholder: ["fields", "streetNameFieldLabel"],
          required: true,
          conditions: {
            and: [
              {
                field: "isPOBoXFormat",
                value: false,
                operator: "equals"
              }
            ]
          },
          validations: [
            {
              method: "minLength",
              value: 1,
              error: "Street name must have at least one character"
            }
          ]
        }),
        useSelectField({
          disabled: disableAddressFields("streetType", addressType),
          key: "streetType",
          label: ["fields", "streetTypeLabel"],
          required: false,
          clearable: true,
          options: selectOptions.streetTypes,
          filterable: true,
          allowFirstOption: true,
          conditions: {
            and: [
              {
                field: "isPOBoXFormat",
                value: false,
                operator: "equals"
              }
            ]
          }
        })
      ],
      { layout: "2-col" }
    ),
    ...useFormFieldGroup(
      [
        useTextField({
          disabled: disableAddressFields("city", addressType),
          key: "city",
          label: ["fields", "cityFieldLabel"],
          placeholder: ["fields", "cityFieldLabel"],
          required: true
        }),
        useSelectField({
          key: "state",
          label: ["fields", "stateLabel"],
          required: true,
          options: selectOptions.states,
          filterable: true,
          allowFirstOption: true,
          disabled: disableState || disableAddressFields("state", addressType)
        }),
        useTextField({
          disabled: disableAddressFields("zipCode", addressType),
          key: "zipCode",
          label: ["fields", "zipCodeFieldLabel"],
          placeholder: ["fields", "zipCodeFieldLabel"],
          required: true,
          validations: [
            {
              method: "minLength",
              value: 4,
              error: "Zip code must have at least four numbers"
            },
            {
              method: "integer",
              error: "Zip code must be numeric only"
            }
          ]
        })
      ],
      { layout: "3-col" }
    )
  ];
}

export const mailingAddressForm: FormBlock[] = [
  useField(
    {
      type: "radio_button_group",
      key: "isSameAsPhysicalAddress",
      preamble: "Is same as location address",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false }
      ],
      isInline: true
    },
    ["mb-8 -mt-12"]
  )
];

function disableAddressFields(key: string, addressType: Partial<IAddressType>) {
  if (!addressType) return false;
  if (addressType === "mailingAddress") return false;
  const quote = store.state.quote.editing;
  const riskAddress = quote?.riskAddress;
  const riskAddressProperties = riskAddress && Object.keys(riskAddress);

  return (
    hasBothCreditAndLoss(quote) &&
    riskAddressProperties &&
    riskAddressProperties.includes(key)
  );
}
