import {
  IAgentApplications,
  IEditingApprove
} from "@/store/modules/agentApplications/types";
// import { ICommunication } from "@/store/modules/communication/types";
import * as dateFns from "date-fns";
import { ICompany } from "@/store/modules/companies/types";
import { IImport } from "@/store/modules/import/types";
import { IAgency } from "@/store/modules/agency/types";
import { IClaims, IClaimsFiservData } from "@/store/modules/claims/types";
import { ITransaction } from "@/store/modules/transactions/types";
import { omit } from "lodash";
import { IMortgage } from "@/store/modules/mortgage/types";
import { IEndorsement } from "../store/modules/endorsements/types";
import { IGbsHelp } from "@/store/modules/gbshelp/types";
import { IAtlasFileCategory } from "@/store/modules/atlasFileCategory/types";
import { IUnderWritingQuestion } from "@/store/modules/underWritingQuestion/types";
import { IAccreditedBuilder } from "@/store/modules/accreditedBuilder/types";
import { IQuoteCountyValidation } from "@/store/modules/quoteCountyValidation/types";
import { IFAQ } from "@/store/modules/faq/types";
import { IAtlasPlatform } from "@/store/modules/atlasPlatform/types";
import { IPolicySign } from "@/store/modules/policySign/types";

export const defaultAddressStructure = {
  unitNumber: undefined,
  unitNumberLabel: undefined,
  streetName: "",
  houseNumber: undefined,
  streetDirection: "",
  streetType: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",
  line2: "",
  county: "",
  isPOBoXFormat: false,
  isSameAsPhysicalAddress: true
};

export const settingsBase = () => ({
  _id: "",
  deleted: false,
  isInEmergencyMaintenanceMode: false,
  emergencyMaintenanceModeMessage: "",
  isDefault: false
});

export const defaultApplicant = {
  firstName: "",
  lastName: "",
  email: "",
  dateOfBirth: "",
  maritalStatus: "",
  employerName: "",
  employerAddress: "",
  ssn: ""
};

export const defaultUnderwritingResponse = {
  dwellingType: "",
  damagingFloods: "",
  riskIsIsolated: "",
  businessOnPremises: "",
  dogsOrExoticBreeds: "",
  unfencedPool: "",
  structureUnderConstruction: "",
  unusualLiabilityExposure: "",
  uniqueDesign: "",
  overWater: "",
  overlayOverLivingAreas: "",
  isMobile: "",
  houseIsVacant: "",
  dwellingHasMoreThan2SolarPanels: "",
  hasBasementWithSumpPump: "",
  isConvicted: "",
  surroundedByWater: "",
  hasRescindedPolicy: "",
  propertyUpdates: "",
  swimmingPoolOnPremises: "",
  poolApprovedFence: "",
  poolDivingBoard: "",
  poolSlide: "",
  buildingUpdates: "",
  wiringUpdateYear: "",
  heatingUpdateYear: "",
  ageOfRoof: "",
  roofingMaterialShingles: "",
  saltWater5Miles: "",
  aluminumWiring: "",
  hasHeatingSystem: "",
  primaryDwelling: "",
  nearMajorSaltWaterBodies: "",
  underWriterApprovalCode: "",
  approvedQuestionableAnswers: false
};

export const quoteApplicantDefaults = () => ({
  applicant: { ...defaultApplicant },
  previousAddress: { ...defaultAddressStructure },
  riskAddress: { ...defaultAddressStructure },
  underwritersCode: "",
  coApplicants: [],
  effectiveDate: {},
  movedInYears: ""
});

export const quoteDiscountsDefaults = {
  hailResistantRoof: "",
  hailResistantRoofAtlas: "",
  yearInstalled: "",
  yearInstalledAtlas: "",
  monitoredAlarmSW: false,
  monitoredAlarmAtlas: false,
  accreditedBuilder: false,
  accreditedBuilderName: "",
  companionAuto: false,
  lossFreeExperienceCredit: false,
  lossFreeExperienceCreditAtlas: false,
  matureCitizen: false,
  hvacAge: false,
  hvacInstallationYear: "",
  ageOfInsuredSW: "",
  companionAutoAtlas: false,
  ageOfHome: 0,
  yearOfRoof: "",
  newHomeCredit: false,
  ageOfInsuredCheck: false,
  fortifiedRoof: false,
  fortifiedRoofColonial: false,
  colonialCertificateExpirationDate: null,
  certificateExpirationDate: null
};

export const quoteBase = () => ({
  _id: "",
  policyType: "",
  effectiveDate: "",
  policyNumber: "",
  companyNumbers: [],
  county: "",
  deleted: false,
  paymentPlan: "",
  formType: "",
  useAge: "",
  dwellType: "",
  structureType: "",
  limitDed1: "",
  limitLiab: "",
  limitDed2: "",
  limitDed3: "",
  limitMed: "",
  entrance: "",
  yearBuild: "",
  yearOfRoof: "",
  squareFeet: "",
  amountDWP: "",
  amountUPP: "",
  amountOTS: "",
  amountDWPCompanyOverrides: [], // { companyNumber: number, amount: string }
  amountOTSCompanyOverrides: [],
  numberOfStories: "",
  marketValue: "",
  replacementCost: "",
  trampolineOnPremises: "",
  trampolineInFenceYard: "",
  dwellingOnSeawardIfOfHwy59: "",
  numberOfNonWeatherClaims: "",
  riskAddress: "",
  territoryCode: "",
  territoryCodeForSeacoast: "",
  territoryCodeForPPC: "",
  endorsements: [],
  occupancy: "",
  priorCoverage: false,
  riskNewToAgency: false,
  priorCarrier: "",
  priorPolicyNumber: undefined,
  priorPolicyExpirationDate: "",
  remarks: "",
  attachments: [],
  underwritingResponses: { ...defaultUnderwritingResponse },
  discounts: { ...quoteDiscountsDefaults },
  billing: "",
  roofMaterial: "",
  occupiedDaily: "",
  purchaseDate: "",
  roofStructure: "",
  lossReport: {
    lossInformation: []
  },
  waterLimit: "",
  limitedSlabFoundation: "",
  dogsOrExoticBreeds: undefined,
  deadBolts: "",
  fireExtinguisherOnPremises: "",
  swimmingPoolOnPremises: "",
  poolApprovedFence: "",
  trampolineApprovedFence: "",
  poolDivingBoard: "",
  poolSlide: "",
  propertyUpdates: "",
  wiringUpdateYear: "",
  plumbingUpdateYear: "",
  heatingUpdateYear: "",
  hasUnderwriterApproved: false,
  approvalCode: "",
  hasConfirmedSolarPanel: null
});

export const userBase = () => ({
  displayName: "",
  firstName: "",
  lastName: "",
  email: "",
  agentCode: "",
  password: "",
  phone: {
    primary: "",
    primaryVerified: {
      verified: "",
      verifiedOn: "",
      verifyCode: "",
      verifyInit: ""
    },
    secondary: "",
    secondaryVerified: {
      verified: "",
      verifiedOn: "",
      verifyCode: "",
      verifyInit: ""
    }
  },
  enableMfa: false,
  licenseNumber: "",
  licenseNumberExpirationDate: "",
  role: "",
  status: "",
  country: "",
  state: "",
  tags: [],
  companyIds: [],
  physicalAddress: defaultAddressStructure,
  mailingAddress: defaultAddressStructure,
  //remove when agency logic is ready
  agencyId: "",
  disabledPermissions: [],
  allAgencyCodes: [],
  adjusterCode: "",
  adjustingFirm: "",
  isAtlasAdmin: false,
  ezlynxAgencies: [],
  atlasAdmin: {
    title: "",
    department: ""
  }
});

const actions = {
  cancellationAction: "",
  limitAction: "",
  mortgageAction: "",
  endorsementAction: ""
};

const description = {
  limitDescription: "",
  endorsementDescription: ""
};

const mortgage = {
  loanNumber: "",
  listed: "",
  billed: "",
  remarks: "",
  mortgage: "",
  address: "",
  mortgageAddress: defaultAddressStructure
};

export const baseChangeRequest = () => ({
  _id: "",
  modifiedOn: new Date(),
  status: "Not Submitted",
  // userId: "",
  submittedBy: "",
  email: "",
  attachments: [],
  printed: false,
  created: new Date(),
  deleted: false,
  submittedOn: ""
});

export function propertyChangeRequestBase() {
  const toOmit = [
    "billing",
    "actions.cancellationAction",
    "cancellation",
    "payPlan",
    "agentInfo.agencyAddress.line2",
    "agencyAddress.country",
    "agencyAddress.county"
  ];
  const base: any = {
    ...baseChangeRequest(),
    data: { ...omit(changeRequestData, toOmit) }
  };
  return base;
}

export function payPlanChangeRequestBase() {
  const toOmit = [
    "billing",
    "agentInfo.agencyAddress",
    "actions",
    "cancellation",
    "description",
    "mortgage",
    "agentInfo.agencyAddress.line2",
    "agencyAddress.country",
    "agencyAddress.county"
  ];
  const base: any = {
    ...baseChangeRequest(),
    data: { ...omit(changeRequestData, toOmit) }
  };
  return base;
}

export function billingChangeRequestBase() {
  const toOmit = [
    "payPlan",
    "mortgage",
    "actions",
    "description",
    "cancellation",
    "agentInfo.agencyAddress",
    "agentInfo.agencyAddress.line2",
    "agencyAddress.country",
    "agencyAddress.county"
  ];
  const base: any = {
    ...baseChangeRequest(),
    data: { ...omit(changeRequestData, toOmit) }
  };
  return base;
}

const changeRequestData = {
  agentInfo: {
    agentCode: "",
    agentPhone: undefined,
    agencyName: "",
    agencyAddress: defaultAddressStructure
  },
  insured: {
    firstName: "",
    lastName: "",
    location: defaultAddressStructure,
    insuredAddress: defaultAddressStructure
  },
  policyInfo: {
    policyPrefix: "",
    policyNumber: undefined,
    effectiveDate: "",
    companyNum: "",
    agencyCode: "",
    agencyName: ""
  },
  cancellation: {
    agentCertify: false,
    other: "",
    reason: ""
  },
  billing: {
    agentCertify: false,
    other: "",
    reason: "",
    remarks: ""
  },
  payPlan: {
    billTo: "",
    payPlanOptions: ""
  },
  isLapsed: false,
  actions: actions,
  mortgage: mortgage,
  description: description
};

/**
 * On Commenting out the things below
 * Specifying base data involving nested objects is a bit tricky.
 * Now that I'm thinking again, I wonder even why we really needed to be
 * setting these defaults anyway. They were meant as I recall, just to make
 * mutations easier. I wonder if we can have a better solution.
 *
 * THE BELOW WORKS ON EDITING BUT I WONDER IF IT WORKS ON CREATING. //TODO:
 */

// export function changeRequestDataForCreating() {
//   return changeRequestBase();
// }

export const baseUser = () => ({
  firstName: "",
  lastName: "",
  displayName: "",
  email: "",
  role: "user",
  status: "",
  phone: {
    primary: "",
    secondary: ""
  },
  password: "",
  responsesToOnboardingQuestions: [],
  hasDoneOnboarding: false,
  country: "",
  state: "",
  tags: []
});

export const activityLoginBase = () => ({
  _id: "",
  name: "",
  userId: "",
  role: "",
  data: {}
});

export const activityLogoutBase = () => ({
  _id: "",
  name: "",
  userId: "",
  role: "",
  collectionType: "",
  documentId: "",
  data: [{}]
});

export const baseAppSettings = () => ({
  name: "",
  theme: {
    bodyBackgroundColor: "",
    logoUrl: "",
    logoIconUrl: "",
    primaryColor: "",
    secondaryColor: "",
    menuPosition: "",
    menuCollapse: "",
    topBarTitlePosition: "",
    views: {
      login: {
        backgroundColor: "",
        backgroundImage: ""
      },
      claimAccount: {
        backgroundColor: "",
        backgroundImage: ""
      },
      passwordReset: {
        backgroundColor: "",
        backgroundImage: ""
      },
      documents: {
        editMenuPosition: ""
      }
    }
  },
  settings: {
    views: {
      login: {
        signInText: "",
        buttonText: "",
        userNameLabel: ""
      },
      claimAccount: {
        claimAccountText: "",
        claimAccountLinkText: "",
        buttonText: ""
      },
      passwordReset: {
        passwordResetText: "",
        passwordTokenSentText: "",
        buttonText: ""
      },
      userDashboard: {
        message: ""
      },
      adminDashboard: {
        message: ""
      }
    }
  },
  deleted: false,
  active: false
});

export function baseCommunication(): any {
  return Object.freeze({
    _id: "",
    title: "",
    deleted: false,
    userIds: [],
    message: "",
    deliveryDate: dateFns.addMinutes(new Date(), 25).toISOString(),
    status: "draft",
    attachments: [],
    acknowledgments: [],
    expirationDate: dateFns.addHours(new Date(), 24).toISOString(),
    alternateDeliveryMethods: ["email"],
    actions: [],
    priority: "high",
    sendImmediate: false,
    recipientType: "",
    requireAcknowledgements: true
  });
}

export function baseAtlasFile(): any {
  return Object.freeze({
    _id: "",
    name: "",
    deleted: false,
    isUploaded: false,
    isPublic: false,
    type: "",
    size: "",
    ext: "",
    category: ""
  });
}

export function baseNotification(): any {
  return Object.freeze({
    _id: "",
    title: "",
    priority: "",
    message: "",
    attachments: [],
    acknowledgments: [],
    userId: "",
    deleted: false,
    expirationDate: new Date().setHours(new Date().getHours() + 12).toString(),
    deliveryMethods: ["app"],
    actions: [],
    acknowledgement: {
      acknowledgedBy: "",
      acknowledgedOn: ""
    },
    attachmentsReadActivityData: []
  });
}

export function baseCounty(): any {
  return Object.freeze({
    _id: "",
    tier: "",
    countyName: "",
    countyCode: "",
    prohibitBinding: false
  });
}

export const baseQuoteCountyValidation: IQuoteCountyValidation = {
  _id: "",
  title: "",
  city: "",
  zipCode: [],
  state: "TX",
  county: "",
  companyNumbers: [],
  exemptedRoles: [],
  validationMessage: "",
  windCheck: "none",
  isActive: true,
  effectiveDate: new Date(),
  exemptedAgencyCodes: []
};
export const baseCompanies = (): Partial<ICompany> => ({
  companyLineOfBusiness: [],
  deleted: false,
  _id: "",
  companyNumber: undefined,
  site: "",
  companyName: "",
  mCompany: undefined,
  NAICCD: undefined,
  createdOn: new Date(),
  modifiedOn: new Date(),
  companyPrefix: [],
  structuredAfter: undefined
});
export const baseAccreditedBuilder = (): Partial<IAccreditedBuilder> => ({
  accreditedBuilderKey: "",
  accreditedBuilderName: "",
  createdOn: new Date(),
  modifiedOn: new Date(),
  createdBy: ""
});
export const baseFAQ = (): Partial<IFAQ> => ({
  question: "",
  answer: "",
  createdOn: new Date(),
  modifiedOn: new Date(),
  createdBy: "",
  attachments: [],
  atlasPlatformIds: []
});
export const baseAtlasPlatform = (): Partial<IAtlasPlatform> => ({
  name: "",
  createdOn: new Date(),
  modifiedOn: new Date(),
  createdBy: "",
  FAQQuestions: [],
  deleted: false,
  _id: ""
});
export const basePolicySign = (): Partial<IPolicySign> => ({
  createdOn: new Date(),
  modifiedOn: new Date(),
  createdBy: "",
  deleted: false,
  _id: "",
  agency: "",
  insuredEmail: "",
  insuredName: "",
  policyNumber: "",
  policyPrefix: ""
});
export const baseImports = (): Partial<IImport> => ({
  importHeadersMetadata: [],
  status: "draft",
  deleted: false,
  _id: "",
  documentId: "",
  createdBy: "",
  name: "",
  result: {} as any,
  createdOn: new Date(),
  modifiedOn: new Date()
});
export const baseClaims = (): Partial<IClaims> => ({
  claimNumber: undefined,
  email: "",
  modified: "",
  status: "",
  submittedBy: "",
  userId: "",
  _id: "",
  data: {
    formType: "",
    formInfo: {
      localAddress: "",
      lossDateSort: "",
      lossDate: "",
      description: "",
      city: "",
      state: "",
      zipCode: "",
      classID: "",
      other: "",
      location: "",
      claimantName: "",
      claimantPhone: "",
      claimantAddress: "",
      reportTo: "",
      claimTransStatus: "",
      paymentOption: "",
      county: ""
    },
    insured: {
      firstName: "",
      lastName: "",
      mailAddress: "",
      insuredAddress: {
        unitNumber: undefined,
        streetName: "",
        houseNumber: "",
        streetDirection: "",
        streetType: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        line2: "",
        county: ""
      }
    },
    agentInfo: {
      agentCode: "",
      agentPhone: "",
      agencyName: "",
      agencyAddress: {
        streetName: "",
        houseNumber: "",
        streetDirection: "",
        streetType: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        line2: "",
        county: ""
      }
    },
    policyInfo: {
      policyPrefix: "",
      policyNumber: "",
      effectiveDate: new Date(),
      companyNum: ""
    },
    info: {
      expDate: new Date(),
      companyID: "",
      agentCode: "",
      claim: "",
      agContacts: "",
      email: "",
      mortgage: {
        mortgage: "",
        mortgageAddress: "",
        mortgagePostalCode: "",
        mortgageCity: "",
        mortgageStateProvCd: "",
        mortZipCode: ""
      },
      mortgageVerifiedQtn: false,
      payOrInd: ""
    },
    client: {
      mi: "",
      mailAddress: "",
      phone: "",
      businessPhone: "",
      otherAuthContact: "",
      otherPhone: "",
      notesComments: ""
    },
    coverages: {
      HOForm: {
        OTSLimit: "",
        PPOLimit: "",
        personalLiability: "",
        medPay: "",
        deductible: {}
      },

      TDPForm: {
        tenantName: "",
        tenantPhone: "",
        eCDeductible: {
          ded3: ""
        }
      },
      branch: "",
      productDesc: "",
      branchName: "",
      bloodWaterRisk: "",
      dateAssigned: "",
      fieldAssigned: "",
      claimRepresentative: "",
      additions: {
        addition1: {
          type: "",
          name: "",
          address: "",
          loanNo: ""
        },
        addition2: {
          type: "",
          name: "",
          address: "",
          loanNo: ""
        },
        addition3: {
          type: "",
          name: "",
          address: "",
          loanNo: ""
        }
      }
    }
  }
});

export const baseApproveAgent = (): Partial<IEditingApprove> => ({
  companyIds: [],
  agentCode: ""
});

export const baseAgentApplications = (): Partial<IAgentApplications> => ({
  agencyName: "",
  other: "",
  underwritingChallenges: "",
  fein: "",
  _id: "",
  entityType: "",
  physicalAddress: {
    ...defaultAddressStructure
  },
  mailingAddress: {
    ...defaultAddressStructure
  },
  phone: "",
  fax: "",
  companyWebAddress: "",
  ownersFirstName: "",
  ownersLastName: "",
  ownersEmail: "",
  commercialBusinessSplit: undefined,
  personalBusinessSplit: undefined,
  totalNumberOfAgencyEmployees: undefined,
  totalNumberOfPersonalLineStaff: undefined,
  totalCombinedYearsForPersonalLinesStaff: undefined,
  motiveForSeekingAppointment: "",
  factorsInfluencingPlacementOfResidentialPropertyBusiness: "",
  primaryFocusWhenSellingPropertyPolicy: "",
  top4ResidentialPropertyCarriers: ["", "", "", ""],
  personalLinesPremiumVolumeInLast6Months: "",
  anyMarketsAccessedThroughMGA: false,
  marketsAccessedThroughMGA: "",
  atlasWillReplaceCurrentCarrier: false,
  currentCarrier: "",
  agencyUsesComparativeRater: false,
  comparativeRaterUsedByAgency: "",
  familiarResidentialProductForms: "",
  howOurMarketFitsWithOtherMarketsAgentRepresents: "",
  marketingPlans: [],
  applicationsPerMonth: undefined,
  firstYearPremiumCommitment: undefined,
  status: "",
  deleted: false,
  deletedBy: ""
});

export const baseAgency = (): Partial<IAgency> => {
  return {
    deleted: false,
    allowInsurorsSeacoast: false,
    agencyCode: "",
    agencyName: "",
    status: "pendingApproval",
    companyIds: [],
    approvedBy: "",
    subAgencyOf: "",
    physicalAddress: { ...defaultAddressStructure } as any,
    mailingAddress: { ...defaultAddressStructure } as any,
    underWriterId: "",
    allowEzlynxRating: false
  };
};

export const baseClaimsFiservData = (): Partial<IClaimsFiservData> => ({
  _id: "",
  policyStatusMessage: "",
  policyStatus: "",
  endorLst: [],
  MsgStatus: "",
  MsgStatusDesc: "",
  ProductDesc: "",
  CompanyName: "",
  PolicyNumber: "",
  PolicyType: "",
  GivenName: "",
  Surname: "",
  insured: [],
  LegalName: "",
  StreetName: "",
  StreetNumber: "",
  City: "",
  State: "",
  Zip: "",
  EffectiveDt: "",
  ExpirationDt: "",
  isExpired: false,
  isHeldActPending: false,
  isIncomplete: false,
  isCancelled: false,
  isLapsedWtRewrite: false,
  isLapsed: false,
  isActWtNonRenewalPending: true,
  isActive: false,
  ConstructionCd: "",
  PhoneNumber: undefined,
  MortgageStateProvCd: "",
  MortgagePostalCode: "",
  PayorInd: "",
  lossFormRequire: {
    clientFirstName: "",
    clientLastName: "",
    clientCity: "",
    clientState: "",
    clientZip: "",
    dtEffect: ""
  },
  lossFormOtherInfo: {
    Client: {
      MailAddress: "",
      Phone: "",
      PolicyNum: ""
    },
    Info: {
      Mortgage: "",
      MortgageCity: "",
      MortgagePostalCode: "",
      MortgageAddress: "",
      MortgageStateProvCd: "",
      PayorInd: "",
      CompanyID: "",
      AgContacts: "",
      ExpDate: ""
    },
    formInfo: {
      ClassId: "",
      LocalAddress: "",
      City: "",
      State: "",
      ZipCode: "",
      LossDate: ""
    },
    Coverages: {
      WindExclude: "",
      WindExcludeStatement: "",
      Branch: "",
      ProductDesc: "",
      BranchName: ""
    }
  },
  formItem: {
    agentCode: "",
    name: "",
    phone: "",
    email: "",
    addrStreet: "",
    addrCity: "",
    addrState: "",
    addrZip: ""
  },
  perm: undefined,
  compNum: undefined,
  KindofLoss: {},
  ATLAS_CLAIM_REPRESENTATIVE: {}
});

export const baseTransaction = (): Partial<ITransaction> => ({
  createdBy: "",
  transactionPolicyNumber: undefined,
  policyPrefix: "",
  status: "",
  onFiserv: false,
  customerName: "",
  transactionId: undefined,
  transactionDate: new Date(),
  timeZone: "",
  sessionId: "",
  paymentCategory: "",
  lastFourDigits: undefined,
  paymentAmount: undefined,
  holderZip: undefined,
  totalAmount: undefined,
  convenienceFee: undefined,
  clientReferenceData: "",
  cardType: "",
  cardExpirationYear: undefined,
  cardExpirationMonth: undefined,
  batchNumber: undefined,
  authCode: "",
  tokenId: "",
  transactionDescription: "",
  mode: "",
  responseMessage: ""
});

export const baseMortgage = (): Partial<IMortgage> => ({
  mortgageName: "",
  shortName: "",
  AICode: "",
  address: "",
  city: "",
  state: "",
  zipCode: ""
});

export const baseEndorsement = (): Partial<IEndorsement> => ({
  formNumber: "",
  formCode: "",
  formName: "",
  formUsage: undefined,
  formLabel: "",
  appliesTo: undefined,
  data: [],
  formTypes: [],
  companies: []
});

export const baseUnderWritingQuestion = (): Partial<IUnderWritingQuestion> => ({
  question: "",
  key: "",
  validResponse: "",
  companies: [],
  needsApprovalCode: undefined,
  additionalModalCheck: undefined,
  additionalModalMessage: "",
  policyType: undefined,
  errorMessage: ""
});

export const baseGbsHelp = (): Partial<IGbsHelp> => ({
  city: "",
  county: "",
  territoryInfo: {
    ppc: "",
    companyTerritories: []
  },
  place: "",
  countyCode: "",
  cityCode: "",
  zipCode: ""
});

export const gbsHelpCompanyTerritoriesDefault = {
  companyNumber: "",
  terr: "",
  seaTerr: "",
  windTerr: "",
  isSeaCoast: false
};

export const baseAtlasFileCategory = (): Partial<IAtlasFileCategory> => ({
  title: "",
  description: ""
});
