import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  BillingChangeRequestState,
  BillingChangeRequestMutations,
  StateKeys
} from "./types";
import { IBillingChangeRequest } from "@/helpers/storageFormBaseInterface";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { defaultState } from "./state";

export const mutations: DefineMutations<
  BillingChangeRequestMutations,
  BillingChangeRequestState
> = {
  [types.SET_STATE]: GenericSetState<BillingChangeRequestState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<BillingChangeRequestState>(),
  [types.SET_EDIT]: GenericSetEdit<
    BillingChangeRequestState,
    string | IBillingChangeRequest
  >("billingChangeRequests", (data: any) => ({ ...data })),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    BillingChangeRequestState,
    keyof IBillingChangeRequest
  >(),
  [types.SET_ITEM]: GenericSetItem<
    BillingChangeRequestState,
    IBillingChangeRequest
  >("billingChangeRequests", (data: any) => ({ ...data })), //TODO: WRITE A getXDefaultsFunction and pass it here in place of the anonymous function
  [types.DELETE_ITEM]: GenericDeleteItem<BillingChangeRequestState>(
    "billingChangeRequests",
    "_id"
  ),
  [types.SET_EDIT_BILLING_CHANGE_REQUEST]: GenericSetEditField<
    BillingChangeRequestState,
    keyof IBillingChangeRequest
  >(),
  [types.SET_INSURED_ADDRESS]: (state, payload) => {
    if (state.editing) {
      state.editing.data.insured = {
        ...state.editing.data.insured,
        ...payload
      };
    }
  },
  [types.CLEAR_BILLING_CHANGE_REQUEST_STORE]: GenericClearStore<
    BillingChangeRequestState
  >(defaultState())
};
