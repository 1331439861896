
import Vue from "vue";
import { ISelectFieldOption } from "@/types";
import { userMapGetters } from "@/store/modules/user";

export default Vue.extend({
  name: "PermissionsEditor",
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      preamble: ""
    };
  },
  methods: {
    isChecked(option: ISelectFieldOption): boolean {
      return !this.value.includes(option.value);
    },
    itemCheckedHandler(option: ISelectFieldOption, checked: boolean): void {
      const { value } = option;

      if (checked && !this.isChecked(option)) {
        this.$emit(
          "change",
          this.value.filter((val: any) => val !== value)
        );
      } else {
        this.$emit("change", [...this.value, value]);
      }
    }
  },
  computed: {
    ...userMapGetters(["getEditingUser"])
  }
});
