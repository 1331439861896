export const focusInputFields = (ref: any) => {
  if (ref) {
    const input = (ref as Vue).$el.querySelector("input");
    const textArea = (ref as Vue).$el.querySelector("textarea");
    if (input) {
      input.focus();
    }
    if (textArea) {
      textArea.focus();
    }
  }
};
