
import Vue from "vue";
import FormBlockRenderer from "./FormBlockRenderer.vue";
import { FormBlock, FormBuilderButtons } from "./types";
import { validateSchema, SchemaValidationData } from "@/helpers/validateSchema";

export default Vue.extend({
  name: "formBuilderWrapper",
  inheritAttrs: false,
  components: {
    FormBlockRenderer
  },
  props: {
    schemas: {
      type: Array as Vue.PropType<FormBlock[]>,
      required: false,
      default: () => []
    },
    formValues: {
      type: Object,
      required: true,
      default: () => ({})
    },
    formValueDefaults: {
      type: Object,
      required: false,
      default: () => ({})
    },
    dynamicOptions: {
      type: Object,
      required: false,
      default: () => ({})
    },
    buttons: {
      type: Array as Vue.PropType<FormBuilderButtons[]>,
      required: false,
      default: () => [] //TODO add validator func when stable
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    autocomplete: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    theFormValueDefaults(): any {
      //not reactive for some reason, we making it reactive here
      return this.formValueDefaults;
    }
  },
  methods: {
    validateFormFields(): SchemaValidationData {
      return validateSchema(this.schemas, this.formValues);
    }
  },
  watch: {
    formValues: {
      deep: true,
      immediate: true,
      handler() {
        const schemaValidation = validateSchema(this.schemas, this.formValues);
        this.$emit("validationChanged", {
          formIsValid: schemaValidation.isValid,
          fieldsWithErrors: Object.keys(schemaValidation.errors),
          fieldErrors: schemaValidation.errors,
          fieldLabels: schemaValidation.labels
        });
      }
    }
  }
});
