import { isExemptedUserRole } from "@/helpers";
import {
  isFRMKValidCreditAndLossInfo,
  allowSAFEUPCR
} from "@/helpers/generalHelpers";
import store from "@/store";
import { get } from "lodash";
import { Route } from "vue-router";

export const canAccessRouteQuoteStep = async (
  toRoute: Route,
  fromRoute: Route,
  next: any
): Promise<void> => {
  const { name = "", params } = toRoute;
  const { quoteId } = params;
  if (quoteId === "application") return next();
  try {
    const quote = await store.dispatch("quote/getQuote", quoteId);
    const hasAdminLevelPrivileges = isExemptedUserRole(
      store.getters["auth/getCurrentUser"].role
    );
    const scoreIndicatorFlag = get(
      quote,
      "creditInformation.data.scoreIndicatorFlag",
      ""
    );

    const hasValidLossInformation =
      quote?.ratingValidations?.hasValidLossInformation;

    const veriskUnderwritersCode =
      get(quote, "quoteApplication.underwritersCode", "") || "";

    switch (name) {
      case "territory":
        if (!quote.ratingValidations.hasValidRiskAddress) {
          next(`/quotes/${quoteId}/risk-address`);
        }
        break;
      case "property-rating":
        if (!quote.ratingValidations.hasTerritoryInformation) {
          next(`/quotes/${quoteId}/territory`);
        }
        break;
      case "property-ratings": {
        if (
          quote.ratingValidations &&
          quote.ratingValidations.canRateQuote &&
          quote.status === "draft"
        ) {
          next(`/quotes/${quoteId}/property-rating`);
        }
        break;
      }
      case "underwriting-qualifications":
        if (!quote.ratingSelected) {
          next(`/quotes/${quoteId}/property-ratings`);
        }
        break;
      case "rating-application":
        if (!quote.ratingValidations.hasUnderwritingResponses) {
          next(`/quotes/${quoteId}/underwriting-qualifications`);
        }

        if (
          scoreIndicatorFlag === "DP" ||
          (quote.ratingValidations.hasCreditRating &&
            ["D", "2", "4", "5", "C", "X", "N", "M"].includes(
              scoreIndicatorFlag
            ) &&
            veriskUnderwritersCode == "")
        ) {
          next();
        } else if (
          isFRMKValidCreditAndLossInfo(quote) ||
          allowSAFEUPCR(quote) ||
          (quote.ratingValidations.hasCreditRating && !hasAdminLevelPrivileges)
        ) {
          next(`/quotes/${quoteId}/loss-report`);
        }
        break;
      case "loss-report":
        if (!quote.ratingValidations.hasCreditRating) {
          next(`/quotes/${quoteId}/rating-application`);
        }
        if (hasValidLossInformation && !hasAdminLevelPrivileges) {
          next(`/quotes/${quoteId}/remarks-and-mortgages`);
        }
        break;
      case "remarks-and-mortgages":
        if (!hasValidLossInformation) {
          next(`/quotes/${quoteId}/loss-report`);
        }
        break;
      default:
        next();
        break;
    }
  } catch (error) {
    //letting it proceed since the error will be handled there
    next();
  }
  next();
};
