import Vue from "vue";
import { contactUsMenu, FAQMenu } from "./menuItemsUser";

export const dataEntryPrimaryMenu = () => [
  {
    to: "/users",
    text: Vue.prototype.__getText("menu", "usersLinkText"),
    description: "manage users"
  },
  {
    to: "/agencies",
    text: "Agencies"
  },
  {
    to: "/users",
    text: "Admin",
    description: "General Configs",
    subLinks: [
      { to: "/imports", text: "Imports" },
      { to: "/mortgages", text: "Mortgages" }
    ]
  },
  {
    to: "/documents",
    permission: "documents:read",
    text: Vue.prototype.__getText("menu", "documentsLinkText"),
    description: "View Documents",
    icon: "folders",
    subLinks: [
      {
        permission: "agent_reports:read",
        to: "/agent-reports",
        text: "Agent Reports"
      },
      {
        to: "/documents",
        text: Vue.prototype.__getText("menu", "documentsLinkText"),
        description: "browse documents"
      }
    ]
  },
  {
    to: "/Claims",
    text: "Claims",
    description: "Manage claims",
    icon: "folders",
    subLinks: [
      {
        to: "/claims",
        text: "Claims"
      },
      {
        to: "/claims-lookup",
        text: "Claims Status Lookup"
      },
      {
        to: "/file-claim",
        text: "File a Claim"
      }
    ]
  },
  {
    to: "/policy-inquiry",
    text: "Policy",
    description: "Policy Inquiry",
    icon: "",
    subLinks: [
      {
        to: "/billing",
        text: "Billing Inquiry",
        description: "Get billing information about policies",
        icon: ""
      },
      {
        to: "/quickbillpay",
        text: "Bill Pay",
        description: "Pay bills",
        icon: ""
      },
      {
        to: "/change-requests",
        text: Vue.prototype.__getText("menu", "changeRequestLinkText"),
        description: "Manage change requests",
        icon: "exchange"
      },
      {
        to: "/policy-inquiry",
        text: "Policy Inquiry"
      }
    ]
  },
  {
    to: "/quotes",
    text: "Quotes",
    description: "Manage Quotes",
    icon: "folders",
    subLinks: [
      {
        to: "/personal-liability-policies",
        text: "Personal Liability Application",
        description: "Personal Liability Policy application"
      },
      {
        to: "/quotes",
        text: "Quotes"
      }
    ]
  },
  contactUsMenu,
  FAQMenu
];
