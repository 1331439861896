import defaultsDeep from "lodash/defaultsDeep";
import cloneDeep from "lodash/cloneDeep";
import { baseTransaction } from "@/helpers/defaultObjects";
import { ITransaction } from "@/store/modules/transactions/types";

export function getTransactionDefaults(
  transaction: Partial<ITransaction>
): ITransaction {
  return defaultsDeep(
    cloneDeep(transaction),
    Object.assign({}, baseTransaction())
  );
}
