import BaseService from "./base.service";
import { ResponseWrapper } from "@/services/response_wrapper";
import * as axios from "axios";
import { getPathAPI } from "@/helpers";

export default class BaseCollectionService extends BaseService {
  public collection: string;
  public baseUrl = getPathAPI();
  public url: string;

  constructor(collection: string) {
    super();
    this.collection = collection;
    this.url = `${this.baseUrl}/${this.collection}`;
  }

  /**
   * GET baseURL/collection/:id
   * to fetch a member of the collection if exists
   * @param id
   * @return collectionType | null
   */
  public getOne = async (id: string): Promise<ResponseWrapper> => {
    //will query collection for an item
    const options = {
      useToken: "access"
    };
    const requestConfig: axios.AxiosRequestConfig = {
      url: `${this.url}/${id}`,
      method: "get"
    };
    return await this.request(requestConfig, options);
  };

  /**
   * GET baseURL/collection?query+params
   * to fetch all qualifying member of the collection
   * @param query
   * @return collectionType[]
   */
  public async getMany(query: any): Promise<ResponseWrapper> {
    const options = {
      useToken: "access"
    };
    const requestConfig: axios.AxiosRequestConfig = {
      url: `${this.url}`,
      method: "get",
      params: query
    };
    return await this.request(requestConfig, options);
  }

  /**
   * PUT baseURL/collection with updates
   * to update collection member with specified ID
   * @param id
   * @param updates
   * @returns collectionType | error
   */
  public async updateOne(id: string, updates: any): Promise<ResponseWrapper> {
    const options = {
      useToken: "access"
    };
    const requestConfig: axios.AxiosRequestConfig = {
      url: `${this.url}/${id}`,
      method: "put",
      data: updates
    };
    return await this.request(requestConfig, options);
  }

  /**
   * POST baseURL/collection
   * with data to create new member
   * @param payload
   * @returns collectionType | error
   */
  public async createOne(payload: any): Promise<ResponseWrapper> {
    const options = {
      useToken: "access"
    };
    const requestConfig: axios.AxiosRequestConfig = {
      url: `${this.url}`,
      method: "post",
      data: payload
    };
    return await this.request(requestConfig, options);
  }

  /**
   * DELETE baseURL/collection/:id
   * Deletes item in collection if exists
   * @param id
   * @returns true | false to indicate success
   */
  public async deleteOne(id: string): Promise<ResponseWrapper> {
    const options = {
      useToken: "access"
    };
    const requestConfig: axios.AxiosRequestConfig = {
      url: `${this.url}/${id}`,
      method: "delete"
    };
    return this.request(requestConfig, options);
  }
  // APPROVE AGENT APPLICATION
  public async approve(id: string, updates: any): Promise<ResponseWrapper> {
    const options = {
      useToken: "access"
    };
    const requestConfig: axios.AxiosRequestConfig = {
      url: `${this.url}/${id}/approve`,
      method: "put",
      data: updates
    };
    return this.request(requestConfig, options);
  }
}
