import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { FaqState, FaqMutations, StateKeys, IFAQ } from "./types";
import {
  GenericClearStore,
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates
} from "@/store/helpers";
import { defaultState } from "./state";
import { faqDefault } from "@/helpers/faqDefault";

export const mutations: DefineMutations<FaqMutations, FaqState> = {
  [types.SET_STATE]: GenericSetState<FaqState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<FaqState>(),
  [types.SET_EDIT]: GenericSetEdit<FaqState, string | Partial<IFAQ>>(
    "faqs",
    faqDefault
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<FaqState, keyof IFAQ>(),
  [types.SET_ITEM]: GenericSetItem<FaqState, IFAQ>("faqs", faqDefault),
  [types.DELETE_ITEM]: GenericDeleteItem<FaqState>("faqs", "_id"),
  [types.CLEAR_FAQ_STORE]: GenericClearStore<FaqState>(defaultState())
};
