
import Vue from "vue";
import { capitalize } from "lodash";

export default Vue.extend({
  name: "UserSearchOptionComponent",
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    capitalizeText(text: string): string {
      return capitalize(text);
    }
  }
});
