import { SettingState } from "./types";

export const defaultState = (): SettingState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    settings: [],
    links: {}
  };
};

export const state = defaultState();
