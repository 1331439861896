import { DefineGetters } from "vuex-type-helper";
import {
  UnderWritingQuestionGetters,
  UnderWritingQuestionState
} from "./types";

export const getters: DefineGetters<
  UnderWritingQuestionGetters,
  UnderWritingQuestionState
> = {
  getUnderWritingQuestions: (state: UnderWritingQuestionState) =>
    state.underWritingQuestions,
  getUnderWritingQuestionById: (state: UnderWritingQuestionState) => (
    id: string
  ) => state.underWritingQuestions.find(q => q.id === id),
  getTotalRecords: ({
    links,
    underWritingQuestions
  }: UnderWritingQuestionState) =>
    links && links.totalRecords
      ? links.totalRecords
      : underWritingQuestions.length,
  getUnderWritingQuestionLinks: (state: UnderWritingQuestionState) =>
    state.links
};
