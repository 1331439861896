import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  ClaimsState,
  ClaimsMutations,
  StateKeys,
  IClaims,
  IClaimsFiservData
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import {
  getClaimsDefaults,
  getClaimsFiservDataDefault
} from "@/helpers/claims";
import { defaultState } from "./state";

export const mutations: DefineMutations<ClaimsMutations, ClaimsState> = {
  [types.SET_STATE]: GenericSetState<ClaimsState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<ClaimsState>(),
  [types.SET_EDIT]: GenericSetEdit<ClaimsState, string | IClaims>(
    "claims",
    getClaimsDefaults
  ),
  [types.SET_CLAIMS_FISERV_DATA]: GenericSetItem<
    ClaimsState,
    IClaimsFiservData
  >("claims", getClaimsFiservDataDefault),
  [types.SET_EDIT_FIELD]: GenericSetEditField<ClaimsState, keyof IClaims>(),
  [types.SET_ITEM]: GenericSetItem<ClaimsState, IClaims>(
    "claims",
    getClaimsDefaults
  ),
  [types.DELETE_ITEM]: GenericDeleteItem<ClaimsState>("claims", "_id"),
  [types.CLEAR_CLAIMS_STORE]: GenericClearStore<ClaimsState>(defaultState())
};
