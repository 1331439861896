import { AtlasFileCategoryState } from "./types";

export const defaultState = (): AtlasFileCategoryState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    atlasFileCategories: [],
    links: {}
  };
};

export const state = defaultState();
