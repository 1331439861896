
import Vue from "vue";
export default Vue.extend({
  name: "custom-tax-id-field",
  props: {
    value: {
      type: String,
      default: ""
    },
    taxIdMaskType: {
      type: String,
      required: false
    },
    defaultTaxIdMask: {
      type: String,
      default: "SSN",
      validator: (val: any) => {
        return val === "SSN" || val === "EIN";
      }
    },
    fieldGroupClass: {
      type: String,
      default: "pb-0"
    },
    required: {
      type: Boolean,
      default: true,
      required: false
    },
    label: {
      type: String,
      default: "Tax ID",
      required: false
    },
    showMaskSelector: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
