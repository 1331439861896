
import { logoPath } from "@/helpers/generalHelpers";
import { version } from "../../../package.json";
import Vue from "vue";

interface ILoadingData {
  version: string;
}

export default Vue.extend({
  name: "AppLoading",
  data(): ILoadingData {
    return { version };
  },
  methods: {
    getLogoPath() {
      return logoPath();
    }
  }
});
