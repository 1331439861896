import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  SettingState,
  SettingGetters,
  SettingMutations,
  SettingActions
} from "./types";

const namespaced = true;
export const namespace = "setting";

export const {
  mapState: settingMapState,
  mapGetters: settingMapGetters,
  mapMutations: settingMapMutations,
  mapActions: settingMapActions
} = createNamespacedHelpers<
  SettingState,
  SettingGetters,
  SettingMutations,
  SettingActions
>(namespace);

export const setting: Module<SettingState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
