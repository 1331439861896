import {
  AgentApplicationsActions,
  AgentApplicationsState,
  AgentApplicationsMutations,
  AgentApplicationsGetters,
  IAgentApplications
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import AgentApplicationsService from "@/services/agentApplications.service";

export const actions: DefineActions<
  AgentApplicationsActions,
  AgentApplicationsState,
  AgentApplicationsMutations,
  AgentApplicationsGetters
> = {
  createAgentApplications: GenericCreateOne<
    AgentApplicationsState,
    AgentApplicationsGetters,
    AgentApplicationsActions,
    AgentApplicationsMutations,
    IAgentApplications
  >(AgentApplicationsService, types.SET_ITEM as any),
  getAgentApplications: GenericGetMany<
    AgentApplicationsActions,
    AgentApplicationsState,
    AgentApplicationsMutations,
    AgentApplicationsGetters
  >(AgentApplicationsService, "agentApplications", types.SET_ITEM as any),
  getAgentApplication: GenericGetOne<
    AgentApplicationsActions,
    AgentApplicationsState,
    AgentApplicationsMutations,
    AgentApplicationsGetters
  >(AgentApplicationsService, types.SET_ITEM as any),
  deleteAgentApplications: GenericDeleteOne<
    AgentApplicationsState,
    AgentApplicationsGetters,
    AgentApplicationsActions,
    AgentApplicationsMutations
  >(AgentApplicationsService, types.DELETE_ITEM as any),
  updateAgentApplications: GenericUpdateOne<
    AgentApplicationsState,
    AgentApplicationsGetters,
    AgentApplicationsActions,
    AgentApplicationsMutations,
    { id: string; update: Partial<IAgentApplications> }
  >(AgentApplicationsService, types.SET_ITEM as any),
  // approve agent application
  async approveAgentApplication({ commit }: any, { id, update }) {
    try {
      commit(types.SET_STATES, { makingApiRequest: true });
      const { data } = await AgentApplicationsService.approve(id, update);
      commit(types.SET_STATE, { key: "lastUpdate", value: new Date() });
      commit(
        types.SET_STATE as any,
        { key: "editingApprove", value: data } as any
      );
      return data;
    } finally {
      commit(types.SET_STATES, { makingApiRequest: false });
    }
  },
  printAgentApplication: async ({ commit }, payload) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await AgentApplicationsService.printAgentApplicationById(
        payload
      );
      const responseData = response.data;
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
};
