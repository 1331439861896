import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  ChartState,
  ChartGetters,
  ChartMutations,
  ChartActions
} from "./types";

const namespaced = true;
export const namespace = "chart";

export const {
  mapState: chartMapState,
  mapGetters: chartMapGetters,
  mapMutations: chartMapMutations,
  mapActions: chartMapActions
} = createNamespacedHelpers<
  ChartState,
  ChartGetters,
  ChartMutations,
  ChartActions
>(namespace);

export const chart: Module<ChartState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
