import { AppActions, AppState, AppMutations, AppGetters } from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";

export const actions: DefineActions<
  AppActions,
  AppState,
  AppMutations,
  AppGetters
> = {
  async appHandleResize({ commit, state }) {
    commit("SET_STATE", { key: "isResizing", value: true });
    clearTimeout(state.resizeTimer);
    commit("SET_STATE", {
      key: "resizeTimer",
      value: setTimeout(function() {
        commit("SET_STATE", { key: "isResizing", value: false });
      }, 250)
    });

    const width = window.innerWidth;
    const height = window.innerHeight;
    commit("SET_WINDOW", { width, height });

    if (width <= 640) {
      commit("SET_STATE", { key: "screenSize", value: "sm" });
    } else if (width <= 768) {
      commit("SET_STATE", { key: "screenSize", value: "md" });
    } else if (width <= 1024) {
      commit("SET_STATE", { key: "screenSize", value: "lg" });
    } else if (width <= 1280) {
      commit("SET_STATE", { key: "screenSize", value: "xl" });
    } else {
      commit("SET_STATE", { key: "screenSize", value: "xxl" });
    }
  },
  async appHandleScroll({ commit }, scrollEvent) {
    const { scrollTop = 0 } = scrollEvent.target as HTMLElement;
    commit("SET_SCROLL_TOP", scrollTop);
  },
  async refreshItemStore({ dispatch }, payload) {
    const { id, type } = payload;
    try {
      switch (type) {
        case "uploads":
          await dispatch("uploads/getUpload", id, { root: true });
          break;
        case "documents":
          await dispatch("document/getDocument", id, { root: true });
          break;
      }
    } catch (e) {
      /* Do Nothing */
    }
  },
  async appLastActivity({ commit }) {
    commit(types.SET_STATE, { key: "lastActivity", value: new Date() });
  }
};
