import { RouteConfig } from "vue-router";
import { IRouterConfig } from "@/types";

import userRoutes from "./user";
import adminRoutes from "./admin";
import publicRoutes from "./public";
import sharedRoutes from "./shared";
import personalLiabilityPolicyRoutes from "./admin/personalLiabilityPolicyRoutes";
import { redirectHomeRoute } from "../middleware";

const wildcardRoute: IRouterConfig = {
  path: "*",
  component: () => import("@/views/error/404.vue"),
  meta: {
    requireAuth: false
  }
};

const rootRoute: IRouterConfig = {
  path: "/",
  component: () =>
    import(/* webpackChunkName: "Dashboard" */ "@/views/user/Entry.vue"),
  meta: {
    requireAuth: true,
    allowedRoles: [
      "admin",
      "agent",
      "ezlynx",
      "agencyAdmin",
      "adjuster",
      "claims",
      "dataEntry",
      "subAdmin",
      "underwriting",
      "billing"
    ],
    beforeResolve: redirectHomeRoute
  }
};

const routers: RouteConfig[] = [
  ...personalLiabilityPolicyRoutes,
  ...sharedRoutes,
  ...publicRoutes,
  ...adminRoutes,
  ...userRoutes,
  wildcardRoute,
  rootRoute
];

export default routers;
