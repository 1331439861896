<template>
  <div>
    <transition name="fade">
      <div class="site-inner">
        <div class="wrap">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {};
</script>
