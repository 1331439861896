import { DefineMutations } from "vuex-type-helper";
import { SocketIOState, SocketIOMutations } from "./types";

export const mutations: DefineMutations<SocketIOMutations, SocketIOState> = {
  SOCKET_CONNECT(): void {
    //do nothing
  },
  SOCKET_DISCONNECT(state: SocketIOState): void {
    state.authenticated = false;
  },
  SOCKET_AUTHENTICATED(state: SocketIOState): void {
    state.authenticated = true;
  }
};
