import {
  IAccreditedBuilder,
  AccreditedBuilderActions,
  AccreditedBuilderState,
  AccreditedBuilderMutations,
  AccreditedBuilderGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericDeleteOne,
  GenericUpdateOne,
  GenericGetOne
} from "@/store/helpers/actions";
import AccreditedBuilderService from "@/services/accreditedBuilder.service";

export const actions: DefineActions<
  AccreditedBuilderActions,
  AccreditedBuilderState,
  AccreditedBuilderMutations,
  AccreditedBuilderGetters
> = {
  createAccreditedBuilder: GenericCreateOne<
    AccreditedBuilderState,
    AccreditedBuilderGetters,
    AccreditedBuilderActions,
    AccreditedBuilderMutations,
    IAccreditedBuilder
  >(AccreditedBuilderService, types.SET_ITEM as any),
  getAccreditedBuilder: GenericGetOne<
    AccreditedBuilderState,
    AccreditedBuilderGetters,
    AccreditedBuilderActions,
    AccreditedBuilderMutations
  >(AccreditedBuilderService, types.SET_ITEM),
  getAccreditedBuilders: GenericGetMany<
    AccreditedBuilderState,
    AccreditedBuilderGetters,
    AccreditedBuilderActions,
    AccreditedBuilderMutations
  >(AccreditedBuilderService, "accreditedBuilders", types.SET_ITEM as any),
  deleteAccreditedBuilder: GenericDeleteOne<
    AccreditedBuilderState,
    AccreditedBuilderGetters,
    AccreditedBuilderActions,
    AccreditedBuilderMutations
  >(AccreditedBuilderService, types.DELETE_ITEM as any),
  updateAccreditedBuilder: GenericUpdateOne<
    AccreditedBuilderState,
    AccreditedBuilderGetters,
    AccreditedBuilderActions,
    AccreditedBuilderMutations,
    { id: string; update: Partial<IAccreditedBuilder> }
  >(AccreditedBuilderService, types.SET_ITEM as any)
};
