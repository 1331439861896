
import Vue from "vue";
export default Vue.extend({
  name: "custom-form-types-field",
  props: {
    showInputLabel: {
      type: Boolean,
      required: false,
      default: true
    },
    labelClass: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Select"
    },
    value: {
      type: [Number, String, Array, Boolean, Object],
      default: ""
    },
    options: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    allowFirstOption: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    labelKey: {
      type: [String, Array],
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    showFieldAs: {
      type: String,
      default: ""
    },
    remote: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    remoteMethod: {
      type: Function,
      default: () => {}
    },
    fieldGroupClass: {
      type: String,
      default: "pb-12"
    },
    optionComponent: {
      type: Function,
      required: false
    },
    optionHeight: {
      type: String,
      required: false,
      default: "34px"
    },
    size: {
      type: String,
      required: false
    },
    disabledOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    allowCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    selectFieldClass: {
      type: String,
      required: false,
      default: ""
    },
    fixedLabels: {
      type: Boolean,
      required: false,
      default: false
    },
    lineOfBusiness: {
      type: String,
      required: true,
      default: ""
    }
  },
  methods: {
    getFormTypeName(value: string): string {
      const matched: any = this.options.find(
        (option: any) => option.value === value
      );
      return matched.label;
    },
    change(newValues: any[]): void {
      let newFormTypes = [];
      for (let value of newValues) {
        newFormTypes.push({
          formName: this.getFormTypeName(value),
          value: value
        });
      }
      this.$emit("change", {
        value: newFormTypes,
        lineOfBusiness: this.lineOfBusiness
      });
    }
  },
  computed: {
    valueFormatted(): string[] {
      let result = [];
      if (this.value.length > 0 && typeof this.value[0] === "object") {
        for (let value of this.value) {
          if (value.lineOfBusiness === this.lineOfBusiness) {
            for (let formType of value.formTypes) {
              result.push(formType.value);
            }
          }
        }
      } else {
        result = this.value;
      }

      return result;
    }
  }
});
