import { AgentApplicationsState } from "./types";

export const defaultState = (): AgentApplicationsState => {
  return {
    editing: null,
    makingApiRequest: false,
    lastUpdate: new Date(),
    agentApplications: [],
    links: {},
    editingApprove: null
  };
};

export const state = defaultState();
