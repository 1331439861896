import {
  TransactionsActions,
  TransactionsState,
  TransactionsMutations,
  TransactionsGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import TransactionsService from "@/services/transactions.service";
import { GenericGetMany, GenericGetOne } from "@/store/helpers/actions";

export const actions: DefineActions<
  TransactionsActions,
  TransactionsState,
  TransactionsMutations,
  TransactionsGetters
> = {
  getOneTransaction: GenericGetOne<
    TransactionsState,
    TransactionsGetters,
    TransactionsActions,
    TransactionsMutations
  >(TransactionsService, "transactions", types.SET_ITEM as any),
  getTransactions: GenericGetMany<
    TransactionsState,
    TransactionsGetters,
    TransactionsActions,
    TransactionsMutations
  >(TransactionsService, "transactions", types.SET_ITEM)
};
