import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  QuoteCountyValidationState,
  QuoteCountyValidationGetters,
  QuoteCountyValidationMutations,
  QuoteCountyValidationActions
} from "./types";

const namespaced = true;
export const namespace = "quoteCountyValidation";

export const {
  mapState: quoteCountyValidationMapState,
  mapGetters: quoteCountyValidationMapGetters,
  mapMutations: quoteCountyValidationMapMutations,
  mapActions: quoteCountyValidationMapActions
} = createNamespacedHelpers<
  QuoteCountyValidationState,
  QuoteCountyValidationGetters,
  QuoteCountyValidationMutations,
  QuoteCountyValidationActions
>(namespace);

export const quoteCountyValidation: Module<QuoteCountyValidationState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
