var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('custom-alert',{attrs:{"title":"An Error Occurred","message":_vm.error,"show":!!_vm.error,"type":"danger"},on:{"dismiss":function($event){_vm.error = ''}}}),_c('div',[_c('div',{staticClass:"flex mb-12"},_vm._l((_vm.options),function(option,index){return _c('RadioBlockField',{key:`radio-claim-lookup-type-${index}`,staticClass:"mb-4 sm:mb-0",class:{
          'mr-0 sm:mr-4': index !== _vm.options.length - 1
        },attrs:{"active":option.value === _vm.lookupMethod,"value":option.value,"label":option.label,"displayDirection":"horizontal"},on:{"input":function($event){_vm.lookupMethod = $event}}})}),1),(_vm.lookupMethod === 'policyNumber')?_c('div',{staticClass:"flex flex-col sm:flex-row sm:space-x-4 w-full"},[_c('SelectField',{staticClass:"w-full sm:w-1/2",attrs:{"label":"Policy Prefix","focusOnMount":true,"options":_vm.policyPrefixes,"required":true,"selected":_vm.policyPrefix,"placeholder":"SPHO","filterable":true},on:{"change":function($event){_vm.policyPrefix = $event}}}),_c('TextField',{staticClass:"w-full sm:w-1/2",attrs:{"label":"Policy Number","placeholder":"87963","type":"text","required":true,"value":_vm.policyNumber,"validations":[
          { method: 'integer', error: 'Please enter numbers only' }
        ]},on:{"input":function($event){_vm.policyNumber = $event}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.hasValidLookupInformation ? _vm.processClaimsLookup() : () => {}}}})],1):_vm._e(),(_vm.lookupMethod === 'claimNumber')?_c('div',[_c('TextField',{staticClass:"w-full sm:w-1/2",attrs:{"label":"Claim Number","placeholder":"70686","type":"text","focusOnMount":true,"required":true,"value":_vm.claimNumber,"validations":[
          { method: 'integer', error: 'Please enter numbers only' }
        ]},on:{"input":function($event){_vm.claimNumber = $event}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.hasValidLookupInformation ? _vm.processClaimsLookup() : () => {}}}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }