import {
  IAdjusterfile,
  AdjusterfileActions,
  AdjusterfileState,
  AdjusterfileMutations,
  AdjusterfileGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import AdjusterfileService from "@/services/adjusterfile.service";

export const actions: DefineActions<
  AdjusterfileActions,
  AdjusterfileState,
  AdjusterfileMutations,
  AdjusterfileGetters
> = {
  createAdjusterfile: GenericCreateOne<
    AdjusterfileState,
    AdjusterfileGetters,
    AdjusterfileActions,
    AdjusterfileMutations,
    IAdjusterfile
  >(AdjusterfileService, types.SET_ITEM as any),
  getAdjusterfiles: GenericGetMany<
    AdjusterfileState,
    AdjusterfileGetters,
    AdjusterfileActions,
    AdjusterfileMutations
  >(AdjusterfileService, "adjusterfiles", types.SET_ITEM as any),
  getAdjusterfile: GenericGetOne<
    AdjusterfileState,
    AdjusterfileGetters,
    AdjusterfileActions,
    AdjusterfileMutations
  >(AdjusterfileService, types.SET_ITEM as any),
  deleteAdjusterfile: GenericDeleteOne<
    AdjusterfileState,
    AdjusterfileGetters,
    AdjusterfileActions,
    AdjusterfileMutations
  >(AdjusterfileService, types.DELETE_ITEM as any),
  updateAdjusterfile: GenericUpdateOne<
    AdjusterfileState,
    AdjusterfileGetters,
    AdjusterfileActions,
    AdjusterfileMutations,
    { id: string; update: Partial<IAdjusterfile> }
  >(AdjusterfileService, types.SET_ITEM as any),
  async exportAdjusterFile({ commit }, payload) {
    commit(types.SET_STATES, { makingApiRequest: true });
    await AdjusterfileService.exportAdjusterFile(payload);
    commit(types.SET_STATES, { makingApiRequest: false });
  }
};
