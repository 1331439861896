import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  AdjusterfileState,
  AdjusterfileMutations,
  StateKeys,
  IAdjusterfile
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { defaultState } from "./state";

export const mutations: DefineMutations<
  AdjusterfileMutations,
  AdjusterfileState
> = {
  [types.SET_STATE]: GenericSetState<AdjusterfileState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<AdjusterfileState>(),
  [types.SET_EDIT]: GenericSetEdit<AdjusterfileState, string | IAdjusterfile>(
    "adjusterfiles",
    (data: any) => ({ ...data })
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    AdjusterfileState,
    keyof IAdjusterfile
  >(),
  [types.SET_ITEM]: GenericSetItem<AdjusterfileState, IAdjusterfile>(
    "adjusterfiles",
    (data: any) => ({ ...data })
  ), //TODO: WRITE A getXDefaultsFunction and pass it here in place of the anonymous function
  [types.DELETE_ITEM]: GenericDeleteItem<AdjusterfileState>(
    "adjusterfiles",
    "_id"
  ),
  [types.CLEAR_ADJUSTERFILE_STORE]: GenericClearStore<AdjusterfileState>(
    defaultState()
  )
};
