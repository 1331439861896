
import Vue from "vue";
import { getPathAPI } from "@/helpers";

export default Vue.extend({
  name: "DataView",
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      accessToken: localStorage.getItem("access")
    };
  },
  methods: {
    url() {
      return getPathAPI();
    }
  },
  computed: {}
});
