import EmergencyService from "@/services/emergency.service";
import { DefineActions } from "vuex-type-helper";
import {
  EmergencyActions,
  EmergencyGetters,
  EmergencyMutations,
  EmergencyState
} from "./types";
import * as types from "@/store/mutation-types";

export const actions: DefineActions<
  EmergencyActions,
  EmergencyState,
  EmergencyMutations,
  EmergencyGetters
> = {
  async checkEmergency({ commit }) {
    const response = await EmergencyService.check();
    commit(types.SET_STATE, { key: "emergencyData", value: response.data });
    return response.data;
  }
};
