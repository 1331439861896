import { transform, isEqual, isObject } from "lodash";
import dotObject from "dot-object";

export function fieldIsEmpty(data: any): boolean {
  if (Array.isArray(data)) return false;
  if (data instanceof Date) return false;
  if (data && typeof data === "object") {
    const objectIsEmpty = Object.keys(data).length === 0;
    if (objectIsEmpty) {
      const isDateValue = !isNaN(new Date(data).getTime());
      if (isDateValue) return false;
      else return true;
    }
  } else if (typeof data === "string") {
    return data.length === 0;
  }
  return data === null || data === undefined;
}

export function removeEmptyPaths(
  rawInput: Record<string, any> = {},
  allowEmpty: string[]
): Record<string, any> {
  const flattened = dotObject.dot(rawInput);
  for (const key of Object.keys(flattened)) {
    if (fieldIsEmpty(flattened[key]) && !allowEmpty.includes(key)) {
      delete flattened[key];
    }
  }
  return dotObject.object(flattened);
}

export function formatIfNumericString(value: any) {
  if (typeof value != "string") return value;
  if (!isNaN(value as any) && !isNaN(parseFloat(value)) && value) {
    return +value;
  }
  return value;
}

export function objectDifference(
  object: any,
  base: any,
  allowEmpty: string[] = []
) {
  function changes(object: any, base: any) {
    return transform(object, function(result: any, value, key) {
      if (
        !isEqual(formatIfNumericString(value), formatIfNumericString(base[key]))
      ) {
        if (Array.isArray(value) && Array.isArray(base[key])) {
          result[key] = value;
        } else {
          result[key] =
            isObject(value) && isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      }
    });
  }
  return removeEmptyPaths(changes(object, base), allowEmpty);
}
