import { DefineGetters } from "vuex-type-helper";
import { FaqGetters, FaqState, IFAQ } from "./types";

export const getters: DefineGetters<FaqGetters, FaqState> = {
  getFaqById: (state: FaqState) => (faqId: string) =>
    state.faqs.find((faq: IFAQ) => faq._id === faqId),
  editingFaq: (state: FaqState) => state.editing,
  faqs: (state: FaqState) => state.faqs,
  isLoading: (state: FaqState) => state.makingApiRequest,
  getFaqsLinks: (state: FaqState) => state.links,
  getTotalRecords: ({ links, faqs }: FaqState) =>
    links && links.totalRecords ? links.totalRecords : faqs.length
};
