import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  CompaniesState,
  CompaniesGetters,
  CompaniesMutations,
  CompaniesActions
} from "./types";

const namespaced = true;
export const namespace = "companies";

export const {
  mapState: companiesMapState,
  mapGetters: companiesMapGetters,
  mapMutations: companiesMapMutations,
  mapActions: companiesMapActions
} = createNamespacedHelpers<
  CompaniesState,
  CompaniesGetters,
  CompaniesMutations,
  CompaniesActions
>(namespace);

export const companies: Module<CompaniesState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
