import { DefineGetters } from "vuex-type-helper";
import {
  AtlasFileCategoryGetters,
  AtlasFileCategoryState,
  IAtlasFileCategory
} from "./types";

export const getters: DefineGetters<
  AtlasFileCategoryGetters,
  AtlasFileCategoryState
> = {
  getAtlasFileCategories: (state: AtlasFileCategoryState) =>
    state.atlasFileCategories,
  getAtlasFileCategoryById: (state: AtlasFileCategoryState) => (id: string) =>
    state.atlasFileCategories.find(
      (atlasFileCategory: IAtlasFileCategory) => atlasFileCategory._id === id
    ),
  getAtlasFileCategoryLinks: (state: AtlasFileCategoryState) => state.links,
  getTotalRecords: ({ links, atlasFileCategories }: AtlasFileCategoryState) =>
    links && links.totalRecords
      ? links.totalRecords
      : atlasFileCategories.length,
  editingAtlasFileCategory: (state: AtlasFileCategoryState) => state.editing
};
